import pt from "date-fns/locale/pt";
import React, { useContext, useEffect, useState } from "react";
import { Col, Modal, Row, Form, Button } from "react-bootstrap";
import { registerLocale } from "react-datepicker";
import { BsCheck2, BsChevronRight, BsX } from "react-icons/bs";

import { requiredFieldMsg } from "../../../helpers/messagesValidation";
import {
  useValidation,
  isInvalidFeedBack,
  feedBack,
} from "../../../hooks/Validation";
import { ActiveModalIntegrationContext } from "../AutomaticIntegrationContexts/ActiveModalIntegrationContext";
import { ModalGAutomaticIntegrationStyles } from "./styles";

export default function ModalEditConnectionSql() {
  const {
    setActiveModal,
    activeModal,
    setUpdateIntegration,
    server,
    database,
    user,
    password,
    aditionalConfig,
    setNewConnectionString,
  } = useContext(ActiveModalIntegrationContext);

  registerLocale("pt", pt);
  const [buttonDisable, setButtonDisable] = useState<boolean>(false);
  const [localServer, setLocalServer] = useState<any>()
  const [localDatabase, setLocalDatabase] = useState<any>()
  const [localUser, setLocalUser] = useState<any>()
  const [localPassword, setLocalPassword] = useState<any>()
  const [localAditionalConfig, setLocalAditionalConfig] = useState<any>()

  const [
    validationForm,
    setValidationFormField,
    validationErrors,
    setNewValidationErrors,
  ] = useValidation();

  function UpdateConnectionString() {
    setButtonDisable(true);
    const existErrorConnectionString = findErrorsServer();

    const partialCS =
      "Server=" +
      localServer +
      ";Database=" +
      localDatabase +
      ";User Id=" +
      localUser +
      ";Password=" +
      localPassword +
      ";" +
      localAditionalConfig;
    setNewConnectionString(partialCS);

    if (!existErrorConnectionString) {
      setButtonDisable(false);
      setActiveModal(0);
      setUpdateIntegration(true);
    }
    setButtonDisable(false);
  }

  useEffect(() => {
    updateValidation();
    function updateValidation() {
      validationForm.current = {
        server,
        database,
        user,
        password,
        aditionalConfig,
      };
    }
  }, [server, database, user, password, aditionalConfig]);

  useEffect(() => {
    if (activeModal === 10) {
      setLocalServer(server)
      setLocalDatabase(database)
      setLocalUser(user)
      setLocalPassword(password)
      setLocalAditionalConfig(aditionalConfig)
    }
  }, [activeModal])

  function handleClearAllStates() {
    setLocalServer(server)
    setLocalDatabase(database)
    setLocalUser(user)
    setLocalPassword(password)
    setLocalAditionalConfig(aditionalConfig)
    setActiveModal(0)
  }

  function findErrorsServer() {
    const errors: any = {};

    if (validationForm.current) {
      if (
        !validationForm.current.server ||
        validationForm.current.server === ""
      )
        errors.server = requiredFieldMsg;
      else errors.server = undefined;
      if (
        !validationForm.current.database ||
        validationForm.current.database === ""
      )
        errors.database = requiredFieldMsg;
      else errors.database = undefined;
      if (!validationForm.current.user || validationForm.current.user === "")
        errors.user = requiredFieldMsg;
      else errors.user = undefined;
      if (
        !validationForm.current.password ||
        validationForm.current.password === ""
      )
        errors.password = requiredFieldMsg;
      else errors.password = undefined;
    }
    const result = setNewValidationErrors(errors);
    return result;
  }

  return (
    <ModalGAutomaticIntegrationStyles
      show={activeModal === 10}
      onHide={handleClearAllStates}
    >
      <Modal.Header>
        <Modal.Title>
          <h2>
            Integração via Banco <BsChevronRight size={18} /> Dados de acesso ao
            banco
          </h2>
        </Modal.Title>
        <Button
          className="cursor-pointer"
          variant="white"
          onClick={handleClearAllStates}
        >
          <BsX color="var(--bs-modal-color)" size={28} />
        </Button>
      </Modal.Header>
      <Modal.Body className="step-1">
        <Row>
          <Col>
            <h3>
              Informe os dados que devemos utilizar para acessar o seu banco de
              dados{" "}
            </h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <h4>Servidor e banco</h4>
          </Col>
        </Row>
        <Form.Group as={Row}>
          <Form.Label column sm={12}>
            Servidor*
          </Form.Label>
          <Col sm={12}>
            <Form.Control
              size="sm"
              defaultValue={localServer}
              placeholder="Informe o endereço do servidor"
              isInvalid={isInvalidFeedBack("server", validationErrors)}
              onChange={(e) => [
                setValidationFormField("server", e.target.value),
                setLocalServer(e.target.value),
              ]}
            />
            <Form.Control.Feedback type="invalid">
              {feedBack("server", validationErrors)}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={12}>
            Banco
          </Form.Label>
          <Col sm={12}>
            <Form.Control
              size="sm"
              defaultValue={localDatabase}
              placeholder="Informe a rota para o banco de dados"
              isInvalid={isInvalidFeedBack("database", validationErrors)}
              onChange={(e) => [
                setValidationFormField("database", e.target.value),
                setLocalDatabase(e.target.value),
              ]}
            />
            <Form.Control.Feedback type="invalid">
              {feedBack("database", validationErrors)}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Row className="line-body"></Row>
        <Row>
          <Col>
            <h4>Autenticação</h4>
          </Col>
        </Row>
        <Form.Group as={Row}>
          <Form.Label column sm={12}>
            Usuário
          </Form.Label>
          <Col sm={12}>
            <Form.Control
              size="sm"
              defaultValue={localUser}
              placeholder="Informe o usuário para acesso ao banco"
              isInvalid={isInvalidFeedBack("user", validationErrors)}
              onChange={(e) => [
                setLocalUser(e.target.value),
                setValidationFormField("user", e.target.value),
              ]}
            />
            <Form.Control.Feedback type="invalid">
              {feedBack("user", validationErrors)}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={12}>
            Senha:
          </Form.Label>
          <Col sm={12}>
            <Form.Control
              size="sm"
              defaultValue={localPassword}
              placeholder="Informe a senha para acesso ao banco"
              isInvalid={isInvalidFeedBack("password", validationErrors)}
              onChange={(e) => [
                setLocalPassword(e.target.value),
                setValidationFormField("password", e.target.value),
              ]}
            />
            <Form.Control.Feedback type="invalid">
              {feedBack("password", validationErrors)}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={12}>
            Parâmetros opcionais:
          </Form.Label>
          <Col sm={12}>
            <Form.Control
              size="sm"
              defaultValue={aditionalConfig}
              placeholder="Caso desejar, forneça outros parâmetros para acesso ao banco"
              isInvalid={isInvalidFeedBack("aditionalConfig", validationErrors)}
              onChange={(e) => setLocalAditionalConfig(e.target.value)}
            />
          </Col>
        </Form.Group>
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="primary"
          className="cancel-operation ml-4"
          onClick={handleClearAllStates}
        >
          <BsX className=" mr-2" color="#A56300" size={24} />
          Cancelar
        </Button>

        <Button
          variant="primary"
          className="continue-button"
          disabled={buttonDisable}
          onClick={() => UpdateConnectionString()}
        >
          Salvar
          <BsCheck2 color="#fff" size={22} />
        </Button>
      </Modal.Footer>
    </ModalGAutomaticIntegrationStyles>
  );
}
