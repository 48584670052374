import { createContext, type ReactNode, useState } from "react";

type ChildrenContextProps = {
  children: ReactNode;
};
type ProfileContextData = {
  peddingConfiguration: boolean;
  setPeddingConfiguration: React.Dispatch<React.SetStateAction<boolean>>;
  configurationProfile: any;
  setConfigurationProfile: React.Dispatch<React.SetStateAction<any>>;
};
export const ProfileContext = createContext({} as ProfileContextData);

export default function ProfileContextProvider({
  children,
}: ChildrenContextProps) {
  const [peddingConfiguration, setPeddingConfiguration] = useState(false);
  const [configurationProfile, setConfigurationProfile] = useState<any>();
  return (
    <ProfileContext.Provider
      value={{
        peddingConfiguration,
        setPeddingConfiguration,
        configurationProfile,
        setConfigurationProfile,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
}
