import React, { useContext, useEffect, useState } from "react";
import { Col, Modal, Row, Form, Button, Table } from "react-bootstrap";
import { BsCheck2, BsChevronRight, BsX } from "react-icons/bs";
import Cookies from "universal-cookie";


import BrainzPagination, {
  PaginationProps,
} from "../../../components/Common/BrainzPagination/BrainzPagination";
import Search from "../../../components/Common/Input/Search";
import NoResults from "../../../components/Common/NoResults";
import { showToast } from "../../../components/Common/Toast";
import { ProfileContext } from "../../../contexts/ProfileContext";
import { handleGetPageSize } from "../../../helpers/functions";
import { BrainzUserTeamViewModel } from "../../../interfaces/BrainzUserTeamViewModel";
import { BrainzUserViewModel } from "../../../interfaces/BrainzUserViewModel";
import UserApiInterface from "../../../services/UserApiInterface";
import { TableSyncDataStyles } from "../../../styles/generic";

interface ModalTeamRegisterThirdStepProps {
  goToPreviousStep: (user: BrainzUserTeamViewModel | undefined) => void;
  closeModal: () => void;
}

export default function ModalTeamRegisterThirdStep(
  props: ModalTeamRegisterThirdStepProps
) {
  const { configurationProfile } = useContext(ProfileContext);
  const cookie = new Cookies();
  const institutionId = cookie.get(process.env.REACT_APP_BRAINZ_INSTITUTIONID!);
  const [searchFilter, setSearchFilter] = useState<string>("");
  const [queryPaginationParams, setQueryPaginationParams] = useState(
    `?page=1&pageSize=${handleGetPageSize()}`
  );
  const [paging, setPaging] = useState<PaginationProps | undefined>();
  const [counterUser, setCounterUser] = useState(0);
  const [users, setUsers] = useState<Array<BrainzUserViewModel> | undefined>();
  const [selectedUser, setSelectedUser] = useState<BrainzUserTeamViewModel>({
    identifier: "",
    displayName: "",
    userType: 0,
    id: "",
  });
  const [userType, setUserType] = useState<number>(0);

  function handleAddUserOnClick(user1: BrainzUserViewModel) {
    const user2: BrainzUserTeamViewModel = {
      identifier: user1.identifier,
      displayName: user1.displayName,
      userType,
      id: user1.id,
    };

    setSelectedUser(user2);
  }
  function handleSaveUserInTeam() {
    const payload: BrainzUserTeamViewModel = {
      ...selectedUser,
      userType,
    };

    props.goToPreviousStep(payload);
  }

  function queryFilters() {
    let query = "";
    if (searchFilter !== "") query = query += "&search=" + searchFilter;
    return query;
  }
  function queryPagination() {
    let result: string;
    if (queryPaginationParams) result = `${queryPaginationParams}`;
    else result = "";
    return result;
  }

  useEffect(() => {
    listUsers();
  }, [queryPaginationParams, searchFilter]);

  function listUsers() {
    if (queryPaginationParams) {
      UserApiInterface.get(
        `/User/${institutionId}/List${queryPagination()}${queryFilters()}`
      )
        .then((response) => {
          setPaging(response.data.paging);
          setCounterUser(response.data.paging.totalRecords);
          setUsers(response.data.result);
        })
        .catch((error) => {
          showToast("error", "Algo deu errado, tente novamente mais tarde");
          console.error("Erro => ", error)
        });
    }
  }

  function gotoPageOne() {
    if (paging) {
      const paginationProps: PaginationProps = {
        currentRecord: paging.currentRecord,
        currentPage: 1,
        pageSize: paging.pageSize,
        recordsOnPage: paging.recordsOnPage,
        totalRecords: paging.totalRecords,
        totalPages: paging.totalPages,
      };
      setPaging(paginationProps);
      setQueryPaginationParams(`?page=1&pageSize=${handleGetPageSize()}`);
    }
  }

  useEffect(() => {
    gotoPageOne();
  }, [searchFilter]);

  function getNameByUserType(userType: number) {
    switch (userType) {
      case 1:
        return "Administrativo";
      case 2:
        return "Assistente";
      case 3:
        return "Coordenador";
      case 4:
        return "Aluno";
      case 5:
        return "Professor";
      default:
        return "Usuário";
    }
  }
  return (
    <>
      {configurationProfile && (
        <>
          <Modal.Header>
            <Modal.Title>
              <h2>
                Adicionar Registro <BsChevronRight size={18} /> Turma
                <BsChevronRight size={18} /> Adicionar Pessoa
              </h2>
            </Modal.Title>
            <Button
              className="cursor-pointer"
              variant="white"
              onClick={() => {
                props.closeModal();
              }}
            >
              <BsX color="var(--bs-modal-color)" size={28} />
            </Button>
          </Modal.Header>
          <Modal.Body className="mb-2">
            <Row className="mt-2">
              <Col>
                <h3>
                  <strong>Qual o papel desta pessoa?</strong>
                </h3>
              </Col>
            </Row>
            <br />
            <Row className="">
              {configurationProfile?.map((item: any) => {
                return (
                  <Col md={3} key={item?.userType}>
                    <Form.Check
                      className="button-radio"
                      type="radio"
                      label={getNameByUserType(item.userType)}
                      checked={item.userType === userType}
                      onChange={() => {
                        setUserType(item.userType);
                      }}
                    />
                  </Col>
                );
              })}
            </Row>
            <br />
            <Row className="mt-3">
              <Col>
                <h4>
                  <strong>Busque a pessoa que deseja adicionar</strong>
                </h4>
              </Col>
            </Row>
            <br />
            <Row className="">
              <Col>
                <Search
                  setSearchFilter={setSearchFilter}
                  searchFilter={searchFilter}
                  isDisabled={userType === 0}
                  placeholder="Buscar por Tipo, ID, Nome ou E-mail"
                />
              </Col>
            </Row>
            {searchFilter === "" ? (
              ""
            ) : (
              <>
                {counterUser < 1 ? (
                  <></>
                ) : (
                  <Row className="ml-1 mt-2">
                    <h4>
                      Encontramos{" "}
                      {counterUser > 1 ? (
                        <>{counterUser} pessoas</>
                      ) : (
                        <>{counterUser} pessoa</>
                      )}{" "}
                    </h4>
                  </Row>
                )}

                <Row className="">
                  {users && users.length > 0 ? (
                    <>
                      <TableSyncDataStyles>
                        <Table
                          id="mytable"
                          className="mt-3 table-list"
                          borderless
                          hover
                          responsive
                        >
                          <thead>
                            <tr
                              className="title-table"
                              style={{ textAlignLast: "start" }}
                            >
                              <th>Nome</th>
                              <th>ID</th>
                              <th>E-mail</th>
                            </tr>
                          </thead>
                          <tbody id="mytable">
                            {users.map((item, index) => {
                              return (
                                <tr
                                  id="tr-linha"
                                  key={index}
                                  className={
                                    item?.id === selectedUser?.id
                                      ? "table-values line-selected cursor-pointer font-weight-bold"
                                      : "table-values cursor-pointer "
                                  }
                                  onClick={() => {
                                    handleAddUserOnClick(item);
                                  }}
                                >
                                  <td style={{ textAlignLast: "start" }}>
                                    {item.displayName}
                                  </td>
                                  <td style={{ textAlignLast: "start" }}>
                                    {item.identifier}
                                  </td>
                                  <td style={{ textAlignLast: "start" }}>
                                    {item.email}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                        {paging && (
                          <BrainzPagination
                            setQueryParams={setQueryPaginationParams}
                            paging={paging}
                          />
                        )}
                      </TableSyncDataStyles>
                    </>
                  ) : (
                    <NoResults
                      clearFilterFunction={() => setSearchFilter("")}
                    />
                  )}
                </Row>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="cancel-operation"
              onClick={() => props.goToPreviousStep(undefined)}
            >
              <i>
                <BsX color="#A56300" size="24" />
              </i>
              Descartar Pessoa
            </Button>

            <Button
              variant="primary"
              className="sucess-button"
              disabled={userType === 0 || selectedUser?.id === ""}
              onClick={handleSaveUserInTeam}
            >
              Salvar Pessoa
              <BsCheck2 color="#fff" size={24} />
            </Button>
          </Modal.Footer>
        </>
      )}
    </>
  );
}
