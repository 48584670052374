import React, { useEffect, useState } from "react";
import { Col, Modal, Row, Form, Button } from "react-bootstrap";
import { BsArrowRight, BsChevronRight, BsX, BsXCircle } from "react-icons/bs";
import Cookies from "universal-cookie";

import BrainzComboBox from "../../../components/Common/BrainzComboBox";
import Stepper from "../../../components/Common/Stepper";
import {
  invalidFieldMsg,
  requiredFieldMsg,
} from "../../../helpers/messagesValidation";
import {
  feedBack,
  isInvalidFeedBack,
  useValidation,
} from "../../../hooks/Validation";
import UnitApiInterface from "../../../services/UnitApiInterface";

export interface ModalTeamRegisterFirstStepProps {
  goToNextStep: (
    unitIdentifier: string,
    teamIdentifier: string,
    teamName: string
  ) => void;
  closeModal: () => void;
  data: {
    nameTeam: string;
    identifierTeam: string;
    unitIdentifier: string;
  };
}

export default function ModalTeamRegisterFirstStep(
  props: ModalTeamRegisterFirstStepProps
) {
  const [
    validationForm,
    setValidationFormField,
    validationErrors,
    setNewValidationErrors,
  ] = useValidation();

  const cookie = new Cookies();
  const institutionId = cookie.get(process.env.REACT_APP_BRAINZ_INSTITUTIONID!);
  const [nameTeam, setNameTeam] = useState(props.data.nameTeam);
  const [identifierTeam, setIdentifierTeam] = useState(
    props.data.identifierTeam
  );
  const [unitIdentifier, setUnitIdentifier] = useState(
    props.data.unitIdentifier
  );

  function handleNameTeamOnChange(value: string) {
    setNameTeam(value);
    setValidationFormField("nameTeam", value);
  }

  function handleIdentifierTeamOnChange(value: string) {
    setIdentifierTeam(value);
    setValidationFormField("identifierTeam", value);
  }

  function handleUnitIdentifierOnChange(value: string) {
    setUnitIdentifier(value);
    setValidationFormField("unitIdentifier", value);
  }

  function handlerNextStep() {
    const errors = findErrorsTeam();
    const errorExist = setNewValidationErrors(errors);

    if (!errorExist) {
      props.goToNextStep(unitIdentifier, identifierTeam, nameTeam);
    }
  }

  function findErrorsTeam() {
    const result: any = {};

    if (validationForm.current) {
      if (
        !validationForm.current.nameTeam ||
        validationForm.current.nameTeam === ""
      )
        result.nameTeam = requiredFieldMsg;
      else if (validationForm.current.nameTeam.length < 3)
        result.nameTeam = invalidFieldMsg;
      else result.nameTeam = undefined;

      if (
        !validationForm.current.identifierTeam ||
        validationForm.current.identifierTeam === ""
      )
        result.identifierTeam = requiredFieldMsg;
      else result.identifierTeam = undefined;

      if (
        !validationForm.current.unitIdentifier ||
        validationForm.current.unitIdentifier === ""
      )
        result.unitIdentifier = requiredFieldMsg;
      else result.unitIdentifier = undefined;
    }
    return result;
  }

  useEffect(() => {
    setNewValidationErrors({});
  }, []);

  useEffect(() => {
    updateValidation();

    function updateValidation() {
      validationForm.current = {
        nameTeam,
        identifierTeam,
        unitIdentifier,
      };
    }
  }, [validationForm]);

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <h2>
            Adicionar Registro <BsChevronRight size={18} /> Turma
          </h2>
        </Modal.Title>
        <Button
          className="cursor-pointer"
          variant="white"
          onClick={() => {
            props.closeModal();
          }}
        >
          <BsX color="var(--bs-modal-color)" size={28} />
        </Button>
      </Modal.Header>
      <Modal.Body className="mb-2">
        <Form>
          <Form.Group as={Row}>
            <Form.Label column sm={12}>
              Unidade:
            </Form.Label>
            <Col sm={unitIdentifier !== "" ? 11 : 12}>
              <BrainzComboBox
                setValue={(val) => handleUnitIdentifierOnChange(val)}
                value={unitIdentifier}
                endpoint={`/Unit/${institutionId}/List`}
                idField="identifier"
                valueField="name"
                placeholder={
                  props.data.unitIdentifier !== ""
                    ? "Carregando..."
                    : "Selecione a unidade desta turma"
                }
                isInvalid={isInvalidFeedBack(
                  "unitIdentifier",
                  validationErrors
                )}
                interface={UnitApiInterface}
              />
              <Form.Control.Feedback type="invalid">
                {feedBack("unitIdentifier", validationErrors)}
              </Form.Control.Feedback>
            </Col>
            {unitIdentifier !== "" && (
              <Col sm={1} className="p-0 m-0">
                <Button
                  className="clear-filter"
                  onClick={() => setUnitIdentifier("")}
                >
                  <BsXCircle size={24} color="#A56300" />
                </Button>
              </Col>
            )}
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={12}>
              Nome:
            </Form.Label>
            <Col sm={12}>
              <Form.Control
                placeholder=""
                size="sm"
                type="text"
                value={nameTeam}
                isInvalid={isInvalidFeedBack("nameTeam", validationErrors)}
                onChange={(e) => {
                  handleNameTeamOnChange(e.target.value);
                }}
              />
              <Form.Control.Feedback type="invalid">
                {feedBack("nameTeam", validationErrors)}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={12}>
              Identificador:
            </Form.Label>
            <Col sm={12}>
              <Form.Control
                placeholder=""
                size="sm"
                type="text"
                value={identifierTeam}
                isInvalid={isInvalidFeedBack(
                  "identifierTeam",
                  validationErrors
                )}
                onChange={(e) => {
                  handleIdentifierTeamOnChange(e.target.value);
                }}
              />
              <Form.Control.Feedback type="invalid">
                {feedBack("identifierTeam", validationErrors)}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="cancel-operation"
          onClick={() => {
            props.closeModal();
          }}
        >
          <BsX color="#A56300" size="24" />
          Descartar Turma
        </Button>
        <Stepper currentStep={1} totalSteps={2} />
        <Button
          variant="primary"
          className="success-button"
          onClick={handlerNextStep}
        >
          Continuar
          <BsArrowRight color="#Fff" size="24" />
        </Button>
      </Modal.Footer>
    </>
  );
}
