import { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { BsCheck2, BsChevronRight, BsX } from "react-icons/bs";

import CustomTooltip from "../../../components/Common/CustomTooltip";
import { showToast } from "../../../components/Common/Toast";
import { ModalGenericStyles } from "../../../styles/modalsGeneric";
import { GeneralConfigurationContext } from "../GeneralConfigurationContexts/GeneralConfigurationContext";
import { customTooltipTextAutomaticPassword } from "./utils/functions";
import "./styles/cardStyles.css";

export default function ModalEditPasswordProfile() {
  const {
    activeModal,
    entityType,
    setUpdateIntegration,
    setNewPasswordType,
    newFixedPassword,
    setNewFixedPassword,
    setActiveModal,
    settingsProfile,
  } = useContext(GeneralConfigurationContext);
  const [passwordType, setPasswordType] = useState<number>(
    settingsProfile.defaultPassword
  );
  const [fixedPassword, setFixedPassword] = useState(
    settingsProfile.fixedPassword
      ? settingsProfile.fixedPassword
      : newFixedPassword
  );

  useEffect(() => {
    setPasswordType(settingsProfile.defaultPassword);
    setFixedPassword(settingsProfile.fixedPassword);
  }, []);

  const handleValidateFillFixedPass = () => {
    if (passwordType === 1) {
      if (!newFixedPassword) {
        showToast("error", "Preencha o campo de senha fixa para continuar.");
        return;
      }
    }

    setActiveModal(0);
    setUpdateIntegration(true);
  };

  return (
    <ModalGenericStyles
      show={activeModal === 3}
      onHide={() => setActiveModal(0)}
      className="ModalDomains ModalEditPasswordProfile"
    >
      <Modal.Header>
        <Modal.Title>
          <h2>
            {entityType} <BsChevronRight size={18} /> Senha padrão dos e-mails
          </h2>
        </Modal.Title>
        <Button
          className="cursor-pointer"
          variant="white"
          onClick={() => setActiveModal(0)}
        >
          <BsX color="var(--bs-modal-color)" size={28} />
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <h4>
              Selecione como serão criadas as senhas para acesso às contas.
            </h4>
          </Col>
        </Row>
        <Row>
          <Col
            className={
              passwordType === 3 ? "card-option-selected" : "card-option"
            }
          >
            <Form.Check
              className="button-radio mb-3 mt-2"
              type="radio"
              label="Seguir planilha/consulta"
              checked={passwordType === 3}
              onChange={() => [setPasswordType(3), setNewPasswordType(3)]}
            ></Form.Check>
            <h3>
              Serão utilizadas as senhas especificadas na integração. As senhas
              devem respeitar as regras:<br></br>
              <ul>
                <li>
                  Não pode conter o nome de usuário completo ou endereço da
                  conta de email
                </li>
                <li>Não pode conter símbolos de moedas. Exemplo: $, €, £</li>
              </ul>
            </h3>
            <h3 style={{ color: "#c1944f" }}>
              Caso a senha presente na consulta não respeite as regras, o
              usuário não será criado.
            </h3>
          </Col>
        </Row>
        <Row>
          <Col
            className={
              passwordType === 1 ? "card-option-selected" : "card-option"
            }
          >
            <Form.Check
              className="button-radio mb-3 mt-2"
              type="radio"
              label="Senha fixa"
              checked={passwordType === 1}
              onChange={() => [setPasswordType(1), setNewPasswordType(1)]}
            ></Form.Check>
            <h3>Defina uma senha única e padrão para todas as contas.</h3>
            {passwordType === 1 ? (
              <Form.Control
                size="sm"
                defaultValue={fixedPassword}
                placeholder="Digite aqui a senha padrão para todas as contas"
                //isInvalid={isInvalidFeedBack("urlEndpoint", validationErrors)}
                onChange={(e) => setNewFixedPassword(e.target.value)}
              />
            ) : (
              ""
            )}
          </Col>
        </Row>
        <Row>
          <Col
            className={
              passwordType === 2 ? "card-option-selected" : "card-option"
            }
          >
            <div className="card-with-tooltip">
              <Form.Check
                className="button-radio mb-3 mt-2"
                type="radio"
                label="Geração automática"
                checked={passwordType === 2}
                onChange={() => [setPasswordType(2), setNewPasswordType(2)]}
              />

              <CustomTooltip
                type="info"
                iconColor="#6F6F6F"
                iconSize={20}
                text={customTooltipTextAutomaticPassword}
                placement="right"
                isHTMLText
              />
            </div>
            <h3>As senhas serão geradas automaticamente pelo sistema.</h3>
          </Col>
        </Row>
        <Row>
          <Col
            className={
              passwordType === 4 ? "card-option-selected" : "card-option"
            }
          >
            <Form.Check
              className="button-radio mb-3 mt-2"
              type="radio"
              label="Senha Aleatória"
              checked={passwordType === 4}
              onChange={() => [setPasswordType(4), setNewPasswordType(4)]}
            ></Form.Check>
            <h3>
              O sistema gerará uma senha aleatória, única para cada conta.
            </h3>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button className="cancel-operation" onClick={() => setActiveModal(0)}>
          <BsX className=" mr-2" color="#A56300" size={24} />
          Cancelar
        </Button>
        <Button
          variant="primary"
          className="continue-button"
          onClick={handleValidateFillFixedPass}
        >
          Salvar
          <BsCheck2 color="#fff" size={22} />
        </Button>
      </Modal.Footer>
    </ModalGenericStyles>
  );
}
