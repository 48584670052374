/* eslint-disable react/no-deprecated */
import i18next from "i18next";
import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import { I18nextProvider } from "react-i18next";

import reportWebVitals from "./reportWebVitals";
import common_pt from "./resources/pt/common.json";
import Routes from "./Routes";
import GlobalStyles from "./styles/GlobalStyles";

void i18next.init({
  interpolation: { escapeValue: false },
  lng: "pt",
  resources: {
    pt: {
      common: common_pt,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyles />
    <I18nextProvider i18n={i18next}>
      <Routes />
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
