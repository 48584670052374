import styled from "styled-components";

export const BrainzComboBoxStyles = styled.section`
  /**
   * Main wrapper
   */
  .select-search {
    position: relative;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: center;

    vertical-align: middle;

    font-family: "Lato", "Roboto", sans-serif;

    width: 100%;
    height: 48px;
    background-color: #f5f5f5;

    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;

    border: 1px solid rgb(166, 166, 166);
    border-radius: 8px;

    -webkit-appearance: none;
    appearance: none;
    box-shadow: unset;
  }

  .select-search *,
  .select-search *::after,
  .select-search *::before {
    box-sizing: inherit;
  }

  /**
   * Value wrapper
   */

  .select-search__value {
    z-index: 1;
    position: relative;
    box-sizing: border-box;
    vertical-align: middle;
    display: inline-block;

    width: 100%;
    height: 100%;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;

    border: none;
    border-radius: 8px;

    -webkit-appearance: none;
    appearance: none;

    &::after {
      content: "";
      display: inline-block;
      position: absolute;

      top: calc(50% - 9px);
      right: 19px;
      width: 20px;
      height: 17px;
    }
  }

  /**
   * Input
   */
  .select-search__input {
    box-sizing: border-box;
    display: inline-block;

    width: 100%;

    background: #fff;

    font-size: 14px;

    text-align: left;
    text-overflow: ellipsis;
    line-height: 36px;

    font-family: "Lato", "Roboto", sans-serif;

    width: 100%;
    height: 100%;

    padding: 0.375rem 1.75rem 0.375rem 0.75rem;

    font-size: 1rem;
    font-weight: 400;
    color: #495057;

    border: none;
    border-radius: 8px;
    outline: none;

    -webkit-appearance: none;
    appearance: none;

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      -webkit-appearance: none;
    }

    &:not([readonly]):focus {
      cursor: initial;
    }
  }

  /**
   * Options wrapper
   */
  .select-search__select {
    background: #fff;
    box-shadow: unset;
    z-index: 2147483647 !important;
    position: inherit;

    button {
      width: 100% !important;
      max-width: 100% !important;
      border: unset;
      box-shadow: unset;
      border-radius: unset;
    }
  }

  /**
   * Options
   */
  .select-search__options {
    list-style: none;
    margin-left: -40px;
  }

  /**
   * Option row
   */
  .select-search__row:not(:first-child) {
    border-top: 1px solid #eee;
  }

  /**
   * Option
   */
  .select-search__option,
  .select-search__not-found {
    min-height: 46px;
    height: 100%;
    width: 100%;

    padding: 0 16px;
    background: #fff;

    border: none;
    border-radius: 0;
    outline: none;

    font-size: 14px;
    text-align: left;
    cursor: pointer;
  }

  .select-search--multiple .select-search__option {
    height: 68px;
  }

  .select-search__option.is-selected {
    background: #2e6ce8;
    color: #fff;
  }

  .select-search__option.is-highlighted,
  .select-search__option:not(.is-selected):hover {
    color: #2155ba;
    background-color: #dde9ff;
  }

  .select-search__option.is-highlighted.is-selected,
  .select-search__option.is-selected:hover {
    background: #2155ba;
    color: #fff;
  }

  /**
   * Group
   */
  .select-search__group-header {
    font-size: 10px;
    text-transform: uppercase;
    background: #eee;
    padding: 8px 16px;
  }

  /**
   * States
   */
  .select-search.is-disabled {
    opacity: 0.5;
  }

  .select-search.is-loading .select-search__value::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
    background-size: 8px;
    background-position: center;
    margin-top: 3px;
    background-repeat: no-repeat;
  }

  .select-search:not(.is-disabled) .select-search__input {
    cursor: pointer;
  }

  /**
   * Modifiers
   */
  .select-search--multiple {
    border-radius: 3px;
    overflow: hidden;
  }

  .select-search:not(.is-loading):not(.select-search--multiple)
    .select-search__value::after {
    background-image: url('data:image/svg+xml,<svg width="70" height="70" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 44"><path d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"></path></svg>');
    background-size: 53px 49px;
    background-position: initial;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .select-search--multiple .select-search__input {
    cursor: initial;
  }

  .select-search--multiple .select-search__input {
    border-radius: 3px 3px 0 0;
  }

  .select-search--multiple:not(.select-search--search) .select-search__input {
    cursor: default;
  }

  .select-search:not(.select-search--multiple) .select-search__select {
    position: absolute;
    z-index: 2;
    top: 44px;
    right: 0;
    left: 0;
    border-radius: 3px;
    overflow: auto;
    max-height: 360px;
  }

  .select-search--multiple .select-search__select {
    position: relative;
    overflow: auto;
    max-height: 260px;
    border-top: 1px solid #eee;
    border-radius: 0 0 3px 3px;
  }

  .select-search__not-found {
    height: auto;
    padding: 16px;
    text-align: center;
    color: #888;
  }
  .brainz-combobox-arrow {
    position: absolute;
    top: 18px;
    right: 30px;
    z-index: 100;
    pointer-events: none;
  }
`;
