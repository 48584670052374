import { useContext, useEffect, useState } from "react";
import { Col, Modal, Row, Form, Button } from "react-bootstrap";
import { BsCheck2, BsChevronRight, BsX } from "react-icons/bs";
import Cookies from "universal-cookie";

import PageLoader from "../../../components/Common/Loader";
import { showToast } from "../../../components/Common/Toast";
import {
  idInvalidMsg,
  invalidFieldMsg,
  nameInvalidFieldMsg,
  requiredFieldMsg,
} from "../../../helpers/messagesValidation";
import {
  feedBack,
  isInvalidFeedBack,
  useValidation,
} from "../../../hooks/Validation";
import { BrainzUnitPayload } from "../../../interfaces/BrainzUnitPayload";
import UnitApiInterface from "../../../services/UnitApiInterface";
import { ModalGenericStyles } from "../../../styles/modalsGeneric";
import { ActiveModalContext } from "../SyncDataContexts/ActiveModalContext";

// 0 - fechado
// 1 - register
// 2 - usuario
// 3 - unidade
// 4 - turma
// 5 - success
// 6 - cancelar
// 7 - error

export default function ModalUnitRegister() {
  const { activeModal, setActiveModal, setPreviousActiveModal, setUpdateUnit } =
    useContext(ActiveModalContext);
  const [idUnit, setIdUnit] = useState("");
  const [nameUnit, setNameUnit] = useState("");
  const [identifierUnit, setIdentifierUnit] = useState("");
  const [buttonDisable, setButtonDisable] = useState<boolean>(false);
  const [
    validationForm,
    setValidationFormField,
    validationErrors,
    setNewValidationErrors,
  ] = useValidation();
  const cookie = new Cookies();
  const institutionId = cookie.get(process.env.REACT_APP_BRAINZ_INSTITUTIONID!);

  const [loading, setLoading] = useState(false);

  function handleNameOnChangeUnit(value: string) {
    setNameUnit(value);
    setValidationFormField("nameUnit", value);
  }

  function handleIdentifierOnChangeUnit(value: string) {
    setIdentifierUnit(value);
    setValidationFormField("identifierUnit", value);
  }

  function findErrorsUnit() {
    const result: any = {};

    if (validationForm.current) {
      if (
        !validationForm.current.nameUnit ||
        validationForm.current.nameUnit === ""
      )
        result.nameUnit = requiredFieldMsg;
      else if (validationForm.current.nameUnit.length < 3)
        result.nameUnit = invalidFieldMsg;
      else if (validationForm.current.nameUnit.toUpperCase() === "NULL")
        result.nameUnit = nameInvalidFieldMsg;
      else result.nameUnit = undefined;

      if (
        !validationForm.current.identifierUnit ||
        validationForm.current.identifierUnit === ""
      )
        result.identifierUnit = requiredFieldMsg;
      else if (validationForm.current.identifierUnit.toUpperCase() === "NULL")
        result.identifierUnit = idInvalidMsg;
      else result.identifierUnit = undefined;
    }
    return result;
  }

  function clearModalUnit() {
    setIdUnit("");
    setIdentifierUnit("");
    setNameUnit("");
  }

  function handleSaveDataUnit() {
    const errors = findErrorsUnit();
    const errorExist = setNewValidationErrors(errors);
    if (!errorExist) {
      setButtonDisable(true);
      createDataUnit();
    }

    function createDataUnit() {
      setLoading(true);
      const payload: Array<BrainzUnitPayload> = [
        {
          identifier: identifierUnit,
          name: nameUnit,
        },
      ];

      return UnitApiInterface.post(`/Unit/${institutionId}/Insert`, payload)
        .then((response) => {
          if (response.status === 200) {
            const errors = response.data.result[0].errors;
            if (!errors || errors.length === 0) {
              showToast("success", "Concluído com sucesso.");
              setActiveModal(5);
              setButtonDisable(false);
              setLoading(false);
              setUpdateUnit(true);
              setUpdateUnit(false);
            } else {
              setLoading(false);
              setButtonDisable(false);
              setActiveModal(7);
              showToast(
                "error",
                `${errors[0].name} Code: ${errors[0].code} Id: ${errors[0].id}`
              );
            }
          } else {
            setLoading(false);
            setButtonDisable(false);
            showToast("error", "Algo deu errado, tente novamente mais tarde");
            setActiveModal(7);
          }
        })
        .catch((error) => {
          setLoading(false);
          showToast("error", "Algo deu errado, tente novamente mais tarde");
          setActiveModal(7);
          console.error("Erro => ", error)
        });
    }
  }

  useEffect(() => {
    updateValidation();

    function updateValidation() {
      validationForm.current = {
        identifierUnit,
        nameUnit,
      };
    }
  }, [nameUnit, identifierUnit]);

  useEffect(() => {
    setNewValidationErrors({});
  }, [institutionId]);

  useEffect(() => {
    if (activeModal === 0 || activeModal === 1) {
      clearModalUnit();
    }
  }, [activeModal]);

  return (
    <ModalGenericStyles
      show={activeModal === 3}
      onHide={() => {
        setActiveModal(6);
        setPreviousActiveModal(3);
      }}
    >
      {loading ? (
        <PageLoader />
      ) : (
        <>
          <Modal.Header>
            <Modal.Title>
              <h2>
                Adicionar Registro <BsChevronRight size={18} /> Unidade
              </h2>
            </Modal.Title>
            <Button
              className="cursor-pointer"
              variant="white"
              onClick={() => {
                setActiveModal(6);
                setPreviousActiveModal(3);
              }}
            >
              <BsX color="var(--bs-modal-color)" size={28} />
            </Button>
          </Modal.Header>
          <Modal.Body className="mb-2">
            <Form>
              <Form.Group as={Row}>
                <Form.Label column sm={12}>
                  Nome:
                </Form.Label>
                <Col sm={12}>
                  <Form.Control
                    size="sm"
                    type="text"
                    value={nameUnit}
                    isInvalid={isInvalidFeedBack("nameUnit", validationErrors)}
                    onChange={(e) => {
                      handleNameOnChangeUnit(e.target.value);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {feedBack("nameUnit", validationErrors)}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm={12}>
                  Identificador:
                </Form.Label>
                <Col sm={12}>
                  <Form.Control
                    placeholder=""
                    size="sm"
                    type="text"
                    value={identifierUnit}
                    isInvalid={isInvalidFeedBack(
                      "identifierUnit",
                      validationErrors
                    )}
                    onChange={(e) => {
                      handleIdentifierOnChangeUnit(e.target.value);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {feedBack("identifierUnit", validationErrors)}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="cancel-operation"
              onClick={() => {
                setActiveModal(6);
                setPreviousActiveModal(3);
              }}
            >
              <BsX color="#A56300" size="24" />
              Descartar Unidade
            </Button>
            <Button
              variant="primary"
              className="sucess-button"
              disabled={buttonDisable}
              onClick={handleSaveDataUnit}
            >
              Salvar Unidade
              <BsCheck2 color="#fff" size={24} />
            </Button>
          </Modal.Footer>
        </>
      )}
    </ModalGenericStyles>
  );
}
