import { Button, Col, Modal, Row } from "react-bootstrap";

import { ModalGenericErrorStyles } from "../../../styles/modalsGeneric";

interface InformationModalProps {
  show: boolean;
  onHide: () => void;
  title: string;
  subTitle: string;
  confirmText: string;
  confirmAction: () => void;
  principalIcon: React.ReactNode;
}

export default function InformationModal(props: InformationModalProps) {
  if (props.show)
    return (
      <ModalGenericErrorStyles show={props.show} onHide={props.onHide}>
        <Modal.Body>
          <Row className="my-4">
            <Col>{props.principalIcon}</Col>
          </Row>
          <Row>
            <Col>
              <h2>{props.title}</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>{props.subTitle}</p>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="button-active"
            onClick={() => props.confirmAction()}
          >
            {props.confirmText}
          </Button>
        </Modal.Footer>
      </ModalGenericErrorStyles>
    );
  else return <></>;
}
