import { ListGroup, Row } from "react-bootstrap";
import styled from "styled-components";

export const PaginationButtonsStyles = styled(Row)`
  margin: 0 auto;
  width: fit-content;
  button {
    margin: 3px;
    padding-left: 0.24vmin;
    padding-right: 0.24vmin;
    display: flex;
    align-items: center;
    text-align: center;
    width: 48px;
    height: 48px;

    border: 0.14vmin solid #ffffff;
    background-color: #ffffff !important;
    border-radius: 0.56vmin;
    .active {
      background: #e9effc !important;
      border: 1px solid #e4e3e3 !important;
      box-sizing: border-box;
      border-radius: 4px;
    }
    p {
      font-family: Segoe UI;
      font-style: normal;
      font-weight: 600;
      font-size: 2.22vmin;
      line-height: 2.64vmin;
      color: #2155ba !important;

      padding: 0px;
      margin: auto;
    }
    i {
      margin-bottom: 0.56vmin;
      font-size: 3.61vmin;
      color: #2155ba;
    }
    .disabled {
      background: #ffffff;
      border: none !important;
      box-sizing: border-box;
      border-radius: 0.56vmin;
      i {
        color: #b9b8b8;
      }
    }
    &:hover {
      background: #ffffff;
      border: 0.14vmin solid #2155ba !important;
      box-sizing: border-box;
      border-radius: 0.56vmin;
    }

    &:disabled {
      background: #ffffff;
      border: none !important;
      box-sizing: border-box;
      border-radius: 0.56vmin;
      i {
        color: #b9b8b8;
      }
      &:hover {
        background: #ffffff;
        border: none !important;
        box-sizing: border-box;
        border-radius: 0.56vmin;
      }
    }
    &:active {
      background-color: #e9effc !important;
      border: 0.14vmin solid #2155ba !important;
      box-sizing: border-box;
      border-radius: 0.56vmin;
      box-shadow: 0 0 0 0.28vmin #2155ba !important;
    }
    &:focus {
      background: #ffffff;
      border: 1px solid #2155ba !important;
      box-shadow: 0 0 0 0.28vmin #2155ba !important;
    }
    .active {
      background: #e9effc;
      border: 1px solid #e4e3e3;
      box-sizing: border-box;
      border-radius: 4px;
    }
  }
  button.active {
    background: #ffffff;
    border: 0.14vmin solid #6468c8 !important;
    box-shadow: none;
  }
`;

export const PaginationStyles = styled(Row)`
  h3 {
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #6f6f6f;
  }
  .page-sizes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 8px;
    width: auto;
    height: 42px;
    background: #f5f5f5;
    border: 1px solid #e4e3e3;
    box-sizing: border-box;
    border-radius: 8px;
  }
`;

export const StyledPagedSearchButtonList = styled(ListGroup)`
  .list-group-item {
    border: none;
    padding-left: 0.24vmin;
    padding-right: 0.24vmin;
  }
  button {
    display: flex;
    align-items: center;
    text-align: center;
    width: 6.11vmin;
    height: 6.11vmin;

    border: 0.14vmin solid #ffffff;
    background-color: #ffffff !important;
    border-radius: 0.56vmin;
    p {
      font-family: Segoe UI;
      font-style: normal;
      font-weight: 600;
      font-size: 2.22vmin;
      line-height: 2.64vmin;
      color: #6468c8 !important;
      padding: 0px;
      margin: auto;
    }
    i {
      margin-bottom: 0.56vmin;
      font-size: 3.61vmin;
      color: #6468c8;
    }
    &:hover {
      background: #ffffff;
      border: 0.14vmin solid #6468c8 !important;
      box-sizing: border-box;
      border-radius: 0.56vmin;
    }
    &:disabled {
      background: #ffffff;
      border: 0.14vmin solid #ffffff;
      i {
        color: #b9b8b8;
      }
      &:hover {
        background: #ffffff;
        border: none !important;
        box-sizing: border-box;
        border-radius: 0.56vmin;
      }
    }
    &:active {
      background-color: #ffffff !important;
      border: 0.14vmin solid #6468c8 !important;
      box-sizing: border-box;
      border-radius: 0.56vmin;
      box-shadow: 0 0 0 0.28vmin #6468c899 !important;
    }
    &:focus {
      background: #ffffff;
      border: 1px solid #6468c8 !important;
      box-shadow: 0 0 0 0.28vmin #6468c899 !important;
    }
  }
  button.active {
    background: #ffffff;
    border: 0.14vmin solid #6468c8 !important;
    box-shadow: none;
  }
`;
