import styled from "styled-components";

export const ModalLogContainer = styled.section``;

export const PaginationContainer = styled.section`
  .pagination {
    flex: 1;
    padding-right: 1rem;
  }
`;
