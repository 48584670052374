import React, { useState } from "react";
import { Col, Modal, Row, Button, Table, Nav } from "react-bootstrap";
import {
  BsArrowLeft,
  BsCheck2,
  BsChevronRight,
  BsPersonPlus,
  BsTrash,
  BsX,
} from "react-icons/bs";

import CustomTooltip from "../../../components/Common/CustomTooltip";
import Stepper from "../../../components/Common/Stepper";
import { BrainzUserTeamViewModel } from "../../../interfaces/BrainzUserTeamViewModel";
import { TableSyncDataStyles } from "../../../styles/generic";
import { UsersNavBarStyles } from "../ComponentUsersTeam";
import "./styles.css";

interface ModalTeamRegisterSecondStepProps {
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  closeModal: () => void;
  finishRegister: (users: Array<BrainzUserTeamViewModel>) => void;
  users: Array<BrainzUserTeamViewModel> | undefined;
  setUsers: (users: Array<BrainzUserTeamViewModel>) => void;
}

export default function ModalTeamRegisterSecondStep(
  props: ModalTeamRegisterSecondStepProps
) {
  const [usersSelected, setUsersSelected] = useState<
    Array<BrainzUserTeamViewModel>
  >(props.users ?? []);
  const [activeTab, changeActiveTab] = useState(1);

  const removeUser = (
    identifierUser: string,
    userType?: number | undefined
  ) => {
    const newArr = [...usersSelected];

    newArr?.splice(
      newArr?.findIndex(
        (item) =>
          item?.identifier === identifierUser && item?.userType === userType
      ),
      1
    );

    setUsersSelected(newArr);
    props.setUsers(newArr);
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <h2>
            Adicionar Registro <BsChevronRight size={18} /> Turma
            <BsChevronRight size={18} /> Adicionar Pessoas
          </h2>
        </Modal.Title>
        <Button
          className="cursor-pointer"
          variant="white"
          onClick={() => {
            props.closeModal();
          }}
        >
          <BsX color="var(--bs-modal-color)" size={28} />
        </Button>
      </Modal.Header>
      <Modal.Body className="mb-2">
        <Row>
          <Col md={12}>
            <Row className="mt-3 mr-2">
              <Col>
                <h3>
                  Selecione as pessoas que fazem parte dessa turma.
                  <br /> <br />
                  Para salvar a turma é necessário adicionar ao menos uma pessoa
                  com um dos seguinte perfis:{" "}
                  <strong>Administrativo, Coordenador ou Professor</strong>.
                </h3>
              </Col>
            </Row>
          </Col>
          <Col md={12} style={{ padding: "2rem" }}>
            <Button
              className="button-add-header"
              style={{ float: "left" }}
              variant="primary"
              onClick={() => props.goToNextStep()}
            >
              <BsPersonPlus className="mr-3" color="#2155BA" size={24} />
              Adicionar pessoa
            </Button>
          </Col>
        </Row>
        {usersSelected.length > 0 ? (
          <>
            <Row className="ml-auto pl-0 ml-0 nav-item">
              <UsersNavBarStyles className="pl-0 ml-0 navBarContainer">
                <Nav
                  variant="pills"
                  defaultActiveKey="/home"
                  className="px-0 mt-2 mb-0 pl-0 ml-0"
                >
                  <Nav.Item className="mx-1">
                    <Nav.Link
                      active={activeTab === 1}
                      onClick={() => changeActiveTab(1)}
                      className="pt-3 pb-2"
                    >
                      Administrativo
                      {usersSelected?.filter((a) => a?.userType === 1)
                        .length ? (
                        <div className="quantityUsersContainer">
                          {usersSelected.filter((a) => a.userType === 1).length}
                        </div>
                      ) : (
                        <></>
                      )}
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item className="mx-1">
                    <Nav.Link
                      active={activeTab === 2}
                      onClick={() => changeActiveTab(2)}
                      className="pt-3 pb-2"
                    >
                      Coordenador
                      {usersSelected?.filter((a) => a?.userType === 3)
                        .length ? (
                        <div className="quantityUsersContainer">
                          {usersSelected.filter((a) => a.userType === 3).length}
                        </div>
                      ) : (
                        <></>
                      )}
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item className="mx-1">
                    <Nav.Link
                      active={activeTab === 3}
                      onClick={() => changeActiveTab(3)}
                      className="pt-3 pb-2"
                    >
                      Aluno
                      {usersSelected?.filter((a) => a?.userType === 4)
                        .length ? (
                        <div className="quantityUsersContainer">
                          {usersSelected.filter((a) => a.userType === 4).length}
                        </div>
                      ) : (
                        <></>
                      )}
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item className="mx-0">
                    <Nav.Link
                      active={activeTab === 4}
                      onClick={() => changeActiveTab(4)}
                      className="pt-3 pb-2"
                    >
                      Professor
                      {usersSelected?.filter((a) => a?.userType === 5)
                        .length ? (
                        <div className="quantityUsersContainer">
                          {usersSelected.filter((a) => a.userType === 5).length}
                        </div>
                      ) : (
                        <></>
                      )}
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </UsersNavBarStyles>
            </Row>

            {activeTab === 1 && (
              <UsersTable
                removeUser={removeUser}
                users={usersSelected.filter((a) => a.userType === 1)}
              />
            )}
            {activeTab === 2 && (
              <UsersTable
                removeUser={removeUser}
                users={usersSelected.filter((a) => a.userType === 3)}
              />
            )}
            {activeTab === 3 && (
              <UsersTable
                removeUser={removeUser}
                users={usersSelected.filter((a) => a.userType === 4)}
              />
            )}
            {activeTab === 4 && (
              <UsersTable
                removeUser={removeUser}
                users={usersSelected.filter((a) => a.userType === 5)}
              />
            )}
          </>
        ) : (
          <></>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <Button
            variant="primary"
            className="cancel-operation"
            onClick={() => props.goToPreviousStep()}
          >
            <BsArrowLeft size="24" />
            Voltar
          </Button>
        </div>
        <Stepper totalSteps={2} currentStep={2} />
        <div>
          <Button
            variant="primary"
            className="sucess-button"
            disabled={
              props.users === undefined ||
              props.users?.filter((a) => a.userType !== 4).length < 1
            }
            onClick={() => props.finishRegister(usersSelected)}
          >
            Salvar Turma
            <BsCheck2 color="#fff" size={24} />
          </Button>
        </div>
      </Modal.Footer>
    </>
  );
}

interface UserTableProps {
  removeUser: (identifier: string, userType: number) => void;
  users: Array<BrainzUserTeamViewModel>;
}

function UsersTable({ users, removeUser }: UserTableProps) {
  if (users?.length === 0) {
    return (
      <section className="usersTableEmptyContainer">
        Nenhum usuário com este perfil foi adicionado.
      </section>
    );
  }

  return (
    <TableSyncDataStyles>
      <Table
        className="mt-3 table-list px-4"
        striped
        borderless
        hover
        responsive
      >
        <thead>
          <tr className="title-table" style={{ textAlignLast: "start" }}>
            <th className="border-radius-left">Nome</th>
            <th>ID</th>
            <th className="border-radius-right"></th>
          </tr>
        </thead>
        <tbody>
          {users?.map((item, index) => {
            return (
              <tr key={index} className={"table-values cursor-pointer "}>
                <td style={{ textAlignLast: "start" }}>{item?.displayName}</td>
                <td style={{ textAlignLast: "start" }}>{item?.identifier}</td>
                <td
                  style={{ textAlignLast: "end" }}
                  className="cursor-pointer"
                  onClick={() => removeUser(item?.identifier, item?.userType)}
                >
                  <CustomTooltip
                    type="custom-icon"
                    text="Excluir"
                    placement="bottom"
                    customIcon={<BsTrash color="#A56300" size="24" />}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </TableSyncDataStyles>
  );
}
