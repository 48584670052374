import moment from 'moment'

export function formatDateToSend(value: string) {
    if (value) {
        moment.locale('pt-BR');
        const x = moment(value, 'DD-MM-YYYY')
        const result = x.format("YYYY-MM-DD")
        return result
    } else return ""
}

export function formatFromISODate(value: string) {
    if (value) {
        const x = moment(value)
        const result = x.format("DD/MM/YYYY")
        return result
    } else return ""
}

export function formatStringToDate(value: string) {
    if (value) {
        moment.locale('pt-BR');
        const x = moment(value, 'DD-MM-YYYY')
        return x;
    } else return ""
}
