import { useState } from "react";
import { Row, Card, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BsArrowRepeat, BsPencil } from "react-icons/bs";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";

import { InitialHeaderStyles } from "../../../styles/initialStyles";
import ModalInsertion from "../ModalInsertion";
import "./styles.css";
import UserApiInterface from "../../../services/UserApiInterface";
import { showToast } from "../../../components/Common/Toast";
import PageLoader from "../../../components/Common/Loader";

interface HeadersListObj {
  key: string;
  value: string;
}

const ShowConfig = ({ data, handleReloadPage }: any) => {
  const cookie = new Cookies();
  const [t] = useTranslation("common");
  const institutionId = cookie.get(process.env.REACT_APP_BRAINZ_INSTITUTIONID!);
  const { active, headers, requestType, url } = data;

  const headersList: [HeadersListObj] = headers;

  const [showModalInsertion, setShowModalInsertion] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleShowModalInsertion = () => {
    return setShowModalInsertion(!showModalInsertion);
  };

  const handleReprocess = (userType: number) => {
    if (!userType) {
      showToast("error", "O userType não foi definido.");
      return;
    }
    setIsLoading(true);

    UserApiInterface.post(
      `/User/${institutionId}/ReprocessWriteback/${userType}`
    )
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          showToast("success", `O reprocessamento do retorno para ${userType === 4 ? "alunos" : userType === 5 ? "professores" : ""}  foi adicionado à fila.`)
        } else {
          setIsLoading(false);
          showToast("error", `O reprocessamento do retorno para ${userType === 4 ? "alunos" : userType === 5 ? "professores" : ""} falhou, tente novamente.`)
        }
      })
      .catch((error) => {
        setIsLoading(false);

        showToast("error", "Algo deu errado, tente novamente.");
        console.error("Erro ao reprocessar writeback ln 53. Erro => ", error);
      });
  };

  const renderStatus = () => {
    if (active)
      return (
        <span className="statusText" style={{ color: "#008555" }}>
          Ativo
        </span>
      );

    return (
      <span className="statusText" style={{ color: "#e91f1f" }}>
        Inativo
      </span>
    );
  };

  if (isLoading) return <PageLoader />

  return (
    <>
      <InitialHeaderStyles className="writebackPage">
        <section className="headerWritebackPage headingStickySubPages">
          <Row>
            <Col md={12} className="px-0">
              <h2>Configuração de Retorno</h2>
            </Col>
            <br />
            <br />
            <Col md={12} className="px-0">
              <h3>
                Para receber o retorno de e-mails criados, realize as
                configurações correspondentes.
              </h3>
            </Col>
          </Row>
          <Button onClick={handleShowModalInsertion}>
            <BsPencil color="#0F7EAA" size={24} />
            <span>Editar</span>
          </Button>
        </section>

        <section className="writebackContent">
          <Row className="mb-3 mainRow">
            {active && (

              <Card className="card-integration ">
                <Card.Body className="my-0 py-0">
                  <Row className="div-body">
                    <div className="my-2 internContainerWB">
                      <Row>
                        <h4>Reprocessamento do Retorno</h4>
                      </Row>
                    </div>
                  </Row>

                  <Row className="div-body noBorder">
                    <div className="mt-1 mb-4 internContainerWB">
                      <Row className="button-group-row">
                        <Button size="sm" onClick={() => handleReprocess(4)} disabled={isLoading}>
                          <BsArrowRepeat color="#fff" size={18} />
                          <span>Reprocessar alunos</span>
                        </Button>
                        <Button size="sm" onClick={() => handleReprocess(5)} disabled={isLoading}>
                          <BsArrowRepeat color="#fff" size={18} />
                          <span>Reprocessar professores</span>
                        </Button>
                      </Row>
                    </div>
                  </Row>
                </Card.Body>
              </Card>
            )}

            <Card className="card-integration ">
              <Card.Body className="my-0 py-0">
                <Row className="div-body">
                  <div className="my-2 internContainerWB">
                    <Row>
                      <h4>URL do Endpoint</h4>
                    </Row>
                    <Row>
                      <h5>{url}</h5>
                    </Row>
                  </div>

                  <div className="my-2 internContainerWB internContainerWBStatus">
                    <Row>
                      <h4>Status</h4>
                    </Row>
                    <Row>
                      <h5>{renderStatus()}</h5>
                    </Row>
                  </div>
                </Row>

                <Row className="div-body noBorder">
                  <div className="my-2 internContainerWB">
                    <Row>
                      <h4>Tipo de Requisição</h4>
                    </Row>
                    <Row>
                      <h5>{requestType}</h5>
                    </Row>
                  </div>
                </Row>
              </Card.Body>
            </Card>

            {headersList && headersList.length > 0 && (
              <Card className="card-integration card-custom-table">
                <Card.Body className="my-0 py-0">
                  <Row className="div-body-link">
                    <Col md={11} className="my-2">
                      <Row className="my-2 pl-3">
                        <h4>Headers</h4>
                      </Row>
                      <Row className="title-key">
                        <Col
                          md={5}
                          style={{ textAlignLast: "start", overflow: "auto" }}
                        >
                          Key
                        </Col>
                        <Col
                          md={7}
                          className=""
                          style={{ textAlignLast: "start", overflow: "auto" }}
                        >
                          Value
                        </Col>
                      </Row>
                      {headersList.map((item: HeadersListObj) => {
                        return (
                          <Row className="row-bottom" key={item.key}>
                            <Col
                              md={5}
                              style={{
                                textAlignLast: "start",
                              }}
                            >
                              {item.key}
                            </Col>
                            <Col
                              md={7}
                              className="col-left"
                              style={{
                                textAlignLast: "start",
                              }}
                            >
                              {item.value}
                            </Col>
                          </Row>
                        );
                      })}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            )}
          </Row>
        </section>
      </InitialHeaderStyles>

      <ModalInsertion
        showModal={showModalInsertion}
        handleClose={handleShowModalInsertion}
        titleModal="Editar Configuração"
        isUpdate={true}
        data={{ ...data, random: new Date().getTime() }}
        institutionId={data.institutionId}
        handleReloadPage={handleReloadPage}
      />
    </>
  );
};

export default ShowConfig;
