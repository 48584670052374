import { useContext, useEffect, useState } from "react";
import { Col, Modal, Row, Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BsCheck2, BsChevronRight, BsX } from "react-icons/bs";
import Cookies from "universal-cookie";
import { toPattern } from "vanilla-masker";

import BrainzComboBox from "../../../components/Common/BrainzComboBox";
import CustomTooltip from "../../../components/Common/CustomTooltip";
import PageLoader from "../../../components/Common/Loader";
import { showToast } from "../../../components/Common/Toast";
import { ProfileContext } from "../../../contexts/ProfileContext";
import { formatDateToSend } from "../../../helpers/DateTime";
import { passwordRequirementsMsg } from "../../../helpers/messagesTooltips";
import {
  invalidFieldMsg,
  requiredFieldMsg,
} from "../../../helpers/messagesValidation";
import { isValidDate, isValidEmail } from "../../../helpers/Validation";
import {
  feedBack,
  isInvalidFeedBack,
  useValidation,
} from "../../../hooks/Validation";
import { BrainzUserPayload } from "../../../interfaces/BrainzUserPayload";
import { BrainzUserSettingsViewModel } from "../../../interfaces/BrainzUserSettingsViewModel";
import ApiInterface from "../../../services/ApiInterface";
import UserApiInterface from "../../../services/UserApiInterface";
import { ModalGenericStyles } from "../../../styles/modalsGeneric";
import { ActiveModalContext } from "../SyncDataContexts/ActiveModalContext";

// 0 - fechado
// 1 - register
// 2 - usuario
// 3 - unidade
// 4 - turma
// 5 - success
// 6 - cancelar
// 7 - error

export default function ModalUserRegister() {
  const {
    activeModal,
    setActiveModal,
    setPreviousActiveModal,
    setUpdateUser,
    userSettings,
    setUserSettings,
  } = useContext(ActiveModalContext);

  const { configurationProfile } = useContext(ProfileContext);
  const [t] = useTranslation("common");

  const [
    validationForm,
    setValidationFormField,
    validationErrors,
    setNewValidationErrors,
  ] = useValidation();

  const cookie = new Cookies();
  const institutionId = cookie.get(process.env.REACT_APP_BRAINZ_INSTITUTIONID!);

  const [userType, setUserType] = useState<number>(0);
  const [userTypeString, setUserTypeString] = useState<string>("");
  const [identifier, setIdentifier] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [birthday, setBirthday] = useState("");
  const [cellphone, setCellphone] = useState("");
  const [department, setDepartment] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [document, setDocument] = useState("");
  const [registration, setRegistration] = useState("");

  const [buttonDisable, setButtonDisable] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  function clearModalUser() {
    setIdentifier("");
    setFirstName("");
    setLastName("");
    setDisplayName("");
    setEmail("");
    setPassword("");
    setBirthday("");
    setCellphone("");
    setDepartment("");
    setJobTitle("");
    setDocument("");
    setRegistration("");
    setUserType(0);
    setUserTypeString("");
  }

  function handleIdentifierOnChange(value: string) {
    setIdentifier(value);
    setValidationFormField("identifier", value);
  }

  function handleFirstNameOnChange(value: string) {
    setFirstName(value);
    setValidationFormField("firstName", value);
  }

  function handleLastNameOnChange(value: string) {
    setLastName(value);
    setValidationFormField("lastName", value);
  }

  function handleDisplayNameOnChange(value: string) {
    setDisplayName(value);
    setValidationFormField("displayName", value);
  }

  function handleEmailOnChange(value: string) {
    const newEmail = value.replace(/ /g, "");
    setEmail(newEmail);
    setValidationFormField("email", newEmail);
  }

  function handlePasswordOnChange(value: string) {
    setPassword(value);
    setValidationFormField("password", value);
  }

  function handleBirthdayOnChange(value: string) {
    const masked = toPattern(value, "99/99/9999");
    setBirthday(masked);
    setValidationFormField("birthday", masked);
  }

  function handleCellphoneOnChange(value: string) {
    const masked = toPattern(value, "(99) 99999-9999");
    setCellphone(masked);
    setValidationFormField("cellphone", masked);
  }

  function handleDepartmentOnChange(value: string) {
    setDepartment(value);
    setValidationFormField("department", value);
  }

  function handleJobTitleOnChange(value: string) {
    setJobTitle(value);
    setValidationFormField("jobTitle", value);
  }

  function handleDocumentOnChange(value: string) {
    setDocument(value);
    setValidationFormField("document", value);
  }

  function handleRegistrationOnChange(value: string) {
    setRegistration(value);
    setValidationFormField("registration", value);
  }

  function findErrors() {
    const result: any = {};

    if (validationForm.current) {
      if (
        !validationForm.current.firstName ||
        validationForm.current.firstName === ""
      )
        result.firstName = requiredFieldMsg;
      else if (validationForm.current.firstName.length < 3)
        result.firstName = invalidFieldMsg;
      else result.firstName = undefined;

      if (
        !validationForm.current.lastName ||
        validationForm.current.lastName === ""
      )
        result.lastName = requiredFieldMsg;
      else if (validationForm.current.lastName.length < 3)
        result.lastName = invalidFieldMsg;
      else result.lastName = undefined;

      if (
        !validationForm.current.displayName ||
        validationForm.current.displayName === ""
      )
        result.displayName = requiredFieldMsg;
      else if (validationForm.current.displayName.length < 3)
        result.displayName = invalidFieldMsg;
      else result.displayName = undefined;

      if (userSettings?.defaultEmail !== 1 && userSettings?.defaultEmail !== 5)
        result.email = undefined;
      else if (
        !validationForm.current.email ||
        validationForm.current.email === ""
      )
        result.email = requiredFieldMsg;
      else if (!isValidEmail(validationForm.current.email))
        result.email = invalidFieldMsg;
      else result.email = undefined;

      if (userSettings?.defaultPassword !== 3) result.password = undefined;
      else if (
        !validationForm.current.password ||
        validationForm.current.password === ""
      )
        result.password = requiredFieldMsg;
      else result.password = undefined;

      if (
        !isValidDate(validationForm.current.birthday) &&
        validationForm.current.birthday !== ""
      )
        result.birthday = invalidFieldMsg;
      else result.birthday = undefined;

      if (
        !validationForm.current.registration ||
        validationForm.current.registration === ""
      )
        result.registration = requiredFieldMsg;
      else result.registration = undefined;

      if (
        !validationForm.current.identifier ||
        validationForm.current.identifier === ""
      )
        result.identifier = requiredFieldMsg;
      else result.identifier = undefined;

      if (userType === 0) result.userType = requiredFieldMsg;
      else result.userType = undefined;

      noValidateIfNoIntegrate();
    }
    return result;

    function noValidateIfNoIntegrate() {
      if (userSettings?.integrateBirthday === false)
        result.birthday = undefined;

      if (userSettings?.integrateCellphone === false)
        result.cellphone = undefined;

      if (userSettings?.integrateDepartment === false)
        result.department = undefined;

      if (userSettings?.integrateJobTitle === false)
        result.jobTitle = undefined;

      if (userSettings?.integrateDocument === false)
        result.document = undefined;
    }
  }

  function handleSaveDataUser() {
    const errors = findErrors();
    const errorExist = setNewValidationErrors(errors);
    if (!errorExist) {
      setButtonDisable(true);
      setLoading(true);
      createDataUser();
    }

    function createDataUser() {
      setLoading(true);
      const birthdayValue = formatDateToSend(birthday);

      const payload: Array<BrainzUserPayload> = [
        {
          identifier,
          firstName,
          lastName,
          displayName,
          email,
          password,
          birthday: birthdayValue !== "" ? birthdayValue : undefined,
          cellphone,
          department,
          jobTitle,
          document,
          registration,
        },
      ];
      UserApiInterface.post(
        `/User/${institutionId}/${userType}/Insert`,
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            const errors = response.data.result[0].errors;
            if (!errors || errors.length === 0) {
              showToast("info", "Concluído com sucesso.");
              setActiveModal(5);
              setButtonDisable(false);
              setUpdateUser(true);
              setUpdateUser(false);
              setLoading(false);
            } else {
              setButtonDisable(false);
              setLoading(false);
              if (errors[0].code === "US018") {
                showToast(
                  "error",
                  "Senha cadastrada inválida, verifique os requisitos no icone acima do campo senha"
                );
              }
              if (errors[0].code === "US016") {
                showToast("error", "Domínio do e-mail é inválido");
              } else {
                showToast("error", t("errors." + errors[0].code));
              }
            }
          } else {
            setButtonDisable(false);
            setLoading(false);
            showToast("error", "Algo deu errado, tente novamente mais tarde");
          }
        })
        .catch((error) => {
          setButtonDisable(false);
          setLoading(false);
          showToast("error", "Algo deu errado, tente novamente mais tarde");
          console.error("Erro => ", error)
        });
    }
  }

  function getNameByUserType(userType: number) {
    switch (userType) {
      case 1:
        return "Administrativos";
      case 2:
        return "Assistentes";
      case 3:
        return "Coordenadores";
      case 4:
        return "Alunos";
      case 5:
        return "Professores";
      default:
        return "Usuarios";
    }
  }

  useEffect(() => {
    if (activeModal === 2) {
      setNewValidationErrors({});
      setUserSettings(undefined);
      loadUserSettings(userType);
    }
    function loadUserSettings(userType: number) {
      ApiInterface.get(`/UserIntegrationSettings/${institutionId}/GetAll`)
        .then((response) => {
          const userSettingsList: BrainzUserSettingsViewModel[] =
            response.data.result;
          const userSettingsItem: BrainzUserSettingsViewModel =
            userSettingsList.filter((x) => x.userType === userType)[0];
          setUserSettings(userSettingsItem);
        })
        .catch((error) => {
          showToast("error", "Algo deu errado, tente novamente mais tarde");
          console.error("Erro => ", error)
        });
    }
  }, [userType, institutionId]);

  useEffect(() => {
    updateValidation();

    function updateValidation() {
      validationForm.current = {
        identifier,
        firstName,
        lastName,
        displayName,
        email,
        password,
        birthday,
        cellphone,
        department,
        jobTitle,
        document,
        registration,
      };
    }
  }, [
    identifier,
    firstName,
    lastName,
    displayName,
    email,
    password,
    birthday,
    cellphone,
    department,
    jobTitle,
    document,
    registration,
  ]);

  useEffect(() => {
    setNewValidationErrors({});
  }, [institutionId]);

  useEffect(() => {
    if (activeModal === 0 || activeModal === 1) {
      clearModalUser();
    }
  }, [activeModal]);

  return (
    <>
      {configurationProfile && (
        <ModalGenericStyles
          show={activeModal === 2}
          onHide={() => {
            setActiveModal(6);
            setPreviousActiveModal(2);
          }}
        >
          {loading ? (
            <PageLoader />
          ) : (
            <>
              <Modal.Header>
                <Modal.Title>
                  <h2>
                    Adicionar Registro <BsChevronRight size={18} /> Pessoa
                  </h2>
                </Modal.Title>
                <Button
                  className="cursor-pointer"
                  variant="white"
                  onClick={() => {
                    setActiveModal(6);
                    setPreviousActiveModal(2);
                  }}
                >
                  <BsX color="var(--bs-modal-color)" size={28} />
                </Button>
              </Modal.Header>

              <Modal.Body className="mb-2">
                <Form>
                  <Form.Group as={Row}>
                    <Form.Label column sm={12}>
                      Tipo de pessoa
                    </Form.Label>
                    <Col sm={12}>
                      <BrainzComboBox
                        setValue={(val) => [
                          setUserType(val),
                          setUserTypeString(val),
                        ]}
                        value={userTypeString}
                        idField="id"
                        valueField="name"
                        placeholder="Qual o perfil dessa pessoa?"
                        options={configurationProfile.map((item: any) => {
                          return {
                            name: getNameByUserType(item.userType),
                            value: item.userType,
                          };
                        })}
                        search={false}
                        isInvalid={isInvalidFeedBack(
                          "userType",
                          validationErrors
                        )}
                      />
                      <Form.Control.Feedback type="invalid">
                        {feedBack("userType", validationErrors)}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  {userType === 0 ? (
                    ""
                  ) : (
                    <>
                      <Form.Group as={Row}>
                        <Form.Label column sm={12}>
                          Primeiro nome
                        </Form.Label>
                        <Col sm={12}>
                          <Form.Control
                            placeholder=""
                            size="sm"
                            type="text"
                            value={firstName}
                            isInvalid={isInvalidFeedBack(
                              "firstName",
                              validationErrors
                            )}
                            onChange={(e) => {
                              handleFirstNameOnChange(e.target.value);
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            {feedBack("firstName", validationErrors)}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm={12}>
                          Último nome
                        </Form.Label>
                        <Col sm={12}>
                          <Form.Control
                            placeholder=""
                            size="sm"
                            type="text"
                            value={lastName}
                            isInvalid={isInvalidFeedBack(
                              "lastName",
                              validationErrors
                            )}
                            onChange={(e) => {
                              handleLastNameOnChange(e.target.value);
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            {feedBack("lastName", validationErrors)}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label column sm={12}>
                          Nome apresentação
                        </Form.Label>
                        <Col sm={12}>
                          <Form.Control
                            placeholder=""
                            size="sm"
                            type="text"
                            value={displayName}
                            isInvalid={isInvalidFeedBack(
                              "displayName",
                              validationErrors
                            )}
                            onChange={(e) => {
                              handleDisplayNameOnChange(e.target.value);
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            {feedBack("displayName", validationErrors)}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label column sm={12}>
                          Email
                        </Form.Label>
                        {userSettings?.defaultEmail === 1 ||
                          userSettings?.defaultEmail === 5 ? (
                          <Col sm={12}>
                            <Form.Control
                              placeholder=""
                              size="sm"
                              type="text"
                              value={email}
                              isInvalid={isInvalidFeedBack(
                                "email",
                                validationErrors
                              )}
                              onChange={(e) => {
                                handleEmailOnChange(e.target.value);
                              }}
                            />
                            <Form.Control.Feedback type="invalid">
                              {feedBack("email", validationErrors)}
                            </Form.Control.Feedback>
                          </Col>
                        ) : (
                          <Col sm={12}>
                            <Form.Control
                              placeholder=""
                              disabled={true}
                              size="sm"
                              type="text"
                              value="E-mail será gerado automaticamente"
                            />
                          </Col>
                        )}
                      </Form.Group>

                      <Form.Group as={Row}>
                        {userSettings?.defaultPassword === 3 ? (
                          <>
                            <Form.Label column sm={12}>
                              <span className="mr-2">Senha</span>
                              <CustomTooltip
                                type="info"
                                iconColor="#6F6F6F"
                                iconSize={18}
                                text={passwordRequirementsMsg}
                                placement="right"
                                isHTMLText
                              />
                            </Form.Label>
                            <Col sm={12}>
                              <Form.Control
                                placeholder=""
                                size="sm"
                                type="password"
                                value={password}
                                isInvalid={isInvalidFeedBack(
                                  "password",
                                  validationErrors
                                )}
                                onChange={(e) => {
                                  handlePasswordOnChange(e.target.value);
                                }}
                              />
                              <Form.Control.Feedback type="invalid">
                                {feedBack("password", validationErrors)}
                              </Form.Control.Feedback>
                            </Col>
                          </>
                        ) : (
                          <>
                            <Form.Label column sm={12}>
                              Senha
                            </Form.Label>
                            <Col sm={12}>
                              <Form.Control
                                placeholder=""
                                disabled={true}
                                size="sm"
                                type="text"
                                value={
                                  userSettings?.defaultPassword === 1
                                    ? "Será utilizada a senha fixa padrão"
                                    : userSettings?.defaultPassword === 2
                                      ? "A senha será gerada automaticamente"
                                      : "Será gerada uma senha aleatória"
                                }
                              />
                            </Col>
                          </>
                        )}
                      </Form.Group>

                      {userSettings?.integrateBirthday ? (
                        <Form.Group as={Row}>
                          <Form.Label column sm={12}>
                            Data de nascimento
                          </Form.Label>
                          <Col sm={12}>
                            <Form.Control
                              placeholder=""
                              size="sm"
                              type="text"
                              value={birthday}
                              onChange={(e) => {
                                handleBirthdayOnChange(e.target.value);
                              }}
                            />
                          </Col>
                        </Form.Group>
                      ) : null}

                      {userSettings?.integrateCellphone ? (
                        <Form.Group as={Row}>
                          <Form.Label column sm={12}>
                            Celular
                          </Form.Label>
                          <Col sm={12}>
                            <Form.Control
                              placeholder=""
                              size="sm"
                              type="text"
                              value={cellphone}
                              onChange={(e) => {
                                handleCellphoneOnChange(e.target.value);
                              }}
                            />
                          </Col>
                        </Form.Group>
                      ) : null}

                      {userSettings?.integrateDepartment ? (
                        <Form.Group as={Row}>
                          <Form.Label column sm={12}>
                            Departamento
                          </Form.Label>
                          <Col sm={12}>
                            <Form.Control
                              placeholder=""
                              size="sm"
                              type="text"
                              value={department}
                              onChange={(e) => {
                                handleDepartmentOnChange(e.target.value);
                              }}
                            />
                          </Col>
                        </Form.Group>
                      ) : null}

                      {userSettings?.integrateJobTitle ? (
                        <Form.Group as={Row}>
                          <Form.Label column sm={12}>
                            Cargo
                          </Form.Label>
                          <Col sm={12}>
                            <Form.Control
                              placeholder=""
                              size="sm"
                              type="text"
                              value={jobTitle}
                              onChange={(e) => {
                                handleJobTitleOnChange(e.target.value);
                              }}
                            />
                          </Col>
                        </Form.Group>
                      ) : null}
                      {userSettings?.integrateDocument ? (
                        <Form.Group as={Row}>
                          <Form.Label column sm={12}>
                            Documento
                          </Form.Label>
                          <Col sm={12}>
                            <Form.Control
                              placeholder=""
                              size="sm"
                              type="text"
                              value={document}
                              onChange={(e) => {
                                handleDocumentOnChange(e.target.value);
                              }}
                            />
                          </Col>
                        </Form.Group>
                      ) : null}
                      <Form.Group as={Row}>
                        <Form.Label column sm={12}>
                          Matrícula
                        </Form.Label>
                        <Col sm={12}>
                          <Form.Control
                            placeholder=""
                            size="sm"
                            type="text"
                            value={registration}
                            isInvalid={isInvalidFeedBack(
                              "registration",
                              validationErrors
                            )}
                            onChange={(e) => {
                              handleRegistrationOnChange(e.target.value);
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            {feedBack("registration", validationErrors)}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm={12}>
                          Identificador
                        </Form.Label>
                        <Col sm={12}>
                          <Form.Control
                            placeholder=""
                            size="sm"
                            type="text"
                            value={identifier}
                            isInvalid={isInvalidFeedBack(
                              "identifier",
                              validationErrors
                            )}
                            onChange={(e) => {
                              handleIdentifierOnChange(e.target.value);
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            {feedBack("identifier", validationErrors)}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                    </>
                  )}
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="cancel-operation"
                  onClick={() => {
                    setActiveModal(6);
                    setPreviousActiveModal(2);
                  }}
                >
                  <BsX color="#A56300" size="24" />
                  Descartar Pessoa
                </Button>
                <Button
                  variant="primary"
                  className="sucess-button"
                  disabled={buttonDisable}
                  onClick={handleSaveDataUser}
                >
                  Salvar Pessoa
                  <BsCheck2 color="#fff" size={24} />
                </Button>
              </Modal.Footer>
            </>
          )}
        </ModalGenericStyles>
      )}
    </>
  );
}
