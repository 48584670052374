import { useContext } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { BsExclamationCircle, BsX } from "react-icons/bs";

import { ActiveModalIntegrationContext } from "../AutomaticIntegrationContexts/ActiveModalIntegrationContext";
import { ModalGAutomaticIntegrationErrorStyles } from "./styles";

// 0-fechado
// 1-TypeIntegration
// 2- SQL-First Integration
// 3 - API-First Integration
// 4 - Cancel Integration
// 5 - Success Integration

export default function ModalCancelIntegration() {
  const { setActiveModal, activeModal, previousActiveModal } = useContext(
    ActiveModalIntegrationContext
  );

  return (
    <ModalGAutomaticIntegrationErrorStyles show={activeModal === 4}>
      <Modal.Body>
        <Row className="pb-2 ml-1">
          <Col>
            <BsExclamationCircle size={110} color="#C57700" />
          </Col>
        </Row>
        <Row className="my-3" onClick={() => setActiveModal(0)}>
          <Col>
            <h2>Deseja cancelar a configuração?</h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col>
            <h3>
              Você perderá todos as configurações feitas para este tipo de
              integração.
            </h3>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button className="cancel-button" onClick={() => setActiveModal(0)}>
          <BsX className="icon-close" size={28} color="#A56300" />
          Cancelar Configuração
        </Button>
        <Button
          variant="primary"
          className="sucess-button"
          onClick={() => setActiveModal(previousActiveModal)}
        >
          Continuar Configuração
        </Button>
      </Modal.Footer>
    </ModalGAutomaticIntegrationErrorStyles>
  );
}
