import { useContext } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { BsCheck2, BsExclamationCircle } from "react-icons/bs";

import { ActiveModalIntegrationContext } from "../AutomaticIntegrationContexts/ActiveModalIntegrationContext";
import { ModalGAutomaticIntegrationErrorStyles } from "./styles";

export default function ModalPendingIntegration() {
  const { activeModal, setActiveModal } = useContext(
    ActiveModalIntegrationContext
  );

  return (
    <ModalGAutomaticIntegrationErrorStyles show={activeModal === 7}>
      <Modal.Body>
        <Row className="pb-2 ml-1">
          <Col>
            <BsExclamationCircle size={110} color="#C57700" />
          </Col>
        </Row>
        <Row className="my-3" onClick={() => setActiveModal(0)}>
          <Col>
            <h2>Configuração pendente</h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col>
            <h3>
              Você preencheu todos os dados necessários, mas não conseguimos
              fazer a conexão. Confira suas configurações.
            </h3>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          className="sucess-button"
          onClick={() => setActiveModal(0)}
        >
          Fechar janela
          <BsCheck2 color="#fff" size={22} />
        </Button>
      </Modal.Footer>
    </ModalGAutomaticIntegrationErrorStyles>
  );
}
