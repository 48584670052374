import { Row, Col } from "react-bootstrap";
import {
  BsChevronRight,
  BsClipboardData,
  BsClockHistory,
  BsLightningCharge,
  BsUpload,
} from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

import imageIntegrations from "../../assets/images/image-integrations.png";
import { isValidRoute } from "../../helpers/functions";
import {
  CardComponentStyles,
  InfoPageStyles,
} from "../../styles/infoPageStyles";

const IntegrationsPage = () => {
  const navigate = useNavigate();

  const cookie = new Cookies();
  const institutionId = cookie.get(process.env.REACT_APP_BRAINZ_INSTITUTIONID!);

  const currentInstitutionID = isValidRoute(institutionId)

  const redirectToSyncData = () => {
    navigate(`/sync-data?id=${currentInstitutionID}`);
  };
  const redirectToAutomaticIntegration = () => {
    navigate(`/automatic-integration?id=${currentInstitutionID}`);
  };
  const redirectToManualIntegration = () => {
    navigate(`/manual-integration?id=${currentInstitutionID}`);
  };
  const redirectToLogs = () => {
    navigate(`/Logs?id=${currentInstitutionID}`);
  };
  return (
    <>
      <InfoPageStyles>
        <section className="headingStickyPage">
          <Row className="title-page">Integrações</Row>
          <Row className="sub-title mt-3">
            Configure as formas com que seus dados serão integrados, ou faça
            integrações em tela.
          </Row>
        </section>
        <Row>
          <Row className="pt-2">
            <CardComponentStyles md={12} onClick={redirectToSyncData}>
              <Col md={10} className="">
                <Row className="name-card">
                  <i className="icone bx-grid-alt mr-3">
                    <BsClipboardData size={24} color="#525252" />
                  </i>{" "}
                  Dados Sincronizados
                </Row>
                <Row className="description-card mt-2">
                  Confira os dados integrados de forma automática, manual ou via
                  tela e faça a integração de novos usuários via tela.
                </Row>
              </Col>
              <Col md={1} className="arrow-right">
                <i className="icone bx-grid-alt mr-3">
                  <BsChevronRight size={24} color="#0F7EAA" />
                </i>
              </Col>
            </CardComponentStyles>
            <CardComponentStyles
              md={12}
              onClick={redirectToAutomaticIntegration}
            >
              <Col md={10} className="">
                <Row className="name-card">
                  <i className="icone bx-grid-alt mr-3">
                    <BsLightningCharge size={24} color="#525252" />
                  </i>
                  Integração Automática
                </Row>
                <Row className="description-card mt-2">
                  Realize as configurações necessárias para fazer uma integração
                  automática via banco de dados ou API.{" "}
                </Row>
              </Col>
              <Col md={1} className="arrow-right">
                <i className="icone bx-grid-alt mr-3">
                  <BsChevronRight size={24} color="#0F7EAA" />
                </i>
              </Col>
            </CardComponentStyles>
            <CardComponentStyles md={12} onClick={redirectToManualIntegration}>
              <Col md={10} className="">
                <Row className="name-card">
                  <i className="icone bx-grid-alt mr-3">
                    <BsUpload size={24} color="#525252" />
                  </i>
                  Integração Manual
                </Row>
                <Row className="description-card mt-2">
                  Realize as integrações de forma manual seguindo as orientações
                  e o template fornecido.
                </Row>
              </Col>
              <Col md={1} className="arrow-right">
                <i className="icone bx-grid-alt mr-3">
                  <BsChevronRight size={24} color="#0F7EAA" />
                </i>
              </Col>
            </CardComponentStyles>
            <CardComponentStyles md={12} onClick={redirectToLogs}>
              <Col md={10} className="">
                <Row className="name-card">
                  <i className="icone bx-grid-alt mr-3">
                    <BsClockHistory size={24} color="#525252" />
                  </i>
                  Logs de Execução
                </Row>
                <Row className="description-card mt-2">
                  Veja detalhes sobre os usuários integrados e seus erros.
                </Row>
              </Col>
              <Col md={1} className="arrow-right">
                <i className="icone bx-grid-alt mr-3">
                  <BsChevronRight size={24} color="#0F7EAA" />
                </i>
              </Col>
            </CardComponentStyles>
          </Row>
          <div className="image-back">
            <img src={imageIntegrations} alt="Integrações" />
          </div>
        </Row>
      </InfoPageStyles>
    </>
  );
};
export default IntegrationsPage;
