import { useState, useEffect } from "react";
import { Form, Row, Col, Card, Button } from "react-bootstrap";
import {
  BsArrowRight,
  BsShieldLock,
  BsPersonCheck,
  BsArrowLeft,
  BsCheck,
  BsHouseDoor,
  BsFiles,
  BsExclamationCircle,
} from "react-icons/bs";
import Cookies from "universal-cookie";

import PageLoader from "../../components/Common/Loader";
import { showToast } from "../../components/Common/Toast";
import Footer from "../../components/layouts/Footer";
import { formatStringToDate } from "../../helpers/DateTime";
import {
  invalidFieldMsg,
  requiredFieldMsg,
} from "../../helpers/messagesValidation";
import { isValidDate } from "../../helpers/Validation";
import {
  useValidation,
  isInvalidFeedBack,
  feedBack,
} from "../../hooks/Validation";
import PortalInterface from "../../services/PortalInterface";
import "./styles.css";


function Portal() {
  const cookie = new Cookies();
  function useQuery() {
    return new URLSearchParams(window.location.search);
  }

  const query = useQuery().get("q");
  if (query !== null && query !== "") {
    cookie.set(process.env.REACT_APP_BRAINZ_INSTITUTIONID!, query);
    window.location.href = window.location.origin + "/portal";
  }

  const institutionId = cookie.get(process.env.REACT_APP_BRAINZ_INSTITUTIONID!);
  const [forceReload, setForceReload] = useState(1);
  const [firstValidator, setFirstValidator] = useState(2);
  const [secondValidator, setSecondValidator] = useState(1);
  const [logoUrl, setLogoUrl] = useState("");
  const [backgroundUrl, setBackgroundUrl] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [document, setDocument] = useState("");
  const [name, setName] = useState("");
  const [registration, setRegistration] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [
    validationForm,
    setValidationFormField,
    validationErrors,
    setNewValidationErrors,
  ] = useValidation();

  useEffect(() => {
    loadPage();

    function loadPage() {
      setLoading(true);
      PortalInterface.get(`/PortalSettings/${institutionId}/Get`)
        .then((response) => {
          if (response.data.result.id !== null) {
            const settings = response.data.result;
            setLogoUrl(settings.logoUrl);
            setBackgroundUrl(settings.backgroundUrl);
            setFirstValidator(settings.firstValidator);
            setSecondValidator(settings.secondValidator);
          }
          setLoading(false);
        })
        .catch(() => setLoading(false));
    }
  }, [forceReload]);

  const originalIdNameFirst: string =
    firstValidator === 1
      ? "name"
      : firstValidator === 2
        ? "registration"
        : firstValidator === 3
          ? "birthDate"
          : "document";

  const originalIdNameSecond: string =
    secondValidator === 1
      ? "name"
      : secondValidator === 2
        ? "registration"
        : secondValidator === 3
          ? "birthDate"
          : "document";

  const labelPlaceholderFirst: string =
    firstValidator === 1
      ? "Nome Completo"
      : firstValidator === 2
        ? "Matrícula"
        : firstValidator === 3
          ? "Data de Nascimento (Dia/Mês/Ano)"
          : "CPF";

  const labelPlaceholderSecond: string =
    secondValidator === 1
      ? "Nome Completo"
      : secondValidator === 2
        ? "Matrícula"
        : secondValidator === 3
          ? "Data de Nascimento (Dia/Mês/Ano)"
          : "CPF";

  const handleOnChangeFormsControl = (e: any, modifier: any) => {
    if (!modifier) return;

    if (modifier === 1) {
      setName(e.target.value);
      setValidationFormField("name", e.target.value);
      return;
    }

    if (modifier === 2) {
      setRegistration(e.target.value);
      setValidationFormField("registration", e.target.value);
      return;
    }

    if (modifier === 3) {
      setBirthDate(e.target.value);
      setValidationFormField("birthDate", e.target.value);
      return;
    }

    if (modifier === 4) {
      setDocument(e.target.value);
      setValidationFormField("document", e.target.value);

    }
  };

  function requestInfo() {
    const errors = findErrors();
    const errorExist = setNewValidationErrors(errors);
    if (!errorExist) {
      setLoading(true);
      const payload = {
        name,
        registrationNumber: registration,
        birthDate:
          formatStringToDate(birthDate) === ""
            ? null
            : formatStringToDate(birthDate),
        document,
      };
      PortalInterface.post(`/PortalSettings/${institutionId}/Validate`, payload)
        .then((response) => {
          const result = response.data.result;
          if (result.email !== null) {
            setEmail(result.email);
            setPassword(result.password);
            setStep(3);
          } else {
            showToast("error", "Credenciais não encontradas!");
          }
          setLoading(false);
        })
        .catch(() => setLoading(false));
    }
  }

  function findErrors() {
    const result: any = {};

    if (validationForm.current) {
      if (firstValidator !== 1 && secondValidator !== 1)
        result.name = undefined;
      else if (
        !validationForm.current.name ||
        validationForm.current.name === ""
      )
        result.name = requiredFieldMsg;
      else result.name = undefined;

      if (firstValidator !== 3 && secondValidator !== 3)
        result.birthDate = undefined;
      else if (
        !validationForm.current.birthDate ||
        validationForm.current.birthDate === ""
      )
        result.birthDate = requiredFieldMsg;
      else if (!isValidDate(validationForm.current.birthDate))
        result.birthDate = invalidFieldMsg;
      else result.birthDate = undefined;

      if (firstValidator !== 4 && secondValidator !== 4)
        result.document = undefined;
      else if (
        !validationForm.current.document ||
        validationForm.current.document === ""
      )
        result.document = requiredFieldMsg;
      else result.document = undefined;

      if (firstValidator !== 2 && secondValidator !== 2)
        result.registration = undefined;
      else if (
        !validationForm.current.registration ||
        validationForm.current.registration === ""
      )
        result.registration = requiredFieldMsg;
      else result.registration = undefined;
    }
    return result;
  }

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div className="portalApp">
      <div
        className="container-fixed-height-portal"
        style={{
          backgroundImage: "url(" + backgroundUrl + ")",
          width: "100%",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="container pt-5">
          {institutionId === "" || institutionId === undefined ? (
            <div style={{ textAlign: "center" }}>
              <Row className="my-4">
                <Col>
                  <BsExclamationCircle color="#A56300" size={110} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <h3> Instituição inexistente!</h3>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>Contate o Administrador</p>
                </Col>
              </Row>
            </div>
          ) : (
            <Card className="d-block m-auto mainCardPortalContainer">
              <Card.Body className="pb-4 pl-md-4 pr-md-4 pt-3">
                {step === 1 && (
                  <div>
                    <div
                      className="d-block m-auto"
                      style={{
                        backgroundImage: "url(" + logoUrl + ")",
                        height: "100px",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPositionX: "center",
                      }}
                    />
                    <div className="mt-3 font-weight-bold">BOAS-VINDAS!</div>
                    <div className="mt-3">Aqui você pode:</div>
                    <div className="mt-3">
                      <BsShieldLock className="mr-2"></BsShieldLock> Ver o
                      e-mail e senha que você vai utilizar nos portais da
                      instituição
                    </div>
                    <div className="mt-2">
                      <BsPersonCheck className="mr-2"></BsPersonCheck> Fazer o
                      primeiro acesso usando seu novo e-mail
                    </div>
                    <Button
                      className="mt-4 w-100 text-center btn-portal-container"
                      onClick={() => setStep(2)}
                    >
                      <span>Começar</span>
                      <BsArrowRight />
                    </Button>
                  </div>
                )}
                {step === 2 && (
                  <div>
                    <Row>
                      <Col md="5">
                        <Button
                          variant="outline-primary"
                          className="mt-3 w-100 text-center btn-portal-container "
                          onClick={() => setStep(1)}
                        >
                          <BsArrowLeft />
                          <span>Voltar</span>
                        </Button>
                      </Col>
                      <Col md="2"></Col>
                      <Col md="5">
                        <div
                          className="d-block m-auto"
                          style={{
                            backgroundImage: "url(" + logoUrl + ")",
                            height: "100px",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPositionX: "center",
                          }}
                        ></div>
                      </Col>
                    </Row>
                    <div className="mb-3">
                      Confirme seus dados para continuar:
                    </div>
                    {firstValidator && secondValidator && (
                      <>
                        <Form.Group>
                          <Form.Label>{labelPlaceholderFirst}</Form.Label>
                          <Form.Control
                            size="sm"
                            placeholder={labelPlaceholderFirst}
                            isInvalid={isInvalidFeedBack(
                              originalIdNameFirst,
                              validationErrors
                            )}
                            onChange={(e) =>
                              handleOnChangeFormsControl(e, firstValidator)
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {feedBack(originalIdNameFirst, validationErrors)}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <br />
                        <Form.Group>
                          <Form.Label>{labelPlaceholderSecond}</Form.Label>
                          <Form.Control
                            size="sm"
                            placeholder={labelPlaceholderSecond}
                            isInvalid={isInvalidFeedBack(
                              originalIdNameSecond,
                              validationErrors
                            )}
                            onChange={(e) =>
                              handleOnChangeFormsControl(e, secondValidator)
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {feedBack(originalIdNameSecond, validationErrors)}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </>
                    )}

                    <Button
                      className="mt-4 text-center w-100 btn-portal-container "
                      onClick={() => requestInfo()}
                    >
                      <span>Confirmar</span>
                      <BsCheck size={20} />
                    </Button>
                  </div>
                )}
                {step === 3 && (
                  <div>
                    <Row>
                      <Col md="5">
                        <Button
                          variant="outline-primary"
                          className="mt-3 w-100 text-center btn-portal-container"
                          onClick={() => setStep(1)}
                        >
                          <BsHouseDoor />
                          <span>Início</span>
                        </Button>
                      </Col>
                      <Col md="2"></Col>
                      <Col md="5">
                        <div
                          className="d-block m-auto"
                          style={{
                            backgroundImage: "url(" + logoUrl + ")",
                            height: "100px",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPositionX: "center",
                          }}
                        ></div>
                      </Col>
                    </Row>
                    <div className="mb-2">Aqui estão seus dados de acesso:</div>
                    <div className="mb-1">E-mail:</div>
                    <div className="mb-1 font-weight-bold">{email}</div>
                    <div
                      className="btn btn-sm mb-1 text-info"
                      onClick={() => {
                        navigator.clipboard.writeText(email);
                        showToast("info", "Copiado!");
                      }}
                    >
                      <BsFiles className="mr-2"></BsFiles> Copiar e-mail
                    </div>
                    <div className="mb-1">Senha:</div>
                    <div className="mb-1 font-weight-bold">{password}</div>
                    <div
                      className="btn btn-sm mb-1 text-info"
                      onClick={() => {
                        navigator.clipboard.writeText(password);
                        showToast("info", "Copiado!");
                      }}
                    >
                      <BsFiles className="mr-2"></BsFiles> Copiar senha
                    </div>
                    <div className="mt-2">
                      Utilize-os para acessar as plataformas da instituição
                    </div>
                  </div>
                )}
              </Card.Body>
            </Card>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Portal;
