import { Col } from "react-bootstrap";
import styled from "styled-components";

export const InitialHeaderStyles = styled(Col)`
  .customTooltipButton {
    background: unset;
    width: 40px;
    margin-right: 1rem;
  }

  & > .row {
    margin-left: 0 !important;
    margin-right: 0 !important;

    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .headerWithButton {
    display: flex;
    justify-content: space-between;
    padding-left: 15px !important;

    section {
      display: flex;
      flex-direction: column;
    }
    button {
      width: 48px;
      height: 48px;
      outline: unset;
      box-shadow: unset;

      &:hover {
        & svg {
          fill: #fff;
        }
      }
    }

    .group-buttons {
      display: flex;
      justify-content: flex-end;
      gap: 1rem;

      .export-button {
        width: auto;
      }
    }
  }

  .button-edit {
    h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #0f7eaa;
    }
  }
  .div-space {
    place-content: space-between;
  }
  .div-body {
    margin: 0px 10px 0px 10px;
    place-content: space-between;
    border-bottom: 1px solid #e4e3e3;
    h6 {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #a56300;
    }
  }
  .div-body-links {
    margin: 0px 10px 0px 10px;
    place-content: space-between;
    align-items: center;
    //border-bottom: 1px solid #E4E3E3;
    h6 {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #a56300;
    }
  }
  .title-key {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #6f6f6f;
  }
  .row-bottom {
    padding: 10px 0px 10px 0px;
    border-bottom: 1px solid #e4e3e3;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #525252;
  }
  .col-left {
    border-left: 1px solid #e4e3e3;
  }
  .div-body-entity {
    margin: 12px 10px 12px 10px;
    padding: 10px;
    place-content: space-between !important;
    border-bottom: 1px solid #e4e3e3;
    h6 {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #a56300;
    }
  }
  .card-integration {
    width: 100%;
    //height: 135px;
    background: #ffffff;
    border: 1px solid #e4e3e3;
    box-sizing: border-box;
    border-radius: 8px;
    .title-card {
      height: 56px;
      border-bottom: 2px solid #e4e3e3;
      h3 {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        color: #525252;
      }
      h2 {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #525252;
      }
    }
    .title-card-bottom {
      h2 {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #525252;
      }
    }
    h4 {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #525252;
    }
    h5 {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #6f6f6f;
    }
  }
  .col-fullscreen-button {
    text-align: right;
  }
  h2 {
    font-weight: 600px;
    font-size: 20px;
    line-height: 24px;
    color: #424040;
  }
  h3 {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #6f6f6f;
  }
  h4 {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #6f6f6f;
  }
  .div-center {
    text-align: center;
    margin: 10%;
  }

  .div-data-integration-card,
  .div-data-integration-card-warn,
  .div-data-integration-card-error {
    margin: 25px 0;
    padding: 15px 0;

    display: flex;
    align-items: center;
    justify-content: space-between;
    align-content: center;
    gap: 15px;

    background: #f5f5f5;
    border: 1px solid #0f7eaa;
    box-sizing: border-box;
    border-radius: 8px;

    h2 {
      margin: 0;
      font-size: 16px;
      line-height: 24px;
      color: #525252;
    }

    h3 {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #0f7eaa;
    }

    h6 {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #a56300;
    }
  }

  .div-data-integration-card-warn {
    background: #ffdb6d;
    border-color: #ffc107;
  }

  .div-data-integration-card-error {
    border-color: #d43b26;
    h2 {
      color: #d43b26;
    }
  }

  Button {
    max-width: 262px;
    height: 48px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
  }
  .btn-outline-primary {
    border-color: #2155ba;
    color: #2155ba;

    &:hover {
      background-color: #2155ba;
      color: #fff;
    }
  }

  .headingStickySubPages {
    position: sticky;
    top: 0;
    z-index: 22 !important;
    background-color: #fbfbff;

    padding: 1rem 0;

    .row {
      margin: 0;
    }
  }
`;

export const ItemLogsNavBar = styled(Col)`
  z-index: 100;
  max-width: 80vw;

  .nav-link {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    background-color: #fbfbff !important;
    color: #514f4e !important;
  }
  .nav-link.active {
    font-weight: 600;
    border-bottom: 4px solid #0f7eaa;
  }
`;
