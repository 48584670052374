import { Row, Col, Button } from "react-bootstrap";
import { BsArrowRight } from "react-icons/bs";

import NotResultImage from "../../../assets/images/not-result-image.png";
import { InitialHeaderStyles } from "../../../styles/initialStyles";

interface FirstConfigurationProps {
  headingText: string;
  description?: string;
  handleClick: () => void;
  buttonText: string;
}

const FirstConfiguration = ({
  headingText,
  description,
  handleClick,
  buttonText,
}: FirstConfigurationProps) => {
  return (
    <InitialHeaderStyles className=" mx-0 px-0">
      <div className="div-center">
        <img src={NotResultImage} alt="Sem resultados" />
        <br />
        <br />
        <Row>
          <Col md={12} className="">
            <h2>{headingText}</h2>
          </Col>
        </Row>
        <Row className="my-3">
          <Col md={12} className="">
            <h3>{description}</h3>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="">
            <Button onClick={handleClick}>
              {buttonText}
              <BsArrowRight className="ml-2" color="#fff" size={24} />
            </Button>
          </Col>
        </Row>
      </div>
    </InitialHeaderStyles>
  );
};

export default FirstConfiguration;
