import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { Button, Form, Row, Col, Modal, Table } from "react-bootstrap";
import {
  BsCheck,
  BsCheckCircle,
  BsInfoCircle,
  BsPencil,
  BsX,
  BsXCircle,
  BsXOctagon,
} from "react-icons/bs";
import Cookies from "universal-cookie";

import BrainzComboBox from "../../../components/Common/BrainzComboBox";
import BrainzPagination, {
  PaginationProps,
} from "../../../components/Common/BrainzPagination/BrainzPagination";
import CustomTooltip from "../../../components/Common/CustomTooltip";
import Search from "../../../components/Common/Input/Search";
import PageLoader from "../../../components/Common/Loader";
import NoResults from "../../../components/Common/NoResults";
import { showToast } from "../../../components/Common/Toast";
import { handleGetPageSize } from "../../../helpers/functions";
import { useValidation, feedBack } from "../../../hooks/Validation";
import ApiInterface from "../../../services/ApiInterface";
import UnitApiInterface from "../../../services/UnitApiInterface";
import {
  FiltersStyleComponentUser,
  TableSyncDataStyles,
} from "../../../styles/generic";
import { ModalGenericStyles } from "../../../styles/modalsGeneric";
import { ActiveModalContext } from "../SyncDataContexts/ActiveModalContext";

import "../../../styles/tableCustomStyles.css";
import "./styles.css";

export interface BrainzUserViewModel {
  id: string;
  identifier: string;
  firstName: string;
  lastName: string;
  displayName: string;
  email: string;
  password: string;
  birthday: string;
  cellphone: string;
  department: string;
  jobTitle: string;
  document: string;
  registration: string;
  isStudent: boolean;
  isTeacher: boolean;
  isAdministrative: boolean;
  isAssistant: boolean;
  isCoordinator: boolean;
  creationDate: Date;
  active: boolean;
}

export interface BrainzUserPayload {
  id?: string;
  identifier: string;
  firstName: string;
  lastName: string;
  displayName: string;
  email: string;
  password: string;
  birthday?: string;
  cellphone: string;
  department: string;
  jobTitle: string;
  document: string;
  registration: string;
}

export interface BrainzScreenUserPayload {
  payload: BrainzUserPayload;
  types: Array<number>;
}

export interface LogViewModel {
  tableName: string;
  institutionId: string;
  logId: string;
  identifier: string;
  integrationId: string;
  type: number;
  code: string;
  message: string;
  integrationType: number;
  creationDate: string;
  operationType: number;
}

interface BrainzUnitViewModel {
  id: string;
  name: string;
  identifier: string;
  creationDate: Date;
  active: boolean;
  serviceId: string;
}

export interface BrainzUnitPayload {
  id?: string;
  name: string;
  identifier: string;
}

export default function SyncDataUnit() {
  const { updateUnit, setUpdateUnit } = useContext(ActiveModalContext);

  const cookie = new Cookies();
  const institutionId = cookie.get(process.env.REACT_APP_BRAINZ_INSTITUTIONID!);

  const [loading, setLoading] = useState(true);
  const [loadingModal, setLoadingModal] = useState(true);
  const [
    validationForm,
    setValidationFormField,
    validationErrors,
    setNewValidationErrors,
  ] = useValidation();
  const [id, setId] = useState("");
  const [identifier, setIdentifier] = useState("");
  const [name, setName] = useState("");
  const [paging, setPaging] = useState<PaginationProps | undefined>();
  const [activeFilter, setActiveFilter] = useState("");
  const [searchFilter, setSearchFilter] = useState<string>("");
  const [units, setUnits] = useState<Array<BrainzUnitViewModel> | undefined>();
  const [itemDetails, setItemDetails] = useState<
    BrainzUnitViewModel | undefined
  >();
  const [logsHistory, setLogsHistory] = useState<
    Array<LogViewModel> | undefined
  >();
  const [buttonEdit, setButtonEdit] = useState(false);
  const [buttonClearFilters, setButtonClearFilters] = useState(false);

  const [queryPaginationParams, setQueryPaginationParams] = useState(
    `?page=1&pageSize=${handleGetPageSize()}`
  );
  const [show, setShow] = useState(false);
  const handleModalDetailUnitClose = () => {
    setShow(false);
    setButtonEdit(false);
  };
  const handleModalDetailUnitShow = () => setShow(true);

  function queryPagination() {
    let result: string;
    if (queryPaginationParams) result = `${queryPaginationParams}`;
    else result = "";
    return result;
  }

  function queryFilters() {
    let query = "";
    if (searchFilter !== "") query = query += "&search=" + searchFilter;

    if (activeFilter === "true") query = query += "&activeFilter=true";
    if (activeFilter === "false") query = query += "&activeFilter=false";
    if (activeFilter === "") query = query += "";
    if (query !== "") setButtonClearFilters(true);
    else setButtonClearFilters(false);

    return query;
  }

  function gotoPageOne() {
    if (paging) {
      const paginationProps: PaginationProps = {
        currentRecord: paging.currentRecord,
        currentPage: 1,
        pageSize: paging.pageSize,
        recordsOnPage: paging.recordsOnPage,
        totalRecords: paging.totalRecords,
        totalPages: paging.totalPages,
      };
      setPaging(paginationProps);
      setQueryPaginationParams(`?page=1&pageSize=${handleGetPageSize()}`);
    }
  }

  function loadPage() {
    setLoading(true);

    if (queryPaginationParams)
      UnitApiInterface.get(
        `/Unit/${institutionId}/List${queryPagination()}${queryFilters()}`
      )
        .then((response) => {
          setPaging(response.data.paging);
          setUnits(response.data.result);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          showToast("error", "Algo deu errado, tente novamente mais tarde");
          console.error("Erro => ", error);
        });
  }

  function ClearFilters() {
    setActiveFilter("");
    setSearchFilter("");
    setButtonClearFilters(false);
  }

  const GoDetails = (item: any) => {
    setLoading(true);
    setLoadingModal(true);
    loadLogsHistory(item.identifier);
    setItemDetails(item);
    handleModalDetailUnitShow();
    setLoading(false);
  };

  function handleNameOnChange(value: string) {
    setName(value);
    setValidationFormField("name", value);
  }

  function handleCancelUpdate() {
    setButtonEdit(false);
  }

  function handleSaveData() {
    setButtonEdit(false);
    handleModalDetailUnitClose();
    const payload: Array<BrainzUnitPayload> = [
      {
        id,
        identifier,
        name,
      },
    ];

    setLoading(true);
    return UnitApiInterface.put(`/Unit/${institutionId}/Update`, payload)
      .then((response) => {
        if (response.status === 200) {
          const errors = response.data.result[0].errors;
          if (!errors || errors.length === 0) {
            showToast("info", "Concluído com sucesso.");
            GoDetails(response.data.result[0].value);
            setUpdateUnit(true);
            loadLogsHistory(identifier);
            setLoading(false);
          } else {
            setLoading(false);
            showToast(
              "error",
              `${errors[0].name} Code: ${errors[0].code} Id: ${errors[0].id}`
            );
          }
        } else {
          setLoading(false);
          showToast("error", "Algo deu errado, tente novamente mais tarde");
        }
      })
      .catch((error) => {
        setLoading(false);
        showToast("error", "Algo deu errado, tente novamente mais tarde");
        console.error("Erro => ", error);
      });
  }

  function loadLogsHistory(identifier: string) {
    return ApiInterface.get(
      `/Log/GetLogByIdentifier/Identifier/${institutionId}/${identifier}/1`
    )
      .then((response) => {
        setPaging(response.data.paging);
        setLogsHistory(response.data.result.result);
        setLoading(false);
        setLoadingModal(false);
      })
      .catch((error) => {
        setLoading(false);
        setLoadingModal(false);
        showToast("error", "Algo deu errado, tente novamente mais tarde");
        console.error("Erro => ", error);
      });
  }

  function handleUpdateOnClick(itemDetails: any) {
    setButtonEdit(true);
    validationForm.current = {};
    setNewValidationErrors({});
    setId(itemDetails.id);
    setIdentifier(itemDetails.identifier);
    setName(itemDetails.name);
  }

  useEffect(() => {
    loadPage();
  }, [queryPaginationParams, searchFilter, activeFilter, updateUnit]);

  useEffect(() => {
    gotoPageOne();
  }, [searchFilter, activeFilter, updateUnit, buttonClearFilters]);

  return (
    <>
      <FiltersStyleComponentUser className="w-100 search-filter">
        <Col md={6}>
          <Form.Group>
            <div className="search-filter">
              <Search
                setSearchFilter={setSearchFilter}
                searchFilter={searchFilter}
                placeholder="Buscar por Tipo, Identificador ou Nome"
              />
            </div>
          </Form.Group>
        </Col>

        <Col md={3}>
          <Form.Group>
            <BrainzComboBox
              setValue={(val) => setActiveFilter(val)}
              value={activeFilter}
              idField="id"
              valueField="name"
              placeholder="Status"
              options={[
                { name: "Ativo", value: "true" },
                { name: "Inativo", value: "false" },
              ]}
              search={false}
            />
          </Form.Group>
        </Col>

        <Col>
          {buttonClearFilters === false ? (
            ""
          ) : (
            <Button className="clear-filter" onClick={ClearFilters}>
              <BsXCircle className="bi bi-x-circle" color="#A56300" size={24} />
            </Button>
          )}
        </Col>
      </FiltersStyleComponentUser>
      <Row>
        {loading ? (
          <PageLoader />
        ) : units && units.length > 0 ? (
          <>
            <TableSyncDataStyles className="dataCustomTable">
              <Table
                className="mt-3 table-list"
                striped
                borderless
                hover
                responsive
              >
                <thead>
                  <tr className="title-table">
                    {/* <th className="border-radius-left">Status</th> */}
                    <th className="border-radius-left">Identificador</th>
                    <th>Nome</th>
                    <th>Criado em</th>
                    <th>Id Integração</th>
                    <th className="border-radius-right">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {units?.map((item) => {
                    return (
                      <tr
                        id="tr-linha"
                        key={item.id}
                        className="table-values cursor-pointer"
                      >
                        {/*(item.active)} */}
                        <td>{item.identifier}</td>
                        <td>{item.name}</td>
                        <td>{moment(item.creationDate).format("lll")}</td>
                        <td>{item.serviceId}</td>
                        <td>
                          <CustomTooltip
                            type="action-button"
                            text="Detalhes da unidade"
                            placement="bottom"
                            buttonContent={
                              <div
                                role="button"
                                className="action-item"
                                onClick={() => GoDetails(item)}
                              >
                                <BsInfoCircle color="#0F7EAA" size={24} />
                              </div>
                            }
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <Col md={12}>
                {paging && (
                  <BrainzPagination
                    setQueryParams={setQueryPaginationParams}
                    paging={paging}
                  />
                )}
              </Col>
            </TableSyncDataStyles>
          </>
        ) : (
          <NoResults clearFilterFunction={() => ClearFilters()}></NoResults>
        )}
      </Row>

      {itemDetails !== undefined && (
        <ModalGenericStyles
          show={show}
          onHide={handleModalDetailUnitClose}
          className="ModalDetailSyncDataStyle ModalDetailsSyncDataUnit"
        >
          <Modal.Header>
            <Modal.Title>
              <h2>Detalhes da Unidade</h2>
            </Modal.Title>
            <Button
              className="cursor-pointer"
              variant="white"
              onClick={() => handleModalDetailUnitClose()}
            >
              <BsX color="var(--bs-modal-color)" size={28} />
            </Button>
          </Modal.Header>

          <Modal.Body>
            {loadingModal === true ? (
              <PageLoader />
            ) : (
              <>
                {buttonEdit === true && (
                  <Row className="info-edit">
                    <div className="div-data-integration-card">
                      A alteração dos detalhes do registro pode gerar conflitos
                      com outras integrações feitas de forma automática ou via
                      planilha.
                      <br />
                      <br />
                      Você pode conferir o resultado da alteração na tela Logs
                      de Execução.
                    </div>
                  </Row>
                )}

                <Form>
                  <Form.Group as={Row} className=" my-0 pt-3 pb-3">
                    <Col sm={5}>
                      <Form.Label column sm={12} className=" my-0 py-0">
                        Status
                      </Form.Label>
                      <Form.Label
                        column
                        sm={12}
                        className=" my-0 py-0 row pl-4"
                      >
                        {itemDetails.active === true ? (
                          <BsCheckCircle
                            className="mr-2"
                            color="#008555"
                            size={24}
                          />
                        ) : (
                          <BsXOctagon
                            className="mr-2"
                            color="#A56300"
                            size={24}
                          />
                        )}
                        {itemDetails.active === true ? "Ativo" : "Inativo"}
                      </Form.Label>
                    </Col>
                    <Col sm={6}>
                      <Form.Label column className=" my-0 py-0">
                        Criado em
                      </Form.Label>
                      <Form.Label column className=" my-0 py-0">
                        <h2>
                          {new Date(itemDetails.creationDate).toLocaleString()}
                        </h2>
                      </Form.Label>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className=" my-0 pb-2">
                    <Col sm={11}>
                      <Form.Label column className=" my-0 py-0">
                        Nome
                      </Form.Label>
                      {buttonEdit === false ? (
                        <Form.Label column sm={12} className=" my-0 py-0">
                          <h2>{name === "" ? itemDetails.name : name}</h2>
                        </Form.Label>
                      ) : (
                        <>
                          <Form.Control
                            placeholder=""
                            type="text"
                            name="name"
                            value={name}
                            onChange={(e) => {
                              handleNameOnChange(e.target.value);
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            {feedBack("name", validationErrors)}
                          </Form.Control.Feedback>
                        </>
                      )}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className=" my-0 pb-2">
                    <Col sm={12}>
                      <Form.Label column className=" my-0 py-0">
                        Identificador
                      </Form.Label>
                      <Form.Label column sm={12} className=" my-0 py-0">
                        <h2>{itemDetails.identifier}</h2>
                      </Form.Label>
                    </Col>
                  </Form.Group>
                  {buttonEdit === false ? (
                    <>
                      <h4>Histórico de registros</h4>
                      {logsHistory && logsHistory.length > 0 ? (
                        logsHistory.map((item, index) => {
                          return (
                            <>
                              <div className="card-history my-2" key={index}>
                                <Row className="pt-2 m-auto p-auto">
                                  <Col md={3}>
                                    <p>
                                      {new Date(
                                        item.creationDate
                                      ).toLocaleString()}
                                    </p>
                                  </Col>
                                  <Col md={9}>
                                    <p>{item.message}</p>
                                  </Col>
                                </Row>
                              </div>
                            </>
                          );
                        })
                      ) : (
                        <>
                          {" "}
                          <p>Nenhum histórico de registros</p>
                        </>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </Form>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            {buttonEdit === false ? (
              <>
                <div></div>
                <div>
                  <Button
                    className="button-edit"
                    variant="primary"
                    onClick={() => handleUpdateOnClick(itemDetails)}
                  >
                    <BsPencil color="#2155BA" size={20} />
                    Editar Unidade
                  </Button>
                </div>
              </>
            ) : (
              <>
                <Button
                  className="cancel-operation"
                  variant="primary"
                  onClick={handleCancelUpdate}
                >
                  <BsX color="#A56300" size={24} />
                  Cancelar Edição
                </Button>
                <Button
                  className="continue-button"
                  variant="primary"
                  onClick={handleSaveData}
                >
                  Salvar Alterações
                  <BsCheck color="#fff" size={24} />
                </Button>
              </>
            )}
          </Modal.Footer>
        </ModalGenericStyles>
      )}
    </>
  );
}
