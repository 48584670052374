import { useState, useRef } from "react";

type UseValidationResult = [
  React.MutableRefObject<any>,
  (field: string, value: any) => void,
  any,
  (errors: any) => boolean
];

export const useValidation = (): UseValidationResult => {
  const validationForm = useRef<any | undefined>({});
  const [validationErrors, setValidationErrors] = useState<any | undefined>({});

  function setValidationFormField(field: string, value: any) {
    validationForm.current = {
      ...validationForm.current,
      [field]: value,
    };

    if (!!validationErrors[field])
      setValidationErrors({
        ...validationErrors,
        [field]: null,
      });
  }

  function setNewValidationErrors(errors: any) {
    let errorExist = false;
    Object.values(errors).forEach((item) => {
      if (item !== null && item !== undefined) errorExist = true;
    });

    setValidationErrors(errors);
    return errorExist;
  }

  return [
    validationForm,
    setValidationFormField,
    validationErrors,
    setNewValidationErrors,
  ];
};

export function isInvalidFeedBack(field: string, validationErrors: any) {
  if (validationErrors?.[field]) return true;
  else return false;
}

export function feedBack(field: string, validationErrors: any) {
  if (validationErrors?.[field])
    return validationErrors[field];
  else return "";
}
