/* eslint-disable no-self-compare */
import pt from "date-fns/locale/pt";
import { useState, useEffect, useContext } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import { registerLocale } from "react-datepicker";
import {
  BsArrowClockwise,
  BsArrowRight,
  BsCalendar,
  BsCheckCircle,
  BsChevronRight,
  BsClipboardData,
  BsClockHistory,
  BsDashCircle,
  BsExclamationOctagon,
  BsLightning,
  BsPause,
  BsPencil,
  BsPeople,
  BsPlay,
  BsXCircle,
} from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

import CustomTooltip from "../../components/Common/CustomTooltip";
import { formatDateToDDMMYYYY } from "../../components/Common/Formatter";
import PageLoader from "../../components/Common/Loader";
import { showToast } from "../../components/Common/Toast";
import { automaticIntegrationRunNowMsg } from "../../helpers/messagesTooltips";
import ApiInterface from "../../services/ApiInterface";
import { InitialHeaderStyles } from "../../styles/initialStyles";
import { ActiveModalIntegrationContext } from "./AutomaticIntegrationContexts/ActiveModalIntegrationContext";
import ModalCancelIntegration from "./Modals/ModalCancelIntegration";
import ModalContinueIntegration from "./Modals/ModalContinueIntegration";
import ModalEditConnectionSql from "./Modals/ModalEditConnectionSql";
import ModalEditDate from "./Modals/ModalEditDate";
import ModalEditEntity from "./Modals/ModalEditEntity/";
import ModalEditHeaders from "./Modals/ModalEditHeaders/";
import ModalEditHourInterval from "./Modals/ModalEditHourInterval";
import ModalEditUrlApi from "./Modals/ModalEditUrlApi";
import ModalErrorNowIntegrate from "./Modals/ModalErrorNowIntegrate";
import ModalFirstSetupApi from "./Modals/ModalFirstSetupApi";
import ModalFirstSetupSql from "./Modals/ModalFirstSetupSql";
import ModalIntegrationEntity from "./Modals/ModalIntegrationEntity/";
import ModalPauseIntegration from "./Modals/ModalPauseIntegration";
import ModalPendingIntegration from "./Modals/ModalPendingIntegration";
import ModalStopEntityintegration from "./Modals/ModalStopEntityintegration";
import ModalSuccessEditIntegration from "./Modals/ModalSuccessEditIntegration";
import ModalSuccessIntegration from "./Modals/ModalSuccessIntegration";
import ModalSuccessNowIntegrate from "./Modals/ModalSuccessNowIntegrate";
import ModalTypeIntegration from "./Modals/ModalTypeIntegration/";
import {
  handleFormatStringGCD,
  handleFormatScheduleTimeToShow,
} from "./utils/functions";

// MODALS
// 0-fechado
// 1-TypeIntegration
// 2- SQL-First Integration
// 3 - API-First Integration
// 4 - Cancel Integration
// 5 - Success Integration
// 6 - error First Integration
// 7 - Pending first Integration
// 8 - Edit Date
// 9 - Edit Hour and Interval
// 10 - Edit Connection Sql
// 11 - pause all integration
// 12 - continue integration
// 13 - stop Entity Integration
// 14 - edit Entity Integration
// 15 - integration Entity
// 16 - Success Edit integration Entity
// 17 - Edit Url Endpoint API
// 18 - Edit Headers API
// 19 - Integrate Now
// 22 - record history

export default function AutomaticIntegration(props: any) {
  const {
    setActiveModal,
    activeModal,
    startDate,
    setStartDate,
    newStartDate,
    updateIntegration,
    setUpdateIntegration,
    interval,
    setInterval,
    integrationHour,
    setIntegrationHour,
    newConnectionString,
    server,
    setServer,
    database,
    setDatabase,
    user,
    setUser,
    password,
    setPassword,
    connectionString,
    setConnectionString,
    newStopIntegration,
    stopIntegration,
    setStopIntegration,
    setEntitySelected,
    integrationBase,
    setIntegrationBase,
    newIntegrationBase,
    setIntegrationBaseSelected,
    urlEndpoint,
    setUrlEndpoint,
    newUrlEndpoint,
    headerList,
    setHeaderList,
    typeIntegration,
    setTypeIntegration,
    newTypeIntegration,
    setFrequencyType,
    frequencyType,
    scheduleTimesList,
    setScheduleTimesList,
    intervalTimeList,
    setIntervalTimeList,
  } = useContext(ActiveModalIntegrationContext);

  registerLocale("pt", pt);
  const cookie = new Cookies();
  const institutionId = cookie.get(process.env.REACT_APP_BRAINZ_INSTITUTIONID!);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [firstIntegration, setFirstIntegration] = useState(false);
  const [testConnectionServer, setTestConnectionServer] = useState(true);
  const [integrationEntities, setIntegrationEntities] = useState<any>([]);

  // Local States
  const [typeIntegrationLocal, setTypeIntegrationLocal] = useState<any>();
  const [headerListLocal, setHeaderListLocal] = useState<any>();
  const [frequencyTypeLocal, setFrequencyTypeLocal] = useState<any>();
  const [serverLocal, setServerLocal] = useState<any>();
  const [databaseLocal, setDatabaseLocal] = useState<any>();
  const [userLocal, setUserLocal] = useState<any>();
  const [passwordLocal, setPasswordLocal] = useState<any>();

  useEffect(() => {
    if (updateIntegration === true) {
      handleUpdate();
      if (typeIntegration === 1) {
        testConnectionValidation();
      }
    }
  }, [updateIntegration]);

  useEffect(() => {
    loadPage();
  }, []);

  function loadPage() {
    ApiInterface.get("/AutomaticIntegration/" + institutionId + "/Get")
      .then((response: any) => {
        if (
          response.status === undefined ||
          response.data.result.length === 0
        ) {
          setFirstIntegration(true); // é a primeira integração
          setLoading(false);
        } else {
          setFirstIntegration(false); // não é a primeira integração

          const integrationBase = response.data.result[0];

          setStopIntegration(integrationBase.automaticIntegration.active);
          setIntegrationBase(response.data.result);
          setTypeIntegration(integrationBase.automaticIntegration.dataSource);
          setTypeIntegrationLocal(
            integrationBase.automaticIntegration.dataSource
          );

          const activeEntities = response.data.result.filter(
            (entity: any) => entity.active
          );

          if (activeEntities?.length !== 0) {
            if (activeEntities[0]?.startDate) {
              setStartDate(new Date(activeEntities[0]?.startDate));
            }
          }

          setInterval(integrationBase.automaticIntegration.daysFrequency);
          setIntegrationHour(integrationBase.automaticIntegration.hour);
          setFrequencyType(integrationBase.automaticIntegration.frequencyType);
          setFrequencyTypeLocal(
            integrationBase.automaticIntegration.frequencyType
          );

          setScheduleTimesList(
            integrationBase.automaticIntegration.scheduledHours
          );
          setIntervalTimeList(
            integrationBase.automaticIntegration.scheduledHours
          );
          setConnectionString(
            integrationBase.automaticIntegration.configuration
          );

          if (integrationBase.automaticIntegration.dataSource === 1) {
            const fimServer =
              integrationBase.automaticIntegration.configuration?.search(/;D/i);
            const inicioServer =
              parseInt(
                integrationBase.automaticIntegration.configuration?.search(/=/i)
              ) + 1;
            setServer(
              integrationBase.automaticIntegration.configuration?.slice(
                inicioServer,
                fimServer
              )
            );
            setServerLocal(
              integrationBase.automaticIntegration.configuration?.slice(
                inicioServer,
                fimServer
              )
            );

            const fimDatabase =
              integrationBase.automaticIntegration.configuration?.search(/;U/i);
            const inicioDataBase =
              parseInt(
                integrationBase.automaticIntegration.configuration?.search(
                  /Database=/i
                )
              ) + 9;
            setDatabase(
              integrationBase.automaticIntegration.configuration?.slice(
                inicioDataBase,
                fimDatabase
              )
            );
            setDatabaseLocal(
              integrationBase.automaticIntegration.configuration?.slice(
                inicioDataBase,
                fimDatabase
              )
            );

            const fimUser =
              integrationBase.automaticIntegration.configuration?.search(/;P/i);
            const inicioUser =
              parseInt(
                integrationBase.automaticIntegration.configuration?.search(
                  /User Id=/i
                )
              ) + 8;
            setUser(
              integrationBase.automaticIntegration.configuration?.slice(
                inicioUser,
                fimUser
              )
            );

            setUserLocal(
              integrationBase.automaticIntegration.configuration?.slice(
                inicioUser,
                fimUser
              )
            );
            const fimPassword =
              parseInt(
                integrationBase.automaticIntegration.configuration?.length
              ) - 1;
            const inicioPassword =
              parseInt(
                integrationBase.automaticIntegration.configuration?.search(
                  /Password=/i
                )
              ) + 9;
            setPassword(
              integrationBase.automaticIntegration.configuration?.slice(
                inicioPassword,
                fimPassword
              )
            );
            setPasswordLocal(
              integrationBase.automaticIntegration.configuration?.slice(
                inicioPassword,
                fimPassword
              )
            );
            setIntegrationEntities(response.data.result);
            if (typeIntegration === 1) {
              testConnectionValidation();
            }
          } else {
            const jsonConfiguration =
              integrationBase.automaticIntegration.configuration;
            const objConfiguration = JSON.parse(jsonConfiguration);

            setUrlEndpoint(objConfiguration.Uri);
            const headerlocal = objConfiguration.ApiHeaders.map((item: any) => {
              return {
                key: item.Key,
                value: item.Value,
              };
            });
            setHeaderList(headerlocal);
            setHeaderListLocal(objConfiguration.ApiHeaders);
          }

          setIntegrationEntities(response.data.result);
          setLoading(false);
        }
      })
      .catch((error: any) => {
        console.error(
          "Erro ao buscar configurações de Automatic Integration. Erro => ",
          error
        );
        setLoading(false);
      });
  }

  function testConnectionValidation() {
    setLoading(true);
    if (
      (connectionString !== "" && connectionString !== undefined) ||
      (newConnectionString !== "" && newConnectionString !== undefined)
    ) {
      ApiInterface.get("/Institution/ConnectionStringCheck/" + connectionString)
        .then((response: any) => {
          if (response.status === 200) {
            setTestConnectionServer(true);
          } else {
            setTestConnectionServer(false);
          }
          setLoading(false);
        })
        .catch((error: any) => {
          setLoading(false);
          setActiveModal(6);
          console.error("ERRO AO TESTAR A CONEXÃO COM O BANCO. Erro: ", error);
        });
    }
  }

  function RefreshPage() {
    window.location.reload();
  }
  function redirectToDomains() {
    navigate(`/domains?id=${institutionId}`);
  }
  function redirectToSyncdata() {
    navigate(`/sync-data?id=${institutionId}`);
  }
  function redirectToLogs() {
    navigate(`/logs?id=${institutionId}`);
  }
  function handleUpdate() {
    setLoading(true);

    const payloadUpdate = {
      active:
        newStopIntegration !== stopIntegration &&
        newStopIntegration !== undefined
          ? newStopIntegration
          : stopIntegration,
      institutionId,
      integrationEnabled: true,
      dataSource:
        typeIntegration && newTypeIntegration !== 0
          ? newTypeIntegration
          : typeIntegration,
      startDate:
        newStartDate === new Date() || newStartDate === undefined
          ? startDate
          : newStartDate,
      hour: 0,
      daysFrequency: 0,
      frequencyType,
      scheduledHours:
        frequencyType === 1 ? scheduleTimesList : intervalTimeList,
      automaticIntegrationTypes:
        typeIntegration === 1
          ? newIntegrationBase.length === 0
            ? integrationBase?.map((entity: any) => {
                return {
                  integrationType: entity.integrationType,
                  active: entity.active,
                  inactiveAbsent: entity.inactiveAbsent,
                  automaticIntegrationBySql: {
                    connectionString:
                      newConnectionString !== connectionString &&
                      newConnectionString !== ""
                        ? newConnectionString
                        : connectionString,
                    databaseView:
                      entity?.automaticIntegrationBySql?.databaseView ?? "",
                  },
                };
              })
            : newIntegrationBase?.map((entity: any) => {
                return {
                  integrationType: entity.integrationType,
                  active: entity.active,
                  inactiveAbsent: entity.inactiveAbsent,
                  automaticIntegrationBySql: {
                    connectionString:
                      newConnectionString !== connectionString &&
                      newConnectionString !== ""
                        ? newConnectionString
                        : connectionString,
                    databaseView:
                      entity?.automaticIntegrationBySql?.databaseView ?? "",
                  },
                };
              })
          : newIntegrationBase.length === 0
          ? integrationBase?.map((entity: any) => {
              return {
                integrationType: entity.integrationType,
                active: entity.active,
                inactiveAbsent: entity.inactiveAbsent,
                automaticIntegrationByRestApi: {
                  url:
                    newUrlEndpoint === "" || newUrlEndpoint === undefined
                      ? urlEndpoint
                      : newUrlEndpoint,
                  headers: headerList.map((item: any) => {
                    return {
                      Key: item.key,
                      Value: item.value,
                    };
                  }),
                  route: entity.automaticIntegrationByRestApi.route,
                  fieldParse: entity.automaticIntegrationByRestApi.fieldParse,
                  params: entity.automaticIntegrationByRestApi.params,
                },
              };
            })
          : newIntegrationBase?.map((entity: any) => {
              return {
                integrationType: entity.integrationType,
                active: entity.active,
                inactiveAbsent: entity.inactiveAbsent,
                automaticIntegrationByRestApi: {
                  url:
                    newUrlEndpoint === "" || newUrlEndpoint === undefined
                      ? urlEndpoint
                      : newUrlEndpoint,
                  headers: headerList.map((item: any) => {
                    return {
                      Key: item.key,
                      Value: item.value,
                    };
                  }),
                  route: entity.automaticIntegrationByRestApi.route,
                  fieldParse: entity.automaticIntegrationByRestApi.fieldParse,
                  params: entity.automaticIntegrationByRestApi.params,
                },
              };
            }),
    };

    ApiInterface.put(
      "/AutomaticIntegration/" + institutionId + "/Update",
      payloadUpdate
    )
      .then((response) => {
        if (response.status === 200) {
          setUpdateIntegration(false);
          setLoading(false);
          setActiveModal(16);
          loadPage();
        } else {
          showToast("error", "A informação não foi salva, tente novamente.");
          console.error("ERRO - não salvou os dados. Resposta: ", response);
          setUpdateIntegration(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        showToast("error", "Algo deu errado, tente novamente mais tarde");
        setLoading(false);
        setActiveModal(6);
        setUpdateIntegration(false);
        console.error("ERRO AO SALVAR A INTEGRAÇÃO. Erro => ", error);
      });
  }

  function handleConfirmeStopEntityIntegration(entityType: any, index: any) {
    setEntitySelected(entityType);
    setIntegrationBaseSelected(
      integrationBase.filter(
        (x: any) => x.integrationType === x.integrationType
      )[index]
    );
    setActiveModal(13);
  }
  function handleSetEntityEdit(index: any) {
    setIntegrationBaseSelected(
      integrationBase.filter(
        (x: any) => x.integrationType === x.integrationType
      )[index]
    );
    setActiveModal(14);
  }
  function handleIntegrationEntity(index: any) {
    setIntegrationBaseSelected(
      integrationBase.filter(
        (x: any) => x.integrationType === x.integrationType
      )[index]
    );
    setActiveModal(15);
  }

  function handlerIntegrateNow() {
    setLoading(true);
    ApiInterface.put(
      "/AutomaticIntegration/ " + institutionId + " /IntegrateNow"
    )
      .then((response) => {
        if (response.data.result === true) {
          setActiveModal(19);
        } else {
          setActiveModal(20);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setActiveModal(20);
        console.error("Erro ao Integrar agora. Erro => ", error);
      });
  }

  const handleRenderIntegrationDetailsMessage = () => {
    return (
      <h2>
        Sua integração ocorrerá a partir do dia{" "}
        <strong>
          {formatDateToDDMMYYYY(startDate)}
          {frequencyType === 1 ? (
            <> às {handleFormatScheduleTimeToShow(scheduleTimesList)}</>
          ) : frequencyType === 2 ? (
            <>
              {" "}
              de {handleFormatStringGCD(intervalTimeList)} em{" "}
              {handleFormatStringGCD(intervalTimeList)}h.
            </>
          ) : (
            <> às {integrationBase[0].automaticIntegration.hour}:00</>
          )}
        </strong>
      </h2>
    );
  };

  return (
    <div>
      {loading ? (
        <PageLoader />
      ) : (
        <>
          <InitialHeaderStyles className="headingStickySubPagesIA">
            <Row>
              <Col md={6} className="" style={{ padding: 0 }}>
                <h2>Integração Automática</h2>
              </Col>
              {firstIntegration === true ? (
                ""
              ) : (
                <Col className="pb-1 mr-0 pr-0" style={{ textAlign: "right" }}>
                  <CustomTooltip
                    type="info"
                    iconColor="#A56300"
                    iconSize={24}
                    text={automaticIntegrationRunNowMsg}
                    isHTMLText
                    placement="bottom"
                  />

                  <Button
                    disabled={stopIntegration === false}
                    title="Integrar agora"
                    onClick={() => handlerIntegrateNow()}
                  >
                    {" "}
                    <BsPlay className="mr-3" color="#fff" size={24} />
                    Integrar agora
                  </Button>
                </Col>
              )}
            </Row>
            <Row>
              <Col style={{ padding: "0" }}>
                <h3>
                  {" "}
                  Escolha se deseja integrar via Banco de Dados ou API, e
                  realize as configurações correspondentes.
                </h3>
              </Col>
            </Row>
          </InitialHeaderStyles>
          {firstIntegration === true ? (
            <InitialHeaderStyles>
              <div className="div-center ">
                <Row>
                  <Col md={12} className="">
                    <h2>Você ainda não configurou a integração automática</h2>
                  </Col>
                </Row>
                <Row className="my-3">
                  <Col md={12} className="">
                    <h3>
                      Depois de fazer a primeira configuração, você poderá
                      alterá-las nesta tela.
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="">
                    <Button onClick={() => setActiveModal(1)}>
                      Primeira Configuração
                      <BsArrowRight className="ml-2" color="#fff" size={24} />
                    </Button>
                  </Col>
                </Row>
              </div>
            </InitialHeaderStyles>
          ) : loading ? (
            <PageLoader />
          ) : (
            <InitialHeaderStyles>
              {typeIntegrationLocal === 1 ? (
                testConnectionServer === true ? (
                  <Row className="div-data-integration-card mb-3">
                    <div>
                      <Row className="ml-4">
                        <BsLightning
                          className="mr-3"
                          color="#2155ba"
                          size={24}
                        />
                        {stopIntegration === true ? (
                          handleRenderIntegrationDetailsMessage()
                        ) : (
                          <h2>Sua integração está pausada.</h2>
                        )}{" "}
                      </Row>
                    </div>
                    <div>
                      {stopIntegration === true ? (
                        <Row
                          className="mr-4 mt-1 cursor-pointer"
                          onClick={() => setActiveModal(11)}
                        >
                          <BsPause className="mr-3" color="#A56300" size={24} />
                          <h6>Pausar integração</h6>
                        </Row>
                      ) : (
                        <Row
                          className="mr-4 mt-1 cursor-pointer"
                          onClick={() => setActiveModal(12)}
                        >
                          <BsPlay className="mr-3" color="#0F7EAA" size={24} />
                          <h3>Retomar integração</h3>
                        </Row>
                      )}
                    </div>
                  </Row>
                ) : (
                  <Row className="div-data-integration-card-error mb-3">
                    <div>
                      <Row className="ml-4">
                        <BsExclamationOctagon
                          className="mr-3"
                          color="#D43B26"
                          size={24}
                        />
                        <h2>
                          Não conseguimos realizar a conexão com o banco.
                          Confira os dados de acesso.
                        </h2>
                      </Row>
                    </div>
                    <div>
                      <Row
                        className="mr-4 mt-1 cursor-pointer"
                        onClick={RefreshPage}
                      >
                        <BsArrowClockwise
                          className="mr-3"
                          color="#2155ba"
                          size={24}
                        />
                        <h3>Recarregar página</h3>
                      </Row>
                    </div>
                  </Row>
                )
              ) : (
                <Row className="div-data-integration-card mb-3">
                  <div>
                    <Row className="ml-4">
                      <BsLightning className="mr-3" color="#2155ba" size={24} />
                      {stopIntegration === true ? (
                        handleRenderIntegrationDetailsMessage()
                      ) : (
                        <h2>Sua integração está pausada.</h2>
                      )}
                    </Row>
                  </div>
                  <div>
                    {stopIntegration === true ? (
                      <Row
                        className="mr-4 mt-1 cursor-pointer"
                        onClick={() => setActiveModal(11)}
                      >
                        <BsPause className="mr-3" color="#A56300" size={24} />

                        <h6>Pausar integração</h6>
                      </Row>
                    ) : (
                      <Row
                        className="mr-4 mt-1 cursor-pointer"
                        onClick={() => setActiveModal(12)}
                      >
                        <BsPlay className="mr-3" color="#0F7EAA" size={24} />
                        <h3>Retomar integração</h3>
                      </Row>
                    )}
                  </div>
                </Row>
              )}
              <Row className="mb-3">
                <Card className="card-integration">
                  <Card.Title className="title-card">
                    <Col className="mt-3">
                      <h3>Fonte da integração</h3>
                    </Col>
                  </Card.Title>
                  <Card.Body className="my-0 py-0">
                    <Row className="div-body-links">
                      {typeIntegrationLocal === 1 ? (
                        <div className="my-2">
                          <Row>
                            <h4>SQL Server</h4>
                          </Row>
                          <Row>
                            <h5>
                              Os dados serão lidos do seu banco de dados SQL
                              Server.
                            </h5>
                          </Row>
                        </div>
                      ) : (
                        <div className="my-2">
                          <Row>
                            <h4>Rest API</h4>
                          </Row>
                          <Row>
                            <h5>Os dados serão lidos direto da sua API.</h5>
                          </Row>
                        </div>
                      )}
                      <div
                        className="mt-3 cursor-pointer"
                        // onClick={RefreshPage}
                      >
                        <div>
                          <Row
                            className="button-edit cursor-pointer"
                            onClick={() => setActiveModal(1)}
                          >
                            <BsArrowClockwise
                              className="mr-3"
                              color="#0F7EAA"
                              size={24}
                            />
                            <h3>Alterar fonte dos dados</h3>
                          </Row>
                        </div>
                      </div>
                    </Row>
                  </Card.Body>
                </Card>
              </Row>
              {typeIntegration === 1 ? (
                <Row className="mb-3">
                  <Card className="card-integration ">
                    <Card.Title className="title-card">
                      <Col className="mt-3">
                        <h3>Dados de acesso ao banco</h3>
                      </Col>
                    </Card.Title>
                    <Card.Body className="my-0 py-0">
                      <Row className="div-body">
                        <div className="my-2">
                          <Row>
                            <h4>Servidor e banco</h4>
                          </Row>
                          <Row>
                            <h5>
                              Servidor: {serverLocal}; Banco: {databaseLocal}
                            </h5>
                          </Row>
                        </div>
                        <div
                          className="mt-4 cursor-pointer"
                          onClick={() => setActiveModal(10)}
                        >
                          <div>
                            <Row className="button-edit">
                              <BsPencil
                                className="mr-3"
                                color="#0F7EAA"
                                size={24}
                              />
                              <h3>Editar</h3>
                            </Row>
                          </div>
                        </div>
                      </Row>
                      <Row className="div-body-links">
                        <div className="my-2">
                          <Row>
                            <h4>Autenticação e parâmetros opcionais</h4>
                          </Row>
                          <Row>
                            <h5>
                              Usuário: {userLocal}; Senha: {passwordLocal}
                            </h5>
                          </Row>
                        </div>
                        <div
                          className="mt-4 cursor-pointer"
                          onClick={() => setActiveModal(10)}
                        >
                          <div>
                            <Row className="button-edit">
                              <BsPencil
                                className="mr-3"
                                color="#0F7EAA"
                                size={24}
                              />
                              <h3>Editar</h3>
                            </Row>
                          </div>
                        </div>
                      </Row>
                    </Card.Body>
                  </Card>
                </Row>
              ) : (
                <Row className="mb-3">
                  <Card className="card-integration ">
                    <Card.Title className="title-card">
                      <Col className="mt-3">
                        <h3>Dados da API</h3>
                      </Col>
                    </Card.Title>
                    <Card.Body className="my-0 py-0">
                      <Row className="div-body">
                        <div className="my-2">
                          <Row>
                            <h4>URL do Endpoint</h4>
                          </Row>
                          <Row>
                            <h5>{urlEndpoint}</h5>
                          </Row>
                        </div>
                        <div
                          className="mt-3 cursor-pointer"
                          onClick={() => setActiveModal(17)}
                        >
                          <div>
                            <Row className="button-edit">
                              <BsPencil
                                className="mr-3"
                                color="#0F7EAA"
                                size={24}
                              />
                              <h3>Editar</h3>
                            </Row>
                          </div>
                        </div>
                      </Row>
                      <Row className="div-body-link">
                        <Col md={11} className="my-2">
                          <Row className="my-2 pl-3">
                            <h4>Headers</h4>
                          </Row>
                          {headerListLocal && (
                            <Row className="title-key">
                              <Col md={5} style={{ textAlignLast: "start" }}>
                                Key
                              </Col>
                              <Col
                                md={7}
                                className=""
                                style={{ textAlignLast: "start" }}
                              >
                                Value
                              </Col>
                            </Row>
                          )}
                          {headerListLocal?.map((item: any) => {
                            return (
                              <Row className="row-bottom" key={item.Key}>
                                <Col md={5} style={{ textAlignLast: "start" }}>
                                  {item.Key}
                                </Col>
                                <Col
                                  md={7}
                                  className="col-left"
                                  style={{ textAlignLast: "start" }}
                                >
                                  {item.Value}
                                </Col>
                              </Row>
                            );
                          })}
                        </Col>
                        <Col
                          md={1}
                          className="mt-4 cursor-pointer"
                          onClick={() => setActiveModal(18)}
                        >
                          <Row
                            className="button-edit pr-2"
                            style={{ float: "right" }}
                          >
                            <BsPencil
                              className="mr-3"
                              color="#0F7EAA"
                              size={24}
                            />
                            <h3>Editar</h3>
                          </Row>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Row>
              )}
              <Row className="mb-3">
                <Card className="card-integration ">
                  <Card.Title className="title-card">
                    <Col className="mt-3">
                      <h3>Frequência das integrações</h3>
                    </Col>
                  </Card.Title>
                  <Card.Body className="my-0 py-0">
                    {stopIntegration === false ? (
                      <Row className="div-body">
                        <div className="my-1">
                          <Row>
                            <h4>Data de início</h4>
                          </Row>
                          <Row>
                            <h5>
                              Iniciar as integrações em{" "}
                              {formatDateToDDMMYYYY(startDate)}
                            </h5>
                          </Row>
                        </div>
                        <div className="mt-3 cursor-pointer">
                          <div>
                            <Row
                              className="button-edit cursor-pointer"
                              onClick={() => setActiveModal(8)}
                            >
                              <BsCalendar
                                className="mr-3"
                                color="#0F7EAA"
                                size={24}
                              />
                              <h3>Alterar data</h3>
                            </Row>
                          </div>
                        </div>
                      </Row>
                    ) : (
                      ""
                    )}
                    {frequencyTypeLocal !== 0 && (
                      <Row className="div-body-links">
                        <div className="my-3">
                          <Row>
                            <h4>Tipo de Integração</h4>
                          </Row>
                          <Row>
                            {frequencyTypeLocal === 1 ? (
                              <h5>Agendado</h5>
                            ) : frequencyTypeLocal === 2 ? (
                              <h5>Periódico</h5>
                            ) : (
                              ""
                            )}
                          </Row>
                        </div>
                      </Row>
                    )}

                    <Row className="div-body-links">
                      <div className="my-3">
                        <Row>
                          <h4>
                            {frequencyTypeLocal === 1
                              ? "Horário"
                              : frequencyTypeLocal === 2
                              ? "Intervalo"
                              : ""}
                          </h4>
                        </Row>
                        <Row>
                          {frequencyTypeLocal === 1 ? (
                            <h5>
                              {handleFormatScheduleTimeToShow(
                                scheduleTimesList
                              )}
                            </h5>
                          ) : frequencyTypeLocal === 2 ? (
                            <h5>
                              De {handleFormatStringGCD(intervalTimeList)} em{" "}
                              {handleFormatStringGCD(intervalTimeList)}h
                            </h5>
                          ) : (
                            <h5>
                              Integrar a cada {interval} dia, às{" "}
                              {integrationHour}
                              :00
                            </h5>
                          )}
                        </Row>
                      </div>
                      <div
                        className="cursor-pointer"
                        onClick={() => setActiveModal(9)}
                      >
                        <div>
                          <Row className="button-edit mt-4">
                            <BsPencil
                              className="mr-3"
                              color="#0F7EAA"
                              size={24}
                            />
                            <h3>Editar</h3>
                          </Row>
                        </div>
                      </div>
                    </Row>
                  </Card.Body>
                </Card>
              </Row>
              <Row className="mb-3">
                <Card className="card-integration ">
                  <Card.Title className="title-card">
                    <Col className="mt-3">
                      <h3>Entidades a serem integradas</h3>
                    </Col>
                  </Card.Title>
                  <Card.Body className="my-0 py-0 my-1">
                    <Row className="div-body-links">
                      <div>
                        <Row>
                          <h5>
                            Escolha quais entidades deseja integrar. Para cada
                            uma delas, defina a tabela ou view, bem como se
                            deseja inativar os registros ausentes.
                          </h5>
                        </Row>
                      </div>
                    </Row>
                    {integrationEntities?.map((entity: any, index: any) => {
                      return (
                        <Row className="div-body-entity" key={index}>
                          <Col md={4}>
                            <h3>{entity.integrationTypeName} </h3>
                          </Col>
                          <Col md={4} style={{ color: "#008555" }}>
                            {entity.active === true ? (
                              <>
                                <BsCheckCircle
                                  className="mr-3"
                                  color="#008555"
                                  size={24}
                                />
                                Integrando
                              </>
                            ) : (
                              <>
                                <BsDashCircle
                                  className="mr-3"
                                  color="#525252"
                                  size={24}
                                />
                                Não Integrado
                              </>
                            )}
                          </Col>
                          {entity.active === true ? (
                            <>
                              <Col md={2}>
                                <Row
                                  className="button-edit mb-2 cursor-pointer"
                                  onClick={() => handleSetEntityEdit(index)}
                                >
                                  <BsPencil
                                    className="mr-3"
                                    color="#0F7EAA"
                                    size={24}
                                  />
                                  <h3>Editar dados</h3>
                                </Row>
                              </Col>
                              <Col md={2}>
                                <Row
                                  className="cursor-pointer"
                                  style={{ float: "right" }}
                                  onClick={() =>
                                    handleConfirmeStopEntityIntegration(
                                      entity.integrationType,
                                      index
                                    )
                                  }
                                >
                                  <BsXCircle
                                    className="mr-3"
                                    color="#A56300"
                                    size={24}
                                  />
                                  <h6>Parar integração</h6>
                                </Row>
                              </Col>
                            </>
                          ) : (
                            <>
                              <Col md={4}>
                                <Row
                                  className="button-edit cursor-pointer"
                                  style={{ float: "right" }}
                                  onClick={() => handleIntegrationEntity(index)}
                                >
                                  <h3>
                                    Integrar
                                    {entity.integrationTypeName}
                                  </h3>
                                  <BsArrowRight
                                    className="ml-3"
                                    color="#0F7EAA"
                                    size={24}
                                  />
                                </Row>
                              </Col>
                            </>
                          )}
                        </Row>
                      );
                    })}
                  </Card.Body>
                </Card>
              </Row>
              <Row className="mb-3">
                <Card className="card-integration ">
                  <Card.Title className="title-card">
                    <Col className="mt-3">
                      <h3>Descubra outras funcionalidades do Integrador</h3>
                    </Col>
                  </Card.Title>
                  <Card.Title className="title-card ">
                    <Col
                      className="mt-2 cursor-pointer"
                      onClick={redirectToLogs}
                    >
                      <Row className="div-body-links">
                        <Row>
                          <BsClockHistory
                            className="mx-3"
                            color="#2155BA"
                            size={24}
                          />
                          <h2>
                            Acesse o histórico de todas as integrações feitas,
                            mesmo as que tiveram erros
                          </h2>
                        </Row>
                        <div className="">
                          <BsChevronRight
                            className="ml-3"
                            color="#2155BA"
                            size={24}
                          />
                        </div>
                      </Row>
                    </Col>
                  </Card.Title>
                  <Card.Title className="title-card">
                    <Col
                      className="mt-2 cursor-pointer"
                      onClick={redirectToSyncdata}
                    >
                      <Row className="div-body-links">
                        <Row>
                          <BsClipboardData
                            className="mx-3"
                            color="#2155BA"
                            size={24}
                          />
                          <h2>Consulte todos os dados integrados</h2>
                        </Row>
                        <div>
                          <BsChevronRight
                            className="ml-3"
                            color="#2155BA"
                            size={24}
                          />
                        </div>
                      </Row>
                    </Col>
                  </Card.Title>
                  <Card.Title className="title-card-bottom">
                    <Col
                      className="mt-2 mb-2 cursor-pointer"
                      onClick={redirectToDomains}
                    >
                      <Row className="div-body-links">
                        <Row>
                          <BsPeople
                            className="mx-3"
                            color="#2155BA"
                            size={24}
                          />
                          <h2>
                            Configure como serão criadas as contas de cada
                            perfil da sua instituição
                          </h2>
                        </Row>
                        <div>
                          <BsChevronRight
                            className="ml-3"
                            color="#2155BA"
                            size={24}
                          />
                        </div>
                      </Row>
                    </Col>
                  </Card.Title>
                </Card>
              </Row>
            </InitialHeaderStyles>
          )}
        </>
      )}
      <ModalTypeIntegration />
      <ModalFirstSetupSql />
      <ModalFirstSetupApi />
      <ModalCancelIntegration />
      <ModalSuccessIntegration />
      <ModalSuccessEditIntegration />
      <ModalPendingIntegration />
      <ModalEditDate />
      <ModalEditHourInterval />
      <ModalEditConnectionSql />
      <ModalPauseIntegration />
      {activeModal === 13 ? <ModalStopEntityintegration /> : ""}
      {activeModal === 12 ? <ModalContinueIntegration /> : ""}
      {activeModal === 14 ? <ModalEditEntity /> : ""}
      {activeModal === 15 ? <ModalIntegrationEntity /> : ""}
      <ModalEditUrlApi />
      <ModalEditHeaders />
      <ModalSuccessNowIntegrate />
      <ModalErrorNowIntegrate />
    </div>
  );
}
