import pt from "date-fns/locale/pt";
import React, { useContext, useState, useEffect } from "react";
import { Col, Modal, Row, Form, Button } from "react-bootstrap";
import { registerLocale } from "react-datepicker";
import { BsCheck, BsChevronRight, BsX } from "react-icons/bs";

import CustomTooltip from "../../../../components/Common/CustomTooltip";
import Toggle from "../../../../components/Common/Toggle";
import { requiredFieldMsg } from "../../../../helpers/messagesValidation";
import {
  feedBack,
  isInvalidFeedBack,
  useValidation,
} from "../../../../hooks/Validation";
import { ActiveModalIntegrationContext } from "../../AutomaticIntegrationContexts/ActiveModalIntegrationContext";
import { ModalGAutomaticIntegrationStyles } from "../styles";
import "./styles.css";

export default function ModalEditEntity() {
  registerLocale("pt", pt);

  const {
    activeModal,
    setActiveModal,
    setUpdateIntegration,
    integrationBaseSelected,
    setIntegrationBaseSelected,
    integrationBase,
    setNewIntegrationBase,
  } = useContext(ActiveModalIntegrationContext);

  // VALIDATION VARIABLES
  const [
    validationForm,
    setValidationFormField,
    validationErrors,
    setNewValidationErrors,
  ] = useValidation();

  const [activeAbsent, setActiveAbsent] = useState<boolean>(
    integrationBaseSelected.inactiveAbsent
  );

  const [localRoute, setLocalRoute] = useState<any>("");
  const [localTable, setLocalTable] = useState<any>("");
  const [localFieldParse, setLocalFieldParse] = useState<any>("");
  const [localOtherParams, setLocalOtherParams] = useState<any>("");

  useEffect(() => {
    if (activeModal === 14) {
      setActiveAbsent(integrationBaseSelected.inactiveAbsent);
      setLocalTable(
        integrationBaseSelected.automaticIntegrationBySql.databaseView
      );
      setLocalRoute(
        integrationBaseSelected.automaticIntegrationByRestApi.route
      );

      setLocalFieldParse(
        integrationBaseSelected.automaticIntegrationByRestApi.fieldParse
      );

      setLocalOtherParams(
        integrationBaseSelected.automaticIntegrationByRestApi.params
      );
    }
  }, [activeModal]);

  function toggleInactiveAbsent(value: boolean) {
    // const item = integrationBaseSelected;
    // item.inactiveAbsent = value;
    // setIntegrationBaseSelected(item);
    setActiveAbsent(value);
  }

  function setToggleInactiveAbsent(value: boolean) {
    const item = integrationBaseSelected;
    item.inactiveAbsent = value;
    setIntegrationBaseSelected(item);
  }

  function handleNameOnChange(value: string) {
    const masked = value.replace(/[&/\\#,+()$~%.'":*?<>{}]/g, "");
    // setTable(masked);
    setLocalTable(masked);
  }

  function setTable(masked: string) {
    const item = integrationBaseSelected;
    item.automaticIntegrationBySql.databaseView = masked;
    setIntegrationBaseSelected(item);
  }

  function setRoute(route: string) {
    const item = integrationBaseSelected;
    item.automaticIntegrationByRestApi.route = route;
    setIntegrationBaseSelected(item);
  }

  function setFieldParse(fieldParse: string) {
    const item = integrationBaseSelected;
    item.automaticIntegrationByRestApi.fieldParse = fieldParse;
    setIntegrationBaseSelected(item);
  }

  function setParams(params: string) {
    const item = integrationBaseSelected;
    item.automaticIntegrationByRestApi.params = params;
    setIntegrationBaseSelected(item);
  }

  function findErrors() {
    const errors: any = {};

    if (validationForm.current) {
      // Validation to SQL
      if (integrationBase[0].automaticIntegration.dataSource === 1) {
        if (
          !validationForm.current.databaseView ||
          validationForm.current.databaseView === ""
        )
          errors.databaseView = requiredFieldMsg;
        else errors.databaseView = undefined;
      }

      // Validation to API
      if (integrationBase[0].automaticIntegration.dataSource === 4) {
        if (
          !validationForm.current.route ||
          validationForm.current.route === ""
        )
          errors.route = requiredFieldMsg;
        else errors.route = undefined;

        if (
          !validationForm.current.fieldParse ||
          validationForm.current.fieldParse === ""
        )
          errors.fieldParse = requiredFieldMsg;
        else errors.fieldParse = undefined;
      }
    }

    return errors;
  }

  function save() {
    const errors = findErrors();
    const errorExist = setNewValidationErrors(errors);

    if (errorExist) return;

    const entities = [...integrationBase];
    setRoute(localRoute);
    setParams(localOtherParams);
    setFieldParse(localFieldParse);
    setTable(localTable);
    setToggleInactiveAbsent(activeAbsent);

    const entitiesUpdate = entities.filter(
      (entity: any) =>
        entity.integrationType !== integrationBaseSelected.integrationType
    );
    entitiesUpdate.push(integrationBaseSelected);
    setNewIntegrationBase(entitiesUpdate);
    setUpdateIntegration(true);
    setActiveModal(0);
  }

  function updateValidation() {
    if (integrationBaseSelected.automaticIntegration.dataSource === 1) {
      validationForm.current = {
        databaseView:
          integrationBaseSelected.automaticIntegrationBySql.databaseView,
      };
    }
    if (integrationBaseSelected.automaticIntegration.dataSource === 4) {
      validationForm.current = {
        route: integrationBaseSelected.automaticIntegrationByRestApi.route,
        fieldParse:
          integrationBaseSelected.automaticIntegrationByRestApi.fieldParse,
      };
    }
  }

  useEffect(() => {
    updateValidation();
  }, []);

  return (
    <ModalGAutomaticIntegrationStyles
      show={activeModal === 14}
      onHide={() => setActiveModal(0)}
      className="ModalEditEntity"
    >
      <Modal.Header>
        <Modal.Title>
          <h2>
            Integração <BsChevronRight size={18} /> Configurar entidade
          </h2>
        </Modal.Title>

        <Button
          className="cursor-pointer"
          variant="white"
          onClick={() => setActiveModal(0)}
        >
          <BsX color="var(--bs-modal-color)" size={28} />
        </Button>
      </Modal.Header>
      <Modal.Body className="ml-3">
        {integrationBaseSelected.automaticIntegration.dataSource === 1 ? (
          <>
            <Row className="my-2">
              <Col md={12}>
                <h4>
                  <strong>Nome da entidade: </strong>{" "}
                  {integrationBaseSelected.integrationTypeName}
                </h4>
              </Col>

              {integrationBaseSelected.integrationType === 8 ? (
                ""
              ) : (
                <Col md={12} style={{ margin: "1rem 0" }}>
                  <section className="inactivateContainer">
                    <section className="inactivateItem">
                      <h4>Inativar ausentes</h4>
                      <CustomTooltip
                        text="Inativará registros anteriores que não estejam
                          presentes em uma nova integração."
                        type="info"
                        iconColor="#6F6F6F"
                        iconSize={20}
                        placement="right"
                      />
                    </section>

                    <section className="inactivateItem">
                      {activeAbsent === false ? (
                        <Toggle
                          isSomething={activeAbsent}
                          handleSomething={() => toggleInactiveAbsent(true)}
                          title="Desativado"
                        />
                      ) : (
                        <Toggle
                          isSomething={activeAbsent}
                          handleSomething={() => toggleInactiveAbsent(false)}
                          title="Ativado"
                        />
                      )}
                    </section>
                  </section>
                </Col>
              )}
            </Row>

            <Row>
              <Col md={12}>
                <Form.Group>
                  <Form.Label>Tabela / View (obrigatório)</Form.Label>
                  <Form.Control
                    defaultValue={
                      integrationBaseSelected.automaticIntegrationBySql
                        .databaseView
                    }
                    isInvalid={isInvalidFeedBack(
                      "databaseView",
                      validationErrors
                    )}
                    placeholder="Onde podemos encontrar os dados desta entidade?"
                    onChange={(e) => [
                      handleNameOnChange(e.target.value),
                      setValidationFormField("databaseView", e.target.value),
                    ]}
                  />
                  <Form.Control.Feedback type="invalid">
                    {feedBack("databaseView", validationErrors)}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row className="my-2">
              <Col md={12}>
                <h4>
                  <strong>Nome da entidade: </strong>{" "}
                  {integrationBaseSelected.integrationTypeName}
                </h4>
              </Col>
              {integrationBaseSelected.integrationType === 8 ? (
                ""
              ) : (
                <Col md={12}>
                  <Row className="line-inactivate">
                    <section className="inactivateContainer">
                      <section className="inactivateItem">
                        <h4>Inativar ausentes</h4>
                        <CustomTooltip
                          text="Inativará registros anteriores que não estejam
                          presentes em uma nova integração."
                          type="info"
                          iconColor="#6F6F6F"
                          iconSize={20}
                          placement="right"
                        />
                      </section>

                      <section className="inactivateItem">
                        {activeAbsent === false ? (
                          <Toggle
                            isSomething={activeAbsent}
                            handleSomething={() => toggleInactiveAbsent(true)}
                            title="Desativado"
                          />
                        ) : (
                          <Toggle
                            isSomething={activeAbsent}
                            handleSomething={() => toggleInactiveAbsent(false)}
                            title="Ativado"
                          />
                        )}
                      </section>
                    </section>
                  </Row>
                </Col>
              )}
            </Row>

            <Row className="">
              <Col md={12} className="mb-3">
                <Form.Group>
                  <Form.Label>Rota (obrigatório)</Form.Label>
                  <Form.Control
                    size="sm"
                    defaultValue={
                      integrationBaseSelected.automaticIntegrationByRestApi
                        .route
                    }
                    isInvalid={isInvalidFeedBack("route", validationErrors)}
                    placeholder="Copie e cole a rota"
                    onChange={(e) => [
                      setLocalRoute(e.target.value),
                      setValidationFormField("route", e.target.value),
                    ]}
                  />
                  <Form.Control.Feedback type="invalid">
                    {feedBack("route", validationErrors)}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={12} className="mb-3">
                <Form.Group>
                  <Form.Label>FieldParse (obrigatório)</Form.Label>
                  <Form.Control
                    size="sm"
                    defaultValue={
                      integrationBaseSelected.automaticIntegrationByRestApi
                        .fieldParse
                    }
                    isInvalid={isInvalidFeedBack(
                      "fieldParse",
                      validationErrors
                    )}
                    placeholder="Copie e cole o campo FieldParse"
                    onChange={(e) => [
                      setLocalFieldParse(e.target.value),
                      setValidationFormField("fieldParse", e.target.value),
                    ]}
                  />
                  <Form.Control.Feedback type="invalid">
                    {feedBack("fieldParse", validationErrors)}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={12} className="mb-3">
                <Form.Group>
                  <Form.Label>Outros parâmetros (opcional)</Form.Label>
                  <Form.Control
                    size="sm"
                    defaultValue={
                      integrationBaseSelected.automaticIntegrationByRestApi
                        .params
                    }
                    isInvalid={isInvalidFeedBack(
                      integrationBaseSelected.automaticIntegrationByRestApi
                        .params,
                      validationErrors
                    )}
                    placeholder="Copie e cole os parâmetros opcionais de acesso a API"
                    onChange={(e) => [
                      setLocalOtherParams(e.target.value),
                      setValidationFormField(
                        integrationBaseSelected.automaticIntegrationByRestApi
                          .params,
                        e.target.value
                      ),
                    ]}
                  />
                </Form.Group>
              </Col>
            </Row>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button className="cancel-operation" onClick={() => setActiveModal(0)}>
          <BsX color="#A56300" size={24} />
          Cancelar
        </Button>
        <Button
          variant="primary"
          className="continue-button"
          onClick={() => save()}
        >
          Salvar
          <BsCheck color="#fff" size={24} />
        </Button>
      </Modal.Footer>
    </ModalGAutomaticIntegrationStyles>
  );
}
