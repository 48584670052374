import { createContext, ReactNode, useState } from "react";

type ChildrenContextProps = {
  children: ReactNode;
};
type ModelHeader = {
  key: string;
  value: string;
};

type ActiveModalIntegrationContextData = {
  activeModal: number;
  setActiveModal: React.Dispatch<React.SetStateAction<number>>;
  previousActiveModal: number;
  setPreviousActiveModal: React.Dispatch<React.SetStateAction<number>>;
  startDate: Date;
  setStartDate: React.Dispatch<React.SetStateAction<Date>>;
  newStartDate: Date;
  setNewStartDate: React.Dispatch<React.SetStateAction<Date>>;
  updateIntegration: boolean;
  setUpdateIntegration: React.Dispatch<React.SetStateAction<boolean>>;
  interval: string;
  setInterval: React.Dispatch<React.SetStateAction<string>>;
  newInterval: string;
  setNewInterval: React.Dispatch<React.SetStateAction<string>>;
  integrationHour: number;
  setIntegrationHour: React.Dispatch<React.SetStateAction<number>>;
  newIntegrationHour: number;
  setNewIntegrationHour: React.Dispatch<React.SetStateAction<number>>;
  newConnectionString: string;
  setNewConnectionString: React.Dispatch<React.SetStateAction<string>>;
  server: string;
  setServer: React.Dispatch<React.SetStateAction<string>>;
  database: string;
  setDatabase: React.Dispatch<React.SetStateAction<string>>;
  user: string;
  setUser: React.Dispatch<React.SetStateAction<string>>;
  password: string;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  aditionalConfig: string;
  setAditionalConfig: React.Dispatch<React.SetStateAction<string>>;
  connectionString: string;
  setConnectionString: React.Dispatch<React.SetStateAction<string>>;
  newStopIntegration: any;
  setNewStopIntegration: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >;
  stopIntegration: any;
  setStopIntegration: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  entitySelected: any;
  setEntitySelected: React.Dispatch<React.SetStateAction<any>>;
  integrationBaseSelected: any;
  setIntegrationBaseSelected: React.Dispatch<React.SetStateAction<any>>;
  integrationBase: any;
  setIntegrationBase: React.Dispatch<React.SetStateAction<any>>;
  newIntegrationBase: any;
  setNewIntegrationBase: React.Dispatch<React.SetStateAction<any>>;
  urlEndpoint: string;
  setUrlEndpoint: React.Dispatch<React.SetStateAction<string>>;
  newUrlEndpoint: string;
  setNewUrlEndpoint: React.Dispatch<React.SetStateAction<string>>;
  headerList: any;
  setHeaderList: React.Dispatch<React.SetStateAction<any>>;
  newHeaderList: any;
  setNewHeaderList: React.Dispatch<React.SetStateAction<any>>;
  integrationNow: boolean;
  setIntegrationNow: React.Dispatch<React.SetStateAction<boolean>>;
  typeIntegration: number;
  setTypeIntegration: React.Dispatch<React.SetStateAction<number>>;
  newTypeIntegration: number;
  setNewTypeIntegration: React.Dispatch<React.SetStateAction<number>>;
  frequencyType: number;
  setFrequencyType: React.Dispatch<React.SetStateAction<number>>;
  scheduleTimesList: string;
  setScheduleTimesList: React.Dispatch<React.SetStateAction<string>>;
  intervalTimeList: string;
  setIntervalTimeList: React.Dispatch<React.SetStateAction<string>>;
};
export const ActiveModalIntegrationContext = createContext(
  {} as ActiveModalIntegrationContextData
);

export default function ActiveModalIntegrationContextProvider({
  children,
}: ChildrenContextProps) {
  const [activeModal, setActiveModal] = useState<number>(0);
  const [previousActiveModal, setPreviousActiveModal] = useState<number>(0);
  const [startDate, setStartDate] = useState(new Date());
  const [newStartDate, setNewStartDate] = useState(new Date());
  const [updateIntegration, setUpdateIntegration] = useState(false);
  const [interval, setInterval] = useState("1");
  const [newInterval, setNewInterval] = useState("");
  const [integrationHour, setIntegrationHour] = useState(0);
  const [newIntegrationHour, setNewIntegrationHour] = useState(0);
  const [newConnectionString, setNewConnectionString] = useState("");
  const [server, setServer] = useState("");
  const [database, setDatabase] = useState("");
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [aditionalConfig, setAditionalConfig] = useState("");
  const [connectionString, setConnectionString] = useState("");
  const [newStopIntegration, setNewStopIntegration] = useState<
    boolean | undefined
  >();
  const [stopIntegration, setStopIntegration] = useState<boolean | undefined>();
  const [entitySelected, setEntitySelected] = useState<any>();
  const [integrationBase, setIntegrationBase] = useState<Array<any>>([]);
  const [newIntegrationBase, setNewIntegrationBase] = useState<Array<any>>([]);
  const [integrationBaseSelected, setIntegrationBaseSelected] = useState<
    Array<any>
  >([]);

  const [typeIntegration, setTypeIntegration] = useState(0);
  const [newTypeIntegration, setNewTypeIntegration] = useState(0);
  const [urlEndpoint, setUrlEndpoint] = useState("");
  const [newUrlEndpoint, setNewUrlEndpoint] = useState("");
  const [headerList, setHeaderList] = useState<Array<ModelHeader>>([
    {
      key: "",
      value: "",
    },
  ]);
  const [newHeaderList, setNewHeaderList] = useState<Array<ModelHeader>>([
    {
      key: "",
      value: "",
    },
  ]);
  const [integrationNow, setIntegrationNow] = useState(false);
  const [frequencyType, setFrequencyType] = useState(0);

  const [scheduleTimesList, setScheduleTimesList] = useState("");
  const [intervalTimeList, setIntervalTimeList] = useState("");

  return (
    <ActiveModalIntegrationContext.Provider
      value={{
        activeModal,
        setActiveModal,
        previousActiveModal,
        setPreviousActiveModal,
        startDate,
        setStartDate,
        newStartDate,
        setNewStartDate,
        updateIntegration,
        setUpdateIntegration,
        interval,
        setInterval,
        newInterval,
        setNewInterval,
        integrationHour,
        setIntegrationHour,
        newIntegrationHour,
        setNewIntegrationHour,
        newConnectionString,
        setNewConnectionString,
        server,
        setServer,
        database,
        setDatabase,
        user,
        setUser,
        password,
        setPassword,
        aditionalConfig,
        setAditionalConfig,
        connectionString,
        setConnectionString,
        newStopIntegration,
        setNewStopIntegration,
        stopIntegration,
        setStopIntegration,
        entitySelected,
        setEntitySelected,
        integrationBase,
        setIntegrationBase,
        newIntegrationBase,
        setNewIntegrationBase,
        integrationBaseSelected,
        setIntegrationBaseSelected,
        urlEndpoint,
        setUrlEndpoint,
        newUrlEndpoint,
        setNewUrlEndpoint,
        headerList,
        setHeaderList,
        newHeaderList,
        setNewHeaderList,
        integrationNow,
        setIntegrationNow,
        typeIntegration,
        setTypeIntegration,
        newTypeIntegration,
        setNewTypeIntegration,
        frequencyType,
        setFrequencyType,
        scheduleTimesList,
        setScheduleTimesList,
        intervalTimeList,
        setIntervalTimeList,
      }}
    >
      {children}
    </ActiveModalIntegrationContext.Provider>
  );
}
