import { useContext } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { BsExclamationCircle, BsX } from "react-icons/bs";

import { ActiveModalIntegrationContext } from "../AutomaticIntegrationContexts/ActiveModalIntegrationContext";
import { ModalGAutomaticIntegrationErrorStyles } from "./styles";

export default function ModalStopEntityintegration() {
  const {
    activeModal,
    setActiveModal,
    setUpdateIntegration,
    entitySelected,
    integrationBaseSelected,
    setIntegrationBaseSelected,
    integrationBase,
    setNewIntegrationBase,
  } = useContext(ActiveModalIntegrationContext);

  function save() {
    const item = integrationBaseSelected;
    item.active = false;
    setIntegrationBaseSelected(item);
    const entities = [...integrationBase];
    const entitiesUpdate = entities.filter(
      (entity: any) => entity.integrationType !== entitySelected
    );

    entitiesUpdate.push(integrationBaseSelected);
    setNewIntegrationBase(entitiesUpdate);
    setUpdateIntegration(true);
    setActiveModal(0);
  }

  return (
    <ModalGAutomaticIntegrationErrorStyles
      show={activeModal === 13}
      onHide={() => setActiveModal(0)}
    >
      <Modal.Body>
        <Row className="pb-2 ml-1">
          <Col>
            <BsExclamationCircle size={110} color="#C57700" />
          </Col>
        </Row>
        <Row className="my-3" onClick={() => setActiveModal(0)}>
          <Col>
            <h2>
              Deseja parar de integrar
              {entitySelected === 1 && " Administrativo"}
              {entitySelected === 9 && " Aluno(a)"}
              {entitySelected === 10 && " Professor(a)"}
              {entitySelected === 4 && " Coordenador(a)"}
              {entitySelected === 8 && " Unidades"}
              {entitySelected === 13 && " Turmas"}
              {entitySelected === 14 && " Turmas/Aluno(a)"}
              {entitySelected === 15 && " Turmas/Professor(a)"}
              {entitySelected === 17 && " Turmas/Administrativo"}
              {entitySelected === 12 && " Turmas/Coordenador"}?
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col>
            <h3>
              As integrações não acontecerão até que você as inicie novamente.
            </h3>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button className="cancel-button" onClick={() => save()}>
          <BsX size={28} color="#A56300" />
          Parar integração
        </Button>
        <Button
          variant="primary"
          className="sucess-button"
          onClick={() => setActiveModal(0)}
        >
          Continuar integrando
        </Button>
      </Modal.Footer>
    </ModalGAutomaticIntegrationErrorStyles>
  );
}
