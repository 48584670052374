import { Col } from "react-bootstrap";
import styled from "styled-components";

export const LogDetailsStyles = styled(Col)`
  h2 {
    font-size: 16px;
    line-height: 24px;
    color: #525252;
  }
  h3 {
    margin: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #222;
  }

  .filter-search {
    display: flex;
    padding: 12px 8px;
    background: #f5f5f5;
    border: 1px solid #e4e3e3;
    box-sizing: border-box;
    border-radius: 8px;
    flex: none;
    order: 1;
    align-self: stretch;
    margin-top: 10px;
    height: 48px;
  }
  .button-return-logs {
    height: 48px;
    padding: 0 1rem;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    background-color: transparent;
    color: #2155ba;
    font-weight: 600;

    border: 1px solid #2155ba;
    border-radius: 8px;

    &:hover {
      background-color: #2155ba;
      color: #fff;

      & svg {
        fill: #fff;
      }
    }
  }

  .table-responsive {
    padding-right: 15px;
  }

  .button-update {
    width: 48px;
    height: 48px;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #2155ba;
    padding: 0px;
    background-color: #fff;
    border: 1px solid #2155ba;
    box-sizing: border-box;
    border-radius: 8px;

    &:focus {
      background: none !important;
      border: 1px solid #2155ba !important;
      color: #2155ba !important;
    }

    &:active {
      background: none !important;
      color: #2155ba !important;
    }

    &:hover {
      background: #2155ba !important;
      color: #fff !important;
      border: 1px solid #fff !important;
      .icon-plus {
        fill: #fff !important;
      }
    }

    &:disabled {
      background: none !important;
      border: #2155ba !important;
    }
  }
`;
