import Moment from 'react-moment';
import 'moment/locale/pt-br';

export const formatWorkLoad = (unformatedWorkLoad: string) => {
    const arrayString = unformatedWorkLoad.split(":");
    const formatedString: string = arrayString[0] + "h " + arrayString[1] + "min"
    return formatedString
}

export const formatDateToDDMMYYYY = (unformatedDate: any) => {

    return (
        <Moment locale="pt-BR" format="DD/MM/YYYY">
            {unformatedDate}
        </Moment>
    )
}

export const formatDateFull = (unformatedDate: any) => {
    /*
        example: 
        25 de janeiro de 2021
        12 de agosto de 2021
        16 de agosto de 2021
        11 de novembro de 2021
    */
    return (
        <Moment locale="pt-BR" format="LL">
            {unformatedDate}
        </Moment>
    )
}