import { useState, useEffect, useRef } from "react";
import { Col, Modal, Row, Form, Button } from "react-bootstrap";
import {
  BsArrowLeft,
  BsCheck2,
  BsPencil,
  BsPlusCircle,
  BsTrash,
  BsX,
} from "react-icons/bs";

import CustomTooltip from "../../../components/Common/CustomTooltip";
import PageLoader from "../../../components/Common/Loader";
import { showToast } from "../../../components/Common/Toast";
import Toggle from "../../../components/Common/Toggle";
import {
  invalidFieldMsg,
  requiredFieldMsg,
  urlInvalidMsg,
} from "../../../helpers/messagesValidation";
import {
  useValidation,
  feedBack,
  isInvalidFeedBack,
} from "../../../hooks/Validation";
import ApiInterface from "../../../services/ApiInterface";
import { ModalInsertionStyles } from "./styles";

interface ModalInsertionProps {
  showModal: boolean;
  handleClose: () => void;
  titleModal: string;
  isUpdate?: boolean;
  data?: any;
  handleReloadPage: () => void;
  institutionId: string;
}

const ModalInsertion = ({
  showModal,
  handleClose,
  titleModal,
  isUpdate,
  data,
  handleReloadPage,
  institutionId,
}: ModalInsertionProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const [headersList, setHeadersList] = useState<any[]>([]);
  const [urlEndpoint, setUrlEndpoint] = useState("");
  const [status, setStatus] = useState<boolean>(false);
  const [requestType, setRequestType] = useState("POST");

  const [isAddNewHeader, setIsAddNewHeader] = useState<boolean>(false);
  const [isUpdateNewHeader, setIsUpdateNewHeader] = useState<boolean>(false);
  const [indexHeaderToUpdate, setIndexHeaderToUpdate] = useState<number>(-1);
  const [newKey, setNewKey] = useState<string>("");
  const [newValue, setNewValue] = useState<string>("");

  const addNewHeaderContainer = useRef<HTMLDivElement>(null);

  // Variables
  const maxHeaders = 10;
  const [
    validationForm,
    setValidationFormField,
    validationErrors,
    setNewValidationErrors,
  ] = useValidation();

  useEffect(() => {
    setNewValidationErrors({});

    if (isUpdate) {
      if (!data) return;

      handleChangeURLEndpoint(data.url);
      setStatus(data.active);
      setRequestType(data.requestType);
      if (data.headers) {
        setHeadersList(data.headers);
      }
    }
  }, [data]);

  // FUNCTIONS
  const findErrors = () => {
    const result: any = {};

    if (validationForm.current) {
      if (
        !validationForm.current.urlEndpoint ||
        validationForm.current.urlEndpoint === ""
      )
        result.urlEndpoint = requiredFieldMsg;
      else if (validationForm.current.urlEndpoint?.length < 3)
        result.urlEndpoint = invalidFieldMsg;
      else if (!handleValidateURLInput(validationForm.current.urlEndpoint)) {
        result.urlEndpoint = urlInvalidMsg;
      } else result.urlEndpoint = undefined;

      if (isAddNewHeader) {
        if (
          !validationForm.current.newKey ||
          validationForm.current.newKey === ""
        )
          result.newKey = requiredFieldMsg;
        else result.newKey = undefined;

        if (
          !validationForm.current.newValue ||
          validationForm.current.newValue === ""
        )
          result.newValue = requiredFieldMsg;
        else result.newValue = undefined;
      }
    }

    return result;
  };

  const handleClearAll = () => {
    handleNewKeyChange("");
    handleNewValueChange("");
    setIsAddNewHeader(false);
    setIsUpdateNewHeader(false);
    setIndexHeaderToUpdate(-1);

    handleChangeURLEndpoint("");
    setStatus(false);
    setRequestType("POST");
    setHeadersList([]);

    handleClose();
  };

  const handleSave = () => {
    const errors = findErrors();
    const errorExist = setNewValidationErrors(errors);

    if (errorExist) return;

    setIsLoading(true);

    const payload = {
      url: urlEndpoint,
      requestType,
      active: status,
      headers: headersList?.map((item: any) => {
        return {
          key: item.key,
          value: item.value,
        };
      }),
    };

    if (isUpdate) {
      ApiInterface.put(`/WriteBack/${institutionId}/Update`, payload)
        .then((response) => {
          const result = response?.data?.result;

          if (result) {
            showToast("success", "Configurações atualizadas com sucesso");
            handleReloadPage();
          }

          setIsLoading(false);
        })
        .catch((error) => {
          showToast(
            "error",
            "Erro ao tentar atualizar configurações do Writeback."
          );
          console.error(
            "Erro ao tentar atualizar configurações do WriteBack. Error => ",
            error
          );
          setIsLoading(false);
        });
    } else {
      const postPayload = {
        ...payload,
        institutionId,
      };

      ApiInterface.post(`/WriteBack/${institutionId}/Insert`, postPayload)
        .then((response: any) => {
          if (!response.data) {
            showToast(
              "error",
              "Erro ao tentar criar configurações do Writeback."
            );
            console.error("Erro ao tentar criar configurações do WriteBack.");
            setIsLoading(false);
            handleClearAll();
            return;
          }

          const result = response?.data?.result;

          if (result) {
            showToast("success", "Configurações criadas com sucesso");
            handleReloadPage();
          }

          setIsLoading(false);
        })
        .catch((error) => {
          showToast(
            "error",
            "Erro ao tentar criar configurações do Writeback."
          );
          console.error(
            "Erro ao tentar criar configurações do WriteBack. Error => ",
            error
          );
          setIsLoading(false);
        });
    }

    handleClearAll();
  };

  const handleChangeURLEndpoint = (value: string) => {
    setUrlEndpoint(value);
    setValidationFormField("urlEndpoint", value);
  };

  const handleNewKeyChange = (value: string) => {
    setNewKey(value);
    setValidationFormField("newKey", value);
  };

  const handleNewValueChange = (value: string) => {
    setNewValue(value);
    setValidationFormField("newValue", value);
  };

  const handleShowNewHeaderSection = () => {
    handleNewKeyChange("");
    handleNewValueChange("");
    setIsAddNewHeader(!isAddNewHeader);
    setIsUpdateNewHeader(false);
  };

  const handleShowUpdateHeaderSection = (
    item: { key: string; value: string },
    index: number
  ) => {
    handleNewKeyChange(item.key);
    handleNewValueChange(item.value);
    setIndexHeaderToUpdate(index);

    setIsAddNewHeader(false);
    setIsUpdateNewHeader(true);

    if (addNewHeaderContainer.current) {
      addNewHeaderContainer.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleRemoveHeaderFromList = (index: number) => {
    const array = [...headersList];
    array.splice(index, 1);
    setHeadersList(array);
  };

  const handleAddHeaderFromList = () => {
    if (headersList?.length >= maxHeaders) return;

    const errors = findErrors();
    const errorExist = setNewValidationErrors(errors);

    if (!errorExist) {
      const array = [...headersList];
      const mountHeader = {
        key: newKey,
        value: newValue,
      };

      array.push(mountHeader);
      setHeadersList(array);

      handleShowNewHeaderSection();
    }
  };

  const handleUpdateHeaderFromList = () => {
    const errors = findErrors();
    const errorExist = setNewValidationErrors(errors);

    if (!errorExist) {
      const array = [...headersList];
      const mountHeader = {
        key: newKey,
        value: newValue,
      };

      array[indexHeaderToUpdate] = mountHeader;

      setHeadersList(array);

      setIsUpdateNewHeader(false);
    }
  };

  const handleValidateURLInput = (input: string) => {
    if (input.startsWith("http://") || input.startsWith("https://")) {
      return true;
    } else {
      return false;
    }
  };

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <ModalInsertionStyles show={showModal} onHide={handleClearAll}>
      <Modal.Header>
        <Modal.Title>
          <h2>{titleModal}</h2>
        </Modal.Title>
        <Button
          className="cursor-pointer"
          variant="white"
          onClick={handleClearAll}
        >
          <BsX color="var(--bs-modal-color)" size={24} />
        </Button>
      </Modal.Header>

      <Modal.Body className="mb-2">
        <section>
          <Form>
            <Form.Group as={Row} className="modalInsertionGroupRow">
              <Col sm={12} className="modalInsertionGroupCol">
                <Form.Label column>URL do Endpoint</Form.Label>
                <Form.Control
                  placeholder="Digite a URL do endpoint"
                  size="sm"
                  type="text"
                  value={urlEndpoint}
                  isInvalid={isInvalidFeedBack("urlEndpoint", validationErrors)}
                  onChange={(e) => {
                    handleChangeURLEndpoint(e.target.value);
                  }}
                // disabled={itemToShow}
                />

                <Form.Control.Feedback type="invalid">
                  {feedBack("urlEndpoint", validationErrors)}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="modalInsertionGroupRow mb-2">
              <Col sm={12} className="modalInsertionGroupColToggle">
                <Toggle
                  title="Status"
                  isSomething={status}
                  handleSomething={() => setStatus(!status)}
                // isDisabled={itemToShow}
                />
              </Col>
            </Form.Group>

            <section className="selectContainer modalInsertionGroupRow ">
              <Form.Label>Tipo de Requisição</Form.Label>
              <div className="selectAllChecksContainer">
                <div className="selectCheckboxContainer">
                  <Form.Check
                    className="button-radio"
                    type="radio"
                    label="POST"
                    checked={requestType === "POST"}
                    onChange={() => {
                      setRequestType("POST");
                    }}
                    disabled={false}
                  />
                </div>

                <div className="selectCheckboxContainer ">
                  <Form.Check
                    className="button-radio"
                    type="radio"
                    label="PATCH"
                    checked={requestType === "PATCH"}
                    onChange={() => {
                      setRequestType("PATCH");
                    }}
                    disabled={false}
                  />
                </div>

                <div className="selectCheckboxContainer">
                  <Form.Check
                    className="button-radio"
                    type="radio"
                    label="PUT"
                    checked={requestType === "PUT"}
                    onChange={() => {
                      setRequestType("PUT");
                    }}
                    disabled={false}
                  />
                </div>
              </div>
            </section>

            <Form.Group as={Row} className="modalInsertionGroupRow mb-2">
              <Col sm={12} className="modalInsertionGroupColHeaders">
                <Form.Label column>Headers (opcional)</Form.Label>

                {headersList && headersList?.length !== 0 ? (
                  <section className="headersContainer">
                    <div className="headersTop">
                      <span>Key (chave)</span>
                      <span>Value (valor)</span>
                    </div>

                    <div className="headersContent">
                      {headersList?.map((item: any, index: number) => {
                        return (
                          <div className="headersContentItem" key={index}>
                            <span>{item?.key}</span>
                            <span>{item?.value}</span>
                            <div className="actionButtons">
                              <div className="edit">
                                <CustomTooltip
                                  type="action-button"
                                  text="Editar header"
                                  placement="bottom"
                                  buttonContent={
                                    <Button
                                      variant="link"
                                      onClick={() =>
                                        handleShowUpdateHeaderSection(
                                          item,
                                          index
                                        )
                                      }
                                    >
                                      <BsPencil size={24} color="#2155ba" />
                                    </Button>
                                  }
                                />
                              </div>

                              <div className="trash">
                                <CustomTooltip
                                  type="action-button"
                                  text="Excluir header"
                                  placement="bottom"
                                  buttonContent={
                                    <Button
                                      variant="link"
                                      onClick={() =>
                                        handleRemoveHeaderFromList(index)
                                      }
                                    >
                                      <BsTrash size={24} color="#A56300" />
                                    </Button>
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </section>
                ) : (
                  <section className="headersEmptyContainer">
                    <span>Nenhum header foi adicionado.</span>
                  </section>
                )}
              </Col>
            </Form.Group>

            <section
              className="isAddNewHeaderContainer"
              ref={addNewHeaderContainer}
            >
              {(isAddNewHeader || isUpdateNewHeader) && (
                <div className="contentNewHeader">
                  <Form.Group as={Row} className="modalInsertionGroupRow mb-2">
                    <Col sm={6} className="modalInsertionGroupCol">
                      <Form.Label column>Key (chave)</Form.Label>
                      <Form.Control
                        placeholder="Digite a key (chave)..."
                        size="sm"
                        type="text"
                        value={newKey}
                        isInvalid={isInvalidFeedBack(
                          "newKey",
                          validationErrors
                        )}
                        onChange={(e) => {
                          handleNewKeyChange(e.target.value);
                        }}
                      />

                      <Form.Control.Feedback type="invalid">
                        {feedBack("newKey", validationErrors)}
                      </Form.Control.Feedback>
                    </Col>

                    <Col sm={6} className="modalInsertionGroupCol">
                      <Form.Label column>Value (valor)</Form.Label>
                      <Form.Control
                        placeholder="Digite o value (valor)..."
                        size="sm"
                        type="text"
                        value={newValue}
                        isInvalid={isInvalidFeedBack(
                          "newValue",
                          validationErrors
                        )}
                        onChange={(e) => {
                          handleNewValueChange(e.target.value);
                        }}
                      />

                      <Form.Control.Feedback type="invalid">
                        {feedBack("newValue", validationErrors)}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="buttonsContainer">
                    <>
                      <Button
                        onClick={
                          isAddNewHeader
                            ? handleShowNewHeaderSection
                            : handleUpdateHeaderFromList
                        }
                        className="cancelButton"
                      >
                        Cancelar
                      </Button>

                      <Button
                        onClick={
                          isAddNewHeader
                            ? handleAddHeaderFromList
                            : handleUpdateHeaderFromList
                        }
                        className="saveButton"
                      >
                        Salvar Header
                      </Button>
                    </>
                  </Form.Group>
                </div>
              )}
            </section>

            {!isAddNewHeader &&
              !isUpdateNewHeader &&
              headersList?.length < maxHeaders && (
                <Form.Group as={Row} className="modalInsertionGroupRow mb-2">
                  <Col sm={12} className="modalInsertionGroupCol">
                    <div className="buttonAddHeaders">
                      <Button
                        onClick={handleShowNewHeaderSection}
                        className="button-add-header"
                      >
                        <BsPlusCircle size={24} />
                        Adicionar novo header
                      </Button>
                    </div>
                  </Col>
                </Form.Group>
              )}
          </Form>
        </section>
      </Modal.Body>
      <Modal.Footer>
        <Button className="cancel-operation" onClick={handleClearAll}>
          <BsArrowLeft color="#A56300" size={24} />
          Cancelar
        </Button>
        <Button
          variant="primary"
          className="success-button"
          disabled={isAddNewHeader || isUpdateNewHeader}
          onClick={handleSave}
        >
          Salvar
          <BsCheck2 color="#Fff" size="28" />
        </Button>
      </Modal.Footer>
    </ModalInsertionStyles>
  );
};

export default ModalInsertion;
