import { createContext, ReactNode, useState } from "react";

import { BrainzUserSettingsViewModel } from "../../../interfaces/BrainzUserSettingsViewModel";

type ChildrenContextProps = {
  children: ReactNode;
};
type ActiveModalContextData = {
  activeModal: number;
  setActiveModal: React.Dispatch<React.SetStateAction<number>>;
  previousActiveModal: number;
  setPreviousActiveModal: React.Dispatch<React.SetStateAction<number>>;
  activeTab: number;
  changeActiveTab: React.Dispatch<React.SetStateAction<number>>;
  teamId: string;
  setTeamId: React.Dispatch<React.SetStateAction<string>>;
  listRefresh: number;
  setListRefresh: React.Dispatch<React.SetStateAction<number>>;
  identifierTeam: string;
  setIdentifierTeam: React.Dispatch<React.SetStateAction<string>>;
  updateUser: boolean;
  setUpdateUser: React.Dispatch<React.SetStateAction<boolean>>;
  updateUnit: boolean;
  setUpdateUnit: React.Dispatch<React.SetStateAction<boolean>>;
  updateTeam: boolean;
  setUpdateTeam: React.Dispatch<React.SetStateAction<boolean>>;
  userSettings: any;
  setUserSettings: React.Dispatch<React.SetStateAction<any>>;
  reloadMeeting: boolean;
  setReloadMeeting: React.Dispatch<React.SetStateAction<any>>;

  reloadMeetingFlag: string;
  setReloadMeetingFlag: React.Dispatch<React.SetStateAction<any>>;

  itemToUpdate: any;
  setItemToUpdate: React.Dispatch<React.SetStateAction<any>>;

  itemToShow: any;
  setItemToShow: React.Dispatch<React.SetStateAction<any>>;
};
export const ActiveModalContext = createContext({} as ActiveModalContextData);

export default function ActiveModalContextProvider({
  children,
}: ChildrenContextProps) {
  const [activeModal, setActiveModal] = useState<number>(0);
  const [previousActiveModal, setPreviousActiveModal] = useState<number>(0);
  const [activeTab, changeActiveTab] = useState<number>(1);
  const [teamId, setTeamId] = useState<string>("");
  const [listRefresh, setListRefresh] = useState<number>(0);
  const [identifierTeam, setIdentifierTeam] = useState<string>("");
  const [updateUser, setUpdateUser] = useState(false);
  const [updateUnit, setUpdateUnit] = useState(false);
  const [updateTeam, setUpdateTeam] = useState(false);
  const [userSettings, setUserSettings] = useState<
    BrainzUserSettingsViewModel | undefined
  >();
  const [reloadMeeting, setReloadMeeting] = useState<boolean>(false);
  const [reloadMeetingFlag, setReloadMeetingFlag] = useState<string>("");

  const [itemToUpdate, setItemToUpdate] = useState<any>();
  const [itemToShow, setItemToShow] = useState<any>();

  return (
    <ActiveModalContext.Provider
      value={{
        activeModal,
        setActiveModal,
        previousActiveModal,
        setPreviousActiveModal,
        activeTab,
        changeActiveTab,
        teamId,
        setTeamId,
        listRefresh,
        setListRefresh,
        identifierTeam,
        setIdentifierTeam,
        updateUser,
        setUpdateUser,
        updateUnit,
        setUpdateUnit,
        updateTeam,
        setUpdateTeam,
        userSettings,
        setUserSettings,
        reloadMeeting,
        setReloadMeeting,
        reloadMeetingFlag,
        setReloadMeetingFlag,
        itemToUpdate,
        setItemToUpdate,
        itemToShow,
        setItemToShow,
      }}
    >
      {children}
    </ActiveModalContext.Provider>
  );
}
