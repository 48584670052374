import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { BsFileRichtext, BsInfoCircle, BsXOctagon } from "react-icons/bs";
import Cookies from "universal-cookie";

import BrainzPagination, {
  PaginationProps,
} from "../../../components/Common/BrainzPagination/BrainzPagination";
import CustomTooltip from "../../../components/Common/CustomTooltip";
import PageLoader from "../../../components/Common/Loader";
import NoResults from "../../../components/Common/NoResults";
import { showToast } from "../../../components/Common/Toast";
import { handleGetPageSize, renderStatusTableData } from "../../../helpers/functions";
import TeamApiInterface from "../../../services/TeamApiInterface";
import { TableSyncDataStyles } from "../../../styles/generic";
import { ActiveModalContext } from "../SyncDataContexts/ActiveModalContext";
import { BrainzTeamTableViewModel, BrainzTeamViewModel } from "../SyncDataTeam";
import "../../../styles/tableCustomStyles.css";

interface DataTeamComponentProps {
  setItemActive: React.Dispatch<React.SetStateAction<any>>;
  setToDeleteItem: React.Dispatch<React.SetStateAction<string>>;
  setToReactiveItem: React.Dispatch<React.SetStateAction<any>>;
  handleModalActiveTeamShow: () => void;
  handleModalshowEditTeamShow: () => void;
  searchFilter: string;
  activeFilter: string;
  unitIdFilter: string;
  setButtonClearFilters: React.Dispatch<React.SetStateAction<boolean>>;
  setUnitIdentifier: React.Dispatch<React.SetStateAction<string>>;
  setItemDetails: React.Dispatch<React.SetStateAction<any>>;
  ClearFilters: () => void;
  updateUser: boolean;
}
export default function DataTeamComponent(props: DataTeamComponentProps) {
  const cookie = new Cookies();
  const institutionId = cookie.get(process.env.REACT_APP_BRAINZ_INSTITUTIONID!);
  const { setTeamId, setIdentifierTeam, setActiveModal } = useContext(ActiveModalContext);

  const [loading, setLoading] = useState(true);
  const [queryPaginationParams, setQueryPaginationParams] = useState(
    `?page=1&pageSize=${handleGetPageSize()}`
  );
  const [paging, setPaging] = useState<PaginationProps | undefined>();
  const [unitOptions, setUnitOptions] = useState<Array<any> | undefined>([]);
  const [teams, setTeams] = useState<
    Array<BrainzTeamTableViewModel> | undefined
  >();

  function gotoPageOne() {
    loadPage(`/Team/${institutionId}/List?page=1&pageSize=${handleGetPageSize()}${queryFilters()}`)
  }

  function loadPage(url?: string) {
    const urlParam = url ?? `/Team/${institutionId}/List${queryPagination()}${queryFilters()}`
    setLoading(true);
    if (queryPaginationParams)
      TeamApiInterface.get(urlParam)
        .then((response) => {
          setPaging(response.data.paging);
          const teamList: Array<BrainzTeamViewModel> = response.data.result;
          const newTeamList: Array<BrainzTeamTableViewModel> = teamList.map(
            (item) => {
              return {
                name: item.name,
                identifier: item.identifier,
                creationDate: item.creationDate,
                active: item.active,
                unitName: item.unit.name,
                serviceId: item.serviceId,
                unitTableIdentifier: item.unit.identifier,
                id: item.id,
              };
            }
          );
          setTeams(newTeamList);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          showToast("error", "Algo deu errado, tente novamente mais tarde");
          console.error("Erro => ", error);
        });
  }

  function handleActiveOnClick(item: BrainzTeamViewModel | any) {
    props.setItemActive(item.active);
    props.setToDeleteItem(item.id);
    props.setToReactiveItem(item);
    props.handleModalActiveTeamShow();
  }

  function queryPagination() {
    let result: string;
    if (queryPaginationParams) result = `${queryPaginationParams}`;
    else result = "";
    return result;
  }

  function queryFilters() {
    let query = "";
    if (props.searchFilter !== "")
      query = query += "&search=" + props.searchFilter;
    if (props.activeFilter === "true") query = query += "&activeFilter=true";
    if (props.activeFilter === "false") query = query += "&activeFilter=false";
    if (props.activeFilter === "") query = query += "";
    if (props.unitIdFilter !== "")
      query = query += `&unitIdFilter=${encodeURIComponent(
        props.unitIdFilter
      )}`;

    if (query !== "") props.setButtonClearFilters(true);
    else props.setButtonClearFilters(false);
    return query;
  }

  const GoDetails = (item: any) => {
    setLoading(true);
    props.setItemDetails(item);
    setIdentifierTeam(item.identifier);
    setTeamId(item.id);
    props.setUnitIdentifier(item.unitTableIdentifier);
    props.handleModalshowEditTeamShow();
    setLoading(false);
  };

  const handleOpenRecordHistory = (item: any) => {
    setIdentifierTeam(item.identifier);
    setActiveModal(22)
  }

  useEffect(() => {
    if (unitOptions && unitOptions.length === 1) {
      setTimeout(function () {
        props.setUnitIdentifier(unitOptions[0].value ?? "");
        setUnitOptions(undefined);
      }, 50);
    }
  }, [unitOptions]);

  useEffect(() => {
    loadPage();
  }, [
    queryPaginationParams,
  ]);

  useEffect(() => {
    gotoPageOne()
  }, [
    props.searchFilter,
    props.activeFilter,
    props.unitIdFilter,
    props.updateUser
  ])

  return (
    <Row>
      {loading ? (
        <PageLoader />
      ) : teams && teams.length > 0 ? (
        <>
          <TableSyncDataStyles className="dataCustomTable">
            <Table
              className="mt-3 table-list"
              striped
              borderless
              hover
              responsive
            >
              <thead>
                <tr className="title-table">
                  <th className="border-radius-left">Status</th>
                  <th>Identificador</th>
                  <th>Nome</th>
                  <th>Criado em</th>
                  <th>Id Integração</th>
                  <th>Unidade</th>
                  <th className="border-radius-right">Ações</th>
                </tr>
              </thead>
              <tbody>
                {teams?.map((item: BrainzTeamTableViewModel) => {
                  return (
                    <tr
                      id="tr-linha"
                      key={item.id}
                      className="table-values cursor-pointer"
                    >
                      {renderStatusTableData(item.active)}
                      <td>{item.identifier}</td>
                      <td>{item.name}</td>
                      <td>{moment(item.creationDate).format("lll")}</td>
                      <td>{item.serviceId}</td>
                      <td>{item.unitName}</td>
                      <td className="tableActions">
                        <CustomTooltip
                          type="action-button"
                          text="Detalhes da turma"
                          placement="bottom"
                          buttonContent={
                            <div
                              role="button"
                              className="action-item"
                              onClick={() => GoDetails(item)}
                            >
                              <BsInfoCircle color="#0F7EAA" size={24} />
                            </div>
                          }
                        />

                        <CustomTooltip
                          type="action-button"
                          text="Acessar histórico de registros"
                          placement="bottom"
                          buttonContent={
                            <div
                              role="button"
                              className="action-item"
                              onClick={() => handleOpenRecordHistory(item)}
                            >
                              <BsFileRichtext color="#2155ba" size={24} />
                            </div>
                          }
                        />

                        {item.serviceId && (
                          <CustomTooltip
                            type="action-button"
                            text={
                              item.active ? "Inativar turma" : "Reativar turma"
                            }
                            placement="bottom"
                            buttonContent={
                              <div
                                role="button"
                                className="action-item"
                                onClick={() => handleActiveOnClick(item)}
                              >
                                <BsXOctagon color="#A56300" size={24} />
                              </div>
                            }
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <Col md={12}>
              {paging && (
                <BrainzPagination
                  setQueryParams={setQueryPaginationParams}
                  paging={paging}
                />
              )}
            </Col>
          </TableSyncDataStyles>
        </>
      ) : (
        <NoResults clearFilterFunction={() => props.ClearFilters()} />
      )}
    </Row>
  );
}
