import { createContext, ReactNode, useState } from "react";

import { ModelDomain } from "../Modals/ModalFirstConfigurationProfile";

type ChildrenContextProps = {
  children: ReactNode;
};
type GeneralConfigurationContextData = {
  activeModal: number;
  setActiveModal: React.Dispatch<React.SetStateAction<number>>;
  userType: number;
  setUserType: React.Dispatch<React.SetStateAction<number>>;
  entityType: string;
  setEntityType: React.Dispatch<React.SetStateAction<string>>;
  newEmailType: any;
  setNewEmailType: React.Dispatch<React.SetStateAction<any>>;
  updateIntegration: boolean;
  setUpdateIntegration: React.Dispatch<React.SetStateAction<boolean>>;
  settingsProfile: any;
  setSettingsProfile: React.Dispatch<React.SetStateAction<any>>;
  newPasswordType: any;
  setNewPasswordType: React.Dispatch<React.SetStateAction<any>>;
  newFixedPassword: any;
  setNewFixedPassword: React.Dispatch<React.SetStateAction<any>>;
  newJobTitle: any;
  setNewJobTitle: React.Dispatch<React.SetStateAction<any>>;
  newDocument: any;
  setNewDocument: React.Dispatch<React.SetStateAction<any>>;
  newCellPhone: any;
  setNewCellPhone: React.Dispatch<React.SetStateAction<any>>;
  newDepartment: any;
  setNewDepartment: React.Dispatch<React.SetStateAction<any>>;
  newBirthday: any;
  setNewBirthday: React.Dispatch<React.SetStateAction<any>>;
  newLicenses: any;
  setNewLicenses: React.Dispatch<React.SetStateAction<any>>;
  newDomain: any;
  setNewDomain: React.Dispatch<React.SetStateAction<any>>;
  domain: any;
  setDomain: React.Dispatch<React.SetStateAction<any>>;
  newDomainList: any;
  setNewDomainList: React.Dispatch<React.SetStateAction<any>>;
  firstUpdateIntegration: boolean;
  setFirstUpdateIntegration: React.Dispatch<React.SetStateAction<boolean>>;
};

export const GeneralConfigurationContext = createContext(
  {} as GeneralConfigurationContextData
);

export default function GeneralConfigurationContextProvider({
  children,
}: ChildrenContextProps) {
  const [activeModal, setActiveModal] = useState<number>(9);
  const [userType, setUserType] = useState<number>(4);
  const [entityType, setEntityType] = useState<string>("Aluno(a)");
  const [newEmailType, setNewEmailType] = useState<any>();
  const [updateIntegration, setUpdateIntegration] = useState(false);
  const [firstUpdateIntegration, setFirstUpdateIntegration] = useState(false);
  const [settingsProfile, setSettingsProfile] = useState<any>([]);

  const [newPasswordType, setNewPasswordType] = useState<any>();
  const [newFixedPassword, setNewFixedPassword] = useState<any>();

  const [newJobTitle, setNewJobTitle] = useState<any>();
  const [newDocument, setNewDocument] = useState<any>();
  const [newCellPhone, setNewCellPhone] = useState<any>();
  const [newDepartment, setNewDepartment] = useState<any>();
  const [newBirthday, setNewBirthday] = useState<any>();
  const [newLicenses, setNewLicenses] = useState<any>();

  const [newDomain, setNewDomain] = useState<any>();
  const [domain, setDomain] = useState<any>();
  const [newDomainList, setNewDomainList] = useState<Array<ModelDomain>>([
    {
      domain: "",
      active: false,
    },
  ]);

  return (
    <GeneralConfigurationContext.Provider
      value={{
        activeModal,
        setActiveModal,
        userType,
        setUserType,
        entityType,
        setEntityType,
        newEmailType,
        setNewEmailType,
        updateIntegration,
        setUpdateIntegration,
        settingsProfile,
        setSettingsProfile,
        newPasswordType,
        setNewPasswordType,
        newFixedPassword,
        setNewFixedPassword,
        newJobTitle,
        setNewJobTitle,
        newDocument,
        setNewDocument,
        newCellPhone,
        setNewCellPhone,
        newDepartment,
        setNewDepartment,
        newBirthday,
        setNewBirthday,
        newLicenses,
        setNewLicenses,
        newDomain,
        setNewDomain,
        domain,
        setDomain,
        newDomainList,
        setNewDomainList,
        firstUpdateIntegration,
        setFirstUpdateIntegration,
      }}
    >
      {children}
    </GeneralConfigurationContext.Provider>
  );
}
