import pt from "date-fns/locale/pt";
import React, { useContext, useEffect, useState } from "react";
import { Col, Modal, Row, Form, Button, Table } from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import {
  BsArrowLeft,
  BsArrowRight,
  BsCheck,
  BsCheck2Circle,
  BsChevronDown,
  BsChevronRight,
  BsChevronUp,
  BsDashCircle,
  BsPlusCircle,
  BsTrash,
  BsX,
} from "react-icons/bs";
import Cookies from "universal-cookie";

import CustomTooltip from "../../../components/Common/CustomTooltip";
import FrequencyIntegrationBody from "../../../components/Common/FrequencyIntegrationBody";
import PageLoader from "../../../components/Common/Loader";
import Stepper from "../../../components/Common/Stepper";
import TestConnection from "../../../components/Common/TestConnection/TestConnection";
import { showToast } from "../../../components/Common/Toast";
import Toggle from "../../../components/Common/Toggle";
import { ProfileContext } from "../../../contexts/ProfileContext";
import { requiredFieldMsg } from "../../../helpers/messagesValidation";
import {
  useValidation,
  isInvalidFeedBack,
  feedBack,
} from "../../../hooks/Validation";
import ApiInterface from "../../../services/ApiInterface";
import { ActiveModalIntegrationContext } from "../AutomaticIntegrationContexts/ActiveModalIntegrationContext";
import {
  handleFormatPeriodicIntervalToString,
  handleFormatScheduleTimeToString,
} from "../utils/functions";
import { ModalGAutomaticIntegrationStyles, TableStylesG } from "./styles";

export interface ModelHeader {
  key: string;
  value: string;
}
export default function ModalFirstSetupApi() {
  const {
    setActiveModal,
    activeModal,
    setPreviousActiveModal,
    setNewTypeIntegration,
    frequencyType,
    setScheduleTimesList,
    setIntervalTimeList,
    scheduleTimesList,
    intervalTimeList,
    urlEndpoint,
    headerList,
    startDate,
  } = useContext(ActiveModalIntegrationContext);

  const { configurationProfile } = useContext(ProfileContext);
  registerLocale("pt", pt);

  const cookie = new Cookies();
  const institutionId = cookie.get(process.env.REACT_APP_BRAINZ_INSTITUTIONID!);
  const [trashButtonDisable, setTrashButtonDisable] = useState<boolean>(true);
  const [stepFirstSetupApi, setStepFirstSetupApi] = useState(1);

  const [buttonDisableAddHeader, setButtonDisableAddHeader] =
    useState<boolean>(true);
  const [entityTeamAndTeacherExpand, setEntityTeamAndTeacherExpand] =
    useState(false);

  // const [testConnectionServer, setTestConnectionServer] = useState(false);

  const [selectedScheduleTimes, setSelectedScheduleTimes] = useState<any>([]);
  const [selectedInterval, setSelectedInterval] = useState<any>("");

  const [
    validationForm,
    setValidationFormField,
    validationErrors,
    setNewValidationErrors,
  ] = useValidation();
  const [startDateLocal, setStartDateLocal] = useState<Date | undefined>();
  const [interval, setInterval] = useState("1");
  const [urlEndpointLocal, setUrlEndpointLocal] = useState(urlEndpoint ?? "");
  const [loading, setLoading] = useState(false);
  const [integrationHour, setIntegrationHour] = useState(0);
  const [errorExists, setErrorExists] = useState(false);

  const [headerListLocal, setHeaderListLocal] = useState<Array<ModelHeader>>([
    {
      key: "",
      value: "",
    },
  ]);

  const [integrationEntities, setIntegrationEntities] = useState<any>([]);
  const [localFreqType, setLocalFreqType] = useState<any>(frequencyType);

  function handlerNextStep2() {
    const existErrorUrlEnpoint = findErrorsUrlEndpoint();
    if (!existErrorUrlEnpoint) {
      setStepFirstSetupApi(2);
    }
  }

  function handlerNextStep3() {
    const existErrorFrequencyIntegration = findErrors();
    if (!existErrorFrequencyIntegration) {
      if (localFreqType === 1) {
        const result = handleFormatScheduleTimeToString(selectedScheduleTimes);
        setScheduleTimesList(result);
      }

      if (localFreqType === 2) {
        const result = handleFormatPeriodicIntervalToString(
          selectedInterval?.value
        );
        setIntervalTimeList(result);
      }

      setStepFirstSetupApi(3);
    }
  }

  // function ClearIntegration() {
  //   setStepFirstSetupApi(1);
  //   setStartDateLocal(new Date());
  //   setInterval("1");
  //   setIntegrationHour(0);
  //   setUrlEndpointLocal("");
  //   setErrorExists(false);
  // }

  useEffect(() => {
    updateValidation();
    function updateValidation() {
      validationForm.current = {
        urlEndpointLocal,
        startDateLocal,
        interval,
      };
    }
  }, [urlEndpointLocal, startDateLocal, interval]);

  useEffect(() => {
    setUrlEndpointLocal(urlEndpoint);
    setValidationFormField("urlEndpointLocal", urlEndpoint);
    setButtonDisableAddHeader(false);
  }, [urlEndpoint]);

  useEffect(() => {
    setHeaderListLocal(headerList);
  }, [headerList]);

  useEffect(() => {
    setStartDateLocal(startDate);
  }, [startDate]);

  useEffect(() => {
    setIntegrationEntities([
      {
        name: "Admininstrativo",
        id: 1,
        active: false,
        disabled: false,
        educational: true,
        inactiveAbsent: false,
        route: "",
        params: "",
        fieldParse: "",
        checkbox: true,
        configProfile: configurationProfile?.some(
          (item: any) => item.userType === 1
        ),
        dependants: [],
      },
      {
        name: "Professores",
        id: 10,
        active: false,
        disabled: false,
        educational: true,
        inactiveAbsent: false,
        route: "",
        params: "",
        fieldParse: "",
        checkbox: true,
        configProfile: configurationProfile?.some(
          (item: any) => item.userType === 5
        ),
        dependants: [],
      },
      {
        name: "Alunos",
        id: 9,
        active: false,
        disabled: false,
        educational: true,
        inactiveAbsent: false,
        route: "",
        params: "",
        fieldParse: "",
        checkbox: true,
        configProfile: configurationProfile?.some(
          (item: any) => item.userType === 4
        ),

        dependants: [],
      },
      {
        name: "Coordenador",
        id: 4,
        active: false,
        disabled: false,
        educational: true,
        inactiveAbsent: false,
        route: "",
        params: "",
        fieldParse: "",
        checkbox: true,
        configProfile: configurationProfile?.some(
          (item: any) => item.userType === 3
        ),
        dependants: [],
      },
      {
        name: "Turma",
        id: 13,
        active: false,
        disabled: false,
        educational: true,
        inactiveAbsent: false,
        route: "",
        params: "",
        fieldParse: "",
        checkbox: true,
        configProfile: true,
        dependants: [],
      },
      {
        name: "Unidades",
        id: 8,
        active: false,
        disabled: false,
        educational: true,
        inactiveAbsent: false,
        route: "",
        params: "",
        fieldParse: "",
        checkbox: true,
        configProfile: true,
        dependants: [],
      },
      {
        name: "Turma / Aluno",
        id: 14,
        active: false,
        disabled: true,
        educational: true,
        inactiveAbsent: false,
        route: "",
        params: "",
        fieldParse: "",
        checkbox: false,
        configProfile: true,
        dependants: [13, 9],
      },
      {
        name: "Turma / Professor",
        id: 15,
        active: false,
        disabled: true,
        educational: true,
        inactiveAbsent: false,
        route: "",
        params: "",
        fieldParse: "",
        checkbox: false,
        configProfile: true,
        dependants: [13, 10],
      },
      {
        name: "Turma / Administrativo",
        id: 17,
        active: false,
        disabled: true,
        educational: true,
        inactiveAbsent: false,
        route: "",
        params: "",
        fieldParse: "",
        checkbox: false,
        configProfile: true,
        dependants: [13, 1],
      },
      {
        name: "Turma / Coordenador",
        id: 12,
        active: false,
        disabled: false,
        educational: true,
        inactiveAbsent: false,
        route: "",
        params: "",
        fieldParse: "",
        checkbox: false,
        configProfile: true,
        dependants: [13, 4],
      },
    ]);
  }, [configurationProfile]);

  useEffect(() => {
    if (activeModal === 3) {
      setLocalFreqType(frequencyType);
    }
  }, [activeModal]);

  function setRoute(index: number, route: string) {
    const entities = [...integrationEntities];
    const item = entities[index];
    item.route = route;
    entities[index] = item;
    setIntegrationEntities(entities);
  }
  function setFieldParse(index: number, fieldParse: string) {
    const entities = [...integrationEntities];
    const item = entities[index];
    item.fieldParse = fieldParse;
    entities[index] = item;
    setIntegrationEntities(entities);
  }
  function setParams(index: number, params: string) {
    const entities = [...integrationEntities];
    const item = entities[index];
    item.params = params;
    entities[index] = item;
    setIntegrationEntities(entities);
  }

  function findErrors() {
    const errors: any = {};
    if (validationForm.current) {
      if (
        !validationForm.current.startDateLocal ||
        validationForm.current.startDateLocal === ""
      )
        errors.startDateLocal = requiredFieldMsg;
      else errors.startDateLocal = undefined;

      if (
        !validationForm.current.interval ||
        validationForm.current.interval === "" ||
        validationForm.current.interval === "0"
      )
        errors.interval = requiredFieldMsg;
      else errors.interval = undefined;
    }

    const result = setNewValidationErrors(errors);
    return result;
  }
  function findErrorsEntity() {
    const errors: any = {};
    integrationEntities.forEach((entity) => {
      if (entity.active) {
        if (validationForm.current) {
          if (
            !validationForm.current[entity.route] ||
            validationForm.current[entity.route] === ""
          )
            errors[entity.route] = requiredFieldMsg;
          else errors[entity.route] = undefined;

          if (
            !validationForm.current[entity.fieldParse] ||
            validationForm.current[entity.fieldParse] === ""
          )
            errors[entity.fieldParse] = requiredFieldMsg;
          else errors[entity.fieldParse] = undefined;
        }
      }
    });
    const result = setNewValidationErrors(errors);
    return result;
  }

  function findErrorsUrlEndpoint() {
    const errors: any = {};

    if (validationForm.current) {
      if (
        !validationForm.current.urlEndpointLocal ||
        validationForm.current.urlEndpointLocal === ""
      )
        errors.urlEndpointLocal = requiredFieldMsg;
      else errors.urlEndpointLocal = undefined;
    }
    const result = setNewValidationErrors(errors);
    return result;
  }

  function handleClick(index: number) {
    const entities = [...integrationEntities];
    const item = entities[index];
    item.active = !item.active;
    entities[index] = item;
    setIntegrationEntities(entities);
    activateIntegrations();
  }
  function activateIntegrations() {
    integrationEntities.forEach((entity, index) => {
      if (entity.dependants.length > 0) {
        let active = true;
        entity.dependants.forEach((element) => {
          const dependantEntity = integrationEntities.find(
            (a) => a.id === element
          );
          if (dependantEntity && !dependantEntity.active) {
            active = false;
          }
        });
        setActive(index, active);
      }
    });
  }
  function setActive(index: number, active: boolean) {
    const entities = [...integrationEntities];
    const item = entities[index];
    item.active = active;
    entities[index] = item;
    setIntegrationEntities(entities);
  }

  function toggleInactiveAbsent(index: number, value: boolean) {
    const entities = [...integrationEntities];
    const item = entities[index];
    item.inactiveAbsent = value;
    entities[index] = item;
    setIntegrationEntities(entities);
  }

  function saveData() {
    setLoading(true);

    const errorsEntityViews = findErrorsEntity();

    if (!errorsEntityViews) {
      const errorExist = findErrors();
      const existErrorUrlEnpoint = findErrorsUrlEndpoint();
      const errorEntitiesExist = findErrorsEntity();

      if (errorExist || existErrorUrlEnpoint || errorEntitiesExist) {
        setErrorExists(true);
      }
      if (!errorExist && !existErrorUrlEnpoint && !errorEntitiesExist) {
        setLoading(true);
        setTimeout(() => save(), 20 * 100);
      } else {
        showToast(
          "error",
          "Houve um erro com o formulário, valide seus dados e tente novamente"
        );
      }
    } else {
      setLoading(false);
    }
  }
  function save() {
    setNewTypeIntegration(4);

    const payloadApi = {
      institutionId,
      integrationEnabled: true,
      dataSource: 4,
      startDate: startDateLocal,
      hour: integrationHour,
      daysFrequency: +interval,

      frequencyType: localFreqType,
      scheduledHours:
        localFreqType === 1 ? scheduleTimesList : intervalTimeList,

      automaticIntegrationTypes: integrationEntities.map((entity) => {
        return {
          integrationType: entity.id,
          active: entity.active,
          inactiveAbsent: entity.inactiveAbsent,
          automaticIntegrationByRestApi: {
            url: urlEndpointLocal,
            headers: headerListLocal.map((item) => {
              return {
                Key: item.key,
                Value: item.value,
              };
            }),
            route: entity.route,
            fieldParse: entity.fieldParse,
            params: entity.params,
          },
        };
      }),
    };

    ApiInterface.put(
      "/AutomaticIntegration/" + institutionId + "/Update",
      payloadApi
    )
      .then((response) => {
        if (response.status === 200) {
          setActiveModal(0);
          setLoading(false);

          showToast(
            "success",
            "A configuração foi concluída com sucesso. Você está sendo redirecionado."
          );
          setTimeout(() => {
            window.location.reload();
          }, 15 * 100);
        } else {
          setLoading(false);
          showToast("error", "Algo deu errado, tente novamente mais tarde");
          console.error(
            "Erro na resposta do servidor ao salvar primeira configuração via API. Erro => ",
            response
          );
        }
      })
      .catch((error) => {
        showToast("error", "Algo deu errado, tente novamente mais tarde");
        setLoading(false);
        setActiveModal(6);

        console.error(
          "Erro ao salvar a primeira integração via API. Erro => ",
          error
        );
      });
  }

  function handleHeaderKey(index: any, value: string) {
    const entities = [...headerListLocal];
    const item = entities[index];
    item.key = value;
    entities[index] = item;
    setHeaderListLocal(entities);
  }
  function handleHeaderValue(index: any, value: string) {
    const entities = [...headerListLocal];
    const item = entities[index];
    item.value = value;
    entities[index] = item;
    setHeaderListLocal(entities);
  }

  function removeHeader(index: any) {
    let newArr = [...headerListLocal];
    newArr = newArr.filter((item, i) => i !== index);
    setHeaderListLocal(newArr);
  }

  function AddNewHeader() {
    const entities = [...headerListLocal];
    const newItem: ModelHeader = {
      key: "",
      value: "",
    };
    entities.push(newItem);
    setHeaderListLocal(entities);
  }

  const handleValidateStartDateLocal = () => {
    if (startDateLocal) return true;

    showToast("error", "Preencha o campo de data de início da integração.");
    return false;
  };

  if (loading) {
    return <PageLoader />;
  }

  return configurationProfile ? (
    <ModalGAutomaticIntegrationStyles
      show={activeModal === 3}
      onHide={() => [setActiveModal(4), setPreviousActiveModal(3)]}
    >
      <Modal.Header>
        <Modal.Title>
          {stepFirstSetupApi === 1 ? (
            <>
              {" "}
              <h2>
                Integração via API <BsChevronRight size={18} /> Dados da API
              </h2>
            </>
          ) : (
            <></>
          )}
          {stepFirstSetupApi === 2 ? (
            <>
              {" "}
              <h2>
                Integração via API <BsChevronRight size={18} /> Frequência das
                integrações
              </h2>
            </>
          ) : (
            <></>
          )}
          {stepFirstSetupApi === 3 ? (
            <>
              {" "}
              <h2>
                Integração via API <BsChevronRight size={18} /> Entidades a
                serem integradas
              </h2>
            </>
          ) : (
            <></>
          )}
          {stepFirstSetupApi === 4 || stepFirstSetupApi === 5 ? (
            <>
              {" "}
              <h2>
                Integração via API <BsChevronRight size={18} /> Configurar
                entidades
              </h2>
            </>
          ) : (
            <></>
          )}
        </Modal.Title>
        <Button
          className="cursor-pointer"
          variant="white"
          onClick={() => setActiveModal(0)}
        >
          <BsX color="var(--bs-modal-color)" size={28} />
        </Button>
      </Modal.Header>

      {stepFirstSetupApi === 1 ? (
        <Modal.Body className="step-1">
          <Row>
            <Col>
              <h3>Informe os dados da API a ser utilizada </h3>
            </Col>
          </Row>
          <Form.Group as={Row}>
            <Form.Label column sm={12}>
              URL do endpoint
            </Form.Label>
            <Col sm={12}>
              <Form.Control
                size="sm"
                defaultValue={urlEndpointLocal}
                placeholder="Copie e cole a URL do endpoint"
                isInvalid={isInvalidFeedBack(
                  "urlEndpointLocal",
                  validationErrors
                )}
                onChange={(e) => [
                  setUrlEndpointLocal(e.target.value),
                  setValidationFormField("urlEndpointLocal", e.target.value),
                ]}
              />
              <Form.Control.Feedback type="invalid">
                {feedBack("urlEndpointLocal", validationErrors)}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Row className="mt-3">
            <Col>
              <h4>Headers (Opcional)</h4>
            </Col>
          </Row>
          <TableStylesG>
            <Table
              id="mytable"
              className="table-list ml-0 pl-0"
              borderless
              hover
              responsive
            >
              <thead>
                <tr className="title-table" style={{ textAlignLast: "start" }}>
                  <th>Key (chave)</th>
                  <th>Value (valor)</th>
                  <th></th>
                </tr>
              </thead>
              <tbody id="mytable">
                {headerListLocal?.map((item, index) => {
                  return (
                    <tr id="tr-linha" className="table-values" key={index}>
                      <td>
                        <input
                          className="input-table"
                          placeholder=" Insira a chave"
                          style={{ textAlignLast: "start" }}
                          value={item.key}
                          onChange={(e) => [
                            handleHeaderKey(index, e.target.value),
                            e.target.value !== ""
                              ? setTrashButtonDisable(true)
                              : setTrashButtonDisable(false),
                            e.target.value !== ""
                              ? setButtonDisableAddHeader(false)
                              : setButtonDisableAddHeader(true),
                          ]}
                        />
                      </td>
                      <td>
                        <input
                          className="input-table"
                          placeholder=" Insira a chave"
                          style={{ textAlignLast: "start" }}
                          value={item.value}
                          onChange={(e) =>
                            handleHeaderValue(index, e.target.value)
                          }
                        />
                      </td>
                      {trashButtonDisable === true && (
                        <td
                          onClick={() => removeHeader(index)}
                          className="cursor-pointer trash"
                        >
                          <CustomTooltip
                            type="custom-icon"
                            text="Excluir"
                            placement="bottom"
                            customIcon={<BsTrash color="#A56300" size="22" />}
                          />
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </TableStylesG>
          <Button
            className="button-add-header"
            disabled={buttonDisableAddHeader}
            onClick={AddNewHeader}
          >
            <BsPlusCircle size={24} color="#2155BA" />
            Adicionar outro header
          </Button>
        </Modal.Body>
      ) : (
        <></>
      )}
      {stepFirstSetupApi === 2 ? (
        <Modal.Body className="step-2">
          <Row>
            <Col>
              <h3>
                Configure a frequência com que as integrações serão feitas
              </h3>
            </Col>
          </Row>
          <Form.Group as={Row}>
            <Form.Label column sm={12}>
              Data de início da integração
            </Form.Label>
            <Col sm={12}>
              <DatePicker
                className="input-date w-100"
                placeholderText={
                  "Selecione uma data para iniciar as integrações"
                }
                dateFormat="dd/MM/yyyy"
                selectsRange={false}
                minDate={new Date()}
                locale="pt"
                selected={startDateLocal}
                onChange={(date) => date && setStartDateLocal(date as Date)}
                todayButton="Hoje"
              />
            </Col>
          </Form.Group>
          <br />
          <FrequencyIntegrationBody
            handleChangeSelectedScheduleTimes={(value: any) =>
              setSelectedScheduleTimes(value)
            }
            handleChangeSelectedInterval={(value: any) =>
              setSelectedInterval(value)
            }
            onChangeFreqType={(value: any) => setLocalFreqType(value)}
            frequencyTypeData={localFreqType}
          />
        </Modal.Body>
      ) : (
        <></>
      )}
      {stepFirstSetupApi === 3 ? (
        <Modal.Body className="step-3">
          <Row>
            <Col>
              <h3>Escolha as entidades que deseja integrar</h3>
            </Col>
          </Row>
          <Form.Group as={Col}>
            {integrationEntities?.map((entity, index) => {
              if (entity.educational && entity.checkbox)
                if (entity.configProfile) {
                  return (
                    <Form.Check
                      key={entity.id}
                      className="button-radio button-radio-spaces"
                      checked={entity.active}
                      disabled={entity.disabled}
                      label={entity.name}
                      onChange={() => handleClick(index)}
                    />
                  );
                }

              return "";
            })}
          </Form.Group>
        </Modal.Body>
      ) : (
        <></>
      )}
      {stepFirstSetupApi === 4 ? (
        <Modal.Body className="step-4">
          <Row>
            <Col>
              <h3>
                Insira os dados de cada entidade a ser integrada.
                <br />
                <br />
                Utilize a função <strong>“Inativar Ausentes”</strong> para
                remover os registros que foram integrados anteriormente mas não
                estão presentes na nova integração.
              </h3>
            </Col>
          </Row>
          {integrationEntities?.map((entity, index) => {
            if (entity.active) {
              return (
                <div className="p-2 mb-2" key={entity.id}>
                  <Row className="my-3 mr-1">
                    <Col md={1}>
                      {entityTeamAndTeacherExpand === false ? (
                        <BsCheck2Circle
                          color="#008555"
                          size={24}
                          className="bi bi-check2-circle"
                        />
                      ) : (
                        <BsDashCircle color="#525252" size={24} />
                      )}
                    </Col>
                    <Col md={10}>
                      <h4>{entity.name}</h4>
                    </Col>
                    {entityTeamAndTeacherExpand === false ? (
                      <Col
                        md={1}
                        className=""
                        onClick={() => setEntityTeamAndTeacherExpand(true)}
                      >
                        <BsChevronDown color="#6F6F6F" size={22} />
                      </Col>
                    ) : (
                      <Col
                        md={1}
                        className=""
                        onClick={() => setEntityTeamAndTeacherExpand(false)}
                      >
                        <BsChevronUp color="#0F7EAA" size={22} />
                      </Col>
                    )}
                  </Row>
                  <div>
                    <Row>
                      {entity.id === 8 ? (
                        ""
                      ) : (
                        <Col md={12}>
                          <Row className="line-inactivate">
                            <div>
                              <h3>Inativar ausentes</h3>
                            </div>
                            <div className="row mr-2">
                              {entity.inactiveAbsent === false ? (
                                <Toggle
                                  handleSomething={() =>
                                    toggleInactiveAbsent(index, true)
                                  }
                                  isSomething={entity.inactiveAbsent}
                                  title="Desativado"
                                />
                              ) : (
                                <Toggle
                                  handleSomething={() =>
                                    toggleInactiveAbsent(index, false)
                                  }
                                  isSomething={entity.inactiveAbsent}
                                  title="Ativado"
                                />
                              )}
                            </div>
                          </Row>
                        </Col>
                      )}
                      <Col md={12} className="mb-3">
                        <Form.Group>
                          <Form.Label>Rota (obrigatório)</Form.Label>
                          <Form.Control
                            size="sm"
                            defaultValue={entity.route}
                            isInvalid={isInvalidFeedBack(
                              entity.route,
                              validationErrors
                            )}
                            placeholder="Copie e cole a rota"
                            onChange={(e) => [
                              setRoute(index, e.target.value),
                              setValidationFormField(
                                entity.route,
                                e.target.value
                              ),
                            ]}
                          />
                          <Form.Control.Feedback type="invalid">
                            {feedBack(entity.route, validationErrors)}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md={12} className="mb-3">
                        <Form.Group>
                          <Form.Label>FieldParse (obrigatório)</Form.Label>
                          <Form.Control
                            size="sm"
                            defaultValue={entity.fieldParse}
                            isInvalid={isInvalidFeedBack(
                              entity.fieldParse,
                              validationErrors
                            )}
                            placeholder="Copie e cole o campo FieldParse"
                            onChange={(e) => [
                              setFieldParse(index, e.target.value),
                              setValidationFormField(
                                entity.fieldParse,
                                e.target.value
                              ),
                            ]}
                          />
                          <Form.Control.Feedback type="invalid">
                            {feedBack(entity.fieldParse, validationErrors)}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md={12} className="mb-3">
                        <Form.Group>
                          <Form.Label>Outros parâmetros (opcional)</Form.Label>
                          <Form.Control
                            size="sm"
                            defaultValue={entity.params}
                            isInvalid={isInvalidFeedBack(
                              entity.params,
                              validationErrors
                            )}
                            placeholder="Copie e cole os parâmetros opcionais de acesso a API"
                            onChange={(e) => [
                              setParams(index, e.target.value),
                              setValidationFormField(
                                entity.params,
                                e.target.value
                              ),
                            ]}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                </div>
              );
            } else return "";
          })}
        </Modal.Body>
      ) : (
        <></>
      )}
      {stepFirstSetupApi === 5 && <TestConnection />}
      <Modal.Footer>
        {stepFirstSetupApi === 1 ? (
          <>
            <Button
              variant="primary"
              className="cancel-operation"
              onClick={() => setActiveModal(1)}
            >
              <BsArrowLeft color="#a56300" size={22} />
              Voltar
            </Button>

            <Stepper currentStep={1} totalSteps={4} />

            <Button
              variant="primary"
              className="continue-button"
              //disabled={buttonDisable}
              onClick={() => handlerNextStep2()}
            >
              Continuar
              <BsArrowRight color="#Fff" size={22} />
            </Button>
          </>
        ) : (
          <></>
        )}
        {stepFirstSetupApi === 2 ? (
          <>
            <Button
              variant="primary"
              className="cancel-operation"
              onClick={() => setStepFirstSetupApi(1)}
            >
              <BsArrowLeft color="#a56300" size={22} />
              Voltar
            </Button>

            <Stepper currentStep={2} totalSteps={4} />

            <Button
              variant="primary"
              className="continue-button"
              //disabled={buttonDisable}
              onClick={() => {
                handleValidateStartDateLocal();
                handlerNextStep3();
              }}
            >
              Continuar
              <BsArrowRight color="#Fff" size={22} />
            </Button>
          </>
        ) : (
          <></>
        )}
        {stepFirstSetupApi === 3 ? (
          <>
            <Button
              variant="primary"
              className="cancel-operation"
              onClick={() => setStepFirstSetupApi(2)}
            >
              <BsArrowLeft color="#a56300" size={22} />
              Voltar
            </Button>

            <Stepper currentStep={3} totalSteps={4} />

            <Button
              variant="primary"
              className="continue-button"
              //disabled={buttonDisable}
              onClick={() => setStepFirstSetupApi(4)}
            >
              Continuar
              <BsArrowRight color="#Fff" size={22} />
            </Button>
          </>
        ) : (
          <></>
        )}
        {stepFirstSetupApi === 4 ? (
          <>
            <Button
              variant="primary"
              className="cancel-operation"
              onClick={() => setStepFirstSetupApi(3)}
            >
              <BsArrowLeft color="#a56300" size={22} />
              Voltar
            </Button>

            <Stepper currentStep={4} totalSteps={4} />

            <Button
              variant="primary"
              className="sucess-button"
              onClick={() => saveData()}
            //disabled={buttonDisable}
            >
              Finalizar
              <BsCheck color="#fff" size={24} />
            </Button>
          </>
        ) : (
          <></>
        )}

        {stepFirstSetupApi === 5 ? (
          <>
            <div></div>
            <Button variant="primary" className="cancel-operation">
              <BsX color="#a56300" size={22} />
              Cancelar Operação
            </Button>
            <div></div>
          </>
        ) : (
          ""
        )}
      </Modal.Footer>
    </ModalGAutomaticIntegrationStyles>
  ) : (
    <></>
  );
}
