import pt from "date-fns/locale/pt";
import React, { useState, useContext } from "react";
import { Col, Modal, Button, Form } from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import { BsCheck, BsChevronRight, BsX } from "react-icons/bs";

import { showToast } from "../../../components/Common/Toast";
import { ActiveModalIntegrationContext } from "../AutomaticIntegrationContexts/ActiveModalIntegrationContext";
import { ModalGAutomaticIntegrationStyles } from "./styles";

export default function ModalEditDate() {
  registerLocale("pt", pt);

  const {
    activeModal,
    setActiveModal,
    setNewStartDate,
    setUpdateIntegration,
    startDate,
  } = useContext(ActiveModalIntegrationContext);

  const [startDateLocal, setStartDateLocal] = useState(new Date());

  const handleValidateStartDate = () => {
    if (startDateLocal) {
      setActiveModal(0);
      setUpdateIntegration(true);
      return;
    }

    showToast("error", "Preencha o campo data de início.");
  };

  const handleClearStates = () => {
    setStartDateLocal(startDate);
    setActiveModal(0);
  };

  return (
    <ModalGAutomaticIntegrationStyles
      show={activeModal === 8}
      onHide={handleClearStates}
    >
      <Modal.Header>
        <Modal.Title>
          <h2>
            Integração <BsChevronRight size={18} /> Data de início
          </h2>
        </Modal.Title>
        <Button
          className="cursor-pointer"
          variant="white"
          onClick={handleClearStates}
        >
          <BsX color="var(--bs-modal-color)" size={28} />
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Col sm={12}>
          <Form.Label>Escolha a data de início </Form.Label>
          <DatePicker
            className="w-100 h-100 input-date"
            placeholderText={"Selecione uma data para iniciar as integrações"}
            dateFormat="dd/MM/yyyy"
            selectsRange={false}
            minDate={new Date()}
            locale="pt"
            selected={startDateLocal}
            onChange={(date) => [
              date && setNewStartDate(date as Date),
              setStartDateLocal(date as Date),
            ]}
            todayButton="Hoje"
          />
        </Col>
      </Modal.Body>
      <Modal.Footer>
        <Button className="cancel-operation" onClick={handleClearStates}>
          <BsX color="#A56300" size={24} />
          Cancelar
        </Button>
        <Button
          variant="primary"
          className="continue-button"
          onClick={handleValidateStartDate}
        >
          Salvar
          <BsCheck color="#fff" size={24} />
        </Button>
      </Modal.Footer>
    </ModalGAutomaticIntegrationStyles>
  );
}
