import styled from "styled-components";

export const NotFoundPage = styled.section`
  height: 80vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;

  img {
    max-width: 100%;
  }

  h3 {
    font-weight: 600;
    font-size: 30px;
    line-height: 34px;
    color: rgb(66, 64, 64);
    margin: 1rem 0 0px;
    text-align: center;
  }

  span {
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    color: #6f6f6f;
    text-align: center;
  }

  button {
    margin-top: 10px;
    min-width: 250px;
    height: 48px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    border-radius: 6px;
  }

  @media screen and (max-width: 1025px) {
    h3 {
      font-size: 25px;
    }
  }
`;
