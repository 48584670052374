import AutomaticIntegration from "./AutomaticIntegration";
import ActiveModalIntegrationContextProvider from "./AutomaticIntegrationContexts/ActiveModalIntegrationContext";

export default function AutomaticIntegrationScreen() {
  return (
    <ActiveModalIntegrationContextProvider>
      <AutomaticIntegration />
    </ActiveModalIntegrationContextProvider>
  );
}
