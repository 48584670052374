import pt from "date-fns/locale/pt";
import React, { useContext, useState, useEffect } from "react";
import { Col, Modal, Row, Form, Button, Table } from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import {
  BsArrowLeft,
  BsArrowRight,
  BsCheck2,
  BsCheck2Circle,
  BsChevronDown,
  BsChevronRight,
  BsChevronUp,
  BsDashCircle,
  BsLightningCharge,
  BsPencil,
  BsPlusCircle,
  BsTrash,
  BsX,
} from "react-icons/bs";

import { formatDateToDDMMYYYY } from "../../../../components/Common/Formatter";
import FrequencyIntegrationBody from "../../../../components/Common/FrequencyIntegrationBody";
import Stepper from "../../../../components/Common/Stepper";
import { showToast } from "../../../../components/Common/Toast";
import Toggle from "../../../../components/Common/Toggle";
import { requiredFieldMsg } from "../../../../helpers/messagesValidation";
import {
  useValidation,
  isInvalidFeedBack,
  feedBack,
} from "../../../../hooks/Validation";
import { ActiveModalIntegrationContext } from "../../AutomaticIntegrationContexts/ActiveModalIntegrationContext";
import {
  handleFormatPeriodicIntervalToString,
  handleFormatScheduleTimeToShow,
  handleFormatScheduleTimeToString,
  handleFormatStringGCD,
} from "../../utils/functions";
import { ModelHeader } from "../ModalFirstSetupApi";
import { ModalGAutomaticIntegrationStyles, TableStylesG } from "../styles";
import "./styles.css";
import CustomTooltip from "../../../../components/Common/CustomTooltip";

function ModalContinueIntegration() {
  const {
    setActiveModal,
    activeModal,
    startDate,
    setStartDate,
    setNewStartDate,
    setUpdateIntegration,
    setNewConnectionString,
    server,
    setServer,
    database,
    setDatabase,
    user,
    setUser,
    password,
    setPassword,
    aditionalConfig,
    setAditionalConfig,
    setNewStopIntegration,
    integrationBase,
    setIntegrationBase,
    setNewIntegrationBase,
    urlEndpoint,
    setNewUrlEndpoint,
    headerList,
    setHeaderList,
    setNewHeaderList,
    frequencyType,
    setFrequencyType,
    setScheduleTimesList,
    setIntervalTimeList,
    scheduleTimesList,
    intervalTimeList,
  } = useContext(ActiveModalIntegrationContext);

  registerLocale("pt", pt);

  // VALIDATION VARIABLES
  const [
    validationForm,
    setValidationFormField,
    validationErrors,
    setNewValidationErrors,
  ] = useValidation();

  const [stepResumeIntegration, setStepResumeIntegration] = useState(0);
  const [trashButtonDisable, setTrashButtonDisable] = useState<boolean>(true);
  const [entityTeamAndTeacherExpand, setEntityTeamAndTeacherExpand] =
    useState(false);

  const [selectedScheduleTimes, setSelectedScheduleTimes] = useState<any>([]);
  const [selectedInterval, setSelectedInterval] = useState<any>("");
  const [entitiesValidateList, setEntitiesValidateList] = useState<any>([]);
  const [blockSendIntegrationFrequency, setBlockSendIntegrationFrequency] =
    useState<boolean>(true);

  const [localFreqType, setLocalFreqType] = useState<any>(frequencyType);

  // FUNCTIONS
  const handleCheckEntityValidateList = () => {
    const list = integrationBase?.filter((item: any) => item.active);
    let newList = [];

    if (list) {
      if (integrationBase[0].automaticIntegration.dataSource === 1) {
        newList = list.filter(
          (element: any) =>
            element?.automaticIntegrationBySql?.databaseView === ""
        );
      }

      if (integrationBase[0].automaticIntegration.dataSource === 4) {
        newList = list.filter(
          (element: any) =>
            element?.automaticIntegrationByRestApi?.route === "" ||
            element?.automaticIntegrationByRestApi?.fieldParse === ""
        );
      }
    }

    setEntitiesValidateList(newList);
  };

  const hasErrors = () => {
    const errors = findErrors();
    const errorExist = setNewValidationErrors(errors);

    if (errorExist) return true;

    return false;
  };

  const handleRenderIntegrationDetailsMessage = () => {
    return (
      <Row className="div-data-integration-card-warn">
        <BsLightningCharge size={22} color="#222" />
        <h2>
          Sua integração ocorrerá no dia{" "}
          <strong>
            {formatDateToDDMMYYYY(startDate)}
            {localFreqType === 1 ? (
              <> às {handleFormatScheduleTimeToShow(scheduleTimesList)}</>
            ) : localFreqType === 2 ? (
              <>
                {" "}
                de {handleFormatStringGCD(intervalTimeList)} em{" "}
                {handleFormatStringGCD(intervalTimeList)}h.
              </>
            ) : (
              <> às {integrationBase[0].automaticIntegration.hour}:00</>
            )}
          </strong>
        </h2>
      </Row>
    );
  };

  function saveActiveIntegration() {
    if (!hasErrors() && entitiesValidateList?.length === 0) {
      const partialCS =
        "Server=" +
        server +
        ";Database=" +
        database +
        ";User Id=" +
        user +
        ";Password=" +
        password +
        ";" +
        aditionalConfig;
      handleSaveFrequencyIntegrationOnContext()
      setNewConnectionString(partialCS);
      setNewHeaderList(headerList); //seta nova lista de headers
      setNewIntegrationBase(integrationBase); // seta entidades atualizadas
      handleClearAllStates();
      setNewStopIntegration(true);
      setUpdateIntegration(true);
      return;
    }

    showToast(
      "error",
      "Verifique se todos os campos obrigatórios de configurações estão preenchidos."
    );
  }

  function removeHeader(index: any) {
    let newArr = [...headerList];
    newArr = newArr.filter((item, i) => i !== index);
    setHeaderList(newArr);
  }
  function handleHeaderKey(index: any, value: string) {
    const entities = [...headerList];
    const item = entities[index];
    item.key = value;
    entities[index] = item;
    setHeaderList(entities);
  }
  function handleHeaderValue(index: any, value: string) {
    const entities = [...headerList];
    const item = entities[index];
    item.value = value;
    entities[index] = item;
    setHeaderList(entities);
  }

  function AddNewHeader() {
    const entities = [...headerList];
    const newItem: ModelHeader = {
      key: "",
      value: "",
    };
    entities.push(newItem);
    setHeaderList(entities);
  }

  function handleClick(index: number) {
    const entities = [...integrationBase];
    const item = entities[index];
    item.active = !item.active;
    entities[index] = item;
    setIntegrationBase(entities);
  }

  function toggleInactiveAbsent(index: number) {
    const entities = [...integrationBase];
    const item = entities[index];
    item.inactiveAbsent = !item.inactiveAbsent;
    entities[index] = item;
    setIntegrationBase(entities);
  }

  const handleSaveFrequencyIntegrationOnContext = () => {
    if (blockSendIntegrationFrequency) return;

    setFrequencyType(localFreqType);

    if (localFreqType === 1) {
      const result = handleFormatScheduleTimeToString(selectedScheduleTimes);
      setScheduleTimesList(result);
    }

    if (localFreqType === 2) {
      const result = handleFormatPeriodicIntervalToString(
        selectedInterval?.value
      );
      setIntervalTimeList(result);
    }
  };

  function handleNameOnChange(value: string, index: number) {
    const masked = value.replace(/[&\\#,+()$~%.'":*?<>{}]/g, "");
    setTable(masked, index);
  }
  function setTable(masked: string, index: number) {
    const entities = [...integrationBase];
    const item = entities[index];
    item.automaticIntegrationBySql.databaseView = masked;
    entities[index] = item;
    setIntegrationBase(entities);
  }
  function handleRouteOnChange(value: string, index: number) {
    const route = value.replace(/[&\\#,+()$~%.'":*?<>{}]/g, "");
    setRoute(route, index);
  }
  function setRoute(route: string, index: number) {
    const entities = [...integrationBase];
    const item = entities[index];
    item.automaticIntegrationByRestApi.route = route;
    entities[index] = item;
    setIntegrationBase(entities);
  }
  function handleFieldParseOnChange(value: string, index: number) {
    const fieldParse = value.replace(/[&\\#,+()$~%.'":*?<>{}]/g, "");
    setFieldParse(fieldParse, index);
  }
  function setFieldParse(fieldParse: string, index: number) {
    const entities = [...integrationBase];
    const item = entities[index];
    item.automaticIntegrationByRestApi.fieldParse = fieldParse;
    entities[index] = item;
    setIntegrationBase(entities);
  }
  function handleParamsOnChange(value: string, index: number) {
    const params = value.replace(/[&\\#,+()$~%.'":*?<>{}]/g, "");
    setParams(params, index);
  }
  function setParams(params: string, index: number) {
    const entities = [...integrationBase];
    const item = entities[index];
    item.automaticIntegrationByRestApi.params = params;
    entities[index] = item;
    setIntegrationBase(entities);
  }

  function updateValidation() {
    if (integrationBase[0].automaticIntegration.dataSource === 1) {
      validationForm.current = {
        server,
        database,
        user,
        password,
      };
    }
    if (integrationBase[0].automaticIntegration.dataSource === 4) {
      validationForm.current = {
        urlEndpoint,
      };
    }
  }

  function findErrors() {
    const errors: any = {};

    if (validationForm.current) {
      // Validation to SQL
      if (integrationBase[0].automaticIntegration.dataSource === 1) {
        if (
          !validationForm.current.server ||
          validationForm.current.server === ""
        )
          errors.server = requiredFieldMsg;
        else errors.server = undefined;

        if (
          !validationForm.current.database ||
          validationForm.current.database === ""
        )
          errors.database = requiredFieldMsg;
        else errors.database = undefined;

        if (!validationForm.current.user || validationForm.current.user === "")
          errors.user = requiredFieldMsg;
        else errors.user = undefined;

        if (
          !validationForm.current.password ||
          validationForm.current.password === ""
        )
          errors.password = requiredFieldMsg;
        else errors.password = undefined;
      }

      // Validation to API
      if (integrationBase[0].automaticIntegration.dataSource === 4) {
        if (
          !validationForm.current.urlEndpoint ||
          validationForm.current.urlEndpoint === ""
        )
          errors.urlEndpoint = requiredFieldMsg;
        else errors.urlEndpoint = undefined;
      }
    }

    return errors;
  }

  const handleClickContinue = (step: number) => {
    if (!hasErrors()) {
      setStepResumeIntegration(step);
    }
  };

  useEffect(() => {
    updateValidation();
  }, [server, database, user, password, urlEndpoint]);

  useEffect(() => {
    handleCheckEntityValidateList();
  }, [integrationBase]);

  useEffect(() => {
    if (activeModal === 12) {
      setLocalFreqType(frequencyType);
    }
  }, [activeModal]);

  const handleClearAllStates = () => {
    setBlockSendIntegrationFrequency(true);
    setFrequencyType(frequencyType)
    setActiveModal(0);
  };

  return (
    <ModalGAutomaticIntegrationStyles
      show={activeModal === 12}
      onHide={handleClearAllStates}
      className="ModalContinueIntegration"
    >
      <Modal.Header>
        <Modal.Title>
          <h2>
            Integração Automática <BsChevronRight size={18} />{" "}
            {stepResumeIntegration === 0 && "Retomar integração"}
            {stepResumeIntegration === 1 && "Revisar configurações"}
            {integrationBase[0].automaticIntegration.dataSource === 1
              ? stepResumeIntegration === 2 && "Dados de acesso ao banco"
              : stepResumeIntegration === 2 && "Dados da API"}
            {stepResumeIntegration === 3 && "Frequência das integrações"}
            {stepResumeIntegration === 4 && "Entidades a serem integradas"}
            {stepResumeIntegration === 5 && "Detalhes das entidades"}
          </h2>
        </Modal.Title>
        <Button
          className="cursor-pointer"
          variant="white"
          onClick={handleClearAllStates}
        >
          <BsX color="var(--bs-modal-color)" size={28} />
        </Button>
      </Modal.Header>
      <Modal.Body className="">
        {stepResumeIntegration === 0 && (
          <>
            <Row>
              <Col>
                <h3>
                  Revise as configurações de frequência.
                  <br />
                  <br />
                  Você pode utilizar as mesmas configurações, ou escolher novos
                  parâmetros.
                </h3>
              </Col>
            </Row>
            <Form.Group as={Row}>
              <Form.Label column sm={12}>
                Data de início da integração
              </Form.Label>
              <Col sm={12}>
                <DatePicker
                  className="input-date w-100"
                  placeholderText={
                    "Selecione uma data para iniciar as integrações"
                  }
                  dateFormat="dd/MM/yyyy"
                  selectsRange={false}
                  minDate={new Date()}
                  locale="pt"
                  selected={startDate}
                  onChange={(date) => [
                    date && setStartDate(date as Date),
                    setNewStartDate(date as Date),
                  ]}
                  todayButton="Hoje"
                />
              </Col>
            </Form.Group>
            <br />
            <FrequencyIntegrationBody
              handleChangeSelectedScheduleTimes={(value: any) => {
                setBlockSendIntegrationFrequency(false);
                setSelectedScheduleTimes(value);
              }}
              handleChangeSelectedInterval={(value: any) => {
                setBlockSendIntegrationFrequency(false);
                setSelectedInterval(value);
              }}
              onChangeFreqType={(value: any) => setLocalFreqType(value)}
              frequencyTypeData={localFreqType}
            />
          </>
        )}

        {stepResumeIntegration === 1 && (
          <section className="reviewContainer">
            <Row>
              <Col>
                <h4>
                  Recomendamos que você revise as configurações abaixo.
                  <br />
                  <br />
                  Se estiver tudo certo, você pode pular a revisão e finalizar a
                  configuração.
                </h4>
              </Col>
            </Row>
            {handleRenderIntegrationDetailsMessage()}

            {integrationBase[0].automaticIntegration.dataSource === 1 && (
              <section className="reviewItemContainer">
                <Row className="title">
                  <Col>
                    <h3>Dados de acesso ao banco</h3>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <h4>
                      {integrationBase[0].automaticIntegration.configuration}
                    </h4>
                  </Col>
                </Row>
              </section>
            )}
            {integrationBase[0].automaticIntegration.dataSource === 4 && (
              <section className="reviewItemContainer">
                <Row className="title">
                  <Col>
                    <h3>Dados da API</h3>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h4>
                      <strong>URL do Endpoint:</strong> {urlEndpoint}
                    </h4>
                  </Col>
                </Row>
                <Row className="">
                  <Col>
                    <h4>
                      <strong>Headers</strong>
                    </h4>
                  </Col>
                </Row>
                {headerList && (
                  <Row className="title-key">
                    <Col
                      md={5}
                      style={{ textAlignLast: "start" }}
                      className="headersCol"
                    >
                      Key
                    </Col>
                    <Col
                      md={7}
                      style={{ textAlignLast: "start" }}
                      className="headersCol"
                    >
                      Value
                    </Col>
                  </Row>
                )}
                {headerList?.map((item: any, index: any) => {
                  return (
                    <Row className="row-bottom" key={index}>
                      <Col
                        md={5}
                        style={{ textAlignLast: "start" }}
                        className="headersCol"
                      >
                        {item.key}
                      </Col>
                      <Col
                        md={7}
                        style={{ textAlignLast: "start" }}
                        className="headersCol col-left"
                      >
                        {item.value}
                      </Col>
                    </Row>
                  );
                })}
              </section>
            )}

            <section className="reviewItemContainer">
              <Row className="mt-3">
                <Col>
                  <h3>Entidades a serem integradas</h3>
                </Col>
              </Row>
              {integrationBase?.map((entity: any) => {
                if (entity.active) {
                  return (
                    <Row className="my-1" key={entity.integrationTypeName}>
                      <Col md={4}>
                        <h4>{entity.integrationTypeName}</h4>
                      </Col>
                    </Row>
                  );
                }

                return <></>;
              })}
            </section>
          </section>
        )}
        {stepResumeIntegration === 2 && (
          <>
            {integrationBase[0].automaticIntegration.dataSource === 1 && (
              <>
                <Row>
                  <Col>
                    <h3>
                      Informe os dados que devemos utilizar para acessar o seu
                      banco de dados{" "}
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h4>Servidor e banco</h4>
                  </Col>
                </Row>
                <Form.Group as={Row}>
                  <Form.Label column sm={12}>
                    Servidor*
                  </Form.Label>
                  <Col sm={12}>
                    <Form.Control
                      size="sm"
                      defaultValue={server}
                      placeholder="Informe o endereço do servidor"
                      isInvalid={isInvalidFeedBack("server", validationErrors)}
                      onChange={(e) => [
                        setServer(e.target.value),
                        setValidationFormField("server", e.target.value),
                      ]}
                    />
                    <Form.Control.Feedback type="invalid">
                      {feedBack("server", validationErrors)}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm={12}>
                    Banco
                  </Form.Label>
                  <Col sm={12}>
                    <Form.Control
                      size="sm"
                      defaultValue={database}
                      placeholder="Informe a rota para o banco de dados"
                      isInvalid={isInvalidFeedBack(
                        "database",
                        validationErrors
                      )}
                      onChange={(e) => [
                        setDatabase(e.target.value),
                        setValidationFormField("database", e.target.value),
                      ]}
                    />
                    <Form.Control.Feedback type="invalid">
                      {feedBack("database", validationErrors)}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Row className="line-body"></Row>
                <Row>
                  <Col>
                    <h4>Autenticação</h4>
                  </Col>
                </Row>
                <Form.Group as={Row}>
                  <Form.Label column sm={12}>
                    Usuário
                  </Form.Label>
                  <Col sm={12}>
                    <Form.Control
                      size="sm"
                      defaultValue={user}
                      placeholder="Informe o usuário para acesso ao banco"
                      isInvalid={isInvalidFeedBack("user", validationErrors)}
                      onChange={(e) => [
                        setUser(e.target.value),
                        setValidationFormField("user", e.target.value),
                      ]}
                    />
                    <Form.Control.Feedback type="invalid">
                      {feedBack("user", validationErrors)}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm={12}>
                    Senha:
                  </Form.Label>
                  <Col sm={12}>
                    <Form.Control
                      size="sm"
                      defaultValue={password}
                      placeholder="Informe a senha para acesso ao banco"
                      isInvalid={isInvalidFeedBack(
                        "password",
                        validationErrors
                      )}
                      onChange={(e) => [
                        setPassword(e.target.value),
                        setValidationFormField("password", e.target.value),
                      ]}
                    />

                    <Form.Control.Feedback type="invalid">
                      {feedBack("password", validationErrors)}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm={12}>
                    Parâmetros opcionais:
                  </Form.Label>
                  <Col sm={12}>
                    <Form.Control
                      size="sm"
                      defaultValue={aditionalConfig}
                      placeholder="Caso desejar, forneça outros parâmetros para acesso ao banco"
                      isInvalid={isInvalidFeedBack(
                        "aditionalConfig",
                        validationErrors
                      )}
                      onChange={(e) => setAditionalConfig(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </>
            )}
            {integrationBase[0].automaticIntegration.dataSource === 4 && (
              <>
                <Row className="pb-4">
                  <Col>Insira a URL para acesso ao Endpoint</Col>
                </Row>
                <Form.Group as={Row}>
                  <Form.Label column sm={12}>
                    <h4 className="mb-0">URL do endpoint</h4>
                  </Form.Label>
                  <Col sm={12}>
                    <Form.Control
                      size="sm"
                      defaultValue={urlEndpoint}
                      placeholder="Copie e cole a URL do endpoint"
                      isInvalid={isInvalidFeedBack(
                        "urlEndpoint",
                        validationErrors
                      )}
                      onChange={(e) => [
                        setNewUrlEndpoint(e.target.value),
                        setValidationFormField("urlEndpoint", e.target.value),
                      ]}
                    />
                    <Form.Control.Feedback type="invalid">
                      {feedBack("urlEndpoint", validationErrors)}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Row className="mt-3">
                  <Col>
                    <h3>
                      <strong>Headers (Opcional)</strong>
                    </h3>
                  </Col>
                </Row>
                <TableStylesG>
                  <Table
                    id="mytable"
                    className="table-list ml-0 pl-0"
                    borderless
                    hover
                    responsive
                  >
                    <thead>
                      <tr
                        className="title-table"
                        style={{ textAlignLast: "start" }}
                      >
                        <th>Key (chave)</th>
                        <th>Value (valor)</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody id="mytable">
                      {headerList?.map((item: any, index: any) => {
                        return (
                          <tr
                            id="tr-linha"
                            className="table-values"
                            key={index}
                          >
                            <td>
                              <input
                                className="input-table"
                                placeholder=" Insira a chave"
                                style={{ textAlignLast: "start" }}
                                value={item.key}
                                onChange={(e) => [
                                  handleHeaderKey(index, e.target.value),
                                  e.target.value !== ""
                                    ? setTrashButtonDisable(true)
                                    : setTrashButtonDisable(false),
                                ]}
                              />
                            </td>
                            <td>
                              <input
                                className="input-table"
                                placeholder=" Insira a chave"
                                style={{ textAlignLast: "start" }}
                                value={item.value}
                                onChange={(e) =>
                                  handleHeaderValue(index, e.target.value)
                                }
                              />
                            </td>
                            {trashButtonDisable === true && (
                              <td
                                onClick={() => removeHeader(index)}
                                className="cursor-pointer trash"
                              >
                                <CustomTooltip
                                  type="custom-icon"
                                  text="Excluir"
                                  placement="bottom"
                                  customIcon={
                                    <BsTrash color="#A56300" size="22" />
                                  }
                                />
                              </td>
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </TableStylesG>

                <Button className="button-add-header" onClick={AddNewHeader}>
                  <BsPlusCircle size={22} color="#2155BA" />
                  Adicionar outro header
                </Button>
              </>
            )}
          </>
        )}
        {stepResumeIntegration === 3 && (
          <>
            <Row>
              <Col>
                <h2>
                  Configure a frequência com que as integrações serão feitas
                </h2>
              </Col>
            </Row>
            <Form.Group as={Row}>
              <Form.Label column sm={12}>
                Data de início da integração
              </Form.Label>
              <Col sm={12}>
                <DatePicker
                  className="input-date w-100"
                  placeholderText={
                    "Selecione uma data para iniciar as integrações"
                  }
                  dateFormat="dd/MM/yyyy"
                  selectsRange={false}
                  minDate={new Date()}
                  locale="pt"
                  selected={startDate}
                  onChange={(date) => [
                    date && setStartDate(date as Date),
                    setNewStartDate(date as Date),
                  ]}
                />
              </Col>
            </Form.Group>

            <br />
            <FrequencyIntegrationBody
              handleChangeSelectedScheduleTimes={(value: any) => {
                setBlockSendIntegrationFrequency(false);
                setSelectedScheduleTimes(value);
              }}
              handleChangeSelectedInterval={(value: any) => {
                setBlockSendIntegrationFrequency(false);
                setSelectedInterval(value);
              }}

              onChangeFreqType={(value: any) => setLocalFreqType(value)}
              frequencyTypeData={localFreqType}
            />
          </>
        )}
        {stepResumeIntegration === 4 && (
          <>
            <Row>
              <Col>
                <h3>Escolha as entidades que deseja integrar</h3>
              </Col>
            </Row>
            <Form.Group as={Col}>
              {integrationBase?.map((entity: any, index: any) => {
                return (
                  <Form.Check
                    key={entity.id}
                    className="button-radio button-radio-spaces"
                    checked={entity.active}
                    label={entity.integrationTypeName}
                    onChange={() => handleClick(index)}
                  />
                );
              })}
            </Form.Group>
          </>
        )}
        {stepResumeIntegration === 5 && (
          <>
            <Row>
              <Col>
                <h3>
                  Insira os dados de cada entidade a ser integrada.
                  <br />
                  <br />
                  Utilize a função <strong>“Inativar Ausentes”</strong> para
                  remover os registros que foram integrados anteriormente mas
                  não estão presentes na nova integração.
                </h3>
              </Col>
            </Row>
            {integrationBase?.map((entity: any, index: any) => {
              if (entity.active) {
                return (
                  <div className="p-2 mb-2" key={entity.integrationTypeName}>
                    <Row className="my-3 mr-1">
                      <Col md={1}>
                        {entityTeamAndTeacherExpand === false ? (
                          <BsCheck2Circle
                            color="#008555"
                            size={24}
                            className="bi bi-check2-circle"
                          />
                        ) : (
                          <BsDashCircle color="#525252" size={24} />
                        )}
                      </Col>
                      <Col md={10}>
                        <h4>{entity.integrationTypeName}</h4>
                      </Col>
                      {entityTeamAndTeacherExpand === false ? (
                        <Col
                          md={1}
                          className=""
                          onClick={() => setEntityTeamAndTeacherExpand(true)}
                        >
                          <BsChevronDown color="#6F6F6F" size={22} />
                        </Col>
                      ) : (
                        <Col
                          md={1}
                          className=""
                          onClick={() => setEntityTeamAndTeacherExpand(false)}
                        >
                          <BsChevronUp color="#0F7EAA" size={22} />
                        </Col>
                      )}
                    </Row>
                    <div>
                      <Row>
                        {entity.integrationType === 8 ? (
                          ""
                        ) : (
                          <Col md={12}>
                            <Row className="line-inactivate">
                              <div>
                                <h3>Inativar ausentes</h3>
                              </div>
                              <div className="row mr-2">
                                {entity.inactiveAbsent === false ? (
                                  <Toggle
                                    handleSomething={() =>
                                      toggleInactiveAbsent(index)
                                    }
                                    isSomething={entity.inactiveAbsent}
                                    title="Desativado"
                                  />
                                ) : (
                                  <Toggle
                                    handleSomething={() =>
                                      toggleInactiveAbsent(index)
                                    }
                                    isSomething={entity.inactiveAbsent}
                                    title="Ativado"
                                  />
                                )}
                              </div>
                            </Row>
                          </Col>
                        )}
                        {integrationBase[0].automaticIntegration.dataSource ===
                          1 ? (
                          <Col md={12}>
                            <Form.Group>
                              <Form.Label>Tabela / View</Form.Label>
                              <Form.Control
                                size="sm"
                                defaultValue={
                                  entity.automaticIntegrationBySql.databaseView
                                }
                                isInvalid={isInvalidFeedBack(
                                  entity.integrationTypeName,
                                  validationErrors
                                )}
                                placeholder="Onde podemos encontrar os dados desta entidade?"
                                onChange={(e) => {
                                  handleNameOnChange(e.target.value, index);
                                  setValidationFormField(
                                    entity.integrationTypeName,
                                    e.target.value
                                  );
                                }}
                              />
                              <Form.Control.Feedback type="invalid">
                                {feedBack(
                                  entity.integrationTypeName,
                                  validationErrors
                                )}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        ) : (
                          <>
                            <Col md={12} className="mb-3">
                              <Form.Group>
                                <Form.Label>Rota (obrigatório)</Form.Label>
                                <Form.Control
                                  size="sm"
                                  defaultValue={
                                    entity.automaticIntegrationByRestApi.route
                                  }
                                  isInvalid={isInvalidFeedBack(
                                    entity.automaticIntegrationByRestApi.route,
                                    validationErrors
                                  )}
                                  placeholder="Copie e cole a rota"
                                  onChange={(e) => [
                                    handleRouteOnChange(e.target.value, index),
                                    setValidationFormField(
                                      entity.name,
                                      e.target.value
                                    ),
                                  ]}
                                />
                              </Form.Group>
                            </Col>
                            <Col md={12} className="mb-3">
                              <Form.Group>
                                <Form.Label>
                                  FieldParse (obrigatório)
                                </Form.Label>
                                <Form.Control
                                  size="sm"
                                  defaultValue={
                                    entity.automaticIntegrationByRestApi
                                      .fieldParse
                                  }
                                  isInvalid={isInvalidFeedBack(
                                    entity.automaticIntegrationByRestApi
                                      .fieldParse,
                                    validationErrors
                                  )}
                                  placeholder="Copie e cole o campo FieldParse"
                                  onChange={(e) => [
                                    handleFieldParseOnChange(
                                      e.target.value,
                                      index
                                    ),
                                    setValidationFormField(
                                      entity.name,
                                      e.target.value
                                    ),
                                  ]}
                                />
                              </Form.Group>
                            </Col>
                            <Col md={12} className="mb-3">
                              <Form.Group>
                                <Form.Label>
                                  Outros parâmetros (opcional)
                                </Form.Label>
                                <Form.Control
                                  size="sm"
                                  defaultValue={
                                    entity.automaticIntegrationByRestApi.params
                                  }
                                  isInvalid={isInvalidFeedBack(
                                    entity.automaticIntegrationByRestApi.params,
                                    validationErrors
                                  )}
                                  placeholder="Copie e cole os parâmetros opcionais de acesso a API"
                                  onChange={(e) => [
                                    handleParamsOnChange(e.target.value, index),
                                    setValidationFormField(
                                      entity.name,
                                      e.target.value
                                    ),
                                  ]}
                                />
                              </Form.Group>
                            </Col>
                          </>
                        )}
                      </Row>
                    </div>
                  </div>
                );
              } else return "";
            })}
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        {stepResumeIntegration === 0 && (
          <>
            {/* Empty element */}
            <div></div>

            <Button
              variant="primary"
              className="continue-button"
              onClick={() => {
                handleSaveFrequencyIntegrationOnContext();
                setStepResumeIntegration(1);
              }}
            >
              Continuar
              <BsArrowRight color="#Fff" size={22} />
            </Button>
          </>
        )}
        {stepResumeIntegration === 1 && (
          <>
            <div>
              <CustomTooltip
                type="action-button"
                text="Voltar"
                placement="right"
                buttonContent={
                  <Button
                    className="return-button"
                    variant="primary"
                    onClick={() => setStepResumeIntegration(0)}
                  >
                    <BsArrowLeft color="#a56300" size={22} />
                  </Button>
                }
              />
            </div>
            <div className="groupButtonsFlexGap4">
              <Button
                className="button-edit"
                variant="primary"
                onClick={() => setStepResumeIntegration(2)}
              >
                <BsPencil color="#2155ba" size={20} />
                Editar Dados
              </Button>
              <Button
                variant="primary"
                className="sucess-button"
                // disabled={buttonDisable}
                onClick={() => saveActiveIntegration()}
              >
                Finalizar
                <BsCheck2 color="#fff" size={22} />
              </Button>
            </div>
          </>
        )}
        {stepResumeIntegration === 2 && (
          <>
            <Button
              variant="primary"
              className="cancel-operation"
              onClick={() => setStepResumeIntegration(1)}
            >
              <BsArrowLeft color="#a56300" size={22} />
              Voltar
            </Button>
            <div className="img-steps">
              <Stepper currentStep={1} totalSteps={4} />
            </div>
            <div className="groupButtonsFlexGap0">
              <Button
                variant="primary"
                className="continue-button"
                //disabled={buttonDisable}
                onClick={() => {
                  handleClickContinue(3);
                }}
              >
                Continuar
                <BsArrowRight color="#Fff" size={22} />
              </Button>
              <CustomTooltip
                type="action-button"
                text="Finalizar configuração"
                placement="top"
                buttonContent={
                  <Button
                    className="sucess-button-check ml-2"
                    onClick={() => saveActiveIntegration()}
                  >
                    <BsCheck2 color="#fff" size={22} />
                  </Button>
                }
              />
            </div>
          </>
        )}
        {stepResumeIntegration === 3 && (
          <>
            <Button
              variant="primary"
              className="cancel-operation"
              onClick={() => setStepResumeIntegration(2)}
            >
              <BsArrowLeft color="#a56300" size={22} />
              Voltar
            </Button>
            <div className="img-steps">
              <Stepper currentStep={2} totalSteps={4} />
            </div>
            <div className="groupButtonsFlexGap0">
              <Button
                variant="primary"
                className="continue-button"
                //disabled={buttonDisable}
                onClick={() => {
                  handleSaveFrequencyIntegrationOnContext();
                  setStepResumeIntegration(4);
                }}
              >
                Continuar
                <BsArrowRight color="#Fff" size={22} />
              </Button>
              <CustomTooltip
                type="action-button"
                text="Finalizar configuração"
                placement="top"
                buttonContent={
                  <Button
                    className="sucess-button-check ml-2"
                    onClick={() => saveActiveIntegration()}
                  >
                    <BsCheck2 color="#fff" size={22} />
                  </Button>
                }
              />
            </div>
          </>
        )}
        {stepResumeIntegration === 4 && (
          <>
            <Button
              variant="primary"
              className="cancel-operation"
              onClick={() => setStepResumeIntegration(3)}
            >
              <BsArrowLeft color="#a56300" size={22} />
              Voltar
            </Button>
            <div className="img-steps">
              <Stepper currentStep={3} totalSteps={4} />
            </div>
            <div className="groupButtonsFlexGap0">
              <Button
                variant="primary"
                className="continue-button"
                //disabled={buttonDisable}
                onClick={() => setStepResumeIntegration(5)}
              >
                Continuar
                <BsArrowRight color="#Fff" size={22} />
              </Button>
              <CustomTooltip
                type="action-button"
                text="Finalizar configuração"
                placement="top"
                buttonContent={
                  <Button
                    className="sucess-button-check ml-2"
                    onClick={() => saveActiveIntegration()}
                  >
                    <BsCheck2 color="#fff" size={22} />
                  </Button>
                }
              />
            </div>
          </>
        )}
        {stepResumeIntegration === 5 && (
          <>
            <Button
              variant="primary"
              className="cancel-operation"
              onClick={() => setStepResumeIntegration(4)}
            >
              <BsArrowLeft color="#a56300" size={22} />
              Voltar
            </Button>
            <div className="img-steps">
              <Stepper currentStep={4} totalSteps={4} />
            </div>

            <Button
              variant="primary"
              className="sucess-button"
              //disabled={buttonDisable}
              onClick={() => saveActiveIntegration()}
            >
              Finalizar
              <BsCheck2 color="#fff" size={22} />
            </Button>
          </>
        )}
      </Modal.Footer>
    </ModalGAutomaticIntegrationStyles>
  );
}

export default ModalContinueIntegration;
