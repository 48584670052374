import React from "react";
import { Container, Row } from "react-bootstrap";
import styled from "styled-components";

const Footer = () => {
  const handleGetCurrentYear = () => {
    return new Date().getFullYear();
  };
  return (
    <FooterStyles className="mainFooter">
      <Container fluid={true} className="h-100">
        <Row className="align-items-center footer-row text-center py-2">
          <Row className="ml-2">
            <div className="py-1 ">
              <a href="#" className="footer-link">
                Termos
              </a>
            </div>
            <div className="py-1 ml-4">
              <a href="#" className="footer-link">
                Política de Privacidade
              </a>
            </div>
          </Row>
          <div className="credits">
            <a
              href="https://brainz.group/"
              target="_blank"
              className="footer-link"
              rel="noreferrer"
            >
              Brainz Group
            </a>
            {" - "}
            {handleGetCurrentYear()} ©
          </div>
        </Row>
      </Container>
    </FooterStyles>
  );
};

const FooterStyles = styled.footer`
  z-index: 1;
  position: relative;

  width: 100%;
  height: 51px;

  border-top: 1px solid #d5d0d0;
  background-color: #fbfbff;

  padding: 0rem 2rem;
  display: flex;
  align-items: center;

  font-size: 14px;

  .footer-link {
    color: rgb(100, 104, 200);
    cursor: pointer;
  }
  .footer-link:hover {
    font-weight: bold;
    text-decoration: none;
    color: rgb(100, 104, 200);
  }
  .footer-row {
    min-height: 50px;
    justify-content: space-between;
  }

  .credits {
    color: #525252;
    a {
      color: #525252 !important;
    }
  }
`;

export default Footer;
