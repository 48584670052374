import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";

import PageLoader from "../../../components/Common/Loader";
import { showToast } from "../../../components/Common/Toast";
import { BrainzTeamPayload } from "../../../interfaces/BrainzTeamPayload";
import { BrainzUserTeamPayload } from "../../../interfaces/BrainzUserTeamPayload";
import { BrainzUserTeamViewModel } from "../../../interfaces/BrainzUserTeamViewModel";
import TeamApiInterface from "../../../services/TeamApiInterface";
import UserTeamApiInterface from "../../../services/UserTeamApiInterface";
import { ModalGenericStyles } from "../../../styles/modalsGeneric";
import { ActiveModalContext } from "../SyncDataContexts/ActiveModalContext";
import ModalTeamRegisterFirstStep from "./ModalTeamRegisterFirstStep";
import ModalTeamRegisterSecondStep from "./ModalTeamRegisterSecondStep";
import ModalTeamRegisterThirdStep from "./ModalTeamRegisterThirdStep";

// 0-fechado
// 1-register
// 2-usuario
// 3-unidade
// 4-turma
// 5-success
// 6-cancelar
// 7-error

export default function ModalTeamRegister() {
  const cookie = new Cookies();
  const [t] = useTranslation("common");
  const institutionId = cookie.get(process.env.REACT_APP_BRAINZ_INSTITUTIONID!);
  const [nameTeam, setNameTeam] = useState("");
  const [identifierTeam, setIdentifierTeam] = useState("");
  const [unitIdentifier, setUnitIdentifier] = useState("");
  const [stepAddTeam, setStepAddTeam] = useState(0);
  const [usersSelected, setUsersSelected] = useState<
    Array<BrainzUserTeamViewModel>
  >([]);
  const [loading, setLoading] = useState(false);

  const { activeModal, setActiveModal, setPreviousActiveModal } =
    useContext(ActiveModalContext);

  function clearModalTeam() {
    setIdentifierTeam("");
    setNameTeam("");
    setUsersSelected([]);
    setStepAddTeam(0);
  }

  function handleSaveDataTeam(users: Array<BrainzUserTeamViewModel>) {
    setLoading(true);
    createDataTeam();

    function createDataTeam() {
      const payload: Array<BrainzTeamPayload> = [
        {
          name: nameTeam,
          identifier: identifierTeam,
          unitIdentifier,
        },
      ];

      return TeamApiInterface.post(`/Team/${institutionId}/Insert`, payload)
        .then((response) => {
          if (response.status === 200) {
            const errors = response.data.result[0].errors;
            if (!errors || errors.length === 0) {
              showToast("success", "Turma salva com sucesso.");
              addUsersInTeam(users, response.data.result[0].value.identifier);
            } else {
              setActiveModal(7);
              setLoading(false);
              showToast("error", t("errors." + errors[0].code));
            }
          } else {
            setActiveModal(7);
            setLoading(false);
            showToast("error", "Algo deu errado, tente novamente mais tarde");
          }
        })
        .catch((error) => {
          setLoading(false);
          showToast("error", "Algo deu errado, tente novamente mais tarde");
          console.error("Erro => ", error)
        });
    }
  }

  async function addUsersInTeam(
    users: Array<BrainzUserTeamViewModel>,
    teamIdentifier: string
  ) {
    await sendUserTeams(users, 5, teamIdentifier);
    await sendUserTeams(users, 1, teamIdentifier);
    await sendUserTeams(users, 3, teamIdentifier);
    await sendUserTeams(users, 4, teamIdentifier);
    setActiveModal(5);
    setLoading(false);
  }

  function getNameByType(userType: number) {
    switch (userType) {
      case 1:
        return "Administrativos";
      case 2:
        return "Assistentes";
      case 3:
        return "Coordenadores";
      case 4:
        return "Alunos";
      case 5:
        return "Professores";
      default:
        return "Usuários";
    }
  }

  async function sendUserTeams(
    users: Array<BrainzUserTeamViewModel>,
    userType: number,
    teamIdentifier: string
  ) {
    const payload: Array<BrainzUserTeamPayload> = users
      .filter((item: BrainzUserTeamViewModel) => {
        return item.userType === userType;
      })
      .map((item: BrainzUserTeamViewModel) => {

        const newUserTeamPayload: BrainzUserTeamPayload = {
          teamIdentifier,
          userIdentifier: item.identifier,
        };
        return newUserTeamPayload;
      });
    if (payload.length === 0) return;
    try {
      const response = await UserTeamApiInterface.post(
        `/UserTeam/${institutionId}/${userType}/Insert`,
        payload
      );
      if (response.status === 200) {
        const errors = response.data.result[0].errors;
        if (!errors || errors.length === 0) {
          showToast(
            "success",
            getNameByType(userType) + " adicionados com sucesso."
          );
        } else {
          setActiveModal(7);
          showToast("error", t("errors." + errors[0].code));
        }
      } else {
        setActiveModal(7);
        showToast("error", "Algo deu errado, tente novamente mais tarde");
      }
    } catch (error) {
      showToast("error", "Algo deu errado, tente novamente mais tarde");
    }
  }

  useEffect(() => {
    if (activeModal === 0 || activeModal === 1) {
      clearModalTeam();
    }
  }, [activeModal]);

  useEffect(() => { }, [stepAddTeam]);

  if (loading) return <PageLoader />;

  return (
    <ModalGenericStyles
      show={activeModal === 4}
      onHide={() => {
        setActiveModal(6);
        setPreviousActiveModal(4);
      }}
    >
      {stepAddTeam === 0 && (
        <ModalTeamRegisterFirstStep
          goToNextStep={(unitIdentifier, teamIdentifier, teamName) => {
            setUnitIdentifier(unitIdentifier);
            setIdentifierTeam(teamIdentifier);
            setNameTeam(teamName);
            setStepAddTeam(1);
          }}
          closeModal={() => {
            setActiveModal(6);
            setPreviousActiveModal(4);
          }}
          data={{
            identifierTeam,
            nameTeam,
            unitIdentifier,
          }}
        />
      )}

      {stepAddTeam === 1 && (
        <ModalTeamRegisterSecondStep
          closeModal={() => {
            setActiveModal(6);
            setPreviousActiveModal(4);
          }}
          goToNextStep={() => setStepAddTeam(2)}
          goToPreviousStep={() => setStepAddTeam(0)}
          finishRegister={(users) => handleSaveDataTeam(users)}
          users={usersSelected}
          setUsers={(users) => setUsersSelected(users)}
        />
      )}

      {stepAddTeam === 2 && (
        <ModalTeamRegisterThirdStep
          closeModal={() => {
            setActiveModal(6);
            setPreviousActiveModal(4);
          }}
          goToPreviousStep={(user) => {
            user && setUsersSelected([...usersSelected, user]);
            setStepAddTeam(1);
          }}
        />
      )}
    </ModalGenericStyles>
  );
}


