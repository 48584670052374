import { Row } from "react-bootstrap/";
import styled from "styled-components";

export const FrequencyRowStyle = styled(Row)`
  margin: 0;
  display: flex;
  flex-direction: column;

  .selectContainer {
    margin: 28px 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .selectCheckboxContainer {
    display: flex;
    align-items: center;
    gap: 14px;

    .button-radio {
      margin: 0;

      input {
        width: 30px;
        height: 30px;
      }

      label {
        padding-top: 6px;
      }
    }

    .customTooltipButton {
      padding: 0;
      padding-top: 5px;

      outline: unset;
      box-shadow: unset;
    }
  }

  .scheduleContentContainer,
  .periodicContentContainer {
    input :not(.select-frequency-interval__input) {
      padding: 2px 16px 0px;
      width: 100%;
      height: 48px;
      left: 24px;
      top: 104px;
      background: #ffffff;
      border: 1px solid #a6a6a6;
      box-sizing: border-box;
      border-radius: 8px;
    }
  }

  .select-frequency-interval__control {
    min-height: 48px;
    border-radius: 8px;

    .select-frequency-interval__single-value {
      font-size: 16px;
      line-height: 24px;
      color: #6f6f6f;
    }

    .select-frequency-interval__indicator-separator {
      display: none;
    }

    .select-frequency-interval__dropdown-indicator {
      padding: 0;
      padding-right: 10px;
    }
  }
`;
