import { BrainzAuthContext } from "@brainz_group/webappframework";
import { TableComponent } from "@brainz_group/webappuiframework";
import { useState, useEffect, useContext } from "react";
import { Row, Col, Form, Button, Table } from "react-bootstrap";
import { BsXCircle } from "react-icons/bs";
import Cookies from "universal-cookie";

import BrainzComboBox from "../../components/Common/BrainzComboBox";
import BrainzPagination, {
  PaginationProps,
} from "../../components/Common/BrainzPagination/BrainzPagination";
import PageLoader from "../../components/Common/Loader";
import NoResults from "../../components/Common/NoResults";
import { handleGetPageSize } from "../../helpers/functions";
import ApiInterface from "../../services/ApiInterface";
import { FiltersStyleComponentUser } from "../../styles/generic";
import { InfoPageStyles } from "../../styles/infoPageStyles";
import SingleInstitution from "./SingleInstitution";
import { HomeTableStyles } from "./styles";

export interface BrainzInstitution {
  id: string;
  active: boolean;
  name: string;
  students: number;
  units: number;
  teachers: number;
  teams: number;
  coordinators: number;
  administratives: number;
}

export default function Home() {
  const cookies = new Cookies();

  const { getDecodedToken } = useContext(BrainzAuthContext);

  const [loading, setLoading] = useState(true);
  const [loadingTotals, setLoadingTotals] = useState(true);
  const [onlyInstitution, setOnlyInstitution] = useState(false);
  const [onlyInstitutionName, setOnlyInstitutionName] = useState("");
  const [institutions, setInstitutions] = useState<
    Array<BrainzInstitution> | undefined
  >();
  const [searchFilter, setSearchFilter] = useState<string>("");
  const [searchFilterTimed, setSearchFilterTimed] = useState<string>("");
  const [buttonClearFilters, setButtonClearFilters] = useState(false);
  const [activeFilter, setActiveFilter] = useState("");
  const [queryPaginationParams, setQueryPaginationParams] = useState(
    `?page=1&pageSize=${handleGetPageSize()}`
  );

  const [paging, setPaging] = useState<PaginationProps | undefined>();

  const [counterAdm, setCounterAdm] = useState(0);
  const [counterStudents, setCounterStudents] = useState(0);
  const [counterTeachers, setCounterTeachers] = useState(0);
  const [counterTeams, setCounterTeams] = useState(0);
  const [counterUnits, setCounterUnits] = useState(0);
  const [counterCoordinators, setCounterCoordinators] = useState(0);

  function queryPagination() {
    let result: string;
    if (queryPaginationParams) result = `${queryPaginationParams}`;
    else result = "";
    return result;
  }

  function queryFilters() {
    let query = "";
    if (searchFilter !== "") query = query += "&search=" + searchFilter;
    if (activeFilter === "true") query = query += "&activeFilter=true";
    if (activeFilter === "false") query = query += "&activeFilter=false";
    if (activeFilter === "") query = query += "";
    if (query !== "") setButtonClearFilters(true);
    else setButtonClearFilters(false);
    return query;
  }

  function gotoPageOne() {
    if (paging) {
      const paginationProps: PaginationProps = {
        currentRecord: paging.currentRecord,
        currentPage: 1,
        pageSize: paging.pageSize ?? 10,
        recordsOnPage: paging.recordsOnPage,
        totalRecords: paging.totalRecords,
        totalPages: paging.totalPages,
      };
      setPaging(paginationProps);
      setQueryPaginationParams("?page=1&pageSize=" + handleGetPageSize());
    }
  }

  function loadPage() {
    if (
      cookies.get(process.env.REACT_APP_BRAINZ_INSTITUTIONID!) === undefined
    ) {
      ApiInterface.get(`/Institution/List`).then((response: any) => {
        cookies.set(
          process.env.REACT_APP_BRAINZ_INSTITUTIONID!,
          response.data.result[0].id
        );
      });
    }
    if (queryPaginationParams) {
      setLoading(true);
      ApiInterface.get(
        `/Institution/ListWithCounts${queryPagination()}&${queryFilters()}`
      )
        .then((response) => {
          if (response.data.paging && response.data.result) {
            setPaging(response.data.paging);

            const orderInstitution = response.data.result;
            setInstitutions(
              orderInstitution?.sort((a: any, b: any) => {
                if (a.name > b.name) {
                  return 1;
                }
                if (a.name < b.name) {
                  return -1;
                }
                return 0;
              })
            );

            setOnlyInstitutionName(response.data.result[0].name);
          }

          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error("Erro => ", error);
        });
    }
  }

  function handleClearFilters() {
    setActiveFilter("");
    setSearchFilter("");
    setSearchFilterTimed("");
    setButtonClearFilters(false);
  }

  useEffect(() => {
    gotoPageOne();
  }, [searchFilter, activeFilter]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearchFilter(searchFilterTimed);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchFilterTimed]);

  useEffect(() => {
    loadPage();
  }, [queryPaginationParams, searchFilter, activeFilter]);

  useEffect(() => {
    setLoadingTotals(true);
    ApiInterface.get(`/Institution/ListTotals`)
      .then((response) => {
        const counters = response.data.result;
        setCounterAdm(counters.administratives);
        setCounterStudents(counters.students);
        setCounterTeachers(counters.teachers);
        setCounterTeams(counters.teams);
        setCounterUnits(counters.units);
        setCounterCoordinators(counters.coordinators);
        setLoadingTotals(false);
      })
      .catch((error) => {
        console.error("Erro ao tentar acessar a lista de instituições. Erro => ", error)
        setLoadingTotals(false);
      });
    const token = getDecodedToken();
    if (token?.applications?.indexOf("provisionador_admin") !== -1) {
      setOnlyInstitution(false);
    } else {
      setOnlyInstitution(true);
    }
  }, []);

  return (
    <div>
      {onlyInstitution === false && (
        <section className="headingStickyPageHome">
          <InfoPageStyles
            style={{
              margin: "2rem 0 0",
              padding: "0 1rem",
            }}
          >
            <Row className="">
              <Col md={5} className="mr-0 ">
                <Col
                  md={12}
                  className="align-self-center"
                  style={{ padding: 0 }}
                >
                  <h2 className="title-page">Página Inicial</h2>
                </Col>
                <Col md={12} className="p-0 m-0 ">
                  <FiltersStyleComponentUser className="search-filter">
                    <Col md={7} className="pl-3">
                      <Form.Group>
                        <div className="search-filter">
                          <Form.Control
                            className="filter-name"
                            value={searchFilterTimed}
                            onChange={(e) => {
                              setSearchFilterTimed(e.target.value);
                            }}
                            type="text"
                            placeholder="Buscar por Nome ou Identificador"
                            size="sm"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md={3} className=" m-0 p-0 pl-1">
                      <Form.Group>
                        <BrainzComboBox
                          setValue={(val) => setActiveFilter(val)}
                          value={activeFilter}
                          idField="id"
                          valueField="name"
                          placeholder="Status"
                          options={[
                            { name: "Ativo", value: "true" },
                            { name: "Inativo", value: "false" },
                          ]}
                          search={false}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={1} className=" m-0 pl-1">
                      {buttonClearFilters === false ? (
                        ""
                      ) : (
                        <Button
                          className="clear-filter"
                          onClick={handleClearFilters}
                        >
                          <BsXCircle
                            className="bi bi-x-circle"
                            color="#A56300"
                            size={24}
                          />
                        </Button>
                      )}
                    </Col>
                  </FiltersStyleComponentUser>
                </Col>
              </Col>
              <Col md={7} className="p-0">
                <HomeTableStyles className="p-0">
                  <Table
                    className=" table-list"
                    striped
                    borderless
                    hover
                    responsive
                  >
                    <thead>
                      <tr
                        className="title-table"
                        style={{ textAlignLast: "start" }}
                      >
                        <th className="border-radius-left"></th>
                        <th>Unidades</th>
                        <th>Turmas</th>
                        <th>Professores</th>
                        <th>Alunos</th>
                        <th>Administrativos</th>
                        <th className="border-radius-right">Coordenadores</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr id="tr-linha">
                        <td className="border-radius-left-bottom">Totais:</td>
                        <td>{counterUnits}</td>
                        <td>{counterTeams}</td>
                        <td>{counterTeachers}</td>
                        <td>{counterStudents}</td>
                        <td>{counterAdm}</td>
                        <td className="border-radius-right-bottom">
                          {counterCoordinators}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </HomeTableStyles>
              </Col>
            </Row>
          </InfoPageStyles>
        </section>
      )}

      {loading ? (
        <PageLoader />
      ) : (
        <>
          {onlyInstitution === true ? (
            <SingleInstitution
              onlyInstitutionName={onlyInstitutionName}
              counterAdm={counterAdm}
              counterStudents={counterStudents}
              counterTeachers={counterTeachers}
              counterTeams={counterTeams}
              counterUnits={counterUnits}
              counterCoordinators={counterCoordinators}
            />
          ) : (
            <>
              {loadingTotals ? (
                <PageLoader />
              ) : institutions && institutions.length > 0 ? (
                <>
                  <TableComponent
                    data={institutions}
                    columns={[
                      {
                        headerName: "Status",
                        property: "",
                        type: "icons",
                        headerClass: "border-radius-left",
                        icons: [
                          {
                            nameIcon: "BsCheckCircle",
                            className: "success-status",
                            color: "#008555",
                            size: 24,
                          },
                          {
                            nameIcon: "BsXOctagon",
                            className: "error-status",
                            color: "#A56300",
                            size: 24,
                          },
                        ],
                      },
                      {
                        headerName: "Empresa/Grupo",
                        property: "name",
                        type: "text",
                        headerClass: "",
                      },
                      {
                        headerName: "Identificador",
                        property: "id",
                        type: "text",
                        headerClass: "",
                      },
                      {
                        headerName: "Unidades",
                        property: "units",
                        type: "text",
                        headerClass: "",
                      },
                      {
                        headerName: "Turmas",
                        property: "teams",
                        type: "text",
                        headerClass: "",
                      },
                      {
                        headerName: "Professores",
                        property: "teachers",
                        type: "text",
                        headerClass: "",
                      },
                      {
                        headerName: "Alunos",
                        property: "students",
                        type: "text",
                        headerClass: "",
                      },
                      {
                        headerName: "Administrativos",
                        property: "administratives",
                        type: "text",
                        headerClass: "",
                      },
                      {
                        headerName: "Coordenadores",
                        property: "coordinators",
                        type: "text",
                        headerClass: "border-radius-right",
                      },
                    ]}
                  />

                  <Col md={12}>
                    {paging && (
                      <BrainzPagination
                        setQueryParams={setQueryPaginationParams}
                        paging={paging}
                      />
                    )}
                  </Col>
                </>
              ) : (
                <NoResults clearFilterFunction={handleClearFilters} />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}
