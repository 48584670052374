import pt from "date-fns/locale/pt";
import React, { useContext, useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { registerLocale } from "react-datepicker";
import { BsCheck2, BsChevronRight, BsX } from "react-icons/bs";

import FrequencyIntegrationBody from "../../../components/Common/FrequencyIntegrationBody";
import { ActiveModalIntegrationContext } from "../AutomaticIntegrationContexts/ActiveModalIntegrationContext";
import {
  handleFormatScheduleTimeToString,
  handleFormatPeriodicIntervalToString,
} from "../utils/functions";
import { ModalGAutomaticIntegrationStyles } from "./styles";

export default function ModalEditHourInterval() {
  const {
    setActiveModal,
    activeModal,
    frequencyType,
    setScheduleTimesList,
    setIntervalTimeList,
    setUpdateIntegration,
    setFrequencyType,
  } = useContext(ActiveModalIntegrationContext);

  const [selectedScheduleTimes, setSelectedScheduleTimes] = useState<any>([]);
  const [selectedInterval, setSelectedInterval] = useState<any>("");
  const [localFreqType, setLocalFreqType] = useState<any>(frequencyType);

  registerLocale("pt", pt);

  const handleClickSave = () => {
    setActiveModal(0);
    setFrequencyType(localFreqType);
    setUpdateIntegration(true);

    if (localFreqType === 1) {
      const result = handleFormatScheduleTimeToString(selectedScheduleTimes);
      setScheduleTimesList(result);
    }

    if (localFreqType === 2) {
      const result = handleFormatPeriodicIntervalToString(
        selectedInterval?.value
      );
      setIntervalTimeList(result);
    }
  };

  useEffect(() => {
    if (activeModal === 9) {
      setLocalFreqType(frequencyType);
    }
  }, [activeModal]);

  return (
    <ModalGAutomaticIntegrationStyles
      show={activeModal === 9}
      onHide={() => setActiveModal(0)}
    >
      <Modal.Header>
        <Modal.Title>
          <h2>
            Integração <BsChevronRight size={18} /> Horário e intervalo
          </h2>
        </Modal.Title>
        <Button
          className="cursor-pointer"
          variant="white"
          onClick={() => setActiveModal(0)}
        >
          <BsX color="var(--bs-modal-color)" size={28} />
        </Button>
      </Modal.Header>
      <Modal.Body className="">
        <FrequencyIntegrationBody
          handleChangeSelectedScheduleTimes={(value: any) =>
            setSelectedScheduleTimes(value)
          }
          handleChangeSelectedInterval={(value: any) =>
            setSelectedInterval(value)
          }
          onChangeFreqType={(value: any) => setLocalFreqType(value)}
          frequencyTypeData={localFreqType}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button className="cancel-operation" onClick={() => setActiveModal(0)}>
          <BsX className=" mr-2" color="#A56300" size={24} />
          Cancelar
        </Button>
        <Button
          variant="primary"
          className="continue-button"
          onClick={handleClickSave}
        >
          Salvar
          <BsCheck2 color="#fff" size={22} />
        </Button>
      </Modal.Footer>
    </ModalGAutomaticIntegrationStyles>
  );
}
