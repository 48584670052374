import { Col, Row } from "react-bootstrap";
import styled from "styled-components";

export const FilterStyles = styled(Row)`
  .filter-div {
  }
  .button-update {
    width: 48px;
    height: 48px;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #2155ba;
    padding: 0px;
    background-color: #fff;
    border: 1px solid #2155ba;
    box-sizing: border-box;
    border-radius: 8px;

    &:focus {
      background: none !important;
      border: 1px solid #2155ba !important;
      color: #2155ba !important;
    }

    &:active {
      background: none !important;
      color: #2155ba !important;
    }

    &:hover {
      background: #2155ba !important;
      color: #fff !important;
      border: 1px solid #fff !important;
      .icon-plus {
        fill: #fff !important;
      }
    }

    &:disabled {
      background: none !important;
      border: #2155ba !important;
    }
  }

  .input-date {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 12px 8px;
    width: 142px;
    height: 48px;
    background: #f5f5f5;
    border: 1px solid #e4e3e3;
    box-sizing: border-box;
    border-radius: 8px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin: 0px 0px;
    &:focus {
      box-shadow: none !important;
    }
  }

  .type-integration {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #514f4e;

    Button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 8px;
      width: 190px;
      height: 48px;
      background: #f5f5f5;
      border: 1px solid #e4e3e3;
      box-sizing: border-box;
      border-radius: 8px;
      color: #6f6f6f;
      &:focus {
        background: #f5f5f5 !important;
        border: 0.14vmin solid #0c8292 !important;
        color: #6f6f6f !important;
        box-shadow: none !important;
      }

      &:active {
        background: #f5f5f5 !important;
        color: #6f6f6f !important;
      }
      &:hover {
        background: #f5f5f5 !important;
        color: #6f6f6f !important;
      }
      &:disabled {
        background-color: #f5f5f5 !important;
        color: #6f6f6f !important;
        border: 1px solid #e4e3e3;
      }
    }
    .dropdown-toggle::after {
      display: none;
    }
  }

  .type-entity {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #514f4e;

    Button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 8px;
      width: 160px;
      height: 48px;
      background: #f5f5f5;
      border: 1px solid #e4e3e3;
      box-sizing: border-box;
      border-radius: 8px;
      color: #6f6f6f;

      &:focus {
        background: none !important;
        border: 1px solid #e4e3e3 !important;
        color: #6f6f6f;
        box-shadow: none !important;
      }
      &:active {
        background: none !important;
        color: #6f6f6f !important;
      }
      &:hover:enabled:disabled {
        background: #f5f5f5 !important;
        color: #6f6f6f !important;
      }
      &:disabled {
        background-color: #f5f5f5 !important;
        border: none;
      }
    }
    .dropdown-toggle::after {
      display: none;
    }
  }

  .select-search {
    border-color: rgb(228, 227, 227) !important;
  }

  @media screen and (max-width: 1350px) {
    &.logs-filter {
      flex-direction: column;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 1rem;

      & > div {
        max-width: 100% !important;
        padding: 0 12px !important;

        .react-datepicker-popper {
          z-index: 999 !important;
        }
      }

      .filter-div {
        .pr-4 {
          padding-right: 0 !important;
        }
      }
    }
  }
`;

export const ItemLogsNavBarFilters = styled(Col)`
  z-index: 100;
  height: 50px;
  width: 173px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #f5f5f5;
  border: 1px solid #e4e3e3;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  .card {
    background: #e7f3f7;
  }
  .nav-link {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    height: 44px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border: none;
    flex: none;
    order: 0;
    flex-grow: 0;
    color: #514f4e;
    padding: 12px;
  }
  .nav-link.active {
    width: 80px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    height: 44px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px;
    color: #0f7eaa;
    background: #e7f3f7;
    border: 1px solid #0f7eaa;
    box-sizing: border-box;
    border-radius: 8px;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
`;
