import React, { useContext, useEffect, useState } from "react";
import { Col, Modal, Row, Form, Button } from "react-bootstrap";
import { BsArrowRight, BsChevronRight, BsX } from "react-icons/bs";

import { ActiveModalIntegrationContext } from "../../AutomaticIntegrationContexts/ActiveModalIntegrationContext";
import { ModalGAutomaticIntegrationStyles } from "../styles";
import "./styles.css";

// Integration Types
// SqlDatabase = 1,
// RestApi = 4,

export default function ModalTypeIntegration() {
  const { setActiveModal, activeModal, typeIntegration } = useContext(
    ActiveModalIntegrationContext
  );

  const [typeIntegrationLocal, setTypeIntegrationLocal] =
    useState<number>(typeIntegration);

  const handlerContinueIntegration = () => {
    if (typeIntegrationLocal === 1) {
      setActiveModal(2);
    } else {
      setActiveModal(3);
    }
  };

  const handleClearState = () => {
    setActiveModal(0)
    setTypeIntegrationLocal(typeIntegration)
  }

  useEffect(() => {
    setTypeIntegrationLocal(typeIntegration);
  }, [typeIntegration]);

  return (
    <ModalGAutomaticIntegrationStyles
      show={activeModal === 1}
      onHide={() => handleClearState()}
      className="ModalTypeIntegration"
    >
      <Modal.Header>
        <Modal.Title>
          <h2>
            Integração Automática <BsChevronRight size={18} /> Fonte de Integração
          </h2>
        </Modal.Title>
        <Button
          className="cursor-pointer"
          variant="white"
          onClick={() => handleClearState()}
        >
          <BsX color="var(--bs-modal-color)" size={28} />
        </Button>
      </Modal.Header>
      <Modal.Body className="">
        <Row>
          <Col>
            <h3>Escolha qual será a fonte dos dados a serem integrados</h3>
          </Col>
        </Row>

        <Form.Group as={Row}>
          <Form.Label column sm={12}>
            Banco de dados
          </Form.Label>
          <Col sm={11} className="card-selection">
            <Form.Check
              className="button-radio mt-1"
              type="radio"
              label="SQL Server"
              checked={typeIntegrationLocal === 1}
              onChange={() => {
                setTypeIntegrationLocal(1);
              }}
            />
          </Col>
        </Form.Group>
        <br />
        <Form.Group as={Row}>
          <Form.Label column sm={12}>
            API
          </Form.Label>
          <Col sm={11} className="card-selection">
            <Form.Check
              className="button-radio mt-1"
              type="radio"
              label="Rest API"
              checked={typeIntegrationLocal === 4}
              onChange={() => {
                setTypeIntegrationLocal(4);
              }}
            />
          </Col>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <div> </div>
        <Button
          variant="primary"
          className="continue-button"
          disabled={typeIntegrationLocal !== 4 && typeIntegrationLocal !== 1}
          onClick={handlerContinueIntegration}
        >
          Continuar
          <BsArrowRight className="ml-2" color="#Fff" size={22} />
        </Button>
      </Modal.Footer>
    </ModalGAutomaticIntegrationStyles>
  );
}
