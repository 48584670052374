import styled from "styled-components";

import { ModalGenericStyles } from "../../../styles/modalsGeneric";

export const ModalInsertionStyles = styled(ModalGenericStyles)`
  .modal-body {
    .modalInsertionGroupRow {
      .form-label {
        padding-left: 0;
      }

      .modalInsertionGroupColToggle {
        margin: 32px 0;
      }

      .modalInsertionGroupColHeaders {
        margin: 32px 0 24px;
      }

      .selectAllChecksContainer {
        display: flex;
        align-items: center;
        width: 100%;
        gap: 69px;
        margin-top: 1rem;
      }
    }

    .headersContainer {
      display: flex;
      flex-direction: column;

      .headersTop {
        display: grid;
        grid-template-columns: 40% 40% 20%;
        align-items: center;

        margin-top: 1rem;
        margin-bottom: 1rem;
        padding: 8px 16px 8px 12px;
        height: 44px;

        background: #e4e3e3;
        border-radius: 8px 8px 0 0;

        & span {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          text-align: center;

          color: #424040;
        }
      }

      .headersContent {
        .headersContentItem {
          display: grid;
          grid-template-columns: 40% 40% 20%;
          position: relative;
          border-bottom: 1px solid #d5d5d5;

          span {
            min-height: 56px;
            padding: 8px 16px 8px 12px;
            background: #fefefe;
            word-break: break-all;
            text-align: center;

            &:nth-child(2n) {
              padding: 8px 0 8px 0;
            }
          }

          .actionButtons {
            display: flex;
            justify-content: center;
          }
        }
      }
    }

    .isAddNewHeaderContainer {
      .contentNewHeader {
        margin-bottom: 2rem;
      }

      .buttonsContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 2rem 0 0;
        gap: 3rem;

        button {
          height: 48px;
          width: 42%;
          border-radius: 8px;
          outline: unset;
          box-shadow: unset;
          border: unset;
        }

        .cancelButton {
          background: rgb(165, 99, 0);

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
`;
