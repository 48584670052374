import copy from "copy-to-clipboard";
import { useEffect, useState } from "react";
import { Button, Col, Form, Row, Alert } from "react-bootstrap";
import Cookies from "universal-cookie";

import FirstConfiguration from "../../components/Common/FirstConfiguration";
import PageLoader from "../../components/Common/Loader";
import { showToast } from "../../components/Common/Toast";
import FileUpload from "../../helpers/FileUpload";
import { BrainzPortalSettings } from "../../interfaces/FirstAccess";
import ApiInterface from "../../services/ApiInterface";
import { InitialHeaderStyles } from "../../styles/initialStyles";
import "./styles.css";

function FirstAccess() {
  const cookie = new Cookies();
  const institutionId = cookie.get(process.env.REACT_APP_BRAINZ_INSTITUTIONID!);

  const fileUploader = new FileUpload();

  const [forceReload, setForceReload] = useState(1);
  const [firstValidator, setFirstValidator] = useState(1);
  const [secondValidator, setSecondValidator] = useState(2);
  const [logoUrl, setLogoUrl] = useState("");
  const [backgroundUrl, setBackgroundUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const [id, setId] = useState("");
  const [portalLink, setPortalLink] = useState("");
  const [renderFirstAccessScreen, setRenderFirstAccessScreen] = useState(false);
  const [buttonSaveTitle, setButtonSaveTitle] = useState("Salvar dados");

  async function saveData() {
    const payload = {
      firstValidator,
      secondValidator,
      institutionId,
      logoUrl,
      backgroundUrl,
    } as BrainzPortalSettings;

    // Update
    if (id !== "") {
      payload.id = id;
      ApiInterface.put(`/PortalSettings/Update`, payload).then((response) => {
        if (response.status === 200) {
          showToast("success", "Os dados foram atualizados com sucesso!");
          setForceReload(forceReload + 1);
          setLoading(false);
        } else
          showToast(
            "error",
            "Erro ao atualizar primeiro acesso, tente novamente."
          );
        setLoading(false);
      });

      return;
    }
    // Create
    ApiInterface.post(`/PortalSettings/Insert`, payload).then((response) => {
      if (response.status === 200) {
        showToast("success", "Os dados foram cadastrados com sucesso!");
        setForceReload(forceReload + 1);
        setLoading(false);
      } else
        showToast(
          "error",
          "Erro ao cadastrar primeiro acesso, tente novamente."
        );
      setLoading(false);
    });

    ;
  }

  function CopyToLink() {
    copy(portalLink);
    showToast("info", "Link copiado com sucesso.");
  }

  function loadPage() {
    setLoading(true);
    ApiInterface.get(`/PortalSettings/${institutionId}/Get`)
      .then((response) => {
        if (response.data.result.id !== null) {
          const settings = response.data.result;
          setId(settings.id);
          setLogoUrl(settings.logoUrl);
          setBackgroundUrl(settings.backgroundUrl);
          setFirstValidator(settings.firstValidator);
          setSecondValidator(settings.secondValidator);
          setRenderFirstAccessScreen(true);
          setButtonSaveTitle("Atualizar dados");
        }
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }

  useEffect(() => {
    loadPage();
    setPortalLink(
      window.location.protocol +
      "//" +
      window.location.hostname +
      (window.location.port !== "" ? ":" : "") +
      window.location.port +
      "/portal?q=" +
      institutionId
    );
  }, [forceReload, institutionId]);

  return (
    <div>
      <InitialHeaderStyles className=" mx-0 px-0">
        {loading ? (
          <PageLoader />
        ) : !renderFirstAccessScreen ? (
          <FirstConfiguration
            headingText="Você ainda não configurou o primeiro acesso!"
            description=" Clique no botão abaixo para começar a configuração do primeiro
            acesso."
            handleClick={() => setRenderFirstAccessScreen(true)}
            buttonText="Configurar primeiro acesso"
          />
        ) : (
          <>
            <section className="headingStickySubPages">
              <Row>
                <Col md={12} style={{ padding: 0 }}>
                  <h2>Portal Primeiro Acesso</h2>
                </Col>
                <br />
                <br />
                <Col md={12} style={{ padding: 0 }}>
                  <h3>
                    Personalize a página do portal de primeiro acesso da sua
                    instituição.
                  </h3>
                </Col>
              </Row>
            </section>

            <div className="firstAccessContent">
              <section className="settingsAccessContainer">
                <Row>
                  <Col md="6">
                    <Form.Group>
                      <Form.Label className="d-block">
                        <strong>Logo</strong>
                      </Form.Label>
                      {logoUrl !== "" && (
                        <img
                          className="m-2"
                          src={logoUrl}
                          alt="Logo"
                          style={{ maxHeight: "200px" }}
                        />
                      )}
                      <Form.Control
                        type="file"
                        onChange={async (e: any) => {
                          const formData = new FormData();
                          formData.append("File", e.target.files[0]);
                          fileUploader
                            .uploadTempContainer(formData, {
                              headers: {
                                "Content-Type": "multipart/form-data, image/*",
                              },
                            })
                            .then((result) => setLogoUrl(result.result.path));
                        }}
                        id="custom-file"
                        data-browse="Buscar"
                      />
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group>
                      <Form.Label className="d-block">
                        <strong>Plano de fundo</strong>
                      </Form.Label>
                      {backgroundUrl !== "" && (
                        <img
                          className="m-2"
                          src={backgroundUrl}
                          alt="Logo"
                          style={{ maxHeight: "200px" }}
                        />
                      )}
                      <Form.Control
                        type="file"
                        onChange={async (e: any) => {
                          const formData = new FormData();
                          formData.append("File", e.target.files[0]);
                          fileUploader
                            .uploadTempContainer(formData, {
                              headers: {
                                "Content-Type": "multipart/form-data, image/*",
                              },
                            })
                            .then((result) =>
                              setBackgroundUrl(result.result.path)
                            );
                        }}
                        id="custom-file"
                        data-browse="Buscar"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <br />

                <hr />
                <br />

                <Row>
                  <Col md="6">
                    <Form.Group>
                      <Form.Label>
                        <strong>Primeiro campo para validação</strong>
                      </Form.Label>
                      <Form.Control
                        defaultValue={firstValidator}
                        as="select"
                        size="sm"
                        onChange={(e) => {
                          setFirstValidator(+e.target.value);
                        }}
                      >
                        <option
                          disabled={secondValidator === 1}
                          key="1"
                          value={1}
                        >
                          Nome Completo
                        </option>
                        <option
                          disabled={secondValidator === 2}
                          key="2"
                          value={2}
                        >
                          Matrícula
                        </option>
                        <option
                          disabled={secondValidator === 3}
                          key="3"
                          value={3}
                        >
                          Data de Nascimento
                        </option>
                        <option
                          disabled={secondValidator === 4}
                          key="4"
                          value={4}
                        >
                          CPF/CNPJ
                        </option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group>
                      <Form.Label>
                        <strong>Segundo campo para validação</strong>
                      </Form.Label>
                      <Form.Control
                        defaultValue={secondValidator}
                        as="select"
                        size="sm"
                        onChange={(e) => {
                          setSecondValidator(+e.target.value);
                        }}
                      >
                        <option
                          disabled={firstValidator === 1}
                          key="1"
                          value={1}
                        >
                          Nome Completo
                        </option>
                        <option
                          disabled={firstValidator === 2}
                          key="2"
                          value={2}
                        >
                          Matrícula
                        </option>
                        <option
                          disabled={firstValidator === 3}
                          key="3"
                          value={3}
                        >
                          Data de Nascimento
                        </option>
                        <option
                          disabled={firstValidator === 4}
                          key="4"
                          value={4}
                        >
                          CPF/CNPJ
                        </option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <br />
                <br />
              </section>

              <Row style={{ margin: 0 }}>
                <Alert className="text-center w-100">
                  Para que os usuários da sua instituição possam obter o e-mail
                  e senha criados, copie e compartilhe o link abaixo. <br />
                  <br />
                  <Button
                    onClick={() => CopyToLink()}
                    variant="outline-primary"
                  >
                    Copiar Link
                  </Button>
                </Alert>
              </Row>

              <Row className="mt-3 mb-3 saveContainer">
                <Button
                  className="w-100"
                  disabled={loading}
                  onClick={() => {
                    setLoading(true);
                    saveData();
                  }}
                >
                  {buttonSaveTitle}
                </Button>
              </Row>
            </div>
          </>
        )}
      </InitialHeaderStyles>
    </div>
  );
}

export default FirstAccess;
