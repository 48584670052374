import React, { useState, useContext, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import Select from "react-select";

import { ActiveModalIntegrationContext } from "../../../screens/AutomaticIntegration/AutomaticIntegrationContexts/ActiveModalIntegrationContext";
import {
  handleFormatPeriodicIntervalToSelect,
  handleFormatScheduleTimeToSelect,
  hoursOptions,
  intervalOptions,
} from "../../../screens/AutomaticIntegration/utils/functions";
import CustomTooltip from "../CustomTooltip";
import DropdownIndicator from "../DropdownIndicator";
import { FrequencyRowStyle } from "./styles";

interface FrequencyIntegrationProps {
  handleChangeSelectedScheduleTimes: (value: any) => void;
  handleChangeSelectedInterval: (value: any) => void;

  onChangeFreqType: (value: any) => void;
  frequencyTypeData: any;
}

const FrequencyIntegrationBody = ({
  handleChangeSelectedScheduleTimes,
  handleChangeSelectedInterval,
  onChangeFreqType,
  frequencyTypeData
}: FrequencyIntegrationProps) => {
  const {
    scheduleTimesList,
    intervalTimeList,
  } = useContext(ActiveModalIntegrationContext);

  const [selectedScheduleTimes, setSelectedScheduleTimes] = useState<any>(
    handleFormatScheduleTimeToSelect(scheduleTimesList) || []
  );
  const [selectedInterval, setSelectedInterval] = useState<any>(
    handleFormatPeriodicIntervalToSelect(intervalTimeList) ?? ""
  );


  useEffect(() => {
    if (scheduleTimesList) {
      setSelectedScheduleTimes(
        handleFormatScheduleTimeToSelect(scheduleTimesList)
      );

      handleChangeSelectedScheduleTimes(
        handleFormatScheduleTimeToSelect(scheduleTimesList)
      );
    }

    if (intervalTimeList) {
      setSelectedInterval(
        handleFormatPeriodicIntervalToSelect(intervalTimeList)
      );

      handleChangeSelectedInterval(
        handleFormatPeriodicIntervalToSelect(intervalTimeList)
      );
    }

    if (!scheduleTimesList) {
      handleChangeSelectedScheduleTimes([hoursOptions[0]]);
    }

    if (!intervalTimeList) {
      handleChangeSelectedInterval(intervalOptions[0]);
    }
  }, []);

  return (
    <FrequencyRowStyle className="frequencyIntegrationContainer">
      <Row>
        <Col>
          Selecione o tipo de intervalo para cada integração, agendado ou
          periódico.
        </Col>
      </Row>

      <section className="selectContainer">
        <div className="selectCheckboxContainer">
          <Form.Check
            className="button-radio"
            type="radio"
            label="Agendado"
            checked={frequencyTypeData === 1}
            onChange={() => {
              onChangeFreqType(1);
            }}
            disabled={false}
          />

          <CustomTooltip
            type="info"
            iconColor="#6F6F6F"
            iconSize={20}
            text="Escolha um ou mais horários
              para realizar as integrações"
            placement="bottom"
          />
        </div>

        <div className="selectCheckboxContainer">
          <Form.Check
            className="button-radio"
            type="radio"
            label="Periódico"
            checked={frequencyTypeData === 2}
            onChange={() => {
              onChangeFreqType(2);
            }}
            disabled={false}
          />

          <CustomTooltip
            type="info"
            iconColor="#6F6F6F"
            iconSize={20}
            text="Defina um intervalo de
              tempo entre as integrações"
            placement="bottom"
          />
        </div>
      </section>

      {frequencyTypeData === 1 && (
        <section className="scheduleContentContainer">
          <span>Horário</span>
          <Select
            placeholder="Selecione ao menos um horário..."
            className="selectScheduleOptions"
            classNamePrefix="select-frequency-interval"
            components={{ DropdownIndicator }}
            name="hourstime-type"
            options={hoursOptions}
            value={selectedScheduleTimes}
            onChange={(e) => {
              handleChangeSelectedScheduleTimes(e);
              setSelectedScheduleTimes(e);
            }}
            isMulti
          />
        </section>
      )}

      {frequencyTypeData === 2 && (
        <section className="periodicContentContainer">
          <span>Intervalo</span>
          <Select
            placeholder="Selecione um intervalo..."
            className="selectPeriodicOptions"
            classNamePrefix="select-frequency-interval"
            components={{ DropdownIndicator }}
            name="interval-type"
            options={intervalOptions}
            value={selectedInterval}
            onChange={(e) => {
              handleChangeSelectedInterval(e);
              setSelectedInterval(e);
            }}
          />
        </section>
      )}
    </FrequencyRowStyle>
  );
};

export default FrequencyIntegrationBody;
