import {
  BrowserRouter as Router,
  Routes as Switch,
  Route,
} from "react-router-dom";

import Toast from "./components/Common/Toast";
import LayoutDefault from "./components/layouts/LayoutDefault";
import AutomaticIntegrationScreen from "./screens/AutomaticIntegration/AutomaticIntegrationScreen";
import FirstAccess from "./screens/FirstAccess/";
import GeneralConfigurationScreen from "./screens/GeneralConfiguration/GeneralConfigurationScreen";
import Home from "./screens/Home/";
import IntegrationsPage from "./screens/IntegrationsPage";
import Logs from "./screens/Logs/";
import LogsDetails from "./screens/Logs/LogsDetails";
import ManualIntegration from "./screens/ManualIntegration/";
import NotFound from "./screens/NotFound/";
import Portal from "./screens/Portal/Portal";
import SettingsPage from "./screens/SettingsPage";
import SyncData from "./screens/SyncData/SyncData";
import WriteBack from "./screens/WriteBack";

function Routes() {
  return (
    <Router>
      <Switch>
        <Route key={0} path="/portal" element={<Portal />} />
        <Route
          key={1}
          path="/home"
          element={
            <LayoutDefault>
              <Home />
            </LayoutDefault>
          }
        />
        <Route
          key={2}
          path="/automatic-integration"
          element={
            <LayoutDefault>
              <AutomaticIntegrationScreen />
            </LayoutDefault>
          }
        />
        <Route
          key={3}
          path="/manual-integration"
          element={
            <LayoutDefault>
              <ManualIntegration />
            </LayoutDefault>
          }
        />
        <Route
          key={4}
          path="/domains"
          element={
            <LayoutDefault>
              <GeneralConfigurationScreen />
            </LayoutDefault>
          }
        />

        <Route
          key={6}
          path="/sync-data"
          element={
            <LayoutDefault>
              <SyncData />
            </LayoutDefault>
          }
        />
        <Route
          key={7}
          path="/first-access"
          element={
            <LayoutDefault>
              <FirstAccess />
            </LayoutDefault>
          }
        />

        <Route
          key={12}
          path="/logs"
          element={
            <LayoutDefault>
              <Logs />
            </LayoutDefault>
          }
        />
        <Route
          key={13}
          path="/settings-page"
          element={
            <LayoutDefault>
              <SettingsPage />
            </LayoutDefault>
          }
        />
        <Route
          key={14}
          path="/integrations-page"
          element={
            <LayoutDefault>
              <IntegrationsPage />
            </LayoutDefault>
          }
        />
        <Route
          key={15}
          path="/logs-details"
          element={
            <LayoutDefault>
              <LogsDetails />
            </LayoutDefault>
          }
        />
        <Route
          key={9}
          path="/"
          element={
            <LayoutDefault>
              <Home />
            </LayoutDefault>
          }
        />
        <Route
          key={10}
          path="*"
          element={
            <LayoutDefault>
              <NotFound />
            </LayoutDefault>
          }
        />
        <Route
          key={11}
          path="/writeback"
          element={
            <LayoutDefault>
              <WriteBack />
            </LayoutDefault>
          }
        />
      </Switch>
      <Toast></Toast>
    </Router>
  );
}

export default Routes;
