import { ApiInterface } from "@brainz_group/webappframework";
import { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { BsFiletypeCsv, BsFiletypeXlsx, BsX } from "react-icons/bs";

import PageLoader from "../../../../components/Common/Loader";
import { showToast } from "../../../../components/Common/Toast";
import { downloadCSV, downloadXLSX } from "../../../../helpers/functions";
import { ModalGenericStyles } from "../../../../styles/modalsGeneric";

import "./styles.css";

type DataObj = {
  institutionId: string;
  logEntityType: string;
  integrationLogId: string;
  queryFilters: any;
}

interface Props {
  showModal: boolean;
  onClose: () => void;
  data: DataObj
}

export const ModalExportLogs = ({ showModal, onClose, data }: Props) => {
  const { institutionId, logEntityType, queryFilters, integrationLogId } = data
  const currentTab = "logs";

  const [isLoading, setLoading] = useState<boolean>(false);
  const [csvData, setCsvData] = useState<any>(null);
  const [xlsxData, setXlsxData] = useState<any>(null);

  const queryFiltersReplaced = () => {
    const value = queryFilters()
    if (value) {
      return value.replace('&', '?');
    }

    return value;
  }

  const convertToXlsx = (response) => {
    if (!response.data) return
    setXlsxData(response.data);
  };

  const exportLogsCsv = () => {
    setLoading(true);
    const url = `/Log/ExportLogsCsv/Integration/${institutionId}/${integrationLogId}/${logEntityType}${queryFiltersReplaced()}`;
    ApiInterface.get(url)
      .then((response) => {
        if (response.data) {
          setCsvData(response.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        showToast("error", "Algo deu errado, tente novamente mais tarde");
        console.error("Erro ao exportar logs em CSV. Erro => ", error);
        setLoading(false);
      });
  };

  const exportLogsXlsx = () => {
    setLoading(true);
    const url = `/Log/ExportLogsXlsx/Integration/${institutionId}/${integrationLogId}/${logEntityType}${queryFiltersReplaced()}`;

    ApiInterface.get(url, {
      responseType: "arraybuffer",
    })
      .then((response) => {
        if (response.data) {
          convertToXlsx(response);
        }
        setLoading(false);
      })
      .catch((error) => {
        showToast("error", "Algo deu errado, tente novamente mais tarde");
        console.error("Erro ao exportar logs em XLSX. Erro => ", error);
        setLoading(false);
      });
  };

  const renderModalContent = () => {
    if (isLoading) return <PageLoader />;

    if (csvData) {
      return (
        <section>
          <Row className="pb-2 ml-1">
            <Col>
              <span>
                O arquivo <strong>{currentTab}-data.csv</strong> foi gerado,
                clique no botão abaixo para fazer o download.
              </span>
            </Col>
          </Row>
          <Row className="pb-2 mt-3 ml-1">
            <Col>
              <Button
                onClick={() => downloadCSV(csvData, currentTab)}
                disabled={!csvData}
              >
                Download CSV
              </Button>
            </Col>
          </Row>
        </section>
      );
    }

    if (xlsxData) {
      return (
        <section>
          <Row className="pb-2 ml-1">
            <Col>
              <span>
                O arquivo <strong>{currentTab}-data.xlsx</strong> foi gerado,
                clique no botão abaixo para fazer o download.
              </span>
            </Col>
          </Row>
          <Row className="pb-2 mt-3 ml-1">
            <Col>
              <Button
                onClick={() => downloadXLSX(xlsxData, "logs")}
                disabled={!xlsxData}
              >
                Download XLSX
              </Button>
            </Col>
          </Row>
        </section>
      );
    } else {
      return (
        <>
          <Row className="pb-2 ml-1">
            <Col>
              <h3>Qual o formato de arquivo você deseja exportar?</h3>
            </Col>
          </Row>
          <Row
            className="card-register"
            onClick={() => exportLogsCsv()}
          >
            <BsFiletypeCsv className=" mr-3" color="#525252" size={24} />
            <h3>CSV</h3>
          </Row>
          <Row
            className="card-register"
            onClick={() => exportLogsXlsx()}
          >
            <BsFiletypeXlsx className=" mr-3" color="#525252" size={24} />
            <h3>XLSX</h3>
          </Row>
        </>
      );
    }
  };

  const handleClearStates = () => {
    setCsvData(null);
    setXlsxData(null);
    onClose()
  };

  return (
    <ModalGenericStyles
      onHide={handleClearStates}
      className="SyncModalRegister"
      show={showModal}
    >
      <Modal.Header>
        <Modal.Title className="ml-3">
          <h2>Exportar Logs</h2>
        </Modal.Title>
        <Button
          className="cursor-pointer"
          variant="white"
          onClick={handleClearStates}
        >
          <BsX color="var(--bs-modal-color)" size={28} />
        </Button>
      </Modal.Header>
      <Modal.Body>{renderModalContent()}</Modal.Body>
    </ModalGenericStyles>
  );
}
