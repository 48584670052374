import { useContext } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { BsCheck2, BsCheck2Circle } from "react-icons/bs";

import { ActiveModalIntegrationContext } from "../AutomaticIntegrationContexts/ActiveModalIntegrationContext";
import { ModalGAutomaticIntegrationSuccessStyles } from "./styles";

// 0-fechado
// 1-TypeIntegration
// 2- SQL-First Integration
// 3 - API-First Integration
// 4 - Cancel Integration
// 5 - Success Integration
// 6 - error First Integration

export default function ModalSuccessIntegrationNow() {
  const { setActiveModal, activeModal } = useContext(
    ActiveModalIntegrationContext
  );
  return (
    <ModalGAutomaticIntegrationSuccessStyles show={activeModal === 5}>
      <Modal.Body>
        <Row className="pb-2 ml-1">
          <Col>
            <BsCheck2Circle size={110} color="#008555" />
          </Col>
        </Row>
        <Row className="my-3" onClick={() => setActiveModal(0)}>
          <Col>
            <h2>Configuração concluída!</h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col>
            <h3>
              Suas integrações começarão no dia 00/00/0000.
              <br />
              Caso precise alterar alguma informação, basta fechar esta janela.
            </h3>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          className="sucess-button"
          onClick={() => setActiveModal(0)}
        >
          Fechar janela
          <BsCheck2 size={24} />
        </Button>
      </Modal.Footer>
    </ModalGAutomaticIntegrationSuccessStyles>
  );
}
