import pt from "date-fns/locale/pt";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Modal, Row, Button, Table } from "react-bootstrap";
import { registerLocale } from "react-datepicker";
import {
  BsCheck2,
  BsChevronRight,
  BsPlusCircle,
  BsTrash,
  BsX,
} from "react-icons/bs";

import CustomTooltip from "../../../../components/Common/CustomTooltip";
import { ActiveModalIntegrationContext } from "../../AutomaticIntegrationContexts/ActiveModalIntegrationContext";
import { ModelHeader } from "../ModalFirstSetupApi";
import { ModalGAutomaticIntegrationStyles, TableStylesG } from "../styles";

export default function ModalEditHeaders() {
  const {
    activeModal,
    setActiveModal,
    setUpdateIntegration,
    headerList,
    setNewHeaderList,
    setHeaderList,
  } = useContext(ActiveModalIntegrationContext);

  registerLocale("pt", pt);
  const [buttonDisableAddHeader, setButtonDisableAddHeader] =
    useState<boolean>(true);
  const [trashButtonDisable, setTrashButtonDisable] = useState<boolean>(true);
  const headerListRef = useRef(null);

  function removeHeader(index: any) {
    let newArr = [...headerList];
    newArr = newArr.filter((item, i) => i !== index);
    setHeaderList(newArr);
  }
  function handleHeaderKey(index: any, value: string) {
    const entities = [...headerList];
    const item = entities[index];
    item.key = value;
    entities[index] = item;
    setHeaderList(entities);
  }
  function handleHeaderValue(index: any, value: string) {
    const entities = [...headerList];
    const item = entities[index];
    item.value = value;
    entities[index] = item;
    setHeaderList(entities);
  }

  function AddNewHeader() {
    const entities = [...headerList];
    const newItem: ModelHeader = {
      key: "",
      value: "",
    };
    entities.push(newItem);
    setHeaderList(entities);
  }

  function save() {
    setNewHeaderList(headerList);
    setUpdateIntegration(true);
    setActiveModal(0);
  }

  function handleResetStates() {
    setHeaderList(headerListRef.current);
    setActiveModal(0);
  }

  useEffect(() => {
    if (activeModal === 18) {
      headerListRef.current = headerList;
    }
  }, [activeModal]);

  return (
    <ModalGAutomaticIntegrationStyles
      show={activeModal === 18}
      onHide={handleResetStates}
    >
      <Modal.Header>
        <Modal.Title>
          <h2>
            Integração via API <BsChevronRight size={18} /> Headers
          </h2>
        </Modal.Title>
        <Button
          className="cursor-pointer"
          variant="white"
          onClick={handleResetStates}
        >
          <BsX color="var(--bs-modal-color)" size={28} />
        </Button>
      </Modal.Header>
      <Modal.Body className="step-1">
        <Row>
          <Col>
            <h3>
              Os headers são opcionais, e você pode adicionar quantos precisar.
              <br />
              <br />
              Você pode editar o conteúdo de cada célula da tabela.
              <br />
              <br />
            </h3>
          </Col>
        </Row>
        <TableStylesG>
          <Table
            id="mytable"
            className="table-list ml-0 pl-0"
            borderless
            hover
            responsive
          >
            <thead>
              <tr className="title-table" style={{ textAlignLast: "start" }}>
                <th>Key (chave)</th>
                <th>Value (valor)</th>
                <th></th>
              </tr>
            </thead>
            <tbody id="mytable">
              {headerList?.map((item: any, index: any) => {
                return (
                  <tr id="tr-linha" className="table-values" key={index}>
                    <td>
                      <input
                        className="input-table"
                        placeholder=" Insira a chave"
                        style={{ textAlignLast: "start" }}
                        value={item.key}
                        onChange={(e) => {
                          handleHeaderKey(index, e.target.value);
                        }}
                      />
                    </td>
                    <td>
                      <input
                        className="input-table"
                        placeholder=" Insira o valor"
                        style={{ textAlignLast: "start" }}
                        value={item.value}
                        onChange={(e) =>
                          handleHeaderValue(index, e.target.value)
                        }
                      />
                    </td>
                    {trashButtonDisable === true && (
                      <td
                        onClick={() => removeHeader(index)}
                        className="cursor-pointer trash"
                      >
                        <CustomTooltip
                          type="custom-icon"
                          text="Excluir"
                          placement="bottom"
                          customIcon={<BsTrash color="#A56300" size="24" />}
                        />
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </TableStylesG>

        <Button className="button-add-header" onClick={AddNewHeader}>
          <BsPlusCircle size={24} color="#2155BA" />
          Adicionar outro header
        </Button>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          className="cancel-operation ml-4"
          onClick={handleResetStates}
        >
          <BsX className=" mr-2" color="#A56300" size={24} />
          Cancelar
        </Button>

        <Button
          variant="primary"
          className="continue-button"
          onClick={() => save()}
        >
          Salvar
          <BsCheck2 color="#fff" size={22} />
        </Button>
      </Modal.Footer>
    </ModalGAutomaticIntegrationStyles>
  );
}
