import { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BsCheck2, BsX, BsXCircle } from "react-icons/bs";
import Cookies from "universal-cookie";

import BrainzPagination, {
  PaginationProps,
} from "../../../components/Common/BrainzPagination/BrainzPagination";
import Search from "../../../components/Common/Input/Search";
import NoResults from "../../../components/Common/NoResults";
import { showToast } from "../../../components/Common/Toast";
import { ProfileContext } from "../../../contexts/ProfileContext";
import { handleGetPageSize } from "../../../helpers/functions";
import { BrainzUserTeamPayload } from "../../../interfaces/BrainzUserTeamPayload";
import { BrainzUserTeamViewModel } from "../../../interfaces/BrainzUserTeamViewModel";
import { BrainzUserViewModel } from "../../../interfaces/BrainzUserViewModel";
import UserApiInterface from "../../../services/UserApiInterface";
import UserTeamApiInterface from "../../../services/UserTeamApiInterface";
import { TableSyncDataStyles } from "../../../styles/generic";
import { ModalGenericStyles } from "../../../styles/modalsGeneric";
import { ActiveModalContext } from "../SyncDataContexts/ActiveModalContext";

interface AddUserTeamProps {
  showAddUsers: boolean;
  onHide: () => void;
  userType: number;
  setUserType: React.Dispatch<React.SetStateAction<number>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ModalAddUserTeam(props: AddUserTeamProps) {
  const cookie = new Cookies();
  const institutionId = cookie.get(process.env.REACT_APP_BRAINZ_INSTITUTIONID!);
  const { listRefresh, setListRefresh, identifierTeam } =
    useContext(ActiveModalContext);

  const { configurationProfile } = useContext(ProfileContext);
  const [queryPaginationParamsAddUser, setQueryPaginationParamsAddUser] =
    useState(`?page=1&pageSize=${handleGetPageSize()}`);
  const [counterUser, setCounterUser] = useState(0);
  const [users, setUsers] = useState<Array<BrainzUserViewModel> | undefined>();
  const [searchFilterAddUser, setSearchFilterAddUser] = useState<string>("");
  const [pagingAddUser, setPagingAddUser] = useState<
    PaginationProps | undefined
  >();
  const [buttonDisable, setButtonDisable] = useState<boolean>(false);
  const [t] = useTranslation("common");
  const [idUser, setIdUser] = useState("");
  const [selectedUser, setSelectedUser] = useState<
    BrainzUserTeamViewModel | undefined
  >();

  function handleAddUserOnClick(user1: BrainzUserViewModel) {
    const user2: BrainzUserTeamViewModel = {
      identifier: user1.identifier,
      displayName: user1.displayName,
      userType: user1.type,
      id: user1.id,
    };
    setIdUser(user2.identifier);
    setSelectedUser(user2);
  }

  function queryFiltersAddUser() {
    let query = "";
    if (searchFilterAddUser !== "")
      query = query += "&search=" + searchFilterAddUser;
    return query;
  }

  function queryPaginationAddUser() {
    let result: string;
    if (queryPaginationParamsAddUser)
      result = `${queryPaginationParamsAddUser}`;
    else result = "";
    return result;
  }

  function listUsers() {
    if (queryPaginationParamsAddUser) {
      UserApiInterface.get(
        `/User/${institutionId}/List${queryPaginationAddUser()}${queryFiltersAddUser()}`
      )
        .then((response) => {
          setPagingAddUser(response.data.paging);
          setCounterUser(response.data.paging.totalRecords);
          setUsers(response.data.result);
        })
        .catch((error) => {
          showToast("error", "Algo deu errado, tente novamente mais tarde");
          console.error("Erro => ", error);
        });
    }
  }

  function gotoPageOneAddUser() {
    if (pagingAddUser) {
      const paginationProps: PaginationProps = {
        currentRecord: pagingAddUser.currentRecord,
        currentPage: 1,
        pageSize: pagingAddUser.pageSize,
        recordsOnPage: pagingAddUser.recordsOnPage,
        totalRecords: pagingAddUser.totalRecords,
        totalPages: pagingAddUser.totalPages,
      };
      setPagingAddUser(paginationProps);
      setQueryPaginationParamsAddUser(`?page=1&pageSize=${handleGetPageSize()}`);
    }
  }

  function handlerSaveUserInTeam() {
    props.onHide();
    props.setLoading(true);
    setSearchFilterAddUser("");

    const payload: Array<BrainzUserTeamPayload> = [
      {
        teamIdentifier: identifierTeam,
        userIdentifier: idUser,
      },
    ];

    UserTeamApiInterface.post(
      `/UserTeam/${institutionId}/${props.userType}/Insert`,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          const errors = response.data.result[0].errors;
          if (!errors || errors.length === 0) {
            showToast("success", "Pessoa adicionada com sucesso.");
            setListRefresh(listRefresh + 1);
            props.setLoading(false);
          } else {
            setButtonDisable(false);
            props.setLoading(false);
            showToast("error", t("errors." + errors[0].code));
            console.error(
              "Erro ao tentar salvar usuário em turma. Erro => ",
              response
            );
          }
        } else {
          setButtonDisable(false);
          showToast("error", "Algo deu errado, tente novamente mais tarde");
          props.setLoading(false);
          console.error(
            "Erro ao tentar salvar usuário em turma. Erro => ",
            response
          );
        }
      })
      .catch((error) => {
        setButtonDisable(false);
        showToast("error", "Algo deu errado, tente novamente mais tarde");
        console.error(
          "Erro ao tentar salvar usuário em turma. Erro => ",
          error
        );
        props.setLoading(false);
      });
  }

  function getNameByUserType(userType: number) {
    switch (userType) {
      case 1:
        return "Administrativo";
      case 2:
        return "Assistente";
      case 3:
        return "Coordenador";
      case 4:
        return "Aluno";
      case 5:
        return "Professor";
      default:
        return "Usuário";
    }
  }

  useEffect(() => {
    setTimeout(function () {
      gotoPageOneAddUser();
    }, 2000);
  }, []);

  useEffect(() => {
    listUsers();
  }, [queryPaginationParamsAddUser, searchFilterAddUser]);

  return (
    <>
      {configurationProfile && (
        <ModalGenericStyles
          show={props.showAddUsers}
          onHide={props.onHide}
          className="ModalAddUserTeam"
        >
          <Modal.Header>
            <Modal.Title>
              <h2>Adicionar pessoa na turma</h2>
            </Modal.Title>
            <Button
              className="cursor-pointer"
              variant="white"
              onClick={() => props.onHide()}
            >
              <BsX color="var(--bs-modal-color)" size={28} />
            </Button>
          </Modal.Header>
          <Modal.Body className="mb-2">
            <Row className="mb-3">
              <Col>
                <h3>
                  <strong>Qual o papel desta pessoa?</strong>
                </h3>
              </Col>
            </Row>
            <Row className="">
              {configurationProfile?.map((item: any, index: any) => {
                return (
                  <Col md={3} key={item?.userType}>
                    <Form.Check
                      className="button-radio"
                      type="radio"
                      label={getNameByUserType(item.userType)}
                      checked={item.userType === props.userType}
                      onChange={() => props.setUserType(item.userType)}
                    />
                  </Col>
                );
              })}
            </Row>
            <Row className="mt-4 mb-3">
              <Col>
                <h4>
                  <strong>Busque a pessoa que deseja adicionar</strong>
                </h4>
              </Col>
            </Row>
            <Row>
              <Col
                className={searchFilterAddUser !== "" ? "col-10 " : "col-12"}
              >
                <Search
                  setSearchFilter={setSearchFilterAddUser}
                  searchFilter={searchFilterAddUser}
                  placeholder="Buscar por Tipo, Identificador, Nome ou E-mail"
                />
              </Col>
              {searchFilterAddUser !== "" ? (
                <Col>
                  <Button
                    className="clear-filter mx-0"
                    onClick={() => setSearchFilterAddUser("")}
                  >
                    <BsXCircle size={24} color="#A56300" />
                  </Button>
                </Col>
              ) : (
                ""
              )}
            </Row>
            {searchFilterAddUser === "" ? (
              ""
            ) : (
              <>
                {counterUser < 1 ? (
                  <></>
                ) : (
                  <Row className="ml-1 mt-2">
                    <h4>
                      Encontramos{" "}
                      {counterUser > 1 ? (
                        <>{counterUser} pessoas</>
                      ) : (
                        <>{counterUser} pessoa</>
                      )}{" "}
                    </h4>
                  </Row>
                )}

                <Row className="w-100">
                  {users && users.length > 0 ? (
                    <>
                      <TableSyncDataStyles>
                        <Table
                          id="mytable"
                          className="mt-3 table-list"
                          borderless
                          hover
                          responsive
                        >
                          <thead>
                            <tr
                              className="title-table"
                              style={{ textAlignLast: "start" }}
                            >
                              <th>Nome</th>
                              <th>ID</th>
                              <th>E-mail</th>
                            </tr>
                          </thead>
                          <tbody id="mytable">
                            {users.map((item, index) => {
                              return (
                                <tr
                                  id="tr-linha"
                                  key={index}
                                  className={
                                    item?.id === selectedUser?.id
                                      ? "table-values line-selected cursor-pointer font-weight-bold"
                                      : "table-values cursor-pointer "
                                  }
                                  onClick={() => {
                                    handleAddUserOnClick(item);
                                  }}
                                >
                                  <td style={{ textAlignLast: "start" }}>
                                    {item.displayName}
                                  </td>
                                  <td style={{ textAlignLast: "start" }}>
                                    {item.identifier}
                                  </td>
                                  <td style={{ textAlignLast: "start" }}>
                                    {item.email}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                        {pagingAddUser && pagingAddUser.totalPages > 1 && (
                          <BrainzPagination
                            setQueryParams={setQueryPaginationParamsAddUser}
                            paging={pagingAddUser}
                          />
                        )}
                      </TableSyncDataStyles>
                    </>
                  ) : (
                    <NoResults
                      clearFilterFunction={() => setSearchFilterAddUser("")}
                    />
                  )}
                </Row>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button className="cancel-operation" onClick={() => props.onHide()}>
              <BsX size={22} />
              Descartar registro
            </Button>

            <Button
              variant="primary"
              className="continue-operation"
              disabled={props.userType === 0}
              onClick={handlerSaveUserInTeam}
            >
              Salvar Pessoa
              <BsCheck2 size={22} />
            </Button>
          </Modal.Footer>
        </ModalGenericStyles>
      )}
    </>
  );
}
