import pt from "date-fns/locale/pt";
import React, { useContext, useEffect } from "react";
import { Col, Modal, Row, Form, Button } from "react-bootstrap";
import { registerLocale } from "react-datepicker";
import { BsCheck2, BsChevronRight, BsX } from "react-icons/bs";

import { requiredFieldMsg } from "../../../helpers/messagesValidation";
import {
  feedBack,
  isInvalidFeedBack,
  useValidation,
} from "../../../hooks/Validation";
import { ActiveModalIntegrationContext } from "../AutomaticIntegrationContexts/ActiveModalIntegrationContext";
import { ModalGAutomaticIntegrationStyles } from "./styles";

export default function ModalEditUrlApi() {
  const {
    activeModal,
    setActiveModal,
    setUpdateIntegration,
    urlEndpoint,
    setNewUrlEndpoint,
  } = useContext(ActiveModalIntegrationContext);

  registerLocale("pt", pt);
  const [
    validationForm,
    setValidationFormField,
    validationErrors,
    setNewValidationErrors,
  ] = useValidation();

  useEffect(() => {
    updateValidation();
    function updateValidation() {
      validationForm.current = {
        urlEndpoint,
      };
    }
  }, [urlEndpoint]);

  function findErrorsUrlEndpoint() {
    const errors: any = {};

    if (validationForm.current) {
      if (
        !validationForm.current.urlEndpoint ||
        validationForm.current.urlEndpoint === ""
      )
        errors.urlEndpoint = requiredFieldMsg;
      else errors.urlEndpoint = undefined;
    }
    const result = setNewValidationErrors(errors);
    return result;
  }

  function save() {
    const existErrorUrlEnpoint = findErrorsUrlEndpoint();

    if (!existErrorUrlEnpoint) {
      setUpdateIntegration(true);
      setActiveModal(0);
    }
  }

  return (
    <ModalGAutomaticIntegrationStyles
      show={activeModal === 17}
      onHide={() => setActiveModal(0)}
    >
      <Modal.Header>
        <Modal.Title>
          <h2>
            Integração <BsChevronRight size={18} /> URL do Endpoint
          </h2>
        </Modal.Title>
        <Button
          className="cursor-pointer"
          variant="white"
          onClick={() => setActiveModal(0)}
        >
          <BsX color="var(--bs-modal-color)" size={28} />
        </Button>
      </Modal.Header>
      <Modal.Body className="">
        <Row className="pb-4">
          <Col>Insira a URL para acesso ao Endpoint</Col>
        </Row>

        <Form.Group as={Row}>
          <Form.Label column sm={12}>
            <h4 className="mb-0">URL do endpoint</h4>
          </Form.Label>
          <Col sm={12}>
            <Form.Control
              size="sm"
              defaultValue={urlEndpoint}
              placeholder="Copie e cole a URL do endpoint"
              isInvalid={isInvalidFeedBack("urlEndpoint", validationErrors)}
              onChange={(e) => [
                setNewUrlEndpoint(e.target.value),
                setValidationFormField("urlEndpoint", e.target.value),
              ]}
            />
            <Form.Control.Feedback type="invalid">
              {feedBack("urlEndpoint", validationErrors)}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button className="cancel-operation" onClick={() => setActiveModal(0)}>
          <BsX className=" mr-2" color="#A56300" size={24} />
          Cancelar
        </Button>
        <Button
          variant="primary"
          className="continue-button"
          onClick={() => save()}
        >
          Salvar
          <BsCheck2 color="#fff" size={22} />
        </Button>
      </Modal.Footer>
    </ModalGAutomaticIntegrationStyles>
  );
}
