import { useState, useEffect } from "react";
import Cookies from "universal-cookie";

import FirstConfiguration from "../../components/Common/FirstConfiguration";
import PageLoader from "../../components/Common/Loader";
import ApiInterface from "../../services/ApiInterface";
import ModalInsertion from "./ModalInsertion";
import ShowConfig from "./ShowConfig";

const WriteBack = () => {
  const cookie = new Cookies();
  const institutionId = cookie.get(process.env.REACT_APP_BRAINZ_INSTITUTIONID!);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);
  const [showModalInsertion, setShowModalInsertion] = useState<boolean>(false);

  const [reloadPage, setReloadPage] = useState<boolean>(false);

  const handleFetchApi = () => {
    setIsLoading(true);

    ApiInterface.get(`/WriteBack/${institutionId}/Get`)
      .then((response) => {
        const result = response.data.result;

        if (result) {
          setData(result);
        }

        setReloadPage(false);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(
          "Erro ao tentar buscar configurações do WriteBack. Error => ",
          error
        );
        setIsLoading(false);
      });
  };

  useEffect(() => {
    handleFetchApi();
  }, [reloadPage]);

  if (isLoading) return <PageLoader />;

  if (data) {
    return (
      <ShowConfig data={data} handleReloadPage={() => setReloadPage(true)} />
    );
  }

  return (
    <>
      <FirstConfiguration
        headingText="Você ainda não configurou a integração"
        description="Depois de fazer a primeira configuração, você poderá alterá-las nesta tela."
        handleClick={() => setShowModalInsertion(true)}
        buttonText="Primeira configuração"
      />

      <ModalInsertion
        showModal={showModalInsertion}
        handleClose={() => setShowModalInsertion(false)}
        titleModal="Primeira Configuração"
        isUpdate={false}
        handleReloadPage={() => setReloadPage(true)}
        institutionId={institutionId}
      />
    </>
  );
};

export default WriteBack;
