import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    font-family: "Lato", "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  }
  
  .App {
    min-height: 100vh;
    background-color: #fbfbff;
    overflow-x: hidden!important;
  }

  .container-header {
    padding-right: 32px !important;
    padding-left: 32px !important;
  }

  .container-header .select-search {
    height: 40px;
  }

  .container-header .select-search input {
    font-size: 14px;

  }

  .loader-background {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1000;
    width: 100%;
    height: 100%;
    background-color: #8d8d8d71;
    display: flex;
    align-items: center;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .h-100.container-fluid {
    min-height: 50px;
  }

  .provisionador-title {
    font-size: 30px;
    padding: 20px 0px;
  }

  .provisionador-subtitle {
    font-weight: bold;
    padding: 20px 0px;
  }

  .container-fixed-height {
    height: calc(100vh - 111px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  .container-fixed-height-home {
    height: calc(100vh - 51px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  .container-fixed-height-portal {
    min-height: calc(100vh - 53px);
  }

  .react-datepicker-wrapper {
    display: block;
    width: 100%;
  }

  .react-datepicker__input-container>input {
    width: 100%;
    border: 1px solid #ced4da;
    height: calc(1.5em + .5rem + 2px);
    padding: .25rem .5rem;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: .2rem;
  }

  .react-datepicker__input-container>input:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem #007bff40!important;
  }


  .sidemenu-container {
    overflow-y: hidden;
    height: calc(100% - 72px);
    overflow-y: auto;
    position: absolute;
    right: 0;
    top: 72px;
    width: 260px;
    background: #fafafa;
    padding: 0px 15px;
    padding-bottom: 20px;
    -webkit-box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.31);
    -moz-box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.31);
    box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.31);
  }
  
  .sidemenu-active {
    color: #6468c8;
    font-weight: 700!important;
    display: block;
    
  }

  
  .sidemenu-link {
    color: #6468c8;
    font-weight: 700;
    display: block;
    margin: 15px 0px;
  }

  .sidemenu-link-sec {
    color: rgb(71, 71, 71);
    font-weight: 500;
    display: block;
    margin: 0;
  }


  .sidemenu-divider {
    height: 0;
    border-top: 1px solid rgb(189, 189, 189);
    margin: 30px 0px;
    width: 100%;
  }

  .sidemenu-dropdown {
    position: relative;
    margin-bottom: 5px;
    font-weight: 500;
  }

  .sidemenu-dropdown-links-container {
    position: relative;
    margin-bottom: 15px;
  }

  .sidemenu-dropdown-links-container>.sidemenu-link-sec {
    color: rgb(114, 114, 114);
    padding-left: 10px;
    margin: 10px 0px;
  }

  .orange {
    color: orange;
  }

  .link {
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
  }

  .sidemenu-dropdown-chevron {
    float: right;
    margin-top: 5px;
    color: #6468c8;
  }

  .header-row{
    min-height: 60px;
  }
  header{
    font-family: 'Lato', sans-serif;
    background-color: #F5F5F5;
    color: #FBFBFF;
    min-height: 60px;
  }

  .header-search{
    min-height: 44px;
    border-top-left-radius: 22px!important;
    border-bottom-left-radius: 22px!important;
    padding-left: 30px!important;
  }

  .provisionador-logo{
    padding: 10px;
    width: 367px;
  }
  .header-search-button{
    background-color: #f3711a!important;
    min-height: 45px;
    border-top-right-radius: 12px!important;
    border-bottom-right-radius: 12px!important
  }
  .mobile-title{
    margin-top: auto!important;
    margin-bottom: auto!important;
    font-weight:lighter;
    max-width: 180px;
  }
  .home-icon-mobile{
    max-height: 12px;
  }
  .header-icons{
    max-width: 130px!important;
  }

  .sidebar{
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100px;
    padding: 24px 0px;
    z-index: 999;
    transition: all 0.5s ease;
    background: #F5F5F5;
    box-shadow: 1px 0px 6px rgba(81, 79, 78, 0.2);

  }
  .sidebar-margin{
    margin-left: 106px;
    transition: all 0.5s ease;
    width: calc(100vw - 106px);
  }
  .sidebar-margin.expanded{
    margin-left: 252px;
    width: calc(100vw - 252px);
  }
  .sidebar.open{
    width: 252px;
  }
  .sidebar .logo-details{
    height: 40px;
    display: block;
    clear: both;
    width: 100%;
    text-align: center;
    position: relative;
  }
  
  .sidebar .logo-details #btn{
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    font-size: 22px;
    transition: all 0.4s ease;
    font-size: 23px;
    text-align: center;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .sidebar.open .logo-details #btn{
    text-align: right;
  }
  .sidebar i{
    color: #424040;
    height: 60px;
    min-width: 22px;
    font-size: 28px;
    text-align: center;
    line-height: 60px;
    margin-right: 16px;
  }
  .sidebar .nav-list{
    margin-top: 24px;
    padding: 0;
    height: 100%;
  }
  .sidebar.open .nav-list{
    padding: 0;
  }
  .sidebar li{
    position: relative;
    margin: 0;
    list-style: none;
  }
  .sidebar.open li.nav-item > span + a,
  .sidebar.open li.nav-item .subnav-list .subnav-item > span + a {
    padding: 0 0 0 32px;
  }
  .sidebar li .tooltip{
    position: absolute;
    top: -20px;
    left: calc(100% + 15px);
    z-index: 3;
    background: #424040;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
    padding: 6px 12px;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 400;
    opacity: 0;
    white-space: nowrap;
    pointer-events: none;
    transition: 0s;
    color: #fff;
  }
  .sidemenu-active:before {
    content: "";
    display: block;
    height: 22px;
    width: 4px;
    position: absolute;
    left: 0;
    top: 13px;
    border-radius: 0 8px 8px 0;
    background: #2155BA;
  }

  .sidemenu-active svg path {
    fill: #2155BA;
  }
  
  .sidebar li.subnav-item:hover > span.tooltip,
  .sidebar li:not(.item-open).nav-item:hover > span.tooltip{
    opacity: 1;
    pointer-events: auto;
    transition: all 0.4s ease;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .sidebar li:not(.item-open).subnav-item:hover,
  .sidebar li:not(.item-open).nav-item:hover {
    background: rgba(33, 85, 186, 0.2);
  }
  
  .sidebar li:not(.item-open).subnav-item:focus,
  .sidebar li:not(.item-open).nav-item:focus {
    background: transparent;
    border: 1px solid #0F7EAA;
  }
  
  .sidebar.open li .tooltip{
    display: none;
  }

  .sidebar.open li .icone {
    display: inline-block;
    margin: 0;
  }
  .sidebar.open li.nav-item .icone {
    margin-right: 16px;
  }
  
  .sidebar li a{
    cursor: pointer;
    position: relative;
    display: block;
    height: 48px;
    width: 100%;
    align-items: center;
    text-decoration: none;
    transition: all 0.4s ease;
    background: transparent;
  }

  .sidebar li a i.icone {
    width: 21px;
    height: 21px;
    margin: 0 auto;
    display: block;
    padding: 0;
  }
  
  .sidebar li a .links_name{
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    color: #525252;
    white-space: nowrap;
    opacity: 0;
    pointer-events: none;
    transition: 0.4s;
  }
  .sidebar.open li a .links_name{
    opacity: 1;
    pointer-events: auto;
  }
  
  .sidebar li i{
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    border-radius: 12px;
  }
  .sidebar li.profile{
    position: fixed;
    height: 124px;
    width: 80px;
    left: 0;
    text-align: center;
    bottom: -8px;
    background: transparent;
    transition: all 0.5s ease;
    overflow: hidden;
  }
  .sidebar li.profile button {
    border: none;
    display: inline-block;
    background: transparent;
    width: 100%;
  }
  .sidebar li.profile button i {
    margin: 0;
  }
  .sidebar.open li.profile button i {
    margin-right: 16px;
  }
  
  .sidebar li.profile button:hover path {
    fill: #4447a7;
  }
  .sidebar.open li.profile {
    width: 252px;
  }
  .sidebar li #btn-sidebar .icone svg{
    -webkit-transform: rotate(180deg);
    transform:rotate(180deg);
  }

  .sidebar.open li #btn-sidebar .icone svg{
    -webkit-transform: rotate(360deg);
    transform:rotate(360deg);
  }

  .sidebar.open li #btn-sidebar {
    color: #6468c8;
  }
  
  .sidebar li.item-open{
    background: #ECECEC;
    border-top: 0.5px solid #A6A6A6;
    border-bottom: 0.5px solid #A6A6A6;
  }
  .sidebar li .subnav-list {
    display: none;
  }
  .sidebar li.item-open .subnav-list {
    border-top: 0.5px solid #d8d7d7;
    display: inline-block;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .sidebar li.item-open .subnav-list li {
    margin: 0;
  }
  
  .home-section{
    position: relative;
    background: #E4E9F7;
    min-height: 100vh;
    top: 0;
    left: 78px;
    width: calc(100% - 78px);
    transition: all 0.5s ease;
    z-index: 2;
  }
  .sidebar.open ~ .home-section{
    left: 250px;
    width: calc(100% - 250px);
  }
  .home-section .text{
    display: inline-block;
    color: #11101d;
    font-size: 25px;
    font-weight: 500;
    margin: 18px
  }
  @media (max-width: 420px) {
    .sidebar li .tooltip{
      display: none;
    }
  }
  .btn-primary{
    color: #fff;
    background-color: #2155BA;
    border-color: #2155BA;
  }
  .form-control{
    height:48px;
    border-radius:8px;
  }
  .modal-dialog{
    margin: 50px auto;
    overflow-y: initial!important;
    width: fit-content;
    max-width: fit-content;
    min-width: 400px;
  }
  .clear-filter{
    width:48px;
    height:48px;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #A56300;
    background-color: #fff ;
    border: 1px solid #A56300;
    box-sizing: border-box;
    border-radius: 8px;
    &:focus{
            background: none!important;
            border: 1px solid #A56300!important;
            color: #A56300!important;
            box-shadow:none!important;
        }
        &:active{
            background: none!important;
            color: #A56300!important;
        }
        &:hover {
            background: #A56300!important;
            color: #fff!important;  
            border: 1px solid #fff!important;
            .bi-x-circle{
            fill: #fff!important;  
            }
            svg{
              color:#fff!important
            }
        }
        &:disabled{
            background: none!important;
            border:#A56300!important;;
        }
  } 

  button {
    &:disabled {
      cursor: not-allowed;
    }
  }

.search-filter input{
  background-color:#F5F5F5!important;
  border: 1px solid #E4E3E3;
}

.headingStickyPageHome,
.headingStickySubPagesIA,
.headingStickySubPagesLogs {
  position: sticky;
  top: 0;
  z-index: 22 !important;
  background-color: #fbfbff;

  padding: 1rem 0;
}

@media screen and (max-width: 1350px) {
    .headingStickySubPagesLogs {
      position: initial !important;
    }
  }

.headingStickySubPagesIA {
    .row {
      margin: 0 15px !important;
    }
  }
  
.headingStickySubPagesLogs {
    .row {
      margin: 0;
      background-color: #fbfbff;
    }
  }



.navbar-profile {
  .nav-link {
    display: flex;
    align-items: center;
  }
  .customTooltipButton  {
    height: 24px;
    margin: 0;
  }
}
`;

export default GlobalStyles;
