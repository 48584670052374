import { Col, Modal, Row } from "react-bootstrap";
import styled from "styled-components";

export const FiltersStyleComponentUser = styled(Row)`
  .search-filter-status {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #514f4e;

    Button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 8px;
      width: 113px;
      height: 48px;
      background: #f5f5f5;
      border: 1px solid #e4e3e3;
      box-sizing: border-box;
      border-radius: 8px;
      color: #6f6f6f;

      &:focus {
        background: #f5f5f5 !important;
        border: 0.14vmin solid #0c8292 !important;
        color: #6f6f6f !important;
        box-shadow: none !important;
      }

      &:active {
        background: #f5f5f5 !important;
        color: #6f6f6f !important;
      }
      &:hover {
        background: #f5f5f5 !important;
        color: #6f6f6f !important;
      }
      &:disabled {
        background-color: #f5f5f5 !important;
        color: #6f6f6f !important;
        border: 1px solid #e4e3e3;
      }
    }
    .dropdown-toggle::after {
      display: none;
    }
  }
  margin-top: 15px;

  .filter-name {
    background: #f5f5f5;
    border: 1px solid #e4e3e3;
    box-sizing: border-box;
    border-radius: 8px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin: 0px 0px;
    height: 48px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: #525252;
  }
  .search-filter-usertype {
    //font-family: roboto;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #514f4e;

    Button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 8px;
      width: 171px;
      height: 48px;
      background: #f5f5f5;
      border: 1px solid #e4e3e3;
      box-sizing: border-box;
      border-radius: 8px;
      color: #6f6f6f;
      &:focus {
        background: #f5f5f5 !important;
        border: 0.14vmin solid #0c8292 !important;
        color: #6f6f6f !important;
        box-shadow: none !important;
      }

      &:active {
        background: #f5f5f5 !important;
        color: #6f6f6f !important;
      }
      &:hover {
        background: #f5f5f5 !important;
        color: #6f6f6f !important;
      }
      &:disabled {
        background-color: #f5f5f5 !important;
        color: #6f6f6f !important;
        border: 1px solid #e4e3e3;
      }
    }
    .dropdown-toggle::after {
      display: none;
    }
  }

  .select-search {
    border-color: rgb(228, 227, 227) !important;
  }
`;

export const ModalDetailSyncDataStyle = styled(Modal)`
  font-style: normal;

  .info-edit {
    margin: auto;
    text-align: justify;
    border: 1px solid #a56300;
    box-sizing: border-box;
    border-radius: 8px;
    width: 98%;
    p {
      margin: 10px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #6f6f6f;
    }
  }

  input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 12px 8px;
    height: 48px;
    border: 1px solid #a6a6a6;
    box-sizing: border-box;
    border-radius: 8px;
    &:focus {
      box-shadow: none !important;
    }
    &:active {
      box-shadow: none !important;
    }
  }
  .modal-dialog {
    padding-left: 30px;
    margin: 0px auto;
    overflow-y: initial !important;
    height: 100% !important;
  }
  .modal-content {
    width: 880px !important;
    height: 100% !important;
  }
  .modal-body {
    overflow-y: auto !important;
    overflow-x: clip !important;
    max-height: calc(100vh-100px);

    .card-history {
      width: 100%;
      height: auto;
      background: #d8ebe4;
      border-radius: 8px;
      p {
        color: #363636;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
      }
    }
    Label {
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #6f6f6f;
    }
  }

  h2 {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #424040;
  }
  h3 {
    margin-left: 25px;
    margin-bottom: 0px;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }
  h4 {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #424040;
  }
  .button-edit {
    float: right;
    height: 48px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin: 0px 16px;
    color: #2155ba;
    background: #fff;
    border: 1px solid #2155ba;
    box-sizing: border-box;
    border-radius: 8px;
    &:active {
      color: #2155ba !important;
      background: #fff !important;
    }
    &:focus {
      box-shadow: none !important;
    }
    &:active {
      box-shadow: none !important;
    }
  }
  .button-cancel-edit {
    float: left;
    height: 48px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin: 0px 16px;
    color: #a56300;
    background: #fff;
    border: 1px solid #a56300;
    box-sizing: border-box;
    border-radius: 8px;
    &:active {
      color: #a56300 !important;
      background: #fff !important;
    }
    &:focus {
      background: #fff !important;
      border: 0.14vmin solid #a56300 !important;
      color: #a56300 !important;
      box-shadow: none !important;
    }
    &:active {
      box-shadow: none !important;
    }
  }

  .button-save {
    float: right;
    height: 48px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin: 0px 16px;
    color: #fff;
    background: #008555;
    border: 1px solid #008555;
    box-sizing: border-box;
    border-radius: 8px;
    &:disabled {
      background: #a6a6a6 !important;
      border: 1px solid #a6a6a6 !important;
    }
    &:focus {
      box-shadow: none !important;
    }
    &:active {
      box-shadow: none !important;
    }
  }
`;

export const TableSyncDataStyles = styled(Col)`
  padding-right: 0px;

  .border-radius-left {
    border-top-left-radius: 10px;
  }
  .border-radius-right {
    border-top-right-radius: 10px;
  }
  .title-table {
    height: 44px;
  }
  .table-values {
    height: 56px;
  }

  th {
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #424040;
    background-color: #e4e3e3;
  }
  td {
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #525252;
    vertical-align: middle;

    div.action-item {
      background: unset;
      border: unset;
      box-shadow: unset;
      outline: unset;
    }
  }
  .bg-error-color {
    background-color: #fdf1de;
    color: #a56300;
  }
  .success-status {
    background-color: #e5f4ef;
  }
  .error-status {
    background-color: #fdf1de;
  }
  .line-selected {
    background-color: #e5f4ef;
  }
`;
