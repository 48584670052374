import { useContext, useEffect, useState } from "react";
import { Form, Row, Col, Nav, Button, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BsExclamationCircle, BsTrash, BsXCircle } from "react-icons/bs";
import styled from "styled-components";
import Cookies from "universal-cookie";

import BrainzPagination, {
  PaginationProps,
} from "../../components/Common/BrainzPagination/BrainzPagination";
import CustomTooltip from "../../components/Common/CustomTooltip";
import Search from "../../components/Common/Input/Search";
import PageLoader from "../../components/Common/Loader";
import ConfirmModal from "../../components/Common/Modals/ConfirmModal";
import { showToast } from "../../components/Common/Toast";
import { handleGetPageSize } from "../../helpers/functions";
import { BrainzUserTeamPayload } from "../../interfaces/BrainzUserTeamPayload";
import UserTeamApiInterface from "../../services/UserTeamApiInterface";
import {
  FiltersStyleComponentUser,
  TableSyncDataStyles,
} from "../../styles/generic";
import { ActiveModalContext } from "./SyncDataContexts/ActiveModalContext";

function ComponentUsersTeam() {
  const { listRefresh, identifierTeam } = useContext(ActiveModalContext);

  const cookie = new Cookies();
  const [t] = useTranslation("common");
  const institutionId = cookie.get(process.env.REACT_APP_BRAINZ_INSTITUTIONID!);
  const [loading, setLoading] = useState(true);
  const [searchFilterOwners, setSearchFilterOwners] = useState<string>("");
  const [searchFilterMembers, setSearchFilterMembers] = useState<string>("");
  const [searchFilterAdms, setSearchFilterAdms] = useState<string>("");
  const [searchFilterCoordinators, setSearchFilterCoordinators] =
    useState<string>("");
  const [
    queryPaginationParamsUserTeamOwners,
    setQueryPaginationParamsUserTeamOwners,
  ] = useState(`?page=1&pageSize=${handleGetPageSize()}`);
  const [
    queryPaginationParamsUserTeamMembers,
    setQueryPaginationParamsUserTeamMembers,
  ] = useState(`?page=1&pageSize=${handleGetPageSize()}`);
  const [
    queryPaginationParamsUserTeamAdms,
    setQueryPaginationParamsUserTeamAdms,
  ] = useState(`?page=1&pageSize=${handleGetPageSize()}`);
  const [
    queryPaginationParamsUserTeamCoordinators,
    setQueryPaginationParamsUserTeamCoordinators,
  ] = useState(`?page=1&pageSize=${handleGetPageSize()}`);
  const [pagingUserTeamOwners, setPagingUserTeamOwners] = useState<
    PaginationProps | undefined
  >();
  const [pagingUserTeamMembers, setPagingUserTeamMembers] = useState<
    PaginationProps | undefined
  >();
  const [pagingUserTeamAdms, setPagingUserTeamAdms] = useState<
    PaginationProps | undefined
  >();
  const [pagingUserTeamCoordinators, setPagingUserTeamCoordinators] = useState<
    PaginationProps | undefined
  >();

  const [activeTab, changeActiveTab] = useState(1);

  const [usersTeam, setUsersTeam] = useState<Array<any>>([]);
  const [usersTeamMembers, setUsersTeamMembers] = useState<Array<any>>([]);
  const [usersTeamAdms, setUsersTeamAdms] = useState<Array<any>>([]);
  const [usersTeamCoordinators, setUsersTeamCoordinators] = useState<
    Array<any>
  >([]);
  const [buttonClearFilters, setButtonClearFilters] = useState(false);

  const [showDeleteUser, setShowDeleteUser] = useState(false);
  const handleModalDeleteUserClose = () => setShowDeleteUser(false);
  const handleModalDeleteUserShow = () => setShowDeleteUser(true);

  function ClearFilters() {
    setSearchFilterOwners("");
    setSearchFilterMembers("");
    setSearchFilterAdms("");
    setSearchFilterCoordinators("");
    setButtonClearFilters(false);
  }

  function queryPaginationUserTeamOwners() {
    let result: string;
    if (queryPaginationParamsUserTeamOwners)
      result = `${queryPaginationParamsUserTeamOwners}`;
    else result = "";
    return result;
  }
  function queryPaginationUserTeamMembers() {
    let result: string;
    if (queryPaginationParamsUserTeamMembers)
      result = `${queryPaginationParamsUserTeamMembers}`;
    else result = "";
    return result;
  }
  function queryPaginationUserTeamAdms() {
    let result: string;
    if (queryPaginationParamsUserTeamAdms)
      result = `${queryPaginationParamsUserTeamAdms}`;
    else result = "";
    return result;
  }
  function queryPaginationUserTeamCoordinators() {
    let result: string;
    if (queryPaginationParamsUserTeamCoordinators)
      result = `${queryPaginationParamsUserTeamCoordinators}`;
    else result = "";
    return result;
  }
  function queryFiltersUserTeamOwners() {
    let query = "";
    if (searchFilterOwners !== "")
      query = query += "&search=" + searchFilterOwners;
    if (query !== "") setButtonClearFilters(true);
    else setButtonClearFilters(false);
    return query;
  }
  function queryFiltersUserTeamMembers() {
    let query = "";
    if (searchFilterMembers !== "")
      query = query += "&search=" + searchFilterMembers;
    if (query !== "") setButtonClearFilters(true);
    else setButtonClearFilters(false);
    return query;
  }
  function queryFiltersUserTeamAdms() {
    let query = "";
    if (searchFilterAdms !== "") query = query += "&search=" + searchFilterAdms;
    if (query !== "") setButtonClearFilters(true);
    else setButtonClearFilters(false);
    return query;
  }
  function queryFiltersUserTeamCoordinators() {
    let query = "";
    if (searchFilterCoordinators !== "")
      query = query += "&search=" + searchFilterCoordinators;
    if (query !== "") setButtonClearFilters(true);
    else setButtonClearFilters(false);
    return query;
  }

  const renderEmptyDataText = () => {
    return (
      <section className="usersTableEmptyContainer">
        Nenhum usuário com este perfil foi adicionado.
      </section>
    );
  };

  useEffect(() => {
    loadUsersOwners();
  }, [queryPaginationParamsUserTeamOwners, searchFilterOwners, listRefresh]);
  useEffect(() => {
    loadUsersMembers();
  }, [queryPaginationParamsUserTeamMembers, searchFilterMembers, listRefresh]);
  useEffect(() => {
    loadUsersAdms();
  }, [queryPaginationParamsUserTeamAdms, searchFilterAdms, listRefresh]);
  useEffect(() => {
    loadUsersCoordinators();
  }, [
    queryPaginationParamsUserTeamCoordinators,
    searchFilterCoordinators,
    listRefresh,
  ]);

  function loadUsersOwners() {
    setLoading(true);
    if (queryPaginationParamsUserTeamOwners) {
      UserTeamApiInterface.get(
        `/UserTeam/${institutionId}/${identifierTeam}/ListTeachers${queryPaginationUserTeamOwners()}${queryFiltersUserTeamOwners()}`
      )
        .then((response) => {
          setPagingUserTeamOwners(response.data.paging);
          setUsersTeam(response.data.result);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          showToast("error", "Algo deu errado, tente novamente mais tarde");
          console.error("Erro => ", error);
        });
    }
  }
  function loadUsersMembers() {
    setLoading(true);
    if (queryPaginationParamsUserTeamMembers) {
      UserTeamApiInterface.get(
        `/UserTeam/${institutionId}/${identifierTeam}/ListMembers${queryPaginationUserTeamMembers()}${queryFiltersUserTeamMembers()}`
      )
        .then((response) => {
          setPagingUserTeamMembers(response.data.paging);
          setUsersTeamMembers(response.data.result);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          showToast("error", "Algo deu errado, tente novamente mais tarde");
          console.error("Erro => ", error);
        });
    }
  }
  function loadUsersAdms() {
    setLoading(true);
    if (queryPaginationParamsUserTeamAdms) {
      UserTeamApiInterface.get(
        `/UserTeam/${institutionId}/${identifierTeam}/ListAdministratives${queryPaginationUserTeamAdms()}${queryFiltersUserTeamAdms()}`
      )
        .then((response) => {
          setPagingUserTeamAdms(response.data.paging);
          setUsersTeamAdms(response.data.result);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          showToast("error", "Algo deu errado, tente novamente mais tarde");
          console.error("Erro => ", error);
        });
    }
  }
  function loadUsersCoordinators() {
    setLoading(true);
    if (queryPaginationParamsUserTeamCoordinators) {
      UserTeamApiInterface.get(
        `/UserTeam/${institutionId}/${identifierTeam}/ListCoordinators${queryPaginationUserTeamCoordinators()}${queryFiltersUserTeamCoordinators()}`
      )
        .then((response) => {
          setPagingUserTeamCoordinators(response.data.paging);
          setUsersTeamCoordinators(response.data.result);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          showToast("error", "Algo deu errado, tente novamente mais tarde");
          console.error("Erro => ", error);
        });
    }
  }

  const [identifierUserDelete, setIdentifierUserDelete] = useState<any>();
  const [tempUserType, setTempUserType] = useState<number>();

  function handleRemoveUser(item: any, userType: any) {
    setIdentifierUserDelete(item);
    setTempUserType(userType);
    handleModalDeleteUserShow();
  }

  function deleteUserTeam() {
    handleModalDeleteUserClose();
    setLoading(true);

    const payload: Array<BrainzUserTeamPayload> = [
      {
        teamIdentifier: identifierTeam,
        userIdentifier: identifierUserDelete.identifier,
      },
    ];

    const path = `/UserTeam/${institutionId}/${tempUserType}/Inactive`;

    return UserTeamApiInterface.delete(path, { data: payload })
      .then((response) => {
        if (response.status === 200) {
          const errors = response.data.result[0].errors;
          if (!errors || errors.length === 0) {
            showToast("success", "Pessoa removida com sucesso.");
            //Inserir Function para atualizar lista de
            loadUsersOwners();
            loadUsersMembers();
            loadUsersAdms();
            loadUsersCoordinators();
          } else {
            setLoading(false);
            showToast("error", t("errors." + errors[0].code));
          }
        } else {
          setLoading(false);
          showToast("error", "Algo deu errado, tente novamente mais tarde");
        }
      })
      .catch((error) => {
        setLoading(false);
        showToast("error", "Algo deu errado, tente novamente mais tarde");
        console.error("Erro => ", error);
      });
  }
  const renderActiveTab = (activeTab: number) => {
    switch (activeTab) {
      case 1:
        return (
          <Col>
            <FiltersStyleComponentUser className="w-100 mx-0 px-0">
              <Form.Group
                className={
                  searchFilterOwners !== ""
                    ? "col-10 mx-0 px-0"
                    : "col-12 mx-0 px-0"
                }
              >
                <div className="search-filter">
                  <Search
                    setSearchFilter={setSearchFilterOwners}
                    searchFilter={searchFilterOwners}
                    placeholder="Buscar por Tipo, Identificador, Nome ou E-mail"
                  />
                </div>
              </Form.Group>
              {searchFilterOwners !== "" ? (
                <Button
                  className="clear-filter ml-3"
                  onClick={() => ClearFilters()}
                >
                  <BsXCircle size={24} color="#A56300" />
                </Button>
              ) : (
                ""
              )}
            </FiltersStyleComponentUser>
            {loading ? (
              <PageLoader />
            ) : usersTeam && usersTeam.length > 0 ? (
              <TableSyncDataStyles className="pl-0">
                <Table
                  className="mt-3 pl-0 table-list"
                  striped
                  borderless
                  hover
                  responsive
                >
                  <thead>
                    <tr
                      className="title-table"
                      style={{ textAlignLast: "start" }}
                    >
                      <th className="border-radius-left">Nome</th>
                      <th>Identificador</th>
                      <th className="border-radius-right"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {usersTeam?.map((item, index) => {
                      return (
                        <tr
                          id="tr-linha"
                          key={index}
                          className="table-values cursor-pointer"
                        >
                          <td style={{ textAlignLast: "start" }}>
                            {item.displayName}
                          </td>
                          <td style={{ textAlignLast: "start" }}>
                            {item.identifier}
                          </td>
                          <td
                            style={{ textAlignLast: "end" }}
                            className="cursor-pointer"
                            onClick={() => handleRemoveUser(item, 5)}
                          >
                            <CustomTooltip
                              type="custom-icon"
                              text="Excluir"
                              placement="bottom"
                              customIcon={<BsTrash color="#A56300" size="24" />}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                {pagingUserTeamOwners && pagingUserTeamOwners.totalPages > 1 ? (
                  <BrainzPagination
                    setQueryParams={setQueryPaginationParamsUserTeamOwners}
                    paging={pagingUserTeamOwners}
                  />
                ) : (
                  <></>
                )}
              </TableSyncDataStyles>
            ) : (
              renderEmptyDataText()
            )}
          </Col>
        );
      case 2:
        return (
          <Col className="">
            <FiltersStyleComponentUser className="w-100 mx-0 px-0">
              <Form.Group
                className={
                  searchFilterMembers !== ""
                    ? "col-10 mx-0 px-0"
                    : "col-12 mx-0 px-0"
                }
              >
                <div className="search-filter">
                  <Search
                    setSearchFilter={setSearchFilterMembers}
                    searchFilter={searchFilterMembers}
                    placeholder="Buscar por Tipo, Identificador, Nome ou E-mail"
                  />
                </div>
              </Form.Group>
              {searchFilterMembers !== "" ? (
                <Button
                  className="clear-filter ml-3"
                  onClick={() => ClearFilters()}
                >
                  <BsXCircle size={24} color="#A56300" />
                </Button>
              ) : (
                ""
              )}
            </FiltersStyleComponentUser>
            {loading ? (
              <PageLoader />
            ) : usersTeamMembers && usersTeamMembers.length > 0 ? (
              <TableSyncDataStyles className="pl-0">
                <Table
                  className="mt-3 pl-0 table-list"
                  striped
                  borderless
                  hover
                  responsive
                >
                  <thead>
                    <tr
                      className="title-table"
                      style={{ textAlignLast: "start" }}
                    >
                      <th className="border-radius-left">Nome</th>
                      <th>Identificador</th>
                      <th className="border-radius-right"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {usersTeamMembers?.map((item, index) => {
                      return (
                        <tr
                          key={index}
                          id="tr-linha"
                          className="table-values cursor-pointer"
                        >
                          <td style={{ textAlignLast: "start" }}>
                            {item.displayName}
                          </td>
                          <td style={{ textAlignLast: "start" }}>
                            {item.identifier}
                          </td>
                          <td
                            style={{ textAlignLast: "end" }}
                            className="cursor-pointer"
                            onClick={() => handleRemoveUser(item, 4)}
                          >
                            <CustomTooltip
                              type="custom-icon"
                              text="Excluir"
                              placement="bottom"
                              customIcon={<BsTrash color="#A56300" size="24" />}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                {pagingUserTeamMembers &&
                  pagingUserTeamMembers.totalPages > 1 ? (
                  <BrainzPagination
                    setQueryParams={setQueryPaginationParamsUserTeamMembers}
                    paging={pagingUserTeamMembers}
                  />
                ) : (
                  <></>
                )}
              </TableSyncDataStyles>
            ) : (
              renderEmptyDataText()
            )}
          </Col>
        );
      case 3:
        return (
          <Col className="">
            <FiltersStyleComponentUser className="w-100 mx-0 px-0">
              <Form.Group
                className={
                  searchFilterAdms !== ""
                    ? "col-10 mx-0 px-0"
                    : "col-12 mx-0 px-0"
                }
              >
                <div className="search-filter">
                  <Search
                    setSearchFilter={setSearchFilterAdms}
                    searchFilter={searchFilterAdms}
                    placeholder="Buscar por Tipo, Identificador, Nome ou E-mail"
                  />
                </div>
              </Form.Group>
              {searchFilterAdms !== "" ? (
                <Button
                  className="clear-filter ml-3"
                  onClick={() => ClearFilters()}
                >
                  <BsXCircle size={24} color="#A56300" />
                </Button>
              ) : (
                ""
              )}
            </FiltersStyleComponentUser>
            {loading ? (
              <PageLoader />
            ) : usersTeamAdms && usersTeamAdms.length > 0 ? (
              <TableSyncDataStyles className="pl-0">
                <Table
                  className="mt-3 pl-0 table-list"
                  striped
                  borderless
                  hover
                  responsive
                >
                  <thead>
                    <tr
                      className="title-table"
                      style={{ textAlignLast: "start" }}
                    >
                      <th className="border-radius-left">Nome</th>
                      <th>Identificador</th>
                      <th className="border-radius-right"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {usersTeamAdms.map((item, index) => {
                      return (
                        <tr
                          key={index}
                          id="tr-linha"
                          className="table-values cursor-pointer"
                        >
                          <td style={{ textAlignLast: "start" }}>
                            {item.displayName}
                          </td>
                          <td style={{ textAlignLast: "start" }}>
                            {item.identifier}
                          </td>
                          <td
                            style={{ textAlignLast: "end" }}
                            className="cursor-pointer"
                            onClick={() => handleRemoveUser(item, 1)}
                          >
                            <CustomTooltip
                              type="custom-icon"
                              text="Excluir"
                              placement="bottom"
                              customIcon={<BsTrash color="#A56300" size="24" />}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                {pagingUserTeamAdms && pagingUserTeamAdms.totalPages > 1 ? (
                  <BrainzPagination
                    setQueryParams={setQueryPaginationParamsUserTeamAdms}
                    paging={pagingUserTeamAdms}
                  />
                ) : (
                  <></>
                )}
              </TableSyncDataStyles>
            ) : (
              renderEmptyDataText()
            )}
          </Col>
        );
      case 4:
        return (
          <Col className="">
            <FiltersStyleComponentUser className="w-100 mx-0 px-0">
              <Form.Group
                className={
                  searchFilterCoordinators !== ""
                    ? "col-10 mx-0 px-0"
                    : "col-12 mx-0 px-0"
                }
              >
                <div className="search-filter">
                  <Search
                    setSearchFilter={setSearchFilterCoordinators}
                    searchFilter={searchFilterCoordinators}
                    placeholder="Buscar por Tipo, Identificador, Nome ou E-mail"
                  />
                </div>
              </Form.Group>
              {searchFilterCoordinators !== "" ? (
                <Button
                  className="clear-filter ml-3"
                  onClick={() => ClearFilters()}
                >
                  <BsXCircle size={24} color="#A56300" />
                </Button>
              ) : (
                ""
              )}
            </FiltersStyleComponentUser>
            {loading ? (
              <PageLoader />
            ) : usersTeamCoordinators && usersTeamCoordinators.length > 0 ? (
              <TableSyncDataStyles className="pl-0">
                <Table
                  className="mt-3 pl-0 table-list"
                  striped
                  borderless
                  hover
                  responsive
                >
                  <thead>
                    <tr
                      className="title-table"
                      style={{ textAlignLast: "start" }}
                    >
                      <th className="border-radius-left">Nome</th>
                      <th>Identificador</th>
                      <th className="border-radius-right"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {usersTeamCoordinators.map((item, index) => {
                      return (
                        <tr
                          key={index}
                          id="tr-linha"
                          className="table-values cursor-pointer"
                        >
                          <td style={{ textAlignLast: "start" }}>
                            {item.displayName}
                          </td>
                          <td style={{ textAlignLast: "start" }}>
                            {item.identifier}
                          </td>
                          <td
                            style={{ textAlignLast: "end" }}
                            className="cursor-pointer"
                            onClick={() => handleRemoveUser(item, 3)}
                          >
                            <CustomTooltip
                              type="custom-icon"
                              text="Excluir"
                              placement="bottom"
                              customIcon={<BsTrash color="#A56300" size="24" />}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                {pagingUserTeamCoordinators &&
                  pagingUserTeamCoordinators.totalPages > 1 ? (
                  <BrainzPagination
                    setQueryParams={
                      setQueryPaginationParamsUserTeamCoordinators
                    }
                    paging={pagingUserTeamCoordinators}
                  />
                ) : (
                  <></>
                )}
              </TableSyncDataStyles>
            ) : (
              renderEmptyDataText()
            )}
          </Col>
        );
      default:
        return (
          <Row className="">
            {loading === true ? <PageLoader /> : renderActiveTab(activeTab)}
          </Row>
        );
    }
  };

  return (
    <>
      <section style={{ padding: "32px 0 " }}>
        <Row className="ml-auto pl-0 ml-0 nav-item">
          <UsersNavBarStyles className="pl-0 ml-0">
            <Nav
              variant="pills"
              defaultActiveKey="/home"
              className="px-0 mt-2 mb-0 pl-0 ml-0"
            >
              {/* ADM */}
              <Nav.Item className="mx-1">
                <Nav.Link
                  active={activeTab === 3}
                  onClick={() => changeActiveTab(3)}
                  className="pt-3 pb-2"
                >
                  Administrativo
                  {pagingUserTeamAdms?.totalRecords ? (
                    <div className="quantityUsersContainer">
                      {pagingUserTeamAdms?.totalRecords}
                    </div>
                  ) : (
                    <></>
                  )}
                </Nav.Link>
              </Nav.Item>

              {/* COORD */}
              <Nav.Item className="mx-1">
                <Nav.Link
                  active={activeTab === 4}
                  onClick={() => changeActiveTab(4)}
                  className="pt-3 pb-2"
                >
                  Coordenador
                  {pagingUserTeamCoordinators?.totalRecords ? (
                    <div className="quantityUsersContainer">
                      {pagingUserTeamCoordinators?.totalRecords}
                    </div>
                  ) : (
                    <></>
                  )}
                </Nav.Link>
              </Nav.Item>

              {/* STUDENT */}
              <Nav.Item className="mx-1">
                <Nav.Link
                  active={activeTab === 2}
                  onClick={() => changeActiveTab(2)}
                  className="pt-3 pb-2"
                >
                  Aluno
                  {pagingUserTeamMembers?.totalRecords ? (
                    <div className="quantityUsersContainer">
                      {pagingUserTeamMembers?.totalRecords}
                    </div>
                  ) : (
                    <></>
                  )}
                </Nav.Link>
              </Nav.Item>

              {/* TEACHER */}
              <Nav.Item className="mx-0">
                <Nav.Link
                  active={activeTab === 1}
                  onClick={() => changeActiveTab(1)}
                  className="pt-3 pb-2"
                >
                  Professor
                  {pagingUserTeamOwners?.totalRecords ? (
                    <div className="quantityUsersContainer">
                      {pagingUserTeamOwners?.totalRecords}
                    </div>
                  ) : (
                    <></>
                  )}
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </UsersNavBarStyles>
        </Row>

        <Row className="pt-0">
          {/* {loading === true ? 
                (<PageLoader/>) : (
                    renderActiveTab(activeTab)
                )} */}
          {renderActiveTab(activeTab)}
        </Row>
      </section>

      <ConfirmModal
        show={showDeleteUser}
        onHide={handleModalDeleteUserClose}
        title="Deseja excluir esse usuário dessa turma?"
        subTitle="Você poderá adicionar esse usuário novamente."
        confirmAction={deleteUserTeam}
        refuseAction={handleModalDeleteUserClose}
        confirmText="Excluir da turma"
        refuseText="Manter na turma"
        principalIcon={<BsExclamationCircle color="#A56300" size={110} />}
      />
    </>
  );
}

export const CardNotResultSstyles = styled(Row)`
  align-content: center;
  width: 835px;
  height: 60px;
  background: #f5f5f5;
  border: 1px solid #a56300;
  box-sizing: border-box;
  box-shadow: 0px 0px 2px rgba(81, 79, 78, 0.2);
  border-radius: 8px;

  .usersTableEmptyContainer {
    display: flex;
    padding: 16px;
    margin-top: 2rem;

    font-weight: 600;

    border: 1px solid #e5da00d6;
    border-radius: 8px;
    color: #b29425;
    background: #fffb0012;
  }

  h3 {
    margin: 0px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #525252;
  }
  p {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #a56300;
  }
`;

export const UsersNavBarStyles = styled(Col)`
  z-index: 100;
  max-width: 80vw;
  background-color: #fff;
  .nav-link {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    background-color: #fff !important;
    color: #514f4e !important;
  }
  .nav-link.active {
    font-weight: 600;
    border-bottom: 4px solid #0f7eaa;
  }

  .nav-item {
    position: relative;
  }
  .quantityUsersContainer {
    background-color: #f94545;
    color: #fff;

    width: 22px;
    height: 22px;
    font-size: 12px;

    border-radius: 100px;

    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 0;
    right: 0;
  }
`;

export default ComponentUsersTeam;
