import { Form } from "react-bootstrap";

import { getPageSizeStorageEnv } from "../../../helpers/functions";

interface PageSizeProps {
  currentSizePage: any;
  handleOnChange: (page: number, size: number) => void;
}

export const PageSize = ({ currentSizePage, handleOnChange }: PageSizeProps) => {

  const handleSaveOptionOnStorage = (value: any) => {
    localStorage.setItem(getPageSizeStorageEnv(), value);
  };

  return (
    <>
      <span>Itens por página</span>
      <Form.Group className="ml-3 mb-2 d-inline-block">
        <Form.Control
          className="page-sizes pageSizeContainer"
          size="sm"
          as="select"
          value={currentSizePage}
          onChange={(e) => {
            handleOnChange(1, Number(e.target.value));
            handleSaveOptionOnStorage(e.target.value);
          }}
        >
          {/* <option value={1}>1</option> */}
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={30}>30</option>
          <option value={40}>40</option>
          <option value={50}>50</option>
        </Form.Control>
      </Form.Group>
    </>
  )
}