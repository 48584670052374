import { ApiInterface } from "@brainz_group/webappframework";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { ListGroupItem } from "react-bootstrap";
import {
  BsArrowRepeat,
  BsArrowReturnLeft,
  BsBoxArrowLeft,
  BsChevronDoubleLeft,
  BsClipboardData,
  BsClockHistory,
  BsExclamationCircle,
  BsGear,
  BsHouseDoor,
  BsLightning,
  BsPeople,
  BsUnlock,
  BsUpload,
} from "react-icons/bs";
import { NavLink } from "react-router-dom";
import Cookies from "universal-cookie";

import openLogo from "../../assets/images/logo-aberto.png";
import closedLogo from "../../assets/images/logo-fechado.png";
import { ProfileContext } from "../../contexts/ProfileContext";
import { isValidRoute } from "../../helpers/functions";
import CustomTooltip from "../Common/CustomTooltip";
import PageLoader from "../Common/Loader";

export interface SidebarMenuProps {
  resizeSidebar: () => void;
}

const SidebarMenu = ({ resizeSidebar }: SidebarMenuProps) => {
  const cookie = new Cookies();
  const institutionId = cookie.get(process.env.REACT_APP_BRAINZ_INSTITUTIONID!);

  const currentInstitutionID = isValidRoute(institutionId)

  const [isOpenSidebar, setIsOpenSidebar] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [openSubitemsConfig, setOpenSubitemsConfig] = useState<boolean>(false);
  const [openSubitemsIntegration, setOpenSubitemsIntegration] =
    useState<boolean>(false);

  const {
    peddingConfiguration,
    setPeddingConfiguration,
    setConfigurationProfile,
  } = useContext(ProfileContext);

  // FUNCTIONS
  const handleInitialSizeSidebar = () => {
    const status = localStorage.getItem("isSidebarOpen");

    if (status == "true") setIsOpenSidebar(true);

    if (status == "false") setIsOpenSidebar(false);
  };

  const handleResizeSidebar = () => {
    resizeSidebar();
    setIsOpenSidebar(!isOpenSidebar);
  };

  const handleLogout = () => {
    const cookie = new Cookies();
    cookie.remove(String(process.env.REACT_APP_BRAINZ_NAME_TOKEN), {
      path: "/",
      domain: process.env.REACT_APP_BRAINZ_DOMAIN,
    });
    cookie.remove(process.env.REACT_APP_BRAINZ_INSTITUTIONID!, {
      path: "/",
      domain: process.env.REACT_APP_BRAINZ_DOMAIN,
    });

    window.location.href = process.env["REACT_APP_ACCOUNT_REDIRECT"]!;
  };

  const handleVerifyConfigurationProfile = () => {
    setLoading(true);
    ApiInterface.get("/UserIntegrationSettings/" + institutionId + "/GetAll")
      .then((response) => {
        setConfigurationProfile(response?.data?.result);
        setPeddingConfiguration(response?.data?.result?.length === 0);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados de UserIntegrationSettings. Erro => ", error);
        setLoading(false);
      });
  };

  const handleLoadPage = () => {
    if (cookie.get(process.env.REACT_APP_BRAINZ_INSTITUTIONID!) === undefined) {
      ApiInterface.get(`/Institution/List`).then((response: any) => {
        cookie.set(
          process.env.REACT_APP_BRAINZ_INSTITUTIONID!,
          response.data.result[0].id
        );
      });
    }
  };

  const handleSubitemsIntegration = () => {
    setOpenSubitemsIntegration(true);
    setOpenSubitemsConfig(false);
  };

  const handleOpenSubitemsConfig = () => {
    setOpenSubitemsConfig(true);
    setOpenSubitemsIntegration(false);
  };

  const handleCloseAllSubitems = () => {
    handleClickVerifySubmenu("");
    setOpenSubitemsConfig(false);
    setOpenSubitemsIntegration(false);
  };

  const handleLoadSubitemsCategory = () => {
    const sidebarLS = localStorage.getItem("sidebar");

    if (sidebarLS === "config") {
      handleOpenSubitemsConfig();
    }

    if (sidebarLS === "integra") {
      handleSubitemsIntegration();
    }
  };

  const handleClickVerifySubmenu = (value: any) => {
    localStorage.setItem("sidebar", value);
    handleLoadSubitemsCategory();
  };

  // EFFECTS
  useEffect(() => {
    handleVerifyConfigurationProfile();
  }, [institutionId]);

  useEffect(() => {
    handleLoadPage();
    handleLoadSubitemsCategory();
    handleInitialSizeSidebar();
  }, []);

  if (loading) return <PageLoader />;

  return (
    <Fragment>
      <div className="logo-details">
        <div className="logo_name">
          {isOpenSidebar ? (
            <img src={openLogo} alt="Integrador Logo" />
          ) : (
            <img src={closedLogo} alt="Integrador Logo" />
          )}
        </div>
      </div>
      <ul className="nav-list">
        <ListGroupItem
          as="li"
          className="nav-item p-0 m-0"
          style={{ background: "#f5f5f5" }}
          onClick={handleCloseAllSubitems}
        >
          <span className="tooltip">Início</span>
          <NavLink
            to={`/home?id=${currentInstitutionID}`}
            className={(navData) =>
              `sidemenu-link-sec link ${navData.isActive ? "sidemenu-active" : ""
              }`
            }
          >
            <i className="icone bx-grid-alt">
              <BsHouseDoor className="" color="#525252" size={22} />
            </i>
            <span className="links_name">Início</span>
          </NavLink>
        </ListGroupItem>
        <ListGroupItem
          as="li"
          className={`nav-config nav-item p-0 m-0 ${openSubitemsConfig ? "item-open" : ""
            }`}
          onClick={handleOpenSubitemsConfig}
          style={{ backgroundColor: "#F5F5F5" }}
        >
          <li className="nav-item">
            <span className="tooltip">
              {" "}
              {!peddingConfiguration
                ? "Configurações"
                : "Configuração de perfis pendentes"}
            </span>
            <NavLink
              to={`/settings-page?id=${currentInstitutionID}`}
              className={(navData) =>
                `sidemenu-link-sec link ${navData.isActive ? "sidemenu-active" : ""
                }`
              }
            >
              <i className="icone bx-grid-alt">
                <BsGear className="" color="#525252" size={22} />
              </i>
              <span className="links_name">Configurações</span>
              {isOpenSidebar
                ? peddingConfiguration && (
                  <>
                    {" "}
                    <CustomTooltip
                      type="custom-icon"
                      text="As configuração de perfis estão pendentes."
                      placement="right"
                      customIcon={
                        <BsExclamationCircle color="#A56300" size={24} />
                      }
                    />
                  </>
                )
                : ""}
            </NavLink>
          </li>
          <ul className="subnav-list">
            <li
              onClick={() => handleClickVerifySubmenu("config")}
              className="subnav-item"
            >
              <span className="tooltip">
                {!peddingConfiguration
                  ? "Perfis"
                  : "Configuração de perfis Pendentes"}
              </span>
              <NavLink
                to={`/domains?id=${currentInstitutionID}`}
                className={(navData) =>
                  `sidemenu-link-sec link ${navData.isActive ? "sidemenu-active" : ""
                  }`
                }
              >
                <i className="icone bx-grid-alt">
                  <BsPeople className="" color="#525252" size={22} />
                </i>
                <span className="links_name">Perfis </span>
                {isOpenSidebar
                  ? peddingConfiguration && (
                    <>
                      {" "}
                      <CustomTooltip
                        type="custom-icon"
                        text="Configure pelo menos um perfil para que essa opção seja habilitada."
                        placement="right"
                        customIcon={
                          <BsExclamationCircle color="#A56300" size={24} />
                        }
                      />
                    </>
                  )
                  : ""}
              </NavLink>
            </li>
            <li
              onClick={() => handleClickVerifySubmenu("config")}
              className="subnav-item"
            >
              <span className="tooltip">Portal Primeiro Acesso</span>
              <NavLink
                to={`/first-access?id=${currentInstitutionID}`}
                className={(navData) =>
                  `sidemenu-link-sec link ${navData.isActive ? "sidemenu-active" : ""
                  }`
                }
              >
                <i className="icone bx-grid-alt">
                  <BsUnlock className="" color="#525252" size={22} />
                </i>
                <span className="links_name">Portal Primeiro Acesso </span>
              </NavLink>
            </li>

            <li
              onClick={() => handleClickVerifySubmenu("config")}
              className="subnav-item"
            >
              <span className="tooltip">Configuração de Retorno</span>
              <NavLink
                to={`/writeback?id=${currentInstitutionID}`}
                className={(navData) =>
                  `sidemenu-link-sec link ${navData.isActive ? "sidemenu-active" : ""
                  }`
                }
              >
                <i className="icone bx-grid-alt">
                  <BsArrowReturnLeft className="" color="#525252" size={22} />
                </i>
                <span className="links_name">Configuração de Retorno</span>
              </NavLink>
            </li>
          </ul>
        </ListGroupItem>
        <ListGroupItem
          as="li"
          className={`nav-integra nav-item p-0 m-0 ${openSubitemsIntegration ? "item-open" : ""
            }`}
          onClick={handleSubitemsIntegration}
          disabled={peddingConfiguration}
          style={{ backgroundColor: "#F5F5F5" }}
        >
          <li className="nav-item ">
            <span className="tooltip">Integrações</span>
            <NavLink
              to={peddingConfiguration ? "" : `/integrations-page?id=${currentInstitutionID}`}
              className={(navData) =>
                `sidemenu-link-sec link ${navData.isActive && !peddingConfiguration
                  ? "sidemenu-active "
                  : ""
                }`
              }
            >
              <i className="icone bx-grid-alt">
                <BsArrowRepeat
                  className="icone bx-grid-alt"
                  color="#525252"
                  size={22}
                />
              </i>
              <span className="links_name">
                Integrações
                {peddingConfiguration ? (
                  <>
                    {" "}
                    <CustomTooltip
                      type="custom-icon"
                      text="Configure pelo menos um perfil para que essa opção seja habilitada."
                      placement="right"
                      customIcon={
                        <BsExclamationCircle color="#A56300" size={24} />
                      }
                    />
                  </>
                ) : (
                  ""
                )}
              </span>
            </NavLink>
          </li>
          <ul className="subnav-list">
            <li
              onClick={() => handleClickVerifySubmenu("integra")}
              className="subnav-item"
            >
              <span className="tooltip">Dados Sincronizados</span>
              <NavLink
                to={`/sync-data?id=${currentInstitutionID}`}
                className={(navData) =>
                  `sidemenu-link-sec link ${navData.isActive ? "sidemenu-active" : ""
                  }`
                }
              >
                <i className="icone bx-grid-alt">
                  <BsClipboardData className="" color="#525252" size={22} />
                </i>
                <span className="links_name">Dados Sincronizados </span>
              </NavLink>
            </li>
            <li
              onClick={() => handleClickVerifySubmenu("integra")}
              className="subnav-item"
            >
              <span className="tooltip">Integração Automática</span>
              <NavLink
                to={`/automatic-integration?id=${currentInstitutionID}`}
                className={(navData) =>
                  `sidemenu-link-sec link ${navData.isActive ? "sidemenu-active" : ""
                  }`
                }
              >
                <i className="icone bx-grid-alt">
                  <BsLightning className="" color="#525252" size={22} />
                </i>
                <span className="links_name">Integração Automática </span>
              </NavLink>
            </li>
            <li
              onClick={() => handleClickVerifySubmenu("integra")}
              className="subnav-item"
            >
              <span className="tooltip">Integração Manual</span>
              <NavLink
                to={`/manual-integration?id=${currentInstitutionID}`}
                className={(navData) =>
                  `sidemenu-link-sec link ${navData.isActive ? "sidemenu-active" : ""
                  }`
                }
              >
                <i className="icone bx-grid-alt">
                  <BsUpload className="" color="#525252" size={22} />
                </i>
                <span className="links_name">Integração Manual </span>
              </NavLink>
            </li>
            <li
              onClick={() => handleClickVerifySubmenu("integra")}
              className="subnav-item"
            >
              <span className="tooltip">Logs de Execução</span>
              <NavLink
                to={`/logs?id=${currentInstitutionID}`}
                className={(navData) =>
                  `sidemenu-link-sec link ${navData.isActive ? "sidemenu-active" : ""
                  }`
                }
              >
                <i className="icone bx-grid-alt">
                  <BsClockHistory className="" color="#525252" size={22} />
                </i>
                <span className="links_name">Logs de Execução </span>
              </NavLink>
            </li>
          </ul>
        </ListGroupItem>

        <li className="profile">
          <button id="btn-sidebar" onClick={handleResizeSidebar}>
            <i className="icone bx-grid-alt">
              <BsChevronDoubleLeft className="" color="#6468c8" size={24} />
            </i>
            {isOpenSidebar && <span className="links_name">Esconder Menu</span>}
          </button>
          <button onClick={handleLogout} id="log-out">
            <i className="icone bx-grid-alt">
              <BsBoxArrowLeft className="" color="#525252" size={24} />
            </i>
            {isOpenSidebar && <span className="links_name">Sair</span>}
          </button>
        </li>
      </ul>
    </Fragment>
  );
};

export default SidebarMenu;
