import { useContext } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { BsCheck2Circle } from "react-icons/bs";

import { ModalGenericSuccessStyles } from "../../../styles/modalsGeneric";
import { ActiveModalContext } from "../SyncDataContexts/ActiveModalContext";

export default function ModalSucessRegister() {
  const { activeModal, setActiveModal } = useContext(ActiveModalContext);

  return (
    <ModalGenericSuccessStyles
      show={activeModal === 5}
      onHide={() => setActiveModal(0)}
    >
      <Modal.Body>
        <Row className="pb-2 ml-1">
          <Col>
            <BsCheck2Circle size={110} color="#008555" />
          </Col>
        </Row>
        <Row className="my-3 justify-content-center">
          <h2>Registro adicionado!</h2>
        </Row>
        <Row className="justify-content-center">
          <h3>
            Você pode adicionar outro registro ou voltar para a lista de dados
            integrados
          </h3>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="success-button-outline"
          onClick={() => setActiveModal(1)}
        >
          Adicionar novo registro
        </Button>
        <Button
          variant="primary"
          className="success-button-outline"
          onClick={() => setActiveModal(0)}
        >
          Dados Integrados
        </Button>
      </Modal.Footer>
    </ModalGenericSuccessStyles>
  );
}
