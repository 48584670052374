import { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { BsCheck2, BsChevronRight, BsX } from "react-icons/bs";

import CustomTooltip from "../../../components/Common/CustomTooltip";
import { ModalGenericStyles } from "../../../styles/modalsGeneric";
import { GeneralConfigurationContext } from "../GeneralConfigurationContexts/GeneralConfigurationContext";
import { customTooltipTextEmailsOption1 } from "./utils/functions";
import "./styles/cardStyles.css";

export default function ModalEditEmailProfile() {
  const {
    activeModal,
    entityType,
    setUpdateIntegration,
    setNewEmailType,
    setActiveModal,
    settingsProfile,
  } = useContext(GeneralConfigurationContext);

  const [emailType, setEmailType] = useState<number>(
    settingsProfile.defaultEmail
  );

  useEffect(() => {
    setEmailType(settingsProfile.defaultEmail);
  }, []);

  return (
    <ModalGenericStyles
      show={activeModal === 2}
      onHide={() => setActiveModal(0)}
      className="ModalDomains ModalEditEmailProfile"
    >
      <Modal.Header>
        <Modal.Title>
          <h2>
            {entityType} <BsChevronRight size={18} /> Padrão de criação dos
            e-mails
          </h2>
        </Modal.Title>
        <Button
          className="cursor-pointer"
          variant="white"
          onClick={() => setActiveModal(0)}
        >
          <BsX color="var(--bs-modal-color)" size={28} />
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <h4>Selecione como serão gerados os endereços de e-mail.</h4>
          </Col>
        </Row>

        <Row>
          <Col
            className={emailType === 1 ? "card-option-selected" : "card-option"}
          >
            <Form.Check
              className="button-radio mb-3 mt-2"
              type="radio"
              label="Seguir planilha/consulta"
              checked={emailType === 1}
              onChange={() => [setEmailType(1), setNewEmailType(1)]}
            />
            <h3>
              As contas serão criadas com os endereços de e-mail especificados
              na integração.
            </h3>
          </Col>
        </Row>
        <Row>
          <Col
            className={emailType === 2 ? "card-option-selected" : "card-option"}
          >
            <div className="card-with-tooltip">
              <Form.Check
                className="button-radio mb-3 mt-2"
                type="radio"
                label="Geração Automática - Opção 1"
                checked={emailType === 2}
                onChange={() => [setEmailType(2), setNewEmailType(2)]}
              />

              <CustomTooltip
                type="info"
                iconColor="#6F6F6F"
                iconSize={20}
                text={customTooltipTextEmailsOption1}
                placement="right"
                isHTMLText
              />
            </div>

            <h3>
              Os e-mails serão gerados automaticamente com o seguinte formato:{" "}
              <strong>PN.UN@dominio</strong>. Caso não seja possível criar um
              e-mail nesse formato, serão utilizadas várias outras combinações
              com o nome e sobrenome.
            </h3>
          </Col>
        </Row>
        <Row>
          <Col
            className={emailType === 4 ? "card-option-selected" : "card-option"}
          >
            <Form.Check
              className="button-radio mb-3 mt-2"
              type="radio"
              label="Geração Automática - Opção 2"
              checked={emailType === 4}
              onChange={() => [setEmailType(4), setNewEmailType(4)]}
            ></Form.Check>
            <h3>
              Os e-mails serão gerados de forma automática. O formato a ser
              criado será <strong>PN.UN.DATA@dominio</strong>, caso nessa
              possível criar com esse formato, a segunda forma de validação será{" "}
              <strong>PN.UN.Matricula@dominio</strong>.
            </h3>
          </Col>
        </Row>

        <Row>
          <Col
            className={emailType === 6 ? "card-option-selected" : "card-option"}
          >
            <Form.Check
              className="button-radio mb-3 mt-2"
              type="radio"
              label="Geração Automática - Opção 3"
              checked={emailType === 6}
              onChange={() => [setEmailType(6), setNewEmailType(6)]}
            ></Form.Check>
            <h3>
              Os e-mails serão gerados automaticamente com o seguinte formato:{" "}
              <strong>MT@dominio</strong>.
            </h3>
          </Col>
        </Row>

        <Row>
          <Col
            className={emailType === 7 ? "card-option-selected" : "card-option"}
          >
            <Form.Check
              className="button-radio mb-3 mt-2"
              type="radio"
              label="Geração Automática - Opção 4"
              checked={emailType === 7}
              onChange={() => [setEmailType(7), setNewEmailType(7)]}
            ></Form.Check>
            <h3>
              Os e-mails serão gerados automaticamente com o seguinte formato:{" "}
              <strong>PN.MT@dominio</strong>.
            </h3>
          </Col>
        </Row>

        <Row>
          <Col
            className={emailType === 5 ? "card-option-selected" : "card-option"}
          >
            <Form.Check
              className="button-radio mb-3 mt-2"
              type="radio"
              label="Usuários Federados - Opção 5"
              checked={emailType === 5}
              onChange={() => [setEmailType(5), setNewEmailType(5)]}
            ></Form.Check>
            <h3>
              Não haverá geração de e-mails, apenas serão vinculados os e-mails
              já existentes.
            </h3>
          </Col>
        </Row>

        <Row className="mt-2">
          <Col>
            <h4>
              <strong>LEGENDA:</strong>
              <br />
              PN: Primeiro Nome
              <br />
              UN: Último Nome
              <br />
              Data: Data de Nascimento <br />
              MT: Matrícula
            </h4>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button className="cancel-operation" onClick={() => setActiveModal(0)}>
          <BsX className=" mr-2" color="#A56300" size={24} />
          Cancelar
        </Button>
        <Button
          variant="primary"
          className="continue-button"
          onClick={() => [setActiveModal(0), setUpdateIntegration(true)]}
        >
          Salvar
          <BsCheck2 className=" ml-2" color="#fff" size={24} />
        </Button>
      </Modal.Footer>
    </ModalGenericStyles>
  );
}
