import { useState, useEffect, useContext } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BsPencil } from "react-icons/bs";
import Cookies from "universal-cookie";

import FirstConfiguration from "../../components/Common/FirstConfiguration";
import PageLoader from "../../components/Common/Loader";
import { showToast } from "../../components/Common/Toast";
import Toggle from "../../components/Common/Toggle";
import ApiInterface from "../../services/ApiInterface";
import { InitialHeaderStyles } from "../../styles/initialStyles";
import ComponentAuxiliaryLinks from "./ComponentAuxiliaryLinks";
import { GeneralConfigurationContext } from "./GeneralConfigurationContexts/GeneralConfigurationContext";
import ModalEditDomainProfile from "./Modals/ModalEditDomain";
import ModalEditEmailProfile from "./Modals/ModalEditEmailProfile";
import ModalEditLicenseProfile from "./Modals/ModalEditLicenseProfile";
import ModalEditPasswordProfile from "./Modals/ModalEditPasswordProfile";
import ModalEditVisibleData from "./Modals/ModalEditVisibleData";
import ModalFirstConfigurationProfile from "./Modals/ModalFirstConfigurationProfile";
import ModalSuccessEditConfiguration from "./Modals/ModalSuccessEditConfiguration";
import "./styles.css";

export default function ConfigurationProfile() {
  const {
    activeModal,
    setActiveModal,
    userType,
    newEmailType,
    updateIntegration,
    setUpdateIntegration,
    settingsProfile,
    setSettingsProfile,
    newPasswordType,
    newFixedPassword,
    newJobTitle,
    newDocument,
    newCellPhone,
    newDepartment,
    newBirthday,
    newLicenses,
    newDomain,
    setDomain,
    setFirstUpdateIntegration,
  } = useContext(GeneralConfigurationContext);

  const [t] = useTranslation("common");
  const cookie = new Cookies();
  const institutionId = cookie.get(process.env.REACT_APP_BRAINZ_INSTITUTIONID!);
  const [loading, setLoading] = useState(false);

  const [newRequirePasswordRedefinition, setNewRequirePasswordRedefinition] =
    useState<boolean | undefined>();

  const [licenses, setLicenses] = useState([
    {
      id: "0",
      name: "Gratuita",
      licenseKey: "0",
    },
  ]);

  const [licenseName, setLicenseName] = useState("");

  function loadPage() {
    setLoading(true);
    ApiInterface.get("/Institution/GetLicenses/" + institutionId)
      .then((response) => {
        const licensesResult = response.data.result;
        ApiInterface.get(
          "/UserIntegrationSettings/" +
          institutionId +
          "/GetByUserType/" +
          userType
        )
          .then((response) => {
            setSettingsProfile(response.data.result);
            setDomain(response.data.result?.emailDomain?.split(","));
            setLicenseName(
              licensesResult.filter(
                (item: { licenseKey: any }) =>
                  item.licenseKey === response.data.result.licenseKey
              )[0].name
            );

            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            console.error("Erro => ", error)
          });
      })
      .catch((error) => {
        console.error("Erro => ", error)

      });
  }

  function handleUpdate() {
    const payloadUpdate = {
      id: settingsProfile.id,
      institutionId,
      userType: settingsProfile.userType,
      defaultEmail:
        newEmailType !== settingsProfile.defaultEmail &&
          newEmailType !== undefined
          ? newEmailType
          : settingsProfile.defaultEmail,
      licenseKey:
        newLicenses !== settingsProfile.licenseKey && newLicenses !== undefined
          ? newLicenses
          : settingsProfile.licenseKey,
      emailDomain:
        newDomain !== settingsProfile.emailDomain && newDomain !== undefined
          ? newDomain
          : settingsProfile.emailDomain,
      defaultPassword:
        newPasswordType !== settingsProfile.defaultPassword &&
          newPasswordType !== undefined
          ? newPasswordType
          : settingsProfile.defaultPassword,
      passwordCreationPattern: settingsProfile.passwordCreationPattern,
      requirePasswordRedefinition:
        newRequirePasswordRedefinition !==
          settingsProfile.requirePasswordRedefinition &&
          newRequirePasswordRedefinition !== undefined
          ? newRequirePasswordRedefinition
          : settingsProfile.requirePasswordRedefinition,
      fixedPassword:
        newFixedPassword !== settingsProfile.fixedPassword &&
          newFixedPassword !== undefined
          ? newFixedPassword
          : settingsProfile.fixedPassword,
      integrateBirthday:
        newBirthday !== settingsProfile.integrateBirthday &&
          newBirthday !== undefined
          ? newBirthday
          : settingsProfile.integrateBirthday,
      integrateCellphone:
        newCellPhone !== settingsProfile.integrateCellphone &&
          newCellPhone !== undefined
          ? newCellPhone
          : settingsProfile.integrateCellphone,
      integrateDepartment:
        newDepartment !== settingsProfile.integrateDepartment &&
          newDepartment !== undefined
          ? newDepartment
          : settingsProfile.integrateDepartment,
      integrateJobTitle:
        newJobTitle !== settingsProfile.integrateJobTitle &&
          newJobTitle !== undefined
          ? newJobTitle
          : settingsProfile.integrateJobTitle,
      integrateDocument:
        newDocument !== settingsProfile.integrateDocument &&
          newDocument !== undefined
          ? newDocument
          : settingsProfile.integrateDocument,
    };

    ApiInterface.put(
      "/UserIntegrationSettings/" +
      institutionId +
      "/" +
      settingsProfile.id +
      "/Update",
      payloadUpdate
    )
      .then((response) => {
        const errors = response.data.result.errors;
        if (response.data.result.errors.length === 0) {
          loadPage();
          setActiveModal(4);
          setUpdateIntegration(false);
          setLoading(false);
        } else {
          if (response.data.result.errors[0].id === 16) {
            setUpdateIntegration(false);
            showToast(
              "error",
              "Não é permitido alterar o padrão de e-mails para Automático, com multiplos domínios cadastrados"
            );
            setLoading(false);
          } else {
            showToast("error", t("errors." + errors[0].code));
            setUpdateIntegration(false);
            setLoading(false);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        showToast("error", error);
        setUpdateIntegration(false);
      });
  }

  useEffect(() => {
    if (updateIntegration === true) {
      setLoading(true);
      handleUpdate();
    }
  }, [updateIntegration]);

  useEffect(() => {
    loadPage();
  }, [userType]);

  useEffect(() => { }, [licenses]);

  return (
    <InitialHeaderStyles className=" mx-0 px-0 my-0">
      {loading ? (
        <PageLoader />
      ) : (
        <>
          {settingsProfile === undefined || settingsProfile === null ? (
            <FirstConfiguration
              headingText="Você ainda não configurou este perfil"
              description="Depois de fazer a primeira configuração, você poderá editar cada um dos parâmetros nesta tela."
              handleClick={() => {
                setActiveModal(1);
                setFirstUpdateIntegration(false);
              }}
              buttonText="Primeira Configuração"
            />
          ) : (
            <>
              <Card className="card-integration mb-4">
                <Card.Title className="title-card">
                  <Col className="mt-3">
                    <h3>Padrão de criação dos e-mails</h3>
                  </Col>
                </Card.Title>
                <Card.Body className="pt-2">
                  <Row className="div-body-links ml-0">
                    <Col className="mr-3">
                      <Row>
                        <h4>
                          {settingsProfile.defaultEmail === 1 &&
                            "Seguir planilha / consulta"}
                          {settingsProfile.defaultEmail === 2 &&
                            "Geração Automática"}
                          {settingsProfile.defaultEmail === 4 &&
                            "Geração Automática Alternativa"}
                          {settingsProfile.defaultEmail === 5 &&
                            "Usuários Federados"}
                          {settingsProfile.defaultEmail === 6 &&
                            "Geração Automática - Apenas Matrícula"}
                          {settingsProfile.defaultEmail === 7 &&
                            "Geração Automática - Primeiro Nome + Matrícula"}
                        </h4>
                      </Row>
                      <Row>
                        <h5>
                          {settingsProfile.defaultEmail === 1 &&
                            "As contas serão criadas com os endereços de e-mail especificados na integração."}
                          {settingsProfile.defaultEmail === 2 &&
                            "Os e-mails serão gerados automaticamente com o seguinte formato: PN.UN@dominio. Caso não seja possível criar um e-mail nesse formato, serão utilizadas várias outras combinações com o nome e sobrenome."}
                          {settingsProfile.defaultEmail === 4 &&
                            "Os e-mails serão gerados de forma automática, independente do tipo de integração selecionada. O e-mail será gerado com o primeiro nome e o ultimo nome separados por um ponto, caso não seja possível, irá utilizar o primeiro nome e a matrícula separados por um ponto."}
                          {settingsProfile.defaultEmail === 5 &&
                            "Não haverá geração de e-mails, apenas serão vinculados os e-mails já existentes."}
                          {settingsProfile.defaultEmail === 6 &&
                            "Os e-mails serão gerados automaticamente com o seguinte formato: MT@dominio."}
                          {settingsProfile.defaultEmail === 7 &&
                            "Os e-mails serão gerados automaticamente com o seguinte formato: PN.MT@dominio."}
                        </h5>
                      </Row>
                    </Col>
                    <div
                      className="mt-3 cursor-pointer"
                      onClick={() => setActiveModal(2)}
                    >
                      <Row className="button-edit">
                        <BsPencil className="mr-2" color="#0F7EAA" size={24} />
                        <h3>Editar</h3>
                      </Row>
                    </div>
                  </Row>
                </Card.Body>
              </Card>
              <Card className="card-integration mb-4">
                <Card.Title className="title-card">
                  <Col className="mt-3">
                    <h3>Senha padrão dos e-mails</h3>
                  </Col>
                </Card.Title>
                <Card.Body className="mt-0 pt-2">
                  <Row className="div-body ml-0 mb-3">
                    <Col className="mr-2">
                      <Row>
                        <h4>
                          {settingsProfile.defaultPassword === 3 &&
                            "Seguir planilha / consulta"}
                          {settingsProfile.defaultPassword === 1 &&
                            "Senha fixa"}
                          {settingsProfile.defaultPassword === 2 &&
                            "Geração automática"}
                          {settingsProfile.defaultPassword === 4 &&
                            "Senha aleatória"}
                        </h4>
                      </Row>
                      <Row>
                        <h5>
                          {settingsProfile.defaultPassword === 3 &&
                            " Serão utilizadas as senhas especificadas na integração."}
                          {settingsProfile.defaultPassword === 1 &&
                            " Serão utilizadas as senhas especificadas na integração."}
                          {settingsProfile.defaultPassword === 2 &&
                            " Serão utilizadas as senhas especificadas na integração."}
                          {settingsProfile.defaultPassword === 4 &&
                            " Serão utilizadas as senhas especificadas na integração."}
                        </h5>
                      </Row>
                    </Col>
                    <div
                      className="mt-3 cursor-pointer"
                      onClick={() => setActiveModal(3)}
                    >
                      <Row className="button-edit">
                        <BsPencil className="mr-2" color="#0F7EAA" size={24} />
                        <h3>Editar</h3>
                      </Row>
                    </div>
                  </Row>
                  <Row className="div-body-links ml-0">
                    <Col>
                      <Row>
                        <h4>
                          Solicitar redefinição de senha no primeiro acesso
                        </h4>
                      </Row>
                      <Row>
                        <h5>
                          As pessoas terão de alterar a sua senha na primeira
                          vez em que utilizarem a conta.
                        </h5>
                      </Row>
                    </Col>
                    <div className="mt-3 cursor-pointer">
                      <Toggle
                        handleSomething={() => {
                          setNewRequirePasswordRedefinition(
                            !settingsProfile.requirePasswordRedefinition
                          );
                          setUpdateIntegration(true);
                        }}
                        isSomething={
                          settingsProfile.requirePasswordRedefinition
                        }
                        title={
                          settingsProfile.requirePasswordRedefinition
                            ? "Ativado"
                            : "Desativado"
                        }
                      />
                    </div>
                  </Row>
                </Card.Body>
              </Card>
              <Card className="card-integration mb-4">
                <Card.Title className="title-card">
                  <Col className="mt-3">
                    <h3>Dados a serem exibidos</h3>
                  </Col>
                </Card.Title>
                <Card.Body className="pt-2">
                  <Row className="div-body-links ml-0">
                    <Col className="mr-2">
                      <Row>
                        <h5>
                          Os seguintes dados das contas serão exibidos nos
                          aplicativos. Estes dados deverão estar especificados
                          na integração para serem exibidos.
                        </h5>
                      </Row>
                      <Row>
                        <div>
                          <h4>
                            <ul className="settingsProfileUL settingsProfileAnswer">
                              {settingsProfile.integrateJobTitle && (
                                <li>Cargo</li>
                              )}
                              {settingsProfile.integrateDocument && (
                                <li>Documento</li>
                              )}
                              {settingsProfile.integrateCellphone && (
                                <li>Celular</li>
                              )}
                              {settingsProfile.integrateDepartment && (
                                <li>Departamento</li>
                              )}
                              {settingsProfile.integrateBirthday && (
                                <li>Data de Nascimento</li>
                              )}
                            </ul>
                          </h4>
                        </div>
                      </Row>
                    </Col>
                    <div className="mt-3 cursor-pointer">
                      <Row
                        className="button-edit"
                        onClick={() => setActiveModal(5)}
                      >
                        <BsPencil className="mr-2" color="#0F7EAA" size={24} />
                        <h3>Editar</h3>
                      </Row>
                    </div>
                  </Row>
                </Card.Body>
              </Card>
              <Card className="card-integration mb-4">
                <Card.Title className="title-card">
                  <Col className="mt-3">
                    <h3>Licença atribuída</h3>
                  </Col>
                </Card.Title>
                <Card.Body className="pt-2">
                  <Row className="div-body-links ml-0">
                    <Col>
                      <Row>
                        <h5>
                          A seguinte licença será atribuída às contas criadas
                          para este perfil.
                        </h5>
                      </Row>
                      <Row>
                        <h4 className="settingsProfileAnswer">
                          {licenseName !== ""
                            ? licenseName
                            : "Nenhuma licença atribuída"}
                        </h4>
                      </Row>
                    </Col>
                    <div className="mt-3 cursor-pointer">
                      <Row
                        className="button-edit"
                        onClick={() => setActiveModal(6)}
                      >
                        <BsPencil className="mr-2" color="#0F7EAA" size={24} />
                        <h3>Editar</h3>
                      </Row>
                    </div>
                  </Row>
                </Card.Body>
              </Card>
              <Card className="card-integration mb-4">
                <Card.Title className="title-card">
                  <Col className="mt-3">
                    <h3>Domínio</h3>
                  </Col>
                </Card.Title>
                <Card.Body className="pt-2">
                  <Row className="div-body-links ml-0">
                    <Col>
                      <Row>
                        <h5>
                          Este será o domínio utilizado para a criação de contas
                          com este perfil.
                        </h5>
                      </Row>
                      <Row>
                        {settingsProfile?.emailDomain
                          ?.split(",")
                          .map((item: any) => {
                            return (
                              <h4 className="d-block w-100 settingsProfileAnswer" key={item}>
                                {item}
                              </h4>
                            );
                          })}
                      </Row>
                    </Col>
                    <div className="mt-3 cursor-pointer">
                      <Row
                        className="button-edit"
                        onClick={() => setActiveModal(7)}
                      >
                        <BsPencil className="mr-2" color="#0F7EAA" size={24} />
                        <h3>Editar</h3>
                      </Row>
                    </div>
                  </Row>
                </Card.Body>
              </Card>

              <ComponentAuxiliaryLinks />
            </>
          )}
        </>
      )}
      {activeModal === 1 && (
        <ModalFirstConfigurationProfile loadPageProfile={loadPage} />
      )}
      {activeModal === 2 && <ModalEditEmailProfile />}
      {activeModal === 3 && <ModalEditPasswordProfile />}
      {activeModal === 4 && <ModalSuccessEditConfiguration />}
      {activeModal === 5 && <ModalEditVisibleData />}
      {activeModal === 6 && <ModalEditLicenseProfile />}
      {activeModal === 7 && <ModalEditDomainProfile />}
    </InitialHeaderStyles>
  );
}
