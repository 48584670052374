import { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { BsCheck2, BsChevronRight, BsX } from "react-icons/bs";
import Cookies from "universal-cookie";

import PageLoader from "../../../components/Common/Loader";
import ApiInterface from "../../../services/ApiInterface";
import { ModalGenericStyles } from "../../../styles/modalsGeneric";
import { GeneralConfigurationContext } from "../GeneralConfigurationContexts/GeneralConfigurationContext";

export default function ModalEditLicenseProfile() {
  const {
    activeModal,
    entityType,
    setUpdateIntegration,
    setActiveModal,
    settingsProfile,
    setNewLicenses,
  } = useContext(GeneralConfigurationContext);

  const cookie = new Cookies();
  const institutionId = cookie.get(process.env.REACT_APP_BRAINZ_INSTITUTIONID!);
  const [licenses, setLicenses] = useState([
    {
      id: "0",
      name: "Gratuita",
      licenseKey: "0",
    },
  ]);
  const [licenseSelected, setLicenseSelected] = useState(
    settingsProfile.licenseKey
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLicenseSelected(settingsProfile.licenseKey);
  }, []);

  useEffect(() => {
    loadPage();
  }, [activeModal]);

  function loadPage() {
    setLoading(true);
    ApiInterface.get("/Institution/GetLicenses/" + institutionId)
      .then((response) => {
        setLicenses(response.data.result);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Erro => ", error)
      });
  }
  return (
    <ModalGenericStyles
      show={activeModal === 6}
      onHide={() => setActiveModal(0)}
      className="ModalEditLicenseProfile"
    >
      <Modal.Header>
        <Modal.Title>
          <h2>
            {entityType} <BsChevronRight size={18} /> Licença Atribuída
          </h2>
        </Modal.Title>
        <Button
          className="cursor-pointer"
          variant="white"
          onClick={() => setActiveModal(0)}
        >
          <BsX color="var(--bs-modal-color)" size={28} />
        </Button>
      </Modal.Header>
      {loading ? (
        <PageLoader />
      ) : (
        <Modal.Body>
          <Row>
            <Col>
              <h4>
                A seguinte licença será atribuída às contas criadas para este
                perfil.
              </h4>
            </Col>
          </Row>
          {
            licenses?.map((item) => {
              return (
                <Row key={item.name}>
                  <Col
                    className={
                      item.licenseKey === licenseSelected
                        ? "card-option-selected"
                        : "card-option"
                    }
                  >
                    <Form.Check
                      className="button-radio mb-3 mt-2"
                      type="radio"
                      label={item.name}
                      checked={item.licenseKey === licenseSelected}
                      onChange={() => [
                        setLicenseSelected(item.licenseKey),
                        setNewLicenses(item.licenseKey),
                      ]}
                    />
                  </Col>
                </Row>
              );
            })}
        </Modal.Body>
      )}
      <Modal.Footer>
        <Button className="cancel-operation" onClick={() => setActiveModal(0)}>
          <BsX className=" mr-2" color="#A56300" size={24} />
          Cancelar
        </Button>
        <Button
          variant="primary"
          className="continue-button"
          onClick={() => [setActiveModal(0), setUpdateIntegration(true)]}
        >
          Salvar
          <BsCheck2 color="#fff" size={24} />
        </Button>
      </Modal.Footer>
    </ModalGenericStyles>
  );
}
