import pt from "date-fns/locale/pt";
import React, { useContext, useEffect, useState } from "react";
import { Col, Modal, Row, Form, Button } from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import {
  BsArrowLeft,
  BsArrowRight,
  BsCheck,
  BsCheck2Circle,
  BsChevronDown,
  BsChevronRight,
  BsChevronUp,
  BsDashCircle,
  BsX,
} from "react-icons/bs";
import Cookies from "universal-cookie";

import FrequencyIntegrationBody from "../../../components/Common/FrequencyIntegrationBody";
import PageLoader from "../../../components/Common/Loader";
import Stepper from "../../../components/Common/Stepper";
import TestConnection from "../../../components/Common/TestConnection/TestConnection";
import { showToast } from "../../../components/Common/Toast";
import Toggle from "../../../components/Common/Toggle";
import { ProfileContext } from "../../../contexts/ProfileContext";
import { requiredFieldMsg } from "../../../helpers/messagesValidation";
import {
  useValidation,
  isInvalidFeedBack,
  feedBack,
} from "../../../hooks/Validation";
import ApiInterface from "../../../services/ApiInterface";
import { ActiveModalIntegrationContext } from "../AutomaticIntegrationContexts/ActiveModalIntegrationContext";
import {
  handleFormatPeriodicIntervalToString,
  handleFormatScheduleTimeToString,
} from "../utils/functions";
import { ModalGAutomaticIntegrationStyles } from "./styles";

export default function ModalFirstSetupSql() {
  const { configurationProfile } = useContext(ProfileContext);
  const {
    setActiveModal,
    activeModal,
    setPreviousActiveModal,
    setNewTypeIntegration,
    frequencyType,
    setScheduleTimesList,
    setIntervalTimeList,
    scheduleTimesList,
    intervalTimeList,
    server,
    database,
    user,
    password,
    aditionalConfig,
    startDate,
  } = useContext(ActiveModalIntegrationContext);

  registerLocale("pt", pt);
  const cookie = new Cookies();
  const institutionId = cookie.get(process.env.REACT_APP_BRAINZ_INSTITUTIONID!);

  const [loading, setLoading] = useState<boolean>(false);
  const [stepFirstSetupSql, setStepFirstSetupSql] = useState(1);
  const [entityTeamAndTeacherExpand, setEntityTeamAndTeacherExpand] =
    useState(false);

  const [testConnectionServer, setTestConnectionServer] = useState(false);

  const [serverLocal, setServerLocal] = useState("");
  const [databaseLocal, setDatabaseLocal] = useState("");
  const [userLocal, setUserLocal] = useState("");
  const [passwordLocal, setPasswordLocal] = useState("");
  const [aditionalConfigLocal, setAditionalConfigLocal] = useState("");

  const [
    validationForm,
    setValidationFormField,
    validationErrors,
    setNewValidationErrors,
  ] = useValidation();
  const [connectionString, setConnectionString] = useState<
    string | undefined
  >();
  const [startDateLocal, setStartDateLocal] = useState<Date | undefined>();
  const [interval, setInterval] = useState("1");
  const [integrationHour, setIntegrationHour] = useState(0);
  const [errorExists, setErrorExists] = useState(false);
  const [integrationEntities, setIntegrationEntities] = useState<any>([]);

  const [selectedScheduleTimes, setSelectedScheduleTimes] = useState<any>([]);
  const [selectedInterval, setSelectedInterval] = useState<any>("");
  const [localFreqType, setLocalFreqType] = useState<any>(frequencyType);

  function handlerNextStep2() {
    const existErrorConnectionString = findErrorsServer();
    if (!existErrorConnectionString) {
      const partialCS =
        "Server=" +
        serverLocal +
        ";Database=" +
        databaseLocal +
        ";User Id=" +
        userLocal +
        ";Password=" +
        passwordLocal +
        ";" +
        aditionalConfigLocal;
      setConnectionString(partialCS);
      setStepFirstSetupSql(2);
    }
  }

  function handlerNextStep3() {
    const existErrorFrequencyIntegration = findErrors();
    if (!existErrorFrequencyIntegration) {
      if (localFreqType === 1) {
        const result = handleFormatScheduleTimeToString(selectedScheduleTimes);
        setScheduleTimesList(result);
      }

      if (localFreqType === 2) {
        const result = handleFormatPeriodicIntervalToString(
          selectedInterval?.value
        );
        setIntervalTimeList(result);
      }

      setStepFirstSetupSql(3);
    }
  }

  // function ClearIntegration() {
  //   setStepFirstSetupSql(1);
  //   setServerLocal("");
  //   setDatabaseLocal("");
  //   setUserLocal("");
  //   setPasswordLocal("");
  //   setAditionalConfigLocal("");
  //   setConnectionString("");
  //   setStartDateLocal(new Date());
  //   setInterval("1");
  //   setIntegrationHour(0);
  //   setErrorExists(false);
  // }

  function handleNameOnChange(index: number, value: string) {
    const masked = value.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "");
    setTable(index, masked);
  }

  function setTable(index: number, table: string) {
    const entities = [...integrationEntities];
    const item = entities[index];
    item.table = table;
    entities[index] = item;
    setIntegrationEntities(entities);
  }

  function findErrors() {
    const errors: any = {};
    if (validationForm.current) {
      if (
        !validationForm.current.startDateLocal ||
        validationForm.current.startDateLocal === ""
      )
        errors.startDateLocal = requiredFieldMsg;
      else errors.startDateLocal = undefined;

      if (
        !validationForm.current.interval ||
        validationForm.current.interval === "" ||
        validationForm.current.interval === "0"
      )
        errors.interval = requiredFieldMsg;
      else errors.interval = undefined;
    }

    const result = setNewValidationErrors(errors);
    return result;
  }

  function findErrorsEntity() {
    const errors: any = {};
    integrationEntities.forEach((entity) => {
      if (entity.active) {
        if (validationForm.current) {
          if (
            !validationForm.current[entity.name] ||
            validationForm.current[entity.name] === ""
          )
            errors[entity.name] = requiredFieldMsg;
          else errors[entity.name] = undefined;
        }
      }
    });
    const result = setNewValidationErrors(errors);
    return result;
  }

  function findErrorsServer() {
    const errors: any = {};

    if (validationForm.current) {
      if (
        !validationForm.current.serverLocal ||
        validationForm.current.serverLocal === ""
      )
        errors.serverLocal = requiredFieldMsg;
      else errors.serverLocal = undefined;

      if (
        !validationForm.current.databaseLocal ||
        validationForm.current.databaseLocal === ""
      )
        errors.databaseLocal = requiredFieldMsg;
      else errors.databaseLocal = undefined;

      if (
        !validationForm.current.userLocal ||
        validationForm.current.userLocal === ""
      )
        errors.userLocal = requiredFieldMsg;
      else errors.userLocal = undefined;

      if (
        !validationForm.current.passwordLocal ||
        validationForm.current.passwordLocal === ""
      )
        errors.passwordLocal = requiredFieldMsg;
      else errors.passwordLocal = undefined;
    }

    const result = setNewValidationErrors(errors);

    return result;
  }

  function handleClick(index: number) {
    const entities = [...integrationEntities];
    const item = entities[index];
    item.active = !item.active;
    entities[index] = item;
    setIntegrationEntities(entities);
    activateIntegrations();
  }

  function activateIntegrations() {
    integrationEntities.forEach((entity, index) => {
      if (entity.dependants.length > 0) {
        let active = true;
        entity.dependants.forEach((element) => {
          const dependantEntity = integrationEntities.find(
            (a) => a.id === element
          );
          if (dependantEntity && !dependantEntity.active) {
            active = false;
          }
        });
        setActive(index, active);
      }
    });
  }

  function setActive(index: number, active: boolean) {
    const entities = [...integrationEntities];
    const item = entities[index];
    item.active = active;
    entities[index] = item;
    setIntegrationEntities(entities);
  }

  function toggleInactiveAbsent(index: number, value: boolean) {
    const entities = [...integrationEntities];
    const item = entities[index];
    item.inactiveAbsent = value;
    entities[index] = item;
    setIntegrationEntities(entities);
  }

  function saveData() {
    setLoading(true);

    const errorsEntityViews = findErrorsEntity();
    if (!errorsEntityViews) {
      setStepFirstSetupSql(5);

      ApiInterface.get(
        "/Institution/ConnectionStringCheck/" + connectionString
      ).then((response) => {
        if (response.status === 200) {
          setTestConnectionServer(true);
          setLoading(false);
        } else {
          setTestConnectionServer(false);
          setLoading(false);
        }
      });

      const errorExist = findErrors();
      const connectionStringErrorExist = findErrorsServer();
      const errorEntitiesExist = findErrorsEntity();

      if (errorExist || connectionStringErrorExist || errorEntitiesExist) {
        setErrorExists(true);
      }
      if (!errorExist && !connectionStringErrorExist && !errorEntitiesExist) {
        setLoading(true);
        setTimeout(() => save(), 20 * 100);
      } else {
        showToast(
          "error",
          "Houve um erro com o formulário, valide seus dados e tente novamente"
        );
      }
    } else {
      setLoading(false);

    }
  }

  function save() {
    setNewTypeIntegration(1);

    const payloadSql = {
      institutionId,
      integrationEnabled: true,
      dataSource: 1,
      startDate: startDateLocal,
      hour: integrationHour,
      daysFrequency: +interval,

      frequencyType: localFreqType,
      scheduledHours:
        localFreqType === 1 ? scheduleTimesList : intervalTimeList,

      automaticIntegrationTypes: integrationEntities.map((entity) => {
        return {
          integrationType: entity.id,
          active: entity.active,
          inactiveAbsent: entity.inactiveAbsent,
          automaticIntegrationBySql: {
            connectionString,
            databaseView: entity.table,
          },
        };
      }),
    };
    ApiInterface.put(
      "/AutomaticIntegration/" + institutionId + "/Update",
      payloadSql
    )
      .then((response) => {
        if (testConnectionServer === true) {
          if (response.status === 200) {
            setActiveModal(0);
            setLoading(false);

            showToast(
              "success",
              "A configuração foi concluída com sucesso. Você está sendo redirecionado."
            );
            setTimeout(() => {
              window.location.reload();
            }, 20 * 100);
          } else {
            setLoading(false);
            showToast("error", "Algo deu errado, tente novamente mais tarde");
            console.error(
              "Erro na resposta do servidor ao salvar primeira configuração via SQL. Erro => ",
              response
            );
          }

          return;
        }

        setLoading(false);
        showToast(
          "error",
          "Não foi possível se conectar com o banco, tente novamente."
        );
        setTimeout(() => {
          window.location.reload();
        }, 15 * 100);
      })
      .catch((error) => {
        setLoading(false);
        showToast("error", "Algo deu errado, tente novamente mais tarde");
        setActiveModal(6);
        console.error(
          "Erro ao salvar a primeira integração via SQL. Erro => ",
          error
        );
      });
  }

  const handleValidateStartDate = () => {
    if (startDateLocal) return true;

    showToast("error", "Preencha o campo de data de início da integração.");
    return false;
  };

  useEffect(() => {
    updateValidation();
    function updateValidation() {
      validationForm.current = {
        serverLocal,
        databaseLocal,
        userLocal,
        passwordLocal,
        aditionalConfigLocal,
        startDateLocal,
        interval,
      };
    }
  }, [
    serverLocal,
    databaseLocal,
    userLocal,
    passwordLocal,
    aditionalConfigLocal,
    startDateLocal,
    interval,
  ]);

  useEffect(() => {
    setServerLocal(server);
  }, [server]);

  useEffect(() => {
    setDatabaseLocal(database);
  }, [database]);

  useEffect(() => {
    setUserLocal(user);
  }, [user]);

  useEffect(() => {
    setPasswordLocal(password);
  }, [password]);

  useEffect(() => {
    setAditionalConfigLocal(aditionalConfig);
  }, [aditionalConfig]);

  useEffect(() => {
    setStartDateLocal(startDate);
  }, [startDate]);

  useEffect(() => {
    setIntegrationEntities([
      {
        name: "Admininstrativo",
        id: 1,
        active: false,
        disabled: false,
        educational: true,
        inactiveAbsent: false,
        table: "",
        checkbox: true,
        configProfile:
          configurationProfile?.some((item: any) => item.userType === 1),
        dependants: [],
      },
      {
        name: "Professores",
        id: 10,
        active: false,
        disabled: false,
        educational: true,
        inactiveAbsent: false,
        table: "",
        checkbox: true,
        configProfile:
          configurationProfile?.some((item: any) => item.userType === 5),
        dependants: [],
      },
      {
        name: "Alunos",
        id: 9,
        active: false,
        disabled: false,
        educational: true,
        inactiveAbsent: false,
        table: "",
        checkbox: true,
        configProfile:
          configurationProfile?.some((item: any) => item.userType === 4),
        dependants: [],
      },
      {
        name: "Coordenador",
        id: 4,
        active: false,
        disabled: false,
        educational: true,
        inactiveAbsent: false,
        table: "",
        checkbox: true,
        configProfile:
          configurationProfile?.some((item: any) => item.userType === 3),
        dependants: [],
      },
      {
        name: "Turma",
        id: 13,
        active: false,
        disabled: false,
        educational: true,
        inactiveAbsent: false,
        table: "",
        checkbox: true,
        configProfile: true,
        dependants: [],
      },
      {
        name: "Unidades",
        id: 8,
        active: false,
        disabled: false,
        educational: true,
        inactiveAbsent: false,
        table: "",
        checkbox: true,
        configProfile: true,
        dependants: [],
      },
      {
        name: "Turma/Aluno",
        id: 14,
        active: false,
        disabled: true,
        educational: true,
        inactiveAbsent: false,
        table: "",
        checkbox: false,
        configProfile: true,
        dependants: [13, 9],
      },
      {
        name: "Turma/Professor",
        id: 15,
        active: false,
        disabled: true,
        educational: true,
        inactiveAbsent: false,
        table: "",
        checkbox: false,
        configProfile: true,
        dependants: [13, 10],
      },
      {
        name: "Turma/Administrativo",
        id: 17,
        active: false,
        disabled: true,
        educational: true,
        inactiveAbsent: false,
        table: "",
        checkbox: false,
        configProfile: true,
        dependants: [13, 1],
      },
      {
        name: "Turma/Coordenador",
        id: 12,
        active: false,
        disabled: false,
        educational: true,
        inactiveAbsent: false,
        table: "",
        checkbox: false,
        configProfile: true,
        dependants: [13, 4],
      },
    ]);
  }, [configurationProfile]);

  useEffect(() => {
    if (activeModal === 2) {
      setLocalFreqType(frequencyType);
    }
  }, [activeModal]);

  if (loading) return <PageLoader />;

  return configurationProfile ? (
    <ModalGAutomaticIntegrationStyles
      show={activeModal === 2}
      onHide={() => [setActiveModal(4), setPreviousActiveModal(2)]}
    >
      <Modal.Header>
        <Modal.Title>
          {stepFirstSetupSql === 1 ? (
            <>
              {" "}
              <h2>
                Integração via Banco <BsChevronRight size={18} /> Dados de
                acesso ao banco
              </h2>
            </>
          ) : (
            <></>
          )}
          {stepFirstSetupSql === 2 ? (
            <>
              {" "}
              <h2>
                Integração via Banco <BsChevronRight size={18} /> Frequência das
                integrações
              </h2>
            </>
          ) : (
            <></>
          )}
          {stepFirstSetupSql === 3 ? (
            <>
              {" "}
              <h2>
                Integração via Banco <BsChevronRight size={18} /> Entidades a
                serem integradas
              </h2>
            </>
          ) : (
            <></>
          )}
          {stepFirstSetupSql === 4 || stepFirstSetupSql === 5 ? (
            <>
              {" "}
              <h2>
                Integração via Banco <BsChevronRight size={18} /> Configurar
                entidades
              </h2>
            </>
          ) : (
            <></>
          )}
        </Modal.Title>
        <Button
          className="cursor-pointer"
          variant="white"
          onClick={() => setActiveModal(0)}
        >
          <BsX color="var(--bs-modal-color)" size={28} />
        </Button>
      </Modal.Header>
      {stepFirstSetupSql === 1 ? (
        <Modal.Body className="step-1">
          <Row>
            <Col>
              <h3>
                Informe os dados que devemos utilizar para acessar o seu banco
                de dados{" "}
              </h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <h4>Servidor e banco</h4>
            </Col>
          </Row>
          <Form.Group as={Row}>
            <Form.Label column sm={12}>
              Servidor*
            </Form.Label>
            <Col sm={12}>
              <Form.Control
                size="sm"
                defaultValue={serverLocal}
                placeholder="Informe o endereço do servidor"
                isInvalid={isInvalidFeedBack("serverLocal", validationErrors)}
                onChange={(e) => [
                  setServerLocal(e.target.value),
                  setValidationFormField("serverLocal", e.target.value),
                ]}
              />
              <Form.Control.Feedback type="invalid">
                {feedBack("serverLocal", validationErrors)}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={12}>
              Banco
            </Form.Label>
            <Col sm={12}>
              <Form.Control
                size="sm"
                defaultValue={databaseLocal}
                placeholder="Informe a rota para o banco de dados"
                isInvalid={isInvalidFeedBack("databaseLocal", validationErrors)}
                onChange={(e) => [
                  setDatabaseLocal(e.target.value),
                  setValidationFormField("databaseLocal", e.target.value),
                ]}
              />
              <Form.Control.Feedback type="invalid">
                {feedBack("databaseLocal", validationErrors)}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Row className="line-body"></Row>
          <Row>
            <Col>
              <h4>Autenticação</h4>
            </Col>
          </Row>
          <Form.Group as={Row}>
            <Form.Label column sm={12}>
              Usuário
            </Form.Label>
            <Col sm={12}>
              <Form.Control
                size="sm"
                defaultValue={userLocal}
                placeholder="Informe o usuário para acesso ao banco"
                isInvalid={isInvalidFeedBack("userLocal", validationErrors)}
                onChange={(e) => [
                  setUserLocal(e.target.value),
                  setValidationFormField("userLocal", e.target.value),
                ]}
              />
              <Form.Control.Feedback type="invalid">
                {feedBack("userLocal", validationErrors)}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={12}>
              Senha:
            </Form.Label>
            <Col sm={12}>
              <Form.Control
                size="sm"
                defaultValue={passwordLocal}
                placeholder="Informe a senha para acesso ao banco"
                isInvalid={isInvalidFeedBack("passwordLocal", validationErrors)}
                onChange={(e) => [
                  setPasswordLocal(e.target.value),
                  setValidationFormField("passwordLocal", e.target.value),
                ]}
              />
              <Form.Control.Feedback type="invalid">
                {feedBack("passwordLocal", validationErrors)}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={12}>
              Parâmetros opcionais:
            </Form.Label>
            <Col sm={12}>
              <Form.Control
                size="sm"
                defaultValue={aditionalConfigLocal}
                placeholder="Caso desejar, forneça outros parâmetros para acesso ao banco"
                isInvalid={isInvalidFeedBack(
                  "aditionalConfigLocal",
                  validationErrors
                )}
                onChange={(e) => setAditionalConfigLocal(e.target.value)}
              />
              {/* <Form.Control.Feedback type="invalid">{feedBack("aditionalConfigLocal", validationErrors)}</Form.Control.Feedback> */}
            </Col>
          </Form.Group>
        </Modal.Body>
      ) : (
        <></>
      )}
      {stepFirstSetupSql === 2 ? (
        <Modal.Body className="step-2">
          <Row>
            <Col>
              <h3>
                Configure a frequência com que as integrações serão feitas
              </h3>
            </Col>
          </Row>
          <Form.Group as={Row}>
            <Form.Label column sm={12}>
              Data de início da integração
            </Form.Label>
            <Col sm={12}>
              <DatePicker
                className="input-date w-100"
                placeholderText={
                  "Selecione uma data para iniciar as integrações"
                }
                dateFormat="dd/MM/yyyy"
                selectsRange={false}
                minDate={new Date()}
                locale="pt"
                selected={startDateLocal}
                onChange={(date) => date && setStartDateLocal(date as Date)}
                todayButton="Hoje"
              />
            </Col>
          </Form.Group>
          <br />
          <FrequencyIntegrationBody
            handleChangeSelectedScheduleTimes={(value: any) =>
              setSelectedScheduleTimes(value)
            }
            handleChangeSelectedInterval={(value: any) =>
              setSelectedInterval(value)
            }

            onChangeFreqType={(value: any) => setLocalFreqType(value)}
            frequencyTypeData={localFreqType}
          />
        </Modal.Body>
      ) : (
        <></>
      )}
      {stepFirstSetupSql === 3 ? (
        <Modal.Body className="step-3">
          <Row>
            <Col>
              <h3>Escolha as entidades que deseja integrar</h3>
            </Col>
          </Row>
          <Form.Group as={Col}>
            {integrationEntities?.map((entity, index) => {
              if (entity.educational && entity.checkbox)
                if (entity.configProfile) {
                  return (
                    <Form.Check
                      key={entity.id}
                      className="button-radio button-radio-spaces"
                      checked={entity.active}
                      disabled={entity.disabled}
                      label={entity.name}
                      onChange={() => handleClick(index)}
                    />
                  );
                } else return "";

              return "";
            })}
          </Form.Group>
        </Modal.Body>
      ) : (
        <></>
      )}
      {stepFirstSetupSql === 4 ? (
        <Modal.Body className="step-4">
          <Row>
            <Col>
              <h3>
                Insira os dados de cada entidade a ser integrada.
                <br />
                <br />
                Utilize a função <strong>“Inativar Ausentes”</strong> para
                remover os registros que foram integrados anteriormente mas não
                estão presentes na nova integração.
              </h3>
            </Col>
          </Row>
          {integrationEntities?.map((entity, index) => {
            if (entity.active) {
              return (
                <div className="p-2 mb-2" key={entity.id}>
                  <Row className="my-3 mr-1">
                    <Col md={1}>
                      {entityTeamAndTeacherExpand === false ? (
                        <BsCheck2Circle
                          color="#008555"
                          size={24}
                          className="bi bi-check2-circle"
                        />
                      ) : (
                        <BsDashCircle color="#525252" size={24} />
                      )}
                    </Col>
                    <Col md={10}>
                      <h4>{entity.name}</h4>
                    </Col>
                    {entityTeamAndTeacherExpand === false ? (
                      <Col
                        md={1}
                        className=""
                        onClick={() => setEntityTeamAndTeacherExpand(true)}
                      >
                        <BsChevronDown color="#6F6F6F" size={22} />
                      </Col>
                    ) : (
                      <Col
                        md={1}
                        className=""
                        onClick={() => setEntityTeamAndTeacherExpand(false)}
                      >
                        <BsChevronUp color="#0F7EAA" size={22} />
                      </Col>
                    )}
                  </Row>
                  <div>
                    <Row>
                      {entity.id === 8 ? (
                        ""
                      ) : (
                        <Col md={12}>
                          <Row className="line-inactivate">
                            <div>
                              <h3>Inativar ausentes</h3>
                            </div>
                            <div className="row mr-2">
                              {entity.inactiveAbsent === false ? (
                                <Toggle
                                  handleSomething={() =>
                                    toggleInactiveAbsent(index, true)
                                  }
                                  isSomething={entity.inactiveAbsent}
                                  title="Desativado"
                                />
                              ) : (
                                <Toggle
                                  handleSomething={() =>
                                    toggleInactiveAbsent(index, false)
                                  }
                                  isSomething={entity.inactiveAbsent}
                                  title="Ativado"
                                />
                              )}
                            </div>
                          </Row>
                        </Col>
                      )}
                      <Col md={12}>
                        <Form.Group>
                          <Form.Label>Tabela/View</Form.Label>
                          <Form.Control
                            size="sm"
                            defaultValue={entity?.table ?? ""}
                            value={entity.table}
                            isInvalid={isInvalidFeedBack(
                              entity.name,
                              validationErrors
                            )}
                            placeholder="Onde podemos encontrar os dados desta entidade?"
                            onChange={(e) => [
                              handleNameOnChange(index, e.target.value),
                              setValidationFormField(
                                entity.name,
                                e.target.value
                              ),
                            ]}
                          />
                          <Form.Control.Feedback type="invalid">
                            {feedBack(entity.name, validationErrors)}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                </div>
              );
            } else return "";
          })}
        </Modal.Body>
      ) : (
        <></>
      )}
      {stepFirstSetupSql === 5 && <TestConnection />}
      <Modal.Footer>
        {stepFirstSetupSql === 1 ? (
          <>
            <Button
              variant="primary"
              className="cancel-operation"
              onClick={() => setActiveModal(1)}
            >
              <BsArrowLeft color="#a56300" size={22} />
              Voltar
            </Button>
            <Stepper currentStep={1} totalSteps={4} />
            <Button
              variant="primary"
              className="continue-button"
              //disabled={buttonDisable}
              onClick={() => handlerNextStep2()}
            >
              Continuar
              <BsArrowRight color="#Fff" size={22} />
            </Button>
          </>
        ) : (
          <></>
        )}
        {stepFirstSetupSql === 2 ? (
          <>
            <Button
              variant="primary"
              className="cancel-operation"
              onClick={() => setStepFirstSetupSql(1)}
            >
              <BsArrowLeft color="#a56300" size={22} />
              Voltar
            </Button>
            <Stepper currentStep={2} totalSteps={4} />
            <Button
              variant="primary"
              className="continue-button"
              //disabled={buttonDisable}
              onClick={() => {
                handleValidateStartDate();
                handlerNextStep3();
              }}
            >
              Continuar
              <BsArrowRight color="#Fff" size={22} />
            </Button>
          </>
        ) : (
          <></>
        )}
        {stepFirstSetupSql === 3 ? (
          <>
            <Button
              variant="primary"
              className="cancel-operation"
              onClick={() => setStepFirstSetupSql(2)}
            >
              <BsArrowLeft color="#a56300" size={22} />
              Voltar
            </Button>
            <Stepper currentStep={3} totalSteps={4} />
            <Button
              variant="primary"
              className="continue-button"
              //disabled={buttonDisable}
              onClick={() => setStepFirstSetupSql(4)}
            >
              Continuar
              <BsArrowRight color="#Fff" size={22} />
            </Button>
          </>
        ) : (
          <></>
        )}
        {stepFirstSetupSql === 4 ? (
          <>
            <Button
              variant="primary"
              className="cancel-operation"
              onClick={() => setStepFirstSetupSql(3)}
            >
              <BsArrowLeft color="#a56300" size={22} />
              Voltar
            </Button>
            <Stepper currentStep={4} totalSteps={4} />
            <Button
              variant="primary"
              className="sucess-button"
              //disabled={buttonDisable}
              onClick={() => saveData()}
            >
              Finalizar
              <BsCheck color="#fff" size={24} />
            </Button>
          </>
        ) : (
          <></>
        )}

        {stepFirstSetupSql === 5 ? (
          <>
            <div></div>
            <Button variant="primary" className="cancel-operation">
              <BsX color="#a56300" size={22} />
              Cancelar Operação
            </Button>
            <div></div>
          </>
        ) : (
          ""
        )}
      </Modal.Footer>
    </ModalGAutomaticIntegrationStyles>
  ) : (
    <></>
  );
}
