import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsExclamationCircle } from "react-icons/bs";
import Cookies from "universal-cookie";

import PageLoader from "../../components/Common/Loader";
import ConfirmModal from "../../components/Common/Modals/ConfirmModal";
import { showToast } from "../../components/Common/Toast";
import TeamApiInterface from "../../services/TeamApiInterface";
import DataTeamComponent from "./ComponentsTeam/DataTeamComponent";
import FiltersTeamComponent from "./ComponentsTeam/FiltersTeamComponent";
import ModalAddUserTeam from "./Modals/ModalAddUserTeam";
import ModalDetailsEditTeam from "./Modals/ModalDetailsEditTeam";
import { ActiveModalContext } from "./SyncDataContexts/ActiveModalContext";

export interface BrainzTeamPayload {
  id?: string;
  name: string;
  identifier: string;
  unitIdentifier: string;
  unitId?: string;
}

export interface BrainzUnitViewModel {
  id: string;
  name: string;
  identifier: string;
  creationDate: Date;
  active: boolean;
}

export interface BrainzTeamViewModel {
  name: string;
  identifier: string;
  creationDate: Date;
  active: boolean;
  unitTableIdentifier: string;
  unit: BrainzUnitViewModel;
  unitId: string;
  serviceId: string;
  id: string;
}

export interface BrainzTeamTableViewModel {
  name: string;
  identifier: string;
  creationDate: Date;
  active: boolean;
  unitName: string;
  unitTableIdentifier: string;
  serviceId: string;
  id: string;
}

export default function SyncDataTeam(props: any) {
  const { teamId, identifierTeam } = useContext(ActiveModalContext);

  const cookie = new Cookies();
  const institutionId = cookie.get(process.env.REACT_APP_BRAINZ_INSTITUTIONID!);

  const [loading, setLoading] = useState(false);
  const [t] = useTranslation("common");
  const [userType, setUserType] = useState<number>(0);
  const [activeFilter, setActiveFilter] = useState("");
  const [itemDetails, setItemDetails] = useState<
    BrainzTeamViewModel | undefined
  >();
  const [updateUser, setUpdateUser] = useState(false);
  const [buttonClearFilters, setButtonClearFilters] = useState(false);
  const [unitIdFilter, setUnitIdFilter] = useState("");
  const [searchFilter, setSearchFilter] = useState<string>("");

  const [show, setShow] = useState(false);
  const handleModalshowEditTeamClose = () => setShow(false);
  const handleModalshowEditTeamShow = () => setShow(true);

  const [showAddUsers, setShowAddUsers] = useState(false);

  const [showActiveTeam, setShowActiveTeam] = useState(false);
  const handleModalActiveTeamClose = () => setShowActiveTeam(false);
  const handleModalActiveTeamShow = () => setShowActiveTeam(true);
  const [toDeleteItem, setToDeleteItem] = useState<string>("");

  const [itemActive, setItemActive] = useState<boolean>();
  const [toReactiveItem, setToReactiveItem] = useState<
    BrainzTeamViewModel | undefined
  >();
  const [unitIdentifier, setUnitIdentifier] = useState("");

  const handleModalshowAddUsersClose = () => [
    setShow(true),
    setShowAddUsers(false),
  ];

  const handleModalshowAddUsersShow = () => [
    setShow(false),
    setShowAddUsers(true),
    setUserType(0),
  ];

  function deleteData() {
    handleModalActiveTeamClose();
    setLoading(true);
    const payload: Array<string> = [toDeleteItem];
    return TeamApiInterface.delete(`/Team/${institutionId}/Inactive`, {
      data: payload,
    })
      .then((response) => {
        if (response.status === 200) {
          const errors = response.data.result[0].errors;
          if (!errors || errors.length === 0) {
            showToast("info", "Concluído com sucesso.");
            handleModalActiveTeamClose();
            setUpdateUser(true);
            setUpdateUser(false);
            setLoading(false);
          } else {
            setLoading(false);
            showToast("error", t("errors." + errors[0].code));
          }
        } else {
          setLoading(false);
          showToast("error", "Algo deu errado, tente novamente mais tarde");
        }
      })
      .catch((error) => {
        setLoading(false);
        showToast("error", "Algo deu errado, tente novamente mais tarde");
        console.error("Erro => ", error)
      });
  }

  function reactiveData() {
    handleModalActiveTeamClose();
    setLoading(true);
    const payload = [
      {
        id: toReactiveItem?.id,
        unitIdentifier: toReactiveItem?.unitTableIdentifier,
        unitId: toReactiveItem?.unitId,
        identifier: toReactiveItem?.identifier,
        name: toReactiveItem?.name,
      },
    ];
    return TeamApiInterface.patch(`/Team/${institutionId}/Reactive`, payload)
      .then((response) => {
        if (response.status === 200) {
          const errors = response.data.result[0].errors;
          if (!errors || errors.length === 0) {
            showToast("info", "Concluído com sucesso.");
            setUpdateUser(true);
            setUpdateUser(false);
            setLoading(false);
          } else {
            setLoading(false);
            showToast("error", t("errors." + errors[0].code));
          }
        } else {
          setLoading(false);
          showToast("error", "Algo deu errado, tente novamente mais tarde");
        }
      })
      .catch((error) => {
        setLoading(false);
        showToast("error", "Algo deu errado, tente novamente mais tarde");
        console.error("Error => ", error)
      });
  }

  function ClearFilters() {
    setActiveFilter("");
    setSearchFilter("");
    setUnitIdFilter("");
    setButtonClearFilters(false);
    setUnitIdentifier("");
  }

  if (loading) return <PageLoader />;

  return (
    <>
      <FiltersTeamComponent
        searchFilter={searchFilter}
        setSearchFilter={setSearchFilter}
        activeFilter={activeFilter}
        setActiveFilter={setActiveFilter}
        unitIdFilter={unitIdFilter}
        setUnitIdFilter={setUnitIdFilter}
        buttonClearFilters={buttonClearFilters}
        ClearFilters={ClearFilters}
        setActiveModal={props.setActiveModal}
      />

      <DataTeamComponent
        setItemActive={setItemActive}
        setToDeleteItem={setToDeleteItem}
        setToReactiveItem={setToReactiveItem}
        handleModalActiveTeamShow={handleModalActiveTeamShow}
        handleModalshowEditTeamShow={handleModalshowEditTeamShow}
        searchFilter={searchFilter}
        activeFilter={activeFilter}
        unitIdFilter={unitIdFilter}
        setButtonClearFilters={setButtonClearFilters}
        setUnitIdentifier={setUnitIdentifier}
        setItemDetails={setItemDetails}
        ClearFilters={ClearFilters}
        updateUser={updateUser}
      />

      {itemDetails !== undefined && (
        <ModalDetailsEditTeam
          show={show && itemDetails !== undefined}
          onHide={handleModalshowEditTeamClose}
          itemDetails={itemDetails}
          identifierTeam={identifierTeam}
          teamId={teamId}
          unitIdentifier={unitIdentifier}
          setUnitIdentifier={setUnitIdentifier}
          setUpdateUser={setUpdateUser}
          AddUsersShow={handleModalshowAddUsersShow}
          ClearFilters={ClearFilters}
        />
      )}

      <ConfirmModal
        show={showActiveTeam && itemActive === true}
        onHide={handleModalActiveTeamClose}
        title="Deseja inativar essa turma?"
        subTitle="Você poderá ativar a turma novamente."
        confirmAction={deleteData}
        refuseAction={handleModalActiveTeamClose}
        confirmText="Inativar turma"
        refuseText="Manter ativa"
        principalIcon={<BsExclamationCircle color="#A56300" size={110} />}
      />

      <ConfirmModal
        show={showActiveTeam && itemActive === false}
        onHide={handleModalActiveTeamClose}
        title="Deseja reativar essa turma?"
        subTitle="Você poderá inativar a turma novamente."
        confirmAction={reactiveData}
        refuseAction={handleModalActiveTeamClose}
        confirmText="Reativar turma"
        refuseText="Manter inativa"
        principalIcon={<BsExclamationCircle color="#A56300" size={110} />}
      />

      <ModalAddUserTeam
        showAddUsers={showAddUsers}
        onHide={handleModalshowAddUsersClose}
        userType={userType}
        setUserType={setUserType}
        loading={loading}
        setLoading={setLoading}
      />
    </>
  );
}
