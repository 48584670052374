export const customTooltipTextEmailsOption1 = `<h4>Legenda:</h4>
PN = primeiro nome
<br />
UN = último nome
<br />
NM = nome do meio (válido para todos os nomes do meio)
<br />
PL_PN = primeira letra do primeiro nome
<br />
PL_UN = primeira letra do último nome
<br />
PL_NM = primeira letra do nome do meio (válido para todos os nomes do meio)
<br />
<br />

<h4>Ordem de tentativa de criação:</h4>
1. PN.UN@dominio
<br />
2. PN.NM@dominio
<br />
3. PN.PL_UN@dominio
<br />
4. PN.PL_NM@dominio
<br />
5. PNUN@dominio
<br />
6. PNNM@dominio
<br />
7. PNPL_UN@dominio
<br />
8. PNPL_NM@dominio
<br />
9. UN.PN@dominio
<br />
10. NM.PN@dominio
<br />
11. PL_UN.PN@dominio
<br />
12. PL_NM.PN@dominio
<br />
13. UNPN@dominio
<br />
14. NMPN@dominio
<br />
15. PL_UNPN@dominio
<br />
16. PL_NMPN@dominio
<br />
17. PN.Matricula@dominio
<br />
`;

export const customTooltipTextAutomaticPassword = `A senha será gerada no seguinte modelo: <br/><br/> <strong>Nome@Ano@Matrícula</strong>
<br/><br/> <h5>Exemplo</h5> Nome: John <br /> Ano atual: 2023 <br/> Matrícula: 0001 <br/> <br/> Senha gerada: <strong>John@2023@0001</strong>
`;
