import { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import {
  BsCheck,
  BsCheckCircle,
  BsChevronRight,
  BsPencil,
  BsPersonPlus,
  BsX,
  BsXCircle,
  BsXOctagon,
} from "react-icons/bs";
import Cookies from "universal-cookie";

import BrainzComboBox from "../../../components/Common/BrainzComboBox";
import PageLoader from "../../../components/Common/Loader";
import { showToast } from "../../../components/Common/Toast";
import {
  invalidFieldMsg,
  requiredFieldMsg,
} from "../../../helpers/messagesValidation";
import {
  feedBack,
  isInvalidFeedBack,
  useValidation,
} from "../../../hooks/Validation";
import TeamApiInterface from "../../../services/TeamApiInterface";
import UnitApiInterface from "../../../services/UnitApiInterface";
import { ModalGenericStyles } from "../../../styles/modalsGeneric";
import ComponentUsersTeam from "../ComponentUsersTeam";
import { BrainzTeamPayload } from "../SyncDataTeam";

interface DetailsTeamProps {
  show: boolean;
  onHide: () => void;
  AddUsersShow: () => void;
  itemDetails: any;
  identifierTeam: string;
  teamId: string;
  unitIdentifier: string;
  setUnitIdentifier: React.Dispatch<React.SetStateAction<string>>;
  setUpdateUser: React.Dispatch<React.SetStateAction<boolean>>;
  ClearFilters: () => void;
}

export default function ModalDetailsEditTeam(props: DetailsTeamProps) {
  const cookie = new Cookies();
  const institutionId = cookie.get(process.env.REACT_APP_BRAINZ_INSTITUTIONID!);

  const [loading, setLoading] = useState(false);
  const [buttonEdit, setButtonEdit] = useState(false);
  const [
    validationForm,
    setValidationFormField,
    validationErrors,
    setNewValidationErrors,
  ] = useValidation();
  const [id, setId] = useState("");
  const [identifier, setIdentifier] = useState("");
  const [name, setName] = useState("");

  function handleUpdateOnClick(itemDetails: any) {
    setButtonEdit(true);
    validationForm.current = {};
    setNewValidationErrors({});
    setId(itemDetails.id);
    setIdentifier(itemDetails.identifier);
    setName(itemDetails.name);
    props.setUnitIdentifier(itemDetails.unitTableIdentifier);
    setValidationFormField("unitIdentifier", itemDetails.unitTableIdentifier);
    setValidationFormField("name", itemDetails.name);
  }

  function handleUnitIdentifierOnChange(value: string) {
    props.setUnitIdentifier(value);
    setValidationFormField("unitIdentifier", value);
  }

  function handleNameOnChange(value: string) {
    setName(value);
    setValidationFormField("name", value);
  }

  function findErrorsTeam() {
    const result: any = {};

    if (validationForm.current) {
      if (!validationForm.current.name || validationForm.current.name === "")
        result.name = requiredFieldMsg;
      else if (validationForm.current.name.length < 3)
        result.name = invalidFieldMsg;
      else result.name = undefined;

      if (
        !validationForm.current.unitIdentifier ||
        validationForm.current.unitIdentifier === ""
      )
        result.unitIdentifier = requiredFieldMsg;
      else result.unitIdentifier = undefined;
    }
    return result;
  }

  function handleSaveData() {
    const errors = findErrorsTeam();
    const errorExist = setNewValidationErrors(errors);

    if (errorExist) {
      return;
    }
    if (!id) {
      createData();
    } else {
      updateData();
    }
    function createData() {
      setButtonEdit(false);
      const payload: Array<BrainzTeamPayload> = [
        {
          name,
          identifier,
          unitIdentifier: props.unitIdentifier,
        },
      ];

      setLoading(true);
      return TeamApiInterface.post(`/Team/${institutionId}/Insert`, payload)
        .then((response) => {
          if (response.status === 200) {
            const errors = response.data.result[0].errors;
            if (!errors || errors.length === 0) {
              showToast("info", "Concluído com sucesso.");
            } else {
              setLoading(false);
              showToast(
                "error",
                `${errors[0].name} Code: ${errors[0].code} Id: ${errors[0].id}`
              );
            }
          } else {
            setLoading(false);
            showToast("error", "Algo deu errado, tente novamente mais tarde");
          }
        })
        .catch((error) => {
          setLoading(false);
          showToast("error", "Algo deu errado, tente novamente mais tarde");
          console.error("Erro => ", error);
        });
    }

    function updateData() {
      setButtonEdit(false);
      setLoading(true);

      const payload: Array<BrainzTeamPayload> = [
        {
          id,
          name,
          identifier,
          unitIdentifier: props.unitIdentifier,
        },
      ];

      setLoading(true);
      return TeamApiInterface.put(`/Team/${institutionId}/Update`, payload)
        .then((response) => {
          if (response.status === 200) {
            const errors = response.data.result[0].errors;
            if (!errors || errors.length === 0) {
              setButtonEdit(false);
              setLoading(false);
              props.setUpdateUser(true);
            } else {
              setLoading(false);

              showToast(
                "error",
                `${errors[0].name} Code: ${errors[0].code} Id: ${errors[0].id}`
              );
            }

            props.onHide();
          } else {
            setLoading(false);
            showToast("error", "Algo deu errado, tente novamente mais tarde");
          }
        })
        .catch((error) => {
          setLoading(false);
          showToast("error", "Algo deu errado, tente novamente mais tarde");
          console.error("Erro => ", error);
        });
    }
  }

  return (
    <>
      {loading ? (
        <PageLoader />
      ) : (
        <ModalGenericStyles
          show={props.show}
          onHide={props.onHide}
          className="pr-4"
        >
          <Modal.Header>
            <Modal.Title>
              <h2>
                Detalhes da Turma
                {buttonEdit && (
                  <>
                    <BsChevronRight size={18} />

                    <span>Editar Detalhes da Turma</span>
                  </>
                )}
              </h2>
            </Modal.Title>
            <Button
              className="cursor-pointer"
              variant="white"
              onClick={() => props.onHide()}
            >
              <BsX color="var(--bs-modal-color)" size={28} />
            </Button>
          </Modal.Header>

          <Modal.Body>
            <Form>
              <Form.Group as={Row} className=" my-0 pt-3">
                <Col sm={3}>
                  <Form.Label column sm={12} className=" my-0 py-0">
                    Status
                  </Form.Label>
                  <Form.Label column sm={12} className=" my-0 py-0">
                    {props.itemDetails.active ? (
                      <>
                        <BsCheckCircle
                          size={24}
                          color="#008555"
                          className="mr-2"
                        />
                        <span>Ativo</span>
                      </>
                    ) : (
                      <>
                        <BsXOctagon
                          className="mr-2"
                          color="#A56300"
                          size={24}
                        />
                        <span>Inativo</span>
                      </>
                    )}
                  </Form.Label>
                </Col>
                <Col sm={3}>
                  <Form.Label column className=" my-0 py-0">
                    Criado em
                  </Form.Label>
                  <Form.Label column className=" my-0 py-0">
                    <h2>
                      {new Date(
                        props.itemDetails.creationDate
                      ).toLocaleString()}
                    </h2>
                  </Form.Label>
                </Col>
                <Col sm={6}>
                  <Form.Label column className=" my-0 py-0">
                    Identificador
                  </Form.Label>
                  <Form.Label column sm={12} className=" my-0 py-0">
                    <h2>{props.itemDetails.identifier}</h2>
                  </Form.Label>
                </Col>
              </Form.Group>

              <br />

              <Form.Group as={Row}>
                <Col sm={6}>
                  <Form.Label column className=" my-0 py-0">
                    Nome
                  </Form.Label>
                  {!buttonEdit ? (
                    <Form.Label column sm={12} className=" my-0 py-0">
                      <h2>{name === "" ? props.itemDetails.name : name}</h2>
                    </Form.Label>
                  ) : (
                    <>
                      <Form.Control
                        placeholder=""
                        type="text"
                        name="name"
                        value={name}
                        isInvalid={isInvalidFeedBack("name", validationErrors)}
                        onChange={(e) => {
                          handleNameOnChange(e.target.value);
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {feedBack("name", validationErrors)}
                      </Form.Control.Feedback>
                    </>
                  )}
                </Col>
                <Col sm={6}>
                  <Form.Label column className=" my-0 py-0">
                    Unidade
                  </Form.Label>
                  {!buttonEdit ? (
                    <Form.Label column sm={12} className=" my-0 py-0">
                      <h2>{props.itemDetails.unitName}</h2>
                    </Form.Label>
                  ) : (
                    <Row>
                      <Col sm={10}>
                        <BrainzComboBox
                          setValue={(val) => handleUnitIdentifierOnChange(val)}
                          value={props.unitIdentifier}
                          endpoint={`/Unit/${institutionId}/List`}
                          idField="identifier"
                          valueField="name"
                          placeholder={props.itemDetails.unitName}
                          isInvalid={isInvalidFeedBack(
                            "unitIdentifier",
                            validationErrors
                          )}
                          interface={UnitApiInterface}
                        />
                        <Form.Control.Feedback type="invalid">
                          {feedBack("unitIdentifier", validationErrors)}
                        </Form.Control.Feedback>
                      </Col>
                      <Col sm={1} className="p-0 m-0">
                        <Button
                          className="clear-filter"
                          onClick={() => props.ClearFilters()}
                        >
                          <BsXCircle size={24} color="#A56300" />
                        </Button>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Form.Group>
            </Form>
            {!buttonEdit && <ComponentUsersTeam />}
          </Modal.Body>
          <Modal.Footer>
            {!buttonEdit ? (
              <>
                <Button
                  className="button-edit"
                  variant="primary"
                  style={{ width: "200px", maxWidth: "300px" }}
                  onClick={() => props.AddUsersShow()}
                >
                  <BsPersonPlus size={22} />
                  Adicionar pessoa
                </Button>

                <Button
                  className="button-edit"
                  variant="primary"
                  style={{ width: "200px", maxWidth: "300px" }}
                  onClick={() => handleUpdateOnClick(props.itemDetails)}
                >
                  <BsPencil size={22} />
                  Editar detalhes
                </Button>
              </>
            ) : (
              <>
                <Button
                  className="cancel-operation"
                  variant="primary"
                  onClick={() => setButtonEdit(false)}
                >
                  <BsX size={24} />
                  Cancelar Edição
                </Button>
                <Button
                  className="continue-operation"
                  variant="primary"
                  onClick={handleSaveData}
                >
                  Salvar Alterações
                  <BsCheck size={24} />
                </Button>
              </>
            )}
          </Modal.Footer>
        </ModalGenericStyles>
      )}
    </>
  );
}
