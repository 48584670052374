import { useContext, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { BsFiletypeCsv, BsFiletypeXlsx, BsX } from "react-icons/bs";
import Cookies from "universal-cookie";

import PageLoader from "../../../../components/Common/Loader";
import { showToast } from "../../../../components/Common/Toast";
import { SyncDataFilterContext } from "../../../../contexts/SyncDataFilterContext";
import { downloadCSV, downloadXLSX } from "../../../../helpers/functions";
import TeamApiInterface from "../../../../services/TeamApiInterface";
import UserApiInterface from "../../../../services/UserApiInterface";
import { ModalGenericStyles } from "../../../../styles/modalsGeneric";
import { ActiveModalContext } from "../../SyncDataContexts/ActiveModalContext";
import "./styles.css";

export const ModalExportData = () => {
  const cookie = new Cookies();
  const institutionId = cookie.get(process.env.REACT_APP_BRAINZ_INSTITUTIONID!);
  const { activeModal, setActiveModal } = useContext(ActiveModalContext);
  const {
    activeFilterSaved,
    searchFilterSaved,
    userTypeSaved,
    currentTab,
    unitIdTeamSaved,
  } = useContext(SyncDataFilterContext);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [csvData, setCsvData] = useState<any>(null);
  const [xlsxData, setXlsxData] = useState<any>(null);

  const handleQueryFilters = () => {
    let query = "";
    query = query += "?search=" + searchFilterSaved ?? "";
    if (activeFilterSaved === "true") query = query += "&activeFilter=true";
    if (activeFilterSaved === "false") query = query += "&activeFilter=false";
    if (activeFilterSaved === "") query = query += "";
    if (userTypeSaved && currentTab === "users") query = query += "&userType=" + userTypeSaved;
    if (unitIdTeamSaved && currentTab === "teams") query = query += "&unitId=" + unitIdTeamSaved;

    return query;
  };

  const convertToXlsx = (response) => {
    if (!response.data) return
    setXlsxData(response.data);
  };

  const exportUsersCsv = () => {
    setLoading(true);
    const url = `/User/${institutionId}/ExportUserCsv${handleQueryFilters()}`;
    UserApiInterface.get(url)
      .then((response) => {
        if (response.data) {
          setCsvData(response.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        showToast("error", "Algo deu errado, tente novamente mais tarde");
        console.error("Erro ao exportar usuários em CSV. Erro => ", error);
        setLoading(false);
      });
  };

  const exportUsersXlsx = () => {
    setLoading(true);
    const url = `/User/${institutionId}/ExportUserXlsx${handleQueryFilters()}`;
    UserApiInterface.get(url, {
      responseType: "arraybuffer",
    })
      .then((response) => {
        if (response.data) {
          convertToXlsx(response);
        }
        setLoading(false);
      })
      .catch((error) => {
        showToast("error", "Algo deu errado, tente novamente mais tarde");
        console.error("Erro ao exportar usuários em XLSX. Erro => ", error);
        setLoading(false);
      });
  };

  const exportTeamsCsv = () => {
    setLoading(true);
    const url = `/Team/${institutionId}/ExportClassCsv${handleQueryFilters()}`;
    TeamApiInterface.get(url)
      .then((response) => {
        if (response.data) {
          setCsvData(response.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        showToast("error", "Algo deu errado, tente novamente mais tarde");
        console.error("Erro ao exportar turmas em CSV. Erro => ", error);
        setLoading(false);
      });
  };

  const exportTeamsXlsx = () => {
    setLoading(true);
    const url = `/Team/${institutionId}/ExportClassXlsx${handleQueryFilters()}`;
    TeamApiInterface.get(url, {
      responseType: "blob",
    })
      .then((response) => {
        if (response.data) {
          convertToXlsx(response);
        }
        setLoading(false);
      })
      .catch((error) => {
        showToast("error", "Algo deu errado, tente novamente mais tarde");
        console.error("Erro ao exportar turmas em XLSX. Erro => ", error);
        setLoading(false);
      });
  };

  const renderModalContent = () => {
    if (isLoading) return <PageLoader />;

    if (csvData) {
      return (
        <section>
          <Row className="pb-2 ml-1">
            <Col>
              <span>
                O arquivo <strong>{currentTab}-data.csv</strong> foi gerado,
                clique no botão abaixo para fazer o download.
              </span>
            </Col>
          </Row>
          <Row className="pb-2 mt-3 ml-1">
            <Col>
              <Button
                onClick={() => downloadCSV(csvData, currentTab)}
                disabled={!csvData}
              >
                Download CSV
              </Button>
            </Col>
          </Row>
        </section>
      );
    }

    if (xlsxData) {
      return (
        <section>
          <Row className="pb-2 ml-1">
            <Col>
              <span>
                O arquivo <strong>{currentTab}-data.xlsx</strong> foi gerado,
                clique no botão abaixo para fazer o download.
              </span>
            </Col>
          </Row>
          <Row className="pb-2 mt-3 ml-1">
            <Col>
              <Button
                onClick={() => downloadXLSX(xlsxData, currentTab)}
                disabled={!xlsxData}
              >
                Download XLSX
              </Button>
            </Col>
          </Row>
        </section>
      );
    } else {
      return (
        <>
          <Row className="pb-2 ml-1">
            <Col>
              <h3>Qual o formato de arquivo você deseja exportar?</h3>
            </Col>
          </Row>
          <Row
            className="card-register"
            onClick={() =>
              currentTab === "users" ? exportUsersCsv() : exportTeamsCsv()
            }
          >
            <BsFiletypeCsv className=" mr-3" color="#525252" size={24} />
            <h3>CSV</h3>
          </Row>
          <Row
            className="card-register"
            onClick={() =>
              currentTab === "users" ? exportUsersXlsx() : exportTeamsXlsx()
            }
          >
            <BsFiletypeXlsx className=" mr-3" color="#525252" size={24} />
            <h3>XLSX</h3>
          </Row>
        </>
      );
    }
  };

  const handleClearStates = () => {
    setCsvData(null);
    setXlsxData(null);
    setActiveModal(0);
  };

  return (
    <ModalGenericStyles
      show={activeModal === 55}
      onHide={handleClearStates}
      className="SyncModalRegister"
    >
      <Modal.Header>
        <Modal.Title className="ml-3">
          <h2>Exportar dados</h2>
        </Modal.Title>
        <Button
          className="cursor-pointer"
          variant="white"
          onClick={handleClearStates}
        >
          <BsX color="var(--bs-modal-color)" size={28} />
        </Button>
      </Modal.Header>
      <Modal.Body>{renderModalContent()}</Modal.Body>
    </ModalGenericStyles>
  );
}
