import { Row, Col, Card } from "react-bootstrap";
import {
  BsChevronRight,
  BsUnlock,
  BsLightning,
  BsEnvelope,
} from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

export default function ComponentAuxiliaryLinks() {
  const navigate = useNavigate();
  const cookie = new Cookies();

  const institutionId = cookie.get(process.env.REACT_APP_BRAINZ_INSTITUTIONID!);

  function redirectToFirstAccess() {
    navigate(`/first-access?id=${institutionId}`);
  }
  function redirectToAlertEmails() {
    navigate(`/alert-emails?id=${institutionId}`);
  }
  function redirectToAutomaticIntegration() {
    navigate(`/automatic-integration?id=${institutionId}`);
  }
  return (
    <>
      <Row className="my-3">
        <Col md={12} style={{ padding: 0 }}>
          <Card className="card-integration ">
            <Card.Title className="title-card">
              <Col className="mt-3">
                <h3>Descubra outras funcionalidades do Integrador</h3>
              </Col>
            </Card.Title>
            <Card.Title className="title-card ml-3">
              <Col
                className="mt-2 mb-2 cursor-pointer"
                onClick={redirectToFirstAccess}
              >
                <Row className="div-body-links">
                  <Row>
                    <BsUnlock className="mr-3" color="#2155ba" size={24} />
                    <h2>
                      Configure o portal que as pessoas irão utilizar para ver
                      seu e-mail e senha
                    </h2>
                  </Row>
                  <div>
                    <BsChevronRight
                      className="mr-2"
                      color="#2155ba"
                      size={24}
                    />
                  </div>
                </Row>
              </Col>
            </Card.Title>
            <Card.Title className="title-card ml-3">
              <Col
                className="mt-2 mb-2 cursor-pointer"
                onClick={redirectToAlertEmails}
              >
                <Row className="div-body-links">
                  <Row>
                    <BsEnvelope className="mr-3" color="#2155ba" size={24} />
                    <h2>
                      Escolha o endereço de e-mail no qual deseja receber os
                      alertas do Integrador
                    </h2>
                  </Row>
                  <div>
                    <BsChevronRight
                      className="mr-2"
                      color="#2155ba"
                      size={24}
                    />
                  </div>
                </Row>
              </Col>
            </Card.Title>
            <Card.Title className="title-card-bottom ml-3">
              <Col
                className="mt-2 mb-2 cursor-pointer"
                onClick={redirectToAutomaticIntegration}
              >
                <Row className="div-body-links">
                  <Row>
                    <BsLightning className="mr-3" color="#2155ba" size={24} />
                    <h2>
                      Configure a Integração Automática dos seus dados, via
                      Banco de Dados ou API
                    </h2>
                  </Row>
                  <div>
                    <BsChevronRight
                      className="mr-2"
                      color="#2155ba"
                      size={24}
                    />
                  </div>
                </Row>
              </Col>
            </Card.Title>
          </Card>
        </Col>
      </Row>
    </>
  );
}
