import { Col, Row } from "react-bootstrap";
import styled from "styled-components";

export const InfoPageStyles = styled(Col)`
  margin-top: 1.5rem;
  margin-left: 1rem;

  .title-page {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: #2155ba;
  }

  .sub-title {
    font-size: 18px;
    line-height: 24px;
    color: #525252;
  }

  .image-back {
    z-index: 0;
    position: fixed;
    right: 0px;
    margin-top: 235px;
  }

  .headingStickyPage {
    position: sticky;
    top: 0;
    z-index: 22 !important;
    background-color: #fbfbff;

    margin: 0 -15px;
    padding: 1rem 0;

    .row {
      margin: 0;
    }
  }
`;

export const CardComponentStyles = styled(Row)`
  cursor: pointer;
  box-sizing: border-box;

  z-index: 5;
  margin: 15px;
  width: 740px;
  padding: 16px;

  border: 1px solid #e4e3e3;
  border-radius: 8px;

  color: #525252;
  background-color: #fff;

  .name-card {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
  }
  .description-card {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #6f6f6fd6;

    margin: 0 !important;
    padding-left: 1.5rem;
    padding-top: 12px;
  }

  i {
    font-size: 23px;
    width: 23px;
    height: 23px;
  }
  .arrow-right {
    align-self: center;
    margin-left: auto;
  }

  &:hover {
    transition: all 0.5s;
    border: 1px solid #2155ba;
  }
`;
