import axios from "axios";

import { showToast } from "../components/Common/Toast";

const PortalInterface = axios.create({
  baseURL: process.env.REACT_APP_API_URL + "/api/v1",
});

PortalInterface.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

PortalInterface.interceptors.response.use(
  function (config) {
    return config;
  },
  function (error: any) {
    if (error.response.status === 401) {
      showToast("error", "Não autorizado");
    }
    if (error.response.status === 404) {
      showToast("error", "Houve um erro, favor tentar novamente mais tarde");
    }
    return error;
  }
);

export default PortalInterface;
