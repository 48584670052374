import { useContext } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { BsCheck2, BsExclamationCircle } from "react-icons/bs";

import { ActiveModalIntegrationContext } from "../AutomaticIntegrationContexts/ActiveModalIntegrationContext";
import { ModalGAutomaticIntegrationErrorStyles } from "./styles";

export default function ModalErrorNowIntegrate() {
  const { activeModal, setActiveModal } = useContext(
    ActiveModalIntegrationContext
  );

  return (
    <ModalGAutomaticIntegrationErrorStyles show={activeModal === 20}>
      <Modal.Body>
        <Row className="pb-2 ml-1">
          <Col>
            <BsExclamationCircle color="#A56300" size={110} />
          </Col>
        </Row>
        <Row className="my-3" onClick={() => setActiveModal(0)}>
          <Col>
            <h2>Erro: Integração em andamento</h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={12}>
            <h3>
              Você já rodou uma integração nas últimas 2 horas.
              <br />
              Tente novamente mais tarde.
            </h3>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          className="sucess-button"
          onClick={() => setActiveModal(0)}
        >
          Fechar janela
          <BsCheck2 color="#fff" size={22} className="ml-3" />
        </Button>
      </Modal.Footer>
    </ModalGAutomaticIntegrationErrorStyles>
  );
}
