import { useContext } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { BsExclamationCircle, BsX } from "react-icons/bs";

import { ModalGenericErrorStyles } from "../../../styles/modalsGeneric";
import { ActiveModalContext } from "../SyncDataContexts/ActiveModalContext";

export default function ModalCancelRegister() {
  const { activeModal, setActiveModal, previousActiveModal } =
    useContext(ActiveModalContext);

  return (
    <ModalGenericErrorStyles show={activeModal === 6}>
      <Modal.Body>
        <Row className="pb-2 ml-1">
          <Col>
            <BsExclamationCircle size={110} color="#C57700" />
          </Col>
        </Row>
        <Row className="my-3" onClick={() => setActiveModal(0)}>
          <h2>Deseja descartar este registro?</h2>
        </Row>
        <Row className="justify-content-center">
          <h3>Você perderá todos os dados inseridos.</h3>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button className="cancel-button" onClick={() => setActiveModal(0)}>
          <BsX className="icon-close" size={28} color="#A56300" />
          Descartar Registro
        </Button>
        <Button
          variant="primary"
          className="sucess-button"
          onClick={() => setActiveModal(previousActiveModal)}
        >
          Continuar inclusão
        </Button>
      </Modal.Footer>
    </ModalGenericErrorStyles>
  );
}
