import "./styles.css";

interface ToggleProps {
  title?: string;
  isSomething: boolean;
  handleSomething: Function;
  style?: any;
  isDisabled?: boolean;
}

const Toggle = ({
  title,
  isSomething,
  handleSomething,
  style,
  isDisabled,
}: ToggleProps) => {
  return (
    <div className="toggleContainer" style={style}>
      <div className="toggleTitleContainer">{title}</div>
      {isSomething === false ? (
        <div
          className={`cursor-pointer toggleContentContainer ${isDisabled ? "isDisabled" : ""
            }`}
        >
          <svg
            onClick={() => {
              if (isDisabled) return;
              handleSomething(true);
            }}
            width="80"
            height="40"
            viewBox="0 0 80 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="20" cy="20" r="16" fill="#6F6F6F" />
            <rect
              x="0.5"
              y="0.5"
              width="79"
              height="39"
              rx="19.5"
              stroke="#6F6F6F"
            />
          </svg>
        </div>
      ) : (
        <div
          className={`cursor-pointer toggleContentContainer ${isDisabled ? "isDisabled" : ""
            }`}
        >
          <svg
            onClick={() => {
              if (isDisabled) return;
              handleSomething(false);
            }}
            width="80"
            height="40"
            viewBox="0 0 80 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="80" height="40" rx="20" fill={isDisabled ? "rgba(15, 126, 170, 0.8)" : "#0F7EAA"} />
            <circle cx="60" cy="20" r="16" fill="white" />
          </svg>
        </div>
      )}
    </div>
  );
};

export default Toggle;
