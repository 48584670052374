export const automaticIntegrationRunNowMsg = `
Você pode rodar a integração manualmente clicando no botão ao lado.
<br /><br />
No entanto, isso só pode ser feito uma vez a cada 2 horas. 

<br /><br />Se a integração estiver pausada, você precisa ativá-la novamente para habilitar o botão.
`;

export const passwordRequirementsMsg = `
Crie uma senha forte que não possa ser facilmente descoberta ou quebrada. 
<br /><br />
Ao criar uma nova senha, utilize os requisitos a seguir:
<br /><br />
As senhas de contas exigem pelo menos 3 dos seguintes itens: 
<br /><br />
* Use de 8 a 16 caracteres. 
<br />
* Letras minúsculas <br />
* Letras maiúsculas <br />
* Números (0-9) <br />
* Símbolos, incluindo: ! @ # $ % ^ & * - _ + = [ ] { } | \ : ' , . ? / ${"~ “ < > ( ) ;"}<br />
`;
