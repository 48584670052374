import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { BsInfoCircle, BsQuestionCircle } from "react-icons/bs";
import "./styles.css";

interface CustomTooltipProps {
  text: string;
  type: string;
  iconColor?: string;
  iconSize?: number;
  placement?: any;
  isHTMLText?: boolean;
  buttonContent?: any;
  customIcon?: any;
}

const CustomTooltip = ({
  text,
  type,
  iconColor,
  iconSize,
  placement,
  isHTMLText,
  buttonContent,
  customIcon,
}: CustomTooltipProps) => {
  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      {text}
    </Tooltip>
  );

  const renderTooltipHTML = (props: any) => (
    <Tooltip id="button-tooltip" className="tooltip-html" {...props}>
      <div dangerouslySetInnerHTML={{ __html: text }} />
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement={placement || "right"}
      delay={{ show: 180, hide: 400 }}
      overlay={isHTMLText ? renderTooltipHTML : renderTooltip}
    >
      {type && type === "info" ? (
        <Button variant="link" className="customTooltipButton">
          <BsInfoCircle color={iconColor} size={iconSize} />
        </Button>
      ) : type && type === "question" ? (
        <Button variant="link" className="customTooltipButton">
          <BsQuestionCircle color={iconColor} size={iconSize} />
        </Button>
      ) : type && type === "custom-icon" ? (
        <Button variant="link" className="customTooltipButton">
          {customIcon}
        </Button>
      ) : type && type === "action-button" ? (
        buttonContent
      ) : (
        <></>
      )}
    </OverlayTrigger>
  );
};

export default CustomTooltip;
