import { Modal } from "react-bootstrap";
import styled from "styled-components";

// Modal Generic Styles
export const ModalGenericStyles = styled(Modal)`
  .modal-dialog {
    margin: 2rem auto;
    overflow-y: initial !important;
    height: calc(100% - 4rem);
  }

  .modal-content {
    width: 700px !important;
    height: 100% !important;
  }

  h3 {
    margin: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #525252;
  }
  h2 {
    margin: 0;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #525252;
  }
  h4 {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #424040;
  }

  .line-inactivate {
    margin-left: 0px;
    margin-right: 0px;
    padding-top: 6px;
    place-content: space-between;
  }

  .modal-header {
    align-items: center;

    .modal-title {
      h2 {
        margin: 0;

        & svg {
          margin: 0 8px;
          color: #424040;
        }
      }
    }
  }

  .modal-body {
    overflow-y: auto !important;
    overflow-x: clip !important;
    max-height: calc(100vh - 100px);
    margin-left: 0 !important;
    margin-right: 0 !important;

    margin-left: 0 !important;
    margin-right: 0 !important;

    .form-label,
    .frequencyIntegrationContainer .col,
    .form-label h4 {
      font-weight: bold;
    }

    .button-radio {
      font-size: 16px;
      line-height: 24px;
      color: #6f6f6f;
      input {
        width: 32px;
        height: 32px;
      }
      label {
        padding-left: 20px;
        padding-top: 8px;
      }
    }

    .button-radio-spaces {
      margin-top: 1rem;
    }

    .form-group {
      margin-bottom: 0px;
    }

    .form-control:not(.pageSizeContainer) {
      background: #fff;

      padding: 12px 8px !important;
      width: 100% !important;
      height: 50px !important;

      border: 1px solid #a6a6a6;
      border-radius: 8px;
    }

    .input-date {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      flex: none;
      order: 1;
      align-self: stretch;
      flex-grow: 0;

      padding: 12px 8px;
      margin: 0px 0px;

      width: 142px;
      height: 48px;

      //background: #F5F5F5;
      border: 1px solid #a6a6a6;

      border-radius: 8px;
      &:focus {
        box-shadow: none !important;
      }
    }
  }

  .modal-footer {
    place-content: space-between;

    .groupButtonsFlexGap0 {
      display: flex;
    }

    .groupButtonsFlexGap4 {
      display: flex;
      align-items: center;
      gap: 4rem;
    }

    // Buttons
    button {
      padding: 0 !important;
      box-shadow: unset !important;
      outline: unset !important;

      width: 180px;
      max-width: 180px;
      height: 48px !important;

      display: flex;
      align-items: center;
      justify-content: center;
      gap: 7px;
      border-radius: 8px;

      &:focus {
        border: none !important;
      }
    }

    .continue-button {
      background: #2155ba;

      border: 1px solid #2155ba;

      &:hover,
      &:active {
        color: #fff !important;
        background-color: #1048af !important;
        border-color: #1048af;

        svg {
          fill: #fff !important;
        }
      }
    }

    .button-edit {
      background: #fff;
      color: #2155ba;

      border: 1px solid #2155ba;
      border-radius: 8px;

      &:hover,
      &:active {
        color: #fff !important;
        background-color: #1048af !important;
        border-color: #1048af;

        svg {
          fill: #fff !important;
        }
      }
    }

    .return-button {
      width: 48px;

      background: #fff;
      color: #a56300;

      border: 1px solid #a56300;
      border-radius: 8px;

      &:hover,
      &:active {
        color: #fff !important;
        background-color: #a56300 !important;
        svg {
          fill: #fff !important;
        }
      }
    }

    .sucess-button,
    .sucess-button-check,
    .success-button,
    .success-button-check {
      background: #008555;
      color: #fff !important;

      border: 1px solid #008555;
      border-radius: 8px;

      &:hover,
      &:active {
        opacity: 0.9;

        svg {
          fill: #fff !important;
        }
      }
    }

    .sucess-button-check,
    .success-button-check {
      width: 48px;
    }

    .cancel-operation {
      color: #a56300;
      background-color: #fff;

      border: 1px solid #a56300;
      border-radius: 8px;

      &:hover,
      &:active {
        color: #fff !important;
        background-color: #a56300 !important;
        border: 1px solid #fff;
        svg {
          fill: #fff !important;
        }
      }

      &:focus {
        border: none !important;
      }
    }

    .continue-button-outline {
      background-color: transparent;
      color: #2155ba;
      border: 1px solid #2155ba;

      & svg {
        fill: #2155ba;
      }

      &:hover {
        background-color: #2155ba;
        color: #fff;

        & svg {
          fill: #fff;
        }
      }
    }
  }

  // SPECIFIC STYLES
  .title-key {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #6f6f6f;
  }
  .row-bottom {
    padding: 10px 0px 10px 0px;

    border-bottom: 1px solid #e4e3e3;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #525252;

    .headersCol {
      overflow-wrap: anywhere;
    }
  }
  .col-left {
    border-left: 1px solid #e4e3e3;
  }

  .line-body {
    margin-top: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #e4e3e3;
  }

  .button-add-header {
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;

    padding: 15px;

    width: 100%;
    height: 52px;

    border: 1px solid #2155ba;
    border-radius: 8px;
    box-shadow: unset;
    outline: unset;

    background-color: #fff;
    color: #2155ba;

    &:focus {
      background: none;
      border: 1px solid #2155ba;
      color: #2155ba;
    }

    &:hover {
      background: #2155ba;
      color: #fff;
      border: 1px solid #fff;

      svg {
        fill: #fff !important;
      }
    }

    &:disabled:hover {
      background: A6A6A6;
      border: #a6a6a6;
      svg {
        fill: #a6a6a6;
      }
    }
  }

  .div-data-integration-card,
  .div-data-integration-card-warn {
    margin: 25px 10px;
    padding: 15px;
    align-items: center;
    align-content: center;
    gap: 15px;

    background: #f5f5f5;
    border: 1px solid #0f7eaa;
    box-sizing: border-box;
    border-radius: 8px;

    h2 {
      margin: 0;
      font-size: 16px;
      line-height: 24px;
      color: #525252;
    }
  }

  .div-data-integration-card-warn {
    background: #ffdb6d;
    border-color: #ffc107;
  }
`;

// Modal ERROR Generic
export const ModalGenericErrorStyles = styled(Modal)`
  .modal-content {
    width: 600px;
    height: 420px;

    background: #fff;
    border: 2px solid #c57700;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
  }

  .modal-body {
    margin: 10px;
    padding: 50px 0px 10px;
    text-align: center;
    align-self: center;
  }

  h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #514f4e;
  }
  h3 {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #636060;

    padding: 0 1rem;
    max-height: 4rem;
    overflow: auto;
  }

  .modal-footer {
    border: none;
    justify-content: center;
    gap: 2rem;

    button {
      max-width: 229px;
      width: 100%;
      height: 48px;

      box-sizing: border-box;
      border-radius: 8px;

      display: flex;
      align-items: center;
      justify-content: center;

      &:focus {
        border: none !important;
      }
    }

    .cancel-button {
      color: #a56300;
      background-color: #fff;
      border: 1px solid #a56300;

      &:hover,
      &:active {
        color: #fff !important;
        background-color: #a56300 !important;
        svg {
          fill: #fff !important;
        }
      }
    }

    .sucess-button,
    .success-button {
      background: #2155ba;
      border: 1px solid #2155ba;

      & svg {
        margin-left: 14px;
      }

      &:hover,
      &:active {
        color: #fff !important;
        background-color: #153d89 !important;
        svg {
          fill: #fff !important;
        }
      }
    }

    .continue-button-outline {
      background-color: transparent !important;
      color: #2155ba !important;
      border: 1px solid #2155ba !important;

      &:hover {
        background-color: #2155ba !important;
        color: #fff !important;
      }
    }

    .success-button-outline {
      background-color: transparent;
      color: #2155ba;
      border: 1px solid #2155ba;

      &:hover {
        background-color: #2155ba;
        color: #fff;
      }
    }
  }
`;

// Modal SUCCESS Generic
export const ModalGenericSuccessStyles = styled(ModalGenericErrorStyles)`
  .modal-content {
    border: 2px solid #008555;
  }
`;
