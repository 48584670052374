import { Col, Row } from 'react-bootstrap';
import styled from "styled-components"

import PageLoader from '../../../components/Common/Loader';


function TestConnection(props: any) {
    return (
        <TestConnectionStyles >
            <Row className="pb-4">
                <Col>
                    <PageLoader />
                </Col>
            </Row>
            <Row >
                <Col>
                    <h3>Testando conexão...</h3>
                </Col>
            </Row>
        </TestConnectionStyles>
    )
}

const TestConnectionStyles = styled.div`
    margin:auto;
    align-items:center;
    text-align:center;
    h3{
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #424040;
    } 
`

export default TestConnection;