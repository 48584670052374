import { saveAs } from 'file-saver';
import { BsCheckCircle, BsXOctagon } from "react-icons/bs";
import Cookies from "universal-cookie";

export const renderStatusTableData = (status) => {
  if (status)
    return (
      <td className="success-status">
        <BsCheckCircle color="rgb(0, 133, 85)" size={24} />
      </td>
    );

  return (
    <td className="error-status">
      <BsXOctagon color="rgb(165, 99, 0)" size={24} />
    </td>
  );
};

export const handleGetPageSize = () => {
  let pageSize;

  if (localStorage.getItem(getPageSizeStorageEnv())) {
    pageSize = localStorage.getItem(getPageSizeStorageEnv())
  } else {
    pageSize = 5
  }
  return pageSize
}

export const getPageSizeStorageEnv = () => {
  if (process.env.REACT_APP_PAGINATION_SIZE_OPTION) {
    return process.env.REACT_APP_PAGINATION_SIZE_OPTION
  } else {
    return "paginationSizeOption"
  }
}

export const setLocalToken = () => {
  if (process.env.REACT_APP_ENV !== "local") return;

  const cookies = new Cookies();

  if (!cookies.get(process.env.REACT_APP_BRAINZ_NAME_TOKEN!)) {
    cookies.set(
      String(process.env.REACT_APP_BRAINZ_NAME_TOKEN),
      process.env.REACT_APP_LOCAL_TOKEN
    );
  }

  if (!cookies.get(process.env.REACT_APP_BRAINZ_INSTITUTIONID!)) {
    cookies.set(
      String(process.env.REACT_APP_BRAINZ_INSTITUTIONID),
      process.env.REACT_APP_LOCAL_INSTITUTION_ID
    );
  }
};

export const downloadCSV = (csvData, currentTab = "exported") => {
  if (csvData) {
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${currentTab}-data.csv`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }
};

export const downloadXLSX = (xlsxData, currentTab = "exported") => {
  if (xlsxData) {
    const blob = new Blob([xlsxData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, `${currentTab}-data.xlsx`);
  }
}

export const isValidRoute = (currentInstitutionID: string) => {
  const regex = /\?id=([^&]+)/;
  const match = window.location.search?.match(regex);
  const idValue = match ? match[1] : null;
  if (idValue !== currentInstitutionID) return idValue;
  return currentInstitutionID;
}