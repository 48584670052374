import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { BsXCircle } from "react-icons/bs";
import styled from "styled-components";

import NotResultImage from "../../assets/images/not-result-image.png";

export interface NoResultsProps {
  clearFilterFunction: () => void;
}

export default function NoResults(props: NoResultsProps) {
  return (
    <Row style={{ margin: "auto" }}>
      <NotResultComponentStyles md={12}>
        <Row className="py-4">
          <Col>
            <img src={NotResultImage} alt="Sem resultados" />
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>Não encontramos resultados para sua busca!</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>Tente buscar novamente ou limpar a busca.</p>
          </Col>
        </Row>
        <Row className="mx-3 " style={{ margin: "auto" }}>
          <Col>
            <Button onClick={props.clearFilterFunction}>
              <BsXCircle size={18} />
              Limpar busca
            </Button>
          </Col>
        </Row>
      </NotResultComponentStyles>
    </Row>
  );
}

const NotResultComponentStyles = styled(Col)`
  margin-inline: auto;
  align-items: center;
  text-align: center;
  color: #424040;
  font-style: normal;
  h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
  }
  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }
  Button {
    margin: auto;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #a56300 !important;
    background-color: #fff;
    border: 1px solid #a56300 !important;
    box-sizing: border-box;
    border-radius: 8px;

    display: flex;
    align-items: center;
    gap: 8px;

    &:focus {
      background: none !important;
      border: 1px solid #a56300 !important;
      color: #a56300 !important;
    }
    &:active {
      background: none !important;
      color: #a56300 !important;
    }
    &:hover {
      background: #a56300 !important;
      color: #fff !important;
      border: 1px solid #fff !important;
      .bi-x-circle {
        fill: #fff !important;
      }
      svg {
        color: #fff !important;
      }
    }
    &:disabled {
      background: none !important;
      border: #a56300 !important;
    }
  }
`;
