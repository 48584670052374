import { BsChevronDown } from "react-icons/bs";
import { components } from "react-select";

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <BsChevronDown color="#6E6E6E" size="24" />
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;
