import GeneralConfiguration from "./GeneralConfiguration";
import GeneralConfigurationContextProvider from "./GeneralConfigurationContexts/GeneralConfigurationContext";

export default function GeneralConfigurationScreen() {
  return (
    <GeneralConfigurationContextProvider>
      <GeneralConfiguration />
    </GeneralConfigurationContextProvider>
  );
}
