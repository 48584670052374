import { useContext } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import {
  BsBuilding,
  // BsCalendar2,
  BsPeople,
  BsPerson,
  BsX,
} from "react-icons/bs";

import { ModalGenericStyles } from "../../../../styles/modalsGeneric";
import { ActiveModalContext } from "../../SyncDataContexts/ActiveModalContext";
import "./styles.css";

// 0 - fechado
// 1 - register
// 2 - usuario
// 3 - unidade
// 4 - turma
// 5 - success
// 6 - cancelar
// 7 - error
// 9 - agenda

export default function ModalRegister() {
  const { activeModal, setActiveModal } = useContext(ActiveModalContext);
  return (
    <ModalGenericStyles
      show={activeModal === 1}
      onHide={() => setActiveModal(0)}
      className="SyncModalRegister"
    >
      <Modal.Header>
        <Modal.Title className="ml-3">
          <h2>Adicionar Registro</h2>
        </Modal.Title>
        <Button
          className="cursor-pointer"
          variant="white"
          onClick={() => setActiveModal(0)}
        >
          <BsX color="var(--bs-modal-color)" size={28} />
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Row className="pb-2 ml-1">
          <Col>
            <h3>Qual tipo de registro você deseja adicionar?</h3>
          </Col>
        </Row>
        <Row className="card-register" onClick={() => setActiveModal(2)}>
          <BsPerson className=" mr-3" color="#525252" size={24} />
          <h3>Pessoa</h3>
        </Row>
        <Row className="card-register" onClick={() => setActiveModal(3)}>
          <BsBuilding className=" mr-3" color="#525252" size={24} />
          <h3>Unidade</h3>
        </Row>
        <Row className="card-register" onClick={() => setActiveModal(4)}>
          <BsPeople className=" mr-3" color="#525252" size={24} />
          <h3>Turma</h3>
        </Row>
        {/* <Row className="card-register" onClick={() => setActiveModal(8)}>
          <BsCalendar2 className=" mr-3" color="#525252" size={24} />
          <h3>Agenda</h3>
        </Row> */}
      </Modal.Body>
    </ModalGenericStyles>
  );
}
