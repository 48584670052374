import React from "react";
import { TailSpin } from "react-loader-spinner";

const PageLoader = () => {
  return (
    <div id="loader" className="loader-background">
      <TailSpin
        color="#2155ba"
        height={100}
        width={100}
        wrapperStyle={{ margin: "auto" }}
      />
    </div>
  );
};

export default PageLoader;
