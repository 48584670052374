import { Card, Col, Row } from "react-bootstrap";
import { BsBuilding, BsPeople, BsPeopleFill, BsPerson } from "react-icons/bs";
import styled from "styled-components";

export interface SingleInstitutionProps {
  onlyInstitutionName: string;
  counterUnits: number;
  counterTeams: number;
  counterStudents: number;
  counterTeachers: number;
  counterCoordinators: number;
  counterAdm: number;
}

export default function SingleInstitution(props: SingleInstitutionProps) {
  return (
    <SingleInstitutionStyles>
      <Row>
        <Col className="align-self-center pl-0 mb-4">
          <h2 className="title-page">{props.onlyInstitutionName}</h2>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col lg={4} md={4} sm={6} className="ml-0 pl-0">
          <Card className="si-card p-3 mb-2" title="Total de Unidades">
            <Card.Body>
              <Row>
                <Col md={6}>
                  <Row className="pb-3">
                    <h3>Unidades</h3>
                  </Row>
                  <Row className="pt-3">
                    {" "}
                    <h4>{props.counterUnits}</h4>
                  </Row>
                </Col>
                <Col md={6} style={{ textAlign: "end" }}>
                  <BsBuilding color="#2868E3" size={90} />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4} md={4} sm={6} className="ml-0 pl-0">
          <Card className="si-card p-3 mb-2 " title="Total de Turmas">
            <Card.Body>
              <Row>
                <Col md={6}>
                  <Row className="pb-3">
                    <h3>Turmas</h3>
                  </Row>
                  <Row className="pt-3">
                    {" "}
                    <h4>{props.counterTeams}</h4>
                  </Row>
                </Col>
                <Col md={6} style={{ textAlign: "end" }}>
                  <BsPeopleFill color="#FFEA01" size={90} />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4} md={4} sm={6} className="ml-0 pl-0">
          <Card className="si-card p-3 mb-2 " title="Total de Turmas">
            <Card.Body>
              <Row>
                <Col md={6}>
                  <Row className="pb-3">
                    <h3>Coordenadores</h3>
                  </Row>
                  <Row className="pt-3">
                    {" "}
                    <h4>{props.counterCoordinators}</h4>
                  </Row>
                </Col>
                <Col md={6} style={{ textAlign: "end" }}>
                  <BsPerson color="#635f26" size={90} />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col lg={4} md={4} sm={6} className="ml-0 pl-0">
          <Card className="si-card p-3 mb-2 " title="Total de Alunos">
            <Card.Body>
              <Row>
                <Col md={6}>
                  <Row className="pb-3">
                    <h3>Alunos</h3>
                  </Row>
                  <Row className="pt-3">
                    {" "}
                    <h4>{props.counterStudents}</h4>
                  </Row>
                </Col>
                <Col md={6} style={{ textAlign: "end" }}>
                  <BsPeople color="#2868E3" size={90} />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4} md={4} sm={6} className="ml-0 pl-0">
          <Card className="si-card p-3 mb-2 " title="Total de Professores">
            <Card.Body>
              <Row>
                <Col md={6}>
                  <Row className="pb-3">
                    <h3>Professores</h3>
                  </Row>
                  <Row className="pt-3">
                    {" "}
                    <h4>{props.counterTeachers}</h4>
                  </Row>
                </Col>
                <Col md={6} style={{ textAlign: "end" }}>
                  <BsPerson color="#153D89" size={90} />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4} md={4} sm={6} className="ml-0 pl-0">
          <Card className="si-card p-3 mb-2 " title="Total de Administrativos">
            <Card.Body>
              <Row>
                <Col md={6}>
                  <Row className="pb-3">
                    <h3>Administrativos</h3>
                  </Row>
                  <Row className="pt-3">
                    {" "}
                    <h4>{props.counterAdm}</h4>
                  </Row>
                </Col>
                <Col md={6} style={{ textAlign: "end" }}>
                  <BsPerson color="#5f5f5f" size={90} />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </SingleInstitutionStyles>
  );
}

const SingleInstitutionStyles = styled(Col)`
  margin-top: 3.88vmin;

  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
    color: #424040;
  }
  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 20px;
    color: #525252;
  }
  h4 {
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 20px;
  }

  si-card {
    box-shadow: 0px 8px 10px rgba(54, 54, 54, 0.08);
    background: #f5f5f5;
    border: 0px solid #e4e3e3;
    box-sizing: border-box;
    border-radius: 8px;
  }

  .title-page {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: #2155ba;
    margin: 0;
  }
`;
