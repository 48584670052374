import { BrainzAuthContextProvider } from "@brainz_group/webappframework";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import ProfileContextProvider from "../../contexts/ProfileContext";
import SyncDataFilterContextProvider from "../../contexts/SyncDataFilterContext";
import { setLocalToken } from "../../helpers/functions";
import Footer from "./Footer";
import HeaderTop from "./HeaderTop";
import SidebarMenu from "./SidebarMenu";

export default function LayoutDefault(props: React.PropsWithChildren<any>) {
  const location = useLocation();
  const { pathname } = location;
  const [loading, setLoading] = useState(true);
  const [scrollBrowser, setScrolling] = useState({ scrolling: false });
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);


  function reload(value) {
    const newUrl = `${window.location.pathname}?id=${value}`;
    // Recarregar a página com a nova URL
    window.location.href = newUrl;
  }

  const handleResizeSidebar = () => {
    localStorage.setItem('isSidebarOpen', String(!isSidebarOpen))
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleInitialSizeSidebar = () => {
    const status = localStorage.getItem('isSidebarOpen');

    if (status == "true") setIsSidebarOpen(true)

    if (status == "false") setIsSidebarOpen(false)
  }

  function handleScroll(event: any) {
    if (window.scrollY === 0) {
      setScrolling({ scrolling: false });
    } else {
      setScrolling({ scrolling: true });
    }
  }
  useEffect(() => {
    if (loading) setLoading(false);
  }, [loading]);

  useEffect(() => {
    window?.addEventListener("scroll", handleScroll);
    handleInitialSizeSidebar()
  }, []);

  useEffect(() => {
    setLocalToken();
  }, []);

  return (
    <BrainzAuthContextProvider waitAuthentication={true}>
      <ProfileContextProvider>
        <SyncDataFilterContextProvider>

          <div className="App">
            <div
              className={`sidebar ${isSidebarOpen ? "open" : ""}`}
              data-scroll={scrollBrowser.scrolling ? "scrolling" : ""}
            >
              <SidebarMenu resizeSidebar={handleResizeSidebar} />
            </div>
            <div className={`sidebar-margin ${isSidebarOpen ? "expanded" : ""} `}>
              {location.pathname === "/" || location.pathname === "/home" ? (
                ""
              ) : (
                <HeaderTop reload={(value: string) => reload(value)} />
              )}
              <div
                className={`mx-3 px-3 ${pathname !== "/" && pathname !== "/home"
                  ? "container-fixed-height"
                  : "container-fixed-height-home"
                  }`}
              >
                {!loading && props.children}
              </div>
              <Footer />
            </div>
          </div>
        </SyncDataFilterContextProvider>

      </ProfileContextProvider>
    </BrainzAuthContextProvider>
  );
}
