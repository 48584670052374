import { useContext } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { BsExclamationCircle, BsPause } from "react-icons/bs";

import { ActiveModalIntegrationContext } from "../AutomaticIntegrationContexts/ActiveModalIntegrationContext";
import { ModalGAutomaticIntegrationErrorStyles } from "./styles";

export default function ModalPauseIntegration() {
  const {
    activeModal,
    setActiveModal,
    setUpdateIntegration,
    setNewStopIntegration,
  } = useContext(ActiveModalIntegrationContext);
  return (
    <ModalGAutomaticIntegrationErrorStyles show={activeModal === 11}>
      <Modal.Body>
        <Row className="pb-2 ml-1">
          <Col>
            <BsExclamationCircle size={110} color="#C57700" />
          </Col>
        </Row>
        <Row className="my-3" onClick={() => setActiveModal(0)}>
          <Col>
            <h2>Deseja pausar a configuração?</h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col>
            <h3>
              As integrações não acontecerão até que você as inicie novamente.
            </h3>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="cancel-button"
          onClick={() => [
            setActiveModal(0),
            setNewStopIntegration(false),
            setUpdateIntegration(true),
          ]}
        >
          <BsPause size={24} />
          Pausar integração
        </Button>
        <Button
          variant="primary"
          className="sucess-button"
          onClick={() => setActiveModal(0)}
        >
          Continuar integrando
        </Button>
      </Modal.Footer>
    </ModalGAutomaticIntegrationErrorStyles>
  );
}
