export const requiredFieldMsg: string = "Campo obrigatório";

export const invalidFieldMsg: string = "Campo inválido";

export const nameInvalidFieldMsg: string = "Nome inválido";

export const urlInvalidMsg: string =
  "Campo inválido, necessário começar com http:// ou https://";

export const idInvalidMsg: string = "Identificador inválido";
