export const hoursOptions = [
  { value: 0, label: "00:00" },
  { value: 1, label: "01:00" },
  { value: 2, label: "02:00" },
  { value: 3, label: "03:00" },
  { value: 4, label: "04:00" },
  { value: 5, label: "05:00" },
  { value: 6, label: "06:00" },
  { value: 7, label: "07:00" },
  { value: 8, label: "08:00" },
  { value: 9, label: "09:00" },
  { value: 10, label: "10:00" },
  { value: 11, label: "11:00" },
  { value: 12, label: "12:00" },
  { value: 13, label: "13:00" },
  { value: 14, label: "14:00" },
  { value: 15, label: "15:00" },
  { value: 16, label: "16:00" },
  { value: 17, label: "17:00" },
  { value: 18, label: "18:00" },
  { value: 19, label: "19:00" },
  { value: 20, label: "20:00" },
  { value: 21, label: "21:00" },
  { value: 22, label: "22:00" },
  { value: 23, label: "23:00" },
];

export const intervalOptions = [
  { value: 1, label: "1h" },
  { value: 2, label: "2h" },
  { value: 3, label: "3h" },
  { value: 4, label: "4h" },
  { value: 5, label: "5h" },
  { value: 6, label: "6h" },
  { value: 7, label: "7h" },
  { value: 8, label: "8h" },
  { value: 9, label: "9h" },
  { value: 10, label: "10h" },
  { value: 11, label: "11h" },
  { value: 12, label: "12h" },
  { value: 13, label: "13h" },
  { value: 14, label: "14h" },
  { value: 15, label: "15h" },
  { value: 16, label: "16h" },
  { value: 17, label: "17h" },
  { value: 18, label: "18h" },
  { value: 19, label: "19h" },
  { value: 20, label: "20h" },
  { value: 21, label: "21h" },
  { value: 22, label: "22h" },
  { value: 23, label: "23h" },
];

// find greatest common divisor (máximo divisor comum)
const findGCD = (array: number[]): number => {
  const calculateGCD = (a: number, b: number): number => {
    while (b !== 0) {
      const remainder = a % b;
      a = b;
      b = remainder;
    }
    return a;
  };

  let currentGCD = array[0];
  for (let i = 1; i < array.length; i++) {
    currentGCD = calculateGCD(currentGCD, array[i]);
  }

  return currentGCD;
};

export const handleFormatStringGCD = (list: string) => {
  if (!list || list?.length === 0) return 0;

  const array = list?.split(",")?.map(Number);

  array.sort((a, b) => {
    return a - b;
  });

  const gcdResult = findGCD(array);

  return gcdResult;
};

export const handleFormatPeriodicIntervalToString = (
  inputNumber: number
): string => {
  const upperLimit: number = 23;
  const array: any = [];

  for (let i: number = 0; i <= upperLimit; i++) {
    if (i % inputNumber === 0) {
      array.push(i);
    }
  }

  return array.toString();
};

export const handleFormatPeriodicIntervalToSelect = (list: string) => {
  if (!list || list?.length === 0) return intervalOptions[0];

  const array = list?.split(",")?.map(Number);

  array.sort((a, b) => {
    return a - b;
  });

  const gcdResult = findGCD(array);

  const optionResult = intervalOptions.find(
    (el: any) => el.value === gcdResult
  );

  return optionResult;
};

export const handleFormatScheduleTimeToString = (data: []) => {
  if (!data || data?.length === 0) return "";

  return data
    ?.map((item: any) => item.value)
    ?.sort()
    ?.toString();
};

export const handleFormatScheduleTimeToSelect = (list: string) => {
  if (!list || list?.length === 0) return hoursOptions[0];

  const array = list?.split(",")?.map(Number);

  array.sort((a, b) => {
    return a - b;
  });

  const transformResult = array?.map((item: any) => {
    return hoursOptions.find((option: any) => option?.value === item);
  });

  return transformResult;
};

export const handleFormatScheduleTimeToShow = (list: string) => {
  if (!list || list?.length === 0) return hoursOptions[0].label;

  const array = list?.split(",")?.map(Number);

  array.sort((a, b) => {
    return a - b;
  });

  const transformResult = array?.map((item: any, index: number) => {
    return `${hoursOptions?.find((option: any) => option?.value === item)?.label
      }${array.length === index + 1 ? ". " : ", "}`;
  });

  return transformResult;
};
