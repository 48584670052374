import { useState, useEffect, useContext } from "react";
import { Row, Col, Nav } from "react-bootstrap";
import { BsExclamationCircle } from "react-icons/bs";
import Cookies from "universal-cookie";

import CustomTooltip from "../../components/Common/CustomTooltip";
import PageLoader from "../../components/Common/Loader";
import ProfileContextProvider from "../../contexts/ProfileContext";
import ApiInterface from "../../services/ApiInterface";
import {
  InitialHeaderStyles,
  ItemLogsNavBar,
} from "../../styles/initialStyles";
import ConfigurationProfile from "./ConfigurationProfile";
import { GeneralConfigurationContext } from "./GeneralConfigurationContexts/GeneralConfigurationContext";

export default function GeneralConfiguration() {
  const { setEntityType, setUserType, firstUpdateIntegration } = useContext(
    GeneralConfigurationContext
  );

  const cookie = new Cookies();
  const institutionId = cookie.get(process.env.REACT_APP_BRAINZ_INSTITUTIONID!);
  const [loading, setLoading] = useState(true);
  const [activeTab, changeActiveTab] = useState(9);
  const [firstSetupCompletedStudent, setFirstSetupCompletedStudent] =
    useState(false);
  const [firstSetupCompletedTeacher, setFirstSetupCompletedTeacher] =
    useState(false);
  const [firstSetupCompletedCoordinator, setFirstSetupCompletedCoordinator] =
    useState(false);

  const [
    firstSetupCompletedAdministrative,
    setFirstSetupCompletedAdministrative,
  ] = useState(false);

  useEffect(() => {
    loadPage();
    function loadPage() {
      setLoading(true);

      ApiInterface.get("/UserIntegrationSettings/" + institutionId + "/GetAll")
        .then((response) => {
          const settings = response.data.result;

          settings?.map((item: any) => {
            if (item.userType === 4) {
              setFirstSetupCompletedStudent(true);
            }
            if (item.userType === 5) {
              setFirstSetupCompletedTeacher(true);
            }
            if (item.userType === 3) {
              setFirstSetupCompletedCoordinator(true);
            }
            if (item.userType === 1) {
              setFirstSetupCompletedAdministrative(true);
            }

            return null;
          });
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error("Erro => ", error);
        });
    }
  }, [activeTab, firstUpdateIntegration]);

  if (loading) return <PageLoader />;

  return (
    <ProfileContextProvider>
      <InitialHeaderStyles>
        <section className="headingStickySubPages">
          <Row>
            <Col md={12}>
              <h2>Perfis</h2>
            </Col>
            <br />
            <br />
            <Col md={12}>
              <h3>
                Configure os parâmetros de criação de conta para cada um dos
                perfis da sua instituição.
              </h3>
            </Col>
          </Row>

          <Row className="nav-item">
            <ItemLogsNavBar className="navbar-profile">
              <Nav
                variant="pills"
                defaultActiveKey="/home"
                className="px-0 mt-1 mb-0 pl-0 ml-0"
              >
                <Nav.Item className="mx-0">
                  <Nav.Link
                    active={activeTab === 9}
                    onClick={() => [
                      changeActiveTab(9),
                      setEntityType("Aluno(a)"),
                      setUserType(4),
                    ]}
                    className="pt-3 pb-2"
                  >
                    Aluno(a)
                    {!firstSetupCompletedStudent && (
                      <CustomTooltip
                        type="custom-icon"
                        text="Você ainda não configurou este perfil"
                        placement="bottom"
                        customIcon={
                          <BsExclamationCircle
                            className="ml-2"
                            color="#A56300"
                            size={24}
                          />
                        }
                      />
                    )}
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item className="mx-1">
                  <Nav.Link
                    active={activeTab === 10}
                    onClick={() => [
                      changeActiveTab(10),
                      setEntityType("Professor(a)"),
                      setUserType(5),
                    ]}
                    className="pt-3 pb-2"
                  >
                    Professor(a)
                    {!firstSetupCompletedTeacher && (
                      <CustomTooltip
                        type="custom-icon"
                        text="Você ainda não configurou este perfil"
                        placement="bottom"
                        customIcon={
                          <BsExclamationCircle
                            className="ml-2"
                            color="#A56300"
                            size={24}
                          />
                        }
                      />
                    )}
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item className="mx-1">
                  <Nav.Link
                    active={activeTab === 1}
                    onClick={() => [
                      changeActiveTab(1),
                      setEntityType("Administrativo"),
                      setUserType(1),
                    ]}
                    className="pt-3 pb-2"
                  >
                    Administrativo
                    {!firstSetupCompletedAdministrative && (
                      <CustomTooltip
                        type="custom-icon"
                        text="Você ainda não configurou este perfil"
                        placement="bottom"
                        customIcon={
                          <BsExclamationCircle
                            className="ml-2"
                            color="#A56300"
                            size={24}
                          />
                        }
                      />
                    )}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="mx-1">
                  <Nav.Link
                    active={activeTab === 4}
                    onClick={() => [
                      changeActiveTab(4),
                      setEntityType("Coordenador"),
                      setUserType(3),
                    ]}
                    className="pt-3 pb-2"
                  >
                    Coordenador(a)
                    {!firstSetupCompletedCoordinator && (
                      <CustomTooltip
                        type="custom-icon"
                        text="Você ainda não configurou este perfil"
                        placement="bottom"
                        customIcon={
                          <BsExclamationCircle
                            className="ml-2"
                            color="#A56300"
                            size={24}
                          />
                        }
                      />
                    )}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </ItemLogsNavBar>
          </Row>
        </section>

        <Row>
          <Col md={12}>
            <ConfigurationProfile />
          </Col>
        </Row>
      </InitialHeaderStyles>
    </ProfileContextProvider>
  );
}
