import { Row, Col } from "react-bootstrap";
import {
  BsArrowReturnLeft,
  // BsBell,
  BsChevronRight,
  BsPeople,
  BsUnlock,
} from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

import settings from "../../assets/images/settings.png";
import { isValidRoute } from "../../helpers/functions";
import {
  InfoPageStyles,
  CardComponentStyles,
} from "../../styles/infoPageStyles";

export interface BrainzInstitution {
  name: string;
  students: number;
  units: number;
  teachers: number;
  teams: number;
  administratives: number;
}

function SettingsPage() {
  const navigate = useNavigate();

  const cookie = new Cookies();
  const institutionId = cookie.get(process.env.REACT_APP_BRAINZ_INSTITUTIONID!);

  const currentInstitutionID = isValidRoute(institutionId)

  function redirectToPerfil() {
    navigate(`/domains?id=${currentInstitutionID}`);
  }
  // function redirectToEmailAlerts() {
  //   navigate(`/notify-users?id=${currentInstitutionID}`);
  // }

  function redirectToWriteBack() {
    navigate(`/writeback?id=${currentInstitutionID}`);
  }

  function redirectToFirstAccess() {
    navigate(`/first-access?id=${currentInstitutionID}`);
  }

  return (
    <>
      <InfoPageStyles>
        <section className="headingStickyPage">
          <Row className="title-page">Configurações</Row>
          <Row className="sub-title mt-3">
            Configure os principais parâmetros de funcionamento da sua
            instituição
          </Row>
        </section>

        <Row>
          <Row className="pt-2">
            <CardComponentStyles md={12} onClick={redirectToPerfil}>
              <Col md={10} className="">
                <Row className="name-card">
                  <i className="icone bx-grid-alt mr-3">
                    <BsPeople size={24} color="#525252" />
                  </i>
                  Perfis
                </Row>
                <Row className="description-card mt-2">
                  Configure os parâmetros de criação de contas para cada perfil
                  da instituição
                </Row>
              </Col>
              <Col md={1} className="arrow-right">
                <i className="icone bx-grid-alt">
                  <BsChevronRight size={24} color="#0F7EAA" />
                </i>
              </Col>
            </CardComponentStyles>

            <CardComponentStyles md={12} onClick={redirectToFirstAccess}>
              <Col md={10} className="">
                <Row className="name-card">
                  <i className="icone bx-grid-alt mr-3">
                    <BsUnlock size={24} color="#525252" />
                  </i>{" "}
                  Portal Primeiro Acesso
                </Row>
                <Row className="description-card mt-2">
                  Configure os parâmetros de criação de contas para cada perfil
                  da instituição
                </Row>
              </Col>
              <Col md={1} className="arrow-right">
                <i className="icone bx-grid-alt">
                  <BsChevronRight size={24} color="#0F7EAA" />
                </i>
              </Col>
            </CardComponentStyles>

            <CardComponentStyles md={12} onClick={redirectToWriteBack}>
              <Col md={10} className="">
                <Row className="name-card">
                  <i className="icone bx-grid-alt mr-3">
                    <BsArrowReturnLeft className="" color="#525252" size={24} />
                  </i>{" "}
                  Configuração de Retorno
                </Row>
                <Row className="description-card mt-2">
                  Para receber o retorno de e-mails criados, realize as
                  configurações correspondentes.
                </Row>
              </Col>
              <Col md={1} className="arrow-right">
                <i className="icone bx-grid-alt">
                  <BsChevronRight size={24} color="#0F7EAA" />
                </i>
              </Col>
            </CardComponentStyles>

            {/* <CardComponentStyles md={12} onClick={redirectToEmailAlerts}>
              <Col md={10} className="">
                <Row className="name-card">
                  <i className="icone bx-grid-alt mr-3">
                    <BsBell className="" color="#525252" size={24} />
                  </i>{" "}
                  Configurar notificações para usuários
                </Row>
                <Row className="description-card mt-2">
                  Configure notificações para usuários...
                </Row>
              </Col>
              <Col md={1} className="arrow-right">
                <i className="icone bx-grid-alt">
                  <BsChevronRight size={24} color="#0F7EAA" />
                </i>
              </Col>
            </CardComponentStyles> */}
          </Row>
          <div className="image-back">
            <img src={settings} alt="Configurações" />
          </div>
        </Row>
      </InfoPageStyles>
    </>
  );
}
export default SettingsPage;
