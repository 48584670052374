/* eslint-disable no-var */
import moment from 'moment'

export function isValidEmail(value: string) {
  if (
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      value,
    )
  ) {
    return true;
  }

  return false;
}

export function isValidDate(value: string) {
  if (!value || value.length < 5)
    return false

  moment.locale('pt-BR');
  var x = moment(value, 'DD-MM-YYYY')
  return x.isValid()
}