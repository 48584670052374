import { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { BsCheck2, BsChevronRight, BsX } from "react-icons/bs";

import { ModalGenericStyles } from "../../../styles/modalsGeneric";
import { GeneralConfigurationContext } from "../GeneralConfigurationContexts/GeneralConfigurationContext";

export default function ModalEditVisibleData() {
  const {
    activeModal,
    entityType,
    setUpdateIntegration,
    setActiveModal,
    settingsProfile,
    setNewJobTitle,
    setNewDocument,
    setNewCellPhone,
    setNewDepartment,
    setNewBirthday,
  } = useContext(GeneralConfigurationContext);

  const [jobTitle, setJobTitle] = useState<boolean>(
    settingsProfile.integrateJobTitle
  );
  const [document, setDocument] = useState<boolean>(
    settingsProfile.integrateDocument
  );
  const [cellPhone, setCellPhone] = useState<boolean>(
    settingsProfile.integrateCellphone
  );
  const [department, setDepartment] = useState<boolean>(
    settingsProfile.integrateDepartment
  );
  const [birthday, setBirthday] = useState<boolean>(
    settingsProfile.integrateBirthday
  );

  useEffect(() => {
    setJobTitle(settingsProfile.integrateJobTitle);
    setDocument(settingsProfile.integrateDocument);
    setCellPhone(settingsProfile.integrateCellphone);
    setDepartment(settingsProfile.integrateDepartment);
    setBirthday(settingsProfile.integrateBirthday);
  }, []);

  return (
    <ModalGenericStyles
      show={activeModal === 5}
      onHide={() => setActiveModal(0)}
      className="ModalEditVisibleData"
    >
      <Modal.Header>
        <Modal.Title>
          <h2>
            {entityType} <BsChevronRight size={18} /> Dados a serem exibidos
            (opcional)
          </h2>
        </Modal.Title>
        <Button
          className="cursor-pointer"
          variant="white"
          onClick={() => setActiveModal(0)}
        >
          <BsX color="var(--bs-modal-color)" size={28} />
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <h4>
              Os seguintes dados das contas serão exibidos nos aplicativos
              Microsoft. Estes dados deverão estar especificados na integração
              para serem exibidos.
            </h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Check
              className="button-radio my-3"
              checked={jobTitle}
              label="Cargo"
              onChange={() => [
                setJobTitle(!jobTitle),
                setNewJobTitle(!settingsProfile.integrateJobTitle),
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Check
              className="button-radio my-3"
              checked={document}
              label="Documento"
              onChange={() => [
                setDocument(!document),
                setNewDocument(!settingsProfile.integrateDocument),
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Check
              className="button-radio my-3"
              checked={cellPhone}
              label="Celular"
              onChange={() => [
                setCellPhone(!cellPhone),
                setNewCellPhone(!settingsProfile.integrateCellphone),
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Check
              className="button-radio my-3"
              checked={department}
              label="Departamento"
              onChange={() => [
                setDepartment(!department),
                setNewDepartment(!settingsProfile.integrateDepartment),
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Check
              className="button-radio my-3"
              checked={birthday}
              label="Data de Nascimento"
              onChange={() => [
                setBirthday(!birthday),
                setNewBirthday(!settingsProfile.integrateBirthday),
              ]}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button className="cancel-operation" onClick={() => setActiveModal(0)}>
          <BsX className=" mr-2" color="#A56300" size={24} />
          Cancelar
        </Button>
        <Button
          variant="primary"
          className="continue-button"
          onClick={() => [setActiveModal(0), setUpdateIntegration(true)]}
        >
          Salvar
          <BsCheck2 color="#fff" size={24} />
        </Button>
      </Modal.Footer>
    </ModalGenericStyles>
  );
}
