import ApiInterface from "../services/ApiInterface";

export default class FileUpload {
  public async uploadTempContainer(data: any, config: any) {
    return await ApiInterface.post(
      "/UploadFile/UploadTempContainer",
      data,
      config
    )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.error(err);
      });
  }

  public async uploadFinalContainer(data: any) {
    return await ApiInterface.post("/UploadFile/UploadFinalContainer", data, {
      headers: {
        "Content-Type": "multipart/form-data, image/*",
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.error(err);
      });
  }
}
