import { useContext } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { BsCheck2, BsCheck2Circle } from "react-icons/bs";

import { ActiveModalIntegrationContext } from "../AutomaticIntegrationContexts/ActiveModalIntegrationContext";
import { ModalGAutomaticIntegrationSuccessStyles } from "./styles";

export default function ModalSuccessEditIntegration() {
  const { activeModal, setActiveModal } = useContext(
    ActiveModalIntegrationContext
  );
  return (
    <ModalGAutomaticIntegrationSuccessStyles show={activeModal === 16}>
      <Modal.Body>
        <Row className="pb-2 ml-1">
          <Col>
            <BsCheck2Circle size={110} color="#008555" />
          </Col>
        </Row>
        <Row className="my-3" onClick={() => setActiveModal(0)}>
          <Col>
            <h2>Tudo certo!</h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="mx-5">
            <h3>
              Sua alteração foi salva e a integração será feita na data
              programada.
            </h3>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          className="sucess-button"
          onClick={() => setActiveModal(0)}
        >
          Fechar janela
          <BsCheck2 size={24} />
        </Button>
      </Modal.Footer>
    </ModalGAutomaticIntegrationSuccessStyles>
  );
}
