import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import Cookies from "universal-cookie";

import PageLoader from "../../components/Common/Loader";
import ApiInterface from "../../services/ApiInterface";
import BrainzComboBox from "../Common/BrainzComboBox";

export interface HeaderTopProps {
  reload: any;
}

function HeaderTop(props: HeaderTopProps) {
  const cookies = new Cookies();
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState<any>([]);
  const [selectedOption, setSelectedOption]: any = useState({});

  const handleRenderOptions = (institutionArray: any): any => {
    if (!institutionArray) return;

    const newArr = institutionArray?.map((item: any) => {
      if (
        cookies.get(process.env.REACT_APP_BRAINZ_INSTITUTIONID!) === item.id
      ) {
        setSelectedOption({
          name: !item.active ? `(INATIVO) - ${item.name}` : item.name,
          value: item.id,
        });
      }

      return {
        name: !item.active ? `(INATIVO) - ${item.name}` : item.name,
        value: item.id,
      };
    });
    setOptions(newArr);
  };

  const handleLoadPage = () => {
    setLoading(true);
    ApiInterface.get(`/Institution/List`)
      .then((response: any) => {
        handleRenderOptions(response.data.result);
        if (
          cookies.get(process.env.REACT_APP_BRAINZ_INSTITUTIONID!) === undefined
        ) {
          cookies.set(
            process.env.REACT_APP_BRAINZ_INSTITUTIONID!,
            response.data.result[0].id
          );
        }
        setLoading(false);
      })
      .catch((error: any) => {
        console.error("Erro => ", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    handleLoadPage();
  }, []);

  return (
    <header>
      <Container className="container-header" fluid={true}>
        <Row className="align-items-center header-row mx-1 justify-content-end">
          <Col lg={3} md={4} sm={6} xs={3} className="pr-0">
            {loading ? (
              <PageLoader />
            ) : (
              <Form.Group>
                <Col md={12} className="mx-0 px-0">
                  <BrainzComboBox
                    setValue={(value) => {
                      cookies.set(
                        process.env.REACT_APP_BRAINZ_INSTITUTIONID!,
                        value
                      );
                      props.reload(value);
                    }}
                    value={selectedOption ?? options[0]}
                    idField="id"
                    valueField="name"
                    placeholder="Empresa"
                    options={options}
                    search={false}
                  />
                </Col>
              </Form.Group>
            )}
          </Col>
        </Row>
      </Container>
    </header>
  );
}

export default HeaderTop;
