import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { PaginationControl } from "react-bootstrap-pagination-control";

import { handleGetPageSize } from "../../../helpers/functions";
import { PageSize } from "./PageSize";
import { PaginationStyles } from "./styles";
import "./styles.css";

export interface PaginationProps {
  currentRecord: number;
  currentPage: number;
  pageSize: number;
  recordsOnPage: number;
  totalRecords: number;
  totalPages: number;
}

export interface BrainzPaginationProps {
  setQueryParams: React.Dispatch<React.SetStateAction<string>>;
  paging: PaginationProps;
  hidePageSize?: boolean;

}

const BrainzPagination = ({
  setQueryParams,
  paging,
  hidePageSize = false
}: BrainzPaginationProps) => {
  const { currentPage, pageSize, totalRecords } = paging;
  const [currentSizePage, setCurrentSizePage] = useState<number | undefined>(5);

  const handleOnChange = (page: number, size: number) => {
    setQueryParams(`?page=${page}&pageSize=${size}`);
  };

  useEffect(() => {
    const sizeStorage = handleGetPageSize() ?? pageSize;

    if (sizeStorage) {
      setCurrentSizePage(Number(sizeStorage));
    }
  }, []);

  return (
    <section className="customPagination">
      <PaginationStyles>
        {!hidePageSize && (
          <Col>
            <PageSize
              currentSizePage={currentSizePage}
              handleOnChange={handleOnChange}
            />
          </Col>
        )}
        <PaginationControl
          page={currentPage}
          // between={3}
          total={totalRecords}
          limit={currentSizePage ?? pageSize}
          changePage={(page) => {
            handleOnChange(page, pageSize);
          }}
          ellipsis={1}
          next
          last
        />
      </PaginationStyles>
    </section>
  );
};

export default BrainzPagination;