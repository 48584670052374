import { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { BsCheck2, BsChevronRight, BsX } from "react-icons/bs";
import Cookies from "universal-cookie";

import PageLoader from "../../../components/Common/Loader";
import ApiInterface from "../../../services/ApiInterface";
import { ModalGenericStyles } from "../../../styles/modalsGeneric";
import { GeneralConfigurationContext } from "../GeneralConfigurationContexts/GeneralConfigurationContext";
import { ModelDomain } from "./ModalFirstConfigurationProfile";

export default function ModalEditDomainProfile() {
  const {
    activeModal,
    entityType,
    setUpdateIntegration,
    setNewDomain,
    setNewDomainList,
    setActiveModal,
    settingsProfile,
    domain,
  } = useContext(GeneralConfigurationContext);

  const cookie = new Cookies();
  const institutionId = cookie.get(process.env.REACT_APP_BRAINZ_INSTITUTIONID!);
  const [domains, setDomains] = useState(["your@domain.com"]);
  const [domainSelected, setDomainSelected] = useState(
    settingsProfile.emailDomain
  );
  const [loading, setLoading] = useState(true);
  const [domainList, setDomainList] = useState<Array<ModelDomain>>([
    {
      domain: "",
      active: false,
    },
  ]);

  function loadPage() {
    ApiInterface.get("/Institution/GetDomains/" + institutionId)
      .then((response) => {
        setDomains(response.data.result);
        const domaisLocalList = response.data.result.map((item: any) => {
          return {
            domain: item,
            active: domain.includes(item),
          };
        });
        setDomainList(domaisLocalList);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Erro ao pegar domínios. Erro => ", error)

      });
  }

  function setHandleDomainsList(index: number) {
    const domainsListOld = [...domainList];
    const item = domainsListOld[index];
    item.active = !item.active;
    domainsListOld[index] = item;
    setDomainList(domainsListOld);
    setNewDomainList(domainsListOld);
  }

  function saveDomains() {
    settingsProfile.defaultEmail === 1 || settingsProfile.defaultEmail === 5
      ? setNewDomain(
        domainList
          ?.map((item: any) => {
            if (item.active === true) {
              return item.domain;
            }
          })
          .filter((x) => x !== undefined)
          .toString()
      )
      : setNewDomain(domainSelected);
    setActiveModal(0);
    setUpdateIntegration(true);
  }

  useEffect(() => {
    setDomainSelected(settingsProfile.emailDomain);
  }, []);

  useEffect(() => {
    loadPage();
  }, [activeModal]);

  return (
    <ModalGenericStyles
      show={activeModal === 7}
      onHide={() => setActiveModal(0)}
    >
      <Modal.Header>
        <Modal.Title>
          <h2>
            {entityType} <BsChevronRight size={18} /> Domínios
          </h2>
        </Modal.Title>
        <Button
          className="cursor-pointer"
          variant="white"
          onClick={() => setActiveModal(0)}
        >
          <BsX color="var(--bs-modal-color)" size={28} />
        </Button>
      </Modal.Header>
      {loading ? (
        <PageLoader></PageLoader>
      ) : (
        <Modal.Body>
          <Row>
            <Col>
              <h4>
                Este será o domínio utilizado para a criação de contas com este
                perfil.
              </h4>
            </Col>
          </Row>
          {settingsProfile.defaultEmail === 1 || settingsProfile.defaultEmail === 5
            ?
            domainList?.map((item, index) => {
              return (
                <Row key={index}>
                  <Col className="">
                    <Form.Check
                      className="button-radio my-3"
                      checked={item.active === true}
                      label={item.domain}
                      onChange={() => setHandleDomainsList(index)}
                    />
                  </Col>
                </Row>
              );
            })
            :
            domains?.map((item) => {
              return (
                <Row key={item}>
                  <Col
                    className={
                      item === domainSelected
                        ? "card-option-selected"
                        : "card-option"
                    }
                  >
                    <Form.Check
                      className="button-radio mb-3 mt-2"
                      type="radio"
                      label={item}
                      checked={item === domainSelected}
                      onChange={() => [
                        setDomainSelected(item),
                        setNewDomain(item),
                      ]}
                    />
                  </Col>
                </Row>
              );
            })}
        </Modal.Body>
      )}

      <Modal.Footer>
        <Button
          variant="primary"
          className="cancel-operation"
          onClick={() => setActiveModal(0)}
        >
          <BsX className=" mr-2" color="#A56300" size={24} />
          Cancelar
        </Button>

        <Button
          variant="primary"
          className="continue-button"
          onClick={() => saveDomains()}
        >
          Salvar
          <BsCheck2 color="#fff" size={22} />
        </Button>
      </Modal.Footer>
    </ModalGenericStyles>
  );
}
