import { createContext, type ReactNode, useState } from "react";

type ChildrenContextProps = {
  children: ReactNode;
};
type SyncDataFilterContextData = {
  searchFilterSaved: string;
  setSearchFilterSaved: React.Dispatch<React.SetStateAction<any>>;
  activeFilterSaved: any;
  setActiveFilterSaved: React.Dispatch<React.SetStateAction<any>>;
  userTypeSaved: string;
  setUserTypeSaved: React.Dispatch<React.SetStateAction<any>>;
  currentTab: string;
  setCurrentTab: React.Dispatch<React.SetStateAction<any>>;
  unitIdTeamSaved: string;
  setUnitIdTeamSaved: React.Dispatch<React.SetStateAction<any>>;
};
export const SyncDataFilterContext = createContext({} as SyncDataFilterContextData);

export default function SyncDataFilterContextProvider({
  children,
}: ChildrenContextProps) {
  const [searchFilterSaved, setSearchFilterSaved] = useState<any>();
  const [activeFilterSaved, setActiveFilterSaved] = useState<any>();
  const [userTypeSaved, setUserTypeSaved] = useState<any>();
  const [currentTab, setCurrentTab] = useState<any>();
  const [unitIdTeamSaved, setUnitIdTeamSaved] = useState<any>();
  return (
    <SyncDataFilterContext.Provider
      value={{
        searchFilterSaved,
        setSearchFilterSaved,
        activeFilterSaved,
        setActiveFilterSaved,
        userTypeSaved,
        setUserTypeSaved,
        currentTab,
        setCurrentTab,
        unitIdTeamSaved,
        setUnitIdTeamSaved
      }}
    >
      {children}
    </SyncDataFilterContext.Provider>
  );
}
