import { Button } from "react-bootstrap";
import { BsHouseDoor } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

import image from "../../assets/images/unplugged.png";
import { NotFoundPage } from "./styles";

const NotFound = () => {
  const navigate = useNavigate();
  const cookie = new Cookies();

  const institutionId = cookie.get(process.env.REACT_APP_BRAINZ_INSTITUTIONID!);

  const handleRedirectHome = () => {
    navigate(`/?id=${institutionId}`);
  };
  return (
    <NotFoundPage>
      <img src={image} alt="Página não encontrada" />
      <h3>Página não encontrada</h3>
      <span>
        Tente novamente ou clique no botão abaixo e volte para o início.
      </span>
      <Button onClick={handleRedirectHome}>
        <BsHouseDoor size={22} />
        Voltar ao Início
      </Button>
    </NotFoundPage>
  );
};

export default NotFound;
