import { type AxiosInstance } from "axios";
import React, { useState } from "react";
import SelectSearch, { type SelectSearchOption } from "react-select-search";

import ApiInterface from "../../../services/ApiInterface";
import { BrainzComboBoxStyles } from "./styles";

export interface BrainzComboBoxProps {
  setValue: React.Dispatch<React.SetStateAction<any>>;
  value: string | string[] | undefined;
  endpoint?: string;
  idField: string;
  valueField: string;
  placeholder: string;
  options?: SelectSearchOption[];
  isInvalid?: boolean;
  search?: boolean;
  interface?: AxiosInstance;
  isDisabled?: boolean;
}

export interface BrainzComboBoxItem {
  id: string;
  name: string;
}

function BrainzComboBox(props: BrainzComboBoxProps) {

  const [memoryQuery, setMemoryQuery] = useState<string>();
  const [memoryResponse, setMemoryResponse] = useState([]);

  return (
    <>
      <BrainzComboBoxStyles>
        <SelectSearch
          options={props.options ?? []}
          debounce={1000}
          getOptions={
            !props.options
              ? (query) => {
                if (query === memoryQuery)
                  return new Promise(
                    (resolve, reject) => {
                      resolve(memoryResponse);
                    }
                  );
                return new Promise((resolve, reject) => {
                  (props.interface ?? ApiInterface)
                    .get(
                      props.endpoint +
                      "?page=1&pageSize=100&search=" +
                      query
                    )
                    .then((response) => {
                      setMemoryQuery(query);
                      const result =
                        response.data.result.map(
                          (item: any) => ({
                            value: item[
                              props.idField
                            ],
                            name: item[
                              props.valueField
                            ],
                          })
                        );
                      setMemoryResponse(result);
                      resolve(result);
                    })
                    .catch(reject);
                });
              }
              : undefined
          }
          search={props.search !== false}
          onChange={(e) => props.setValue(e)}
          placeholder={props.placeholder}
          value={props.value}
          className={
            props.isInvalid === true
              ? "select-search is-invalid"
              : "select-search"
          }
          renderValue={(
            valueProps: any,
            snapshot,
            className: string
          ) => {
            const setObject = memoryResponse?.filter(
              (a) => a["value"] === props.value
            )[0];
            if (setObject !== undefined)
              valueProps["value"] = setObject["name"];
            return <input {...valueProps} className={className} />;
          }}
          disabled={props.isDisabled}
        />
      </BrainzComboBoxStyles>
    </>
  );
}

export default BrainzComboBox;
