import { Col } from "react-bootstrap";
import styled from "styled-components";

import {
  ModalGenericStyles,
  ModalGenericErrorStyles,
  ModalGenericSuccessStyles,
} from "../../../styles/modalsGeneric";

// Modal Generic Automatic Integrations
export const ModalGAutomaticIntegrationStyles = styled(ModalGenericStyles)``;

// Table Styles Generic
export const TableStylesG = styled(Col)`
  padding: 0;

  .input-table {
    border: none;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px 0px 0px 0px;
    height: 56px;
    width: 100%;
    padding-left: 2px;
  }
  .border-radius-left {
    border-top-left-radius: 10px;
  }
  .border-radius-right {
    border-top-right-radius: 10px;
  }
  .title-table {
    height: 44px;
  }
  .table-values {
    height: 56px;
  }
  th {
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #424040;
    background-color: #e4e3e3;
  }
  td {
    padding: 0px;
    margin-left: 3px;
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #525252;
  }

  .trash {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 55px;
  }

  .line-selected {
    background-color: #e9effc;
  }
  .bg-error-color {
    background-color: #fdf1de;
    color: #a56300;
  }
  .success {
    background-color: #d8ebe4;
  }
  .success-status {
    background-color: #e5f4ef;
  }
  .error-status {
    background-color: #fdf1de;
  }

  tr:hover {
    background: #fff !important;
  }
`;

// Modal ERROR Generic Automatic Integrations
export const ModalGAutomaticIntegrationErrorStyles = styled(
  ModalGenericErrorStyles
)``;

// Modal SUCCESS Generic Automatic Integrations
export const ModalGAutomaticIntegrationSuccessStyles = styled(
  ModalGenericSuccessStyles
)``;
