import { useContext } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { BsDownload, BsXCircle } from "react-icons/bs";
import Cookies from "universal-cookie";

import BrainzComboBox from "../../../components/Common/BrainzComboBox";
import CustomTooltip from "../../../components/Common/CustomTooltip";
import Search from "../../../components/Common/Input/Search";
import { SyncDataFilterContext } from "../../../contexts/SyncDataFilterContext";
import UnitApiInterface from "../../../services/UnitApiInterface";
import { FiltersStyleComponentUser } from "../../../styles/generic";

interface FiltersTeamComponentProps {
  searchFilter: string;
  setSearchFilter: React.Dispatch<React.SetStateAction<string>>;
  activeFilter: string;
  setActiveFilter: React.Dispatch<React.SetStateAction<string>>;
  unitIdFilter: string;
  setUnitIdFilter: React.Dispatch<React.SetStateAction<string>>;
  buttonClearFilters: boolean;
  ClearFilters: () => void;
  setActiveModal: React.Dispatch<React.SetStateAction<any>>;
}
export default function FiltersTeamComponent(props: FiltersTeamComponentProps) {
  const cookie = new Cookies();
  const institutionId = cookie.get(process.env.REACT_APP_BRAINZ_INSTITUTIONID!);

  const { setActiveFilterSaved, setSearchFilterSaved, setUnitIdTeamSaved, setCurrentTab } = useContext(SyncDataFilterContext)

  // console.log("filters teams => ", props.unitIdFilter, props.searchFilter, props.activeFilter)

  return (
    <FiltersStyleComponentUser className="w-100 search-filter">
      <Col md={5}>
        <Form.Group>
          <Search
            setSearchFilter={props.setSearchFilter}
            searchFilter={props.searchFilter}
            placeholder="Buscar por Tipo, Identificador ou Nome "
          />
        </Form.Group>
      </Col>
      <Col md={2}>
        <Form.Group>
          <BrainzComboBox
            setValue={(val) => props.setActiveFilter(val)}
            value={props.activeFilter}
            idField="id"
            valueField="name"
            placeholder="Status"
            options={[
              { name: "Ativo", value: "true" },
              { name: "Inativo", value: "false" },
            ]}
            search={false}
          />
        </Form.Group>
      </Col>
      <Col md={3}>
        <Form.Group>
          <BrainzComboBox
            setValue={(val) => props.setUnitIdFilter(val)}
            value={props.unitIdFilter}
            endpoint={`/Unit/${institutionId}/List`}
            idField="id"
            valueField="name"
            placeholder="Unidade"
            interface={UnitApiInterface}
          />
        </Form.Group>
      </Col>
      {props.buttonClearFilters && (
        <Col md="auto">
          <CustomTooltip
            type="action-button"
            text="Limpar filtros"
            placement="bottom"
            buttonContent={<Button className="clear-filter" onClick={() => props.ClearFilters()}>
              <BsXCircle size={24} color="#A56300" />
            </Button>}
          />
        </Col>
      )}

      <Col md="auto">
        <CustomTooltip
          type="action-button"
          text="Exportar dados"
          placement="bottom"
          buttonContent={
            <Button
              aria-label="Exportar dados"
              variant="outline-primary"
              onClick={() => {
                setUnitIdTeamSaved(props.unitIdFilter)
                setSearchFilterSaved(props.searchFilter)
                setActiveFilterSaved(props.activeFilter)
                setCurrentTab("teams")
                props.setActiveModal(55)
              }}
            >
              <BsDownload className="bi bi-x-circle" color="" size={20} />
            </Button>
          }
        />
      </Col>
    </FiltersStyleComponentUser>
  );
}
